import Element from "./index.vue";
import {generateSelectProdArgTypes} from "@/mixins/product/selectProd";
import {ControlEnum} from "@/models/Enum/ControlEnum";

export default {
  title: 'web-page/Product/PD01',
  argTypes: {
    buttonColor: {
      control: "color",
      defaultValue: null
    },
    rows: {
      control: ControlEnum.NUMBER,
      defaultValue: 1
    },
    ...generateSelectProdArgTypes()
  }
}
// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `
});

export const element = TemplatePT01.bind({});
