<template>
  <article class="content-container" :style="styles">
    <slot/>
  </article>
</template>

<script>
import themeable from "@/mixins/themeable";

export default {
  name: "ContentContainer",
  mixins: [themeable],
  computed: {
    styles() {
      return {
        background: this.backgroundSet
      }
    },
    backgroundSet() {
      let background = this.theme.backgroundImage ? `url(${this.theme.backgroundImage}) left top repeat scroll` : ""
      let color = this.theme.backgroundColor || "";
      return `${background} ${color}`
    }
  }
}
</script>

<style scoped>
/*padding 頁面最旁邊的留白*/
.content-container {
  /*padding: 0 40px;*/
}
</style>
