<template>
  <header class="main-header header__h06 test header-color" :style="styleColor">
      <a class="header__h06_logo" href="/">
        <img :src="logoURL" alt="logo">
      </a>
    <nav class="header__h06_nav-list">
      <RecursiveNavList class="header__nav-list" :items="list" />
      <MobileRecursiveNavList  class="header__h06_btn-item btn-item" :items="list" />
    </nav>
    <header-buttons class="header__h06_btn-group" />
  </header>
</template>

<script>
import RecursiveNavList from "@/components/web-page/Header/H06/RecursiveNavList";
import MobileRecursiveNavList from "@/components/web-page/Header/H06/MobileRecursiveNavList";
import HeaderButtons from "./components/HeaderButtons";
import {headerColor, testData} from "@/models/headerColorSetting";
import HeaderBaseMixin from "@/mixins/header/HeaderBaseMixin";
import HeaderButtonsMixin from "@/mixins/header/HeaderButtonsMixin";

export default {
  name: "H06",
  components: {MobileRecursiveNavList, RecursiveNavList, HeaderButtons},
  mixins: [headerColor, HeaderBaseMixin, HeaderButtonsMixin],
  props: {
    logoURL: String,
    list: {
      type: Array,
      default: () => testData
    },
    bottomLink: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.main-header {
  background-color: var(--bg-color);
}
.header__h06 {
  display: flex;
  padding: 0 48px;
  z-index: 999;
}


.header__h06::after{
  content: "";
  height: 6px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.header__h06_logo {
  display: block;
  width: 16.64%;
  min-width: 115px;
  padding: 12px 0;
}

.header__h06_logo img {
  width: 115px;
}

.header__h06_nav-list {
  width: 74.88%;
  font-size: 14px;
  flex-grow: 1;
}

.header__h06_btn-group {
  color: var(--button-color)
}

.header__h06_btn-item {
  padding: 0 10px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: inherit;
}

@media screen and (max-width: 1199px) {
  .header__h06_logo {
    width: 100%;
  }
  .main-header {
    justify-content: space-between;
    padding: 0 16px;
  }
  .header__nav-list {
    display: none;
  }
  .header__h06_nav-list {
    display: flex;
    width: auto;
    order: 1;
  }
}
</style>
