<template>
    <div class="component component-bt02" :style="style">
        <div
            v-for="button of buttonGroup"
            :key="button.buttonText"
            :class="{
                small: button.type === 'small',
                medium: button.type === 'medium',
                large: button.type === 'large',
            }"
        >
            <a
                class="bt02__btn"
                :class="{
                    'small-btn': button.type === 'small',
                    'medium-btn': button.type === 'medium',
                    'large-btn': button.type === 'large',
                }"
                :href="button.link"
                >{{ button.buttonText }}</a
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "BT02",
    props: {
        buttonGroup: Array,
        buttonColor: String,
        buttonTextColor: String,
        buttonClickColor: String,
    },
    computed: {
        style() {
            return {
                "--button-color": this.buttonColor,
                "--button-text-color": this.buttonTextColor,
                "--button-click-color": this.buttonClickColor,
            };
        },
    },
};
</script>

<style scoped lang="scss">
/* button group container */
.component-bt02 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

/* button style */
.bt02__btn {
    /* position */
    display: inline-block;
    width: fit-content;
    /* color */
    color: var(--button-text-color);
    background-color: var(--button-color);
    /* border */
    border: 4px solid #ffffff;
    border-radius: 100px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    /* margin and padding */
    padding: 12px 24px;
    margin: auto;
}

.bt02__btn:hover {
    background: var(--button-text-color);
    color: var(--button-color);
    border: 2.5px solid var(--button-color);
}

.bt02__btn:active {
    color: var(--button-click-color);
}

/* small medium large */
.small,
.medium,
.large {
    font-size: 16px;
    font-weight: 700;
}

.medium {
    font-size: 20px;
}
.large {
    font-size: 28px;
}

@media screen and (max-width: 600px) {
    .component-bt02 {
        flex-direction: column;
    }
}
</style>
