import Element from "./index.vue";
import FormRoot from "@/components/web-page/Form/form/index.vue";
import ControlInput from "@/components/web-page/Controls/Input/index.vue";
import ControlRadio from "@/components/web-page/Controls/Radio/index.vue";
import ControlSelect from "@/components/web-page/Controls/Select/index.vue";

export default {
  components: {ControlSelect, ControlRadio, ControlInput, FormRoot},
  title: 'web-page/Controls/Checkbox',
  argTypes: {
    title: {
      control: 'text',
      defaultValue: "選項標題"
    },
    required: {
      control: "boolean",
      defaultValue: false
    },
    items: {
      control: 'array',
      defaultValue:  [
        {name: '多選項一', value: 1},
        {name: '多選項二', value: 2},
        {name: '多選項三', value: 3}
      ]
    }
  }
}

const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element, FormRoot, ControlInput, ControlRadio, ControlSelect},
  data() {
    return {
      datas: {}
    }
  },
  template: `
    <div>
    <Element v-bind="$props"/>

    </div>
  `
});

export const element1 = Template.bind({});
