<template>
  <div class="component component-pd02" >
    <hooper ref="hooper" :settings="settings" style="height:auto;">
      <slide v-for="item of items" :key="item.key">
        <component
            class="pd02-item"
            :is="selectProdComponent"
            :key="item.id"
            :style="componentColor"
            v-bind="dataToComponentProps(item)"
        />
      </slide>
    </hooper>

    <base-pagination class="paging" :paging="paging"  @getPaging="getPaging" :style="componentColor"/>

  </div>
</template>

<script>
import {
  Hooper,
  Slide,
} from 'hooper';
import selectProd from "@/mixins/product/selectProd";
import autoColor from "@/mixins/autoColor";
import BasePagination from "@/components/base/BasePagination.vue";

export default {
  name: "PD02",
  mixins: [selectProd, autoColor],
  components: {BasePagination, Hooper, Slide},
  props: {
    itemNum: {
      type: Number,
      default: 3
    },
    arrowColor: String,
    buttonColor: String,
  },
  computed: {
    settings() {
      return {
        wheelControl: false,
        itemsToShow: 1,
        infiniteScroll: false,
        playSpeed: 3000,
        breakpoints: {
          1024: {
            itemsToShow: this.itemNum ? parseInt(this.itemNum) : 4,
            itemsToSlide: this.itemNum ? parseInt(this.itemNum) : 4,
          },
          768: {
            itemsToShow: 2,
            itemsToSlide: 2
          },
          0: {
            itemsToShow: 1,
            itemsToSlide: 1
          }
        }
      }
    },
  },
  watch: {
    async itemNum(val) {
      await this.getDate(val, this.paging.currentPage);
    }
  },
  methods: {
    async getPaging(page) {
      await this.getDate(this.itemNum, page);
    }
  },
  async created() {
    await this.getDate(this.itemNum);
  }
}
</script>

<style scoped>
:deep(.hooper-slide) {
  height: auto;
}
.paging {
  display: none;
}
.pd02-item {
  width: 90%;
  margin: auto;
  height: 100%;
  padding: 25px 0 12px;
}

@media screen and (min-width: 768px) {
  .component-pd02 {
    margin: auto;
    max-width: 1066px;
  }
}


@media screen and (min-width: 1200px) {
  .paging {
    display: flex;
  }
  .component-pd02 {
    max-width: 1066px;
  }

  .component-pd02 .pd02-item {
    transition: transform .3s ease;
  }
  .component-pd02 .pd02-item:hover {
    transform: translateY(-25px);
  }
}

</style>
