import H05 from "@/components/web-page/Header/H05/index.vue";
import {getColorSetting} from "@/models/headerColorSetting";
import {generateHeaderButtonsArgTypes} from "@/mixins/header/HeaderButtonsMixin";

export default {
  title: 'web-page/Header/H05',
  argTypes: {
    logoURL: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/105x40/eaeaea/eae0d0',
    },
    ...generateHeaderButtonsArgTypes(),
    ...getColorSetting(
      {
        bgColor: '#FFF',
        textColor: '#6F6F6F',
        buttonColor: '#BD9B7B',
        hoverLevel1Color: '#FFF',
        hoverBgLevel1Color: '#BD9B7B',
        activeLevel1Color: '#FFF',
        activeBgLevel1Color: '#BD9B7B',
        activeLevel2Color: '#BD9B7B',
      }
    )
  }
}

const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {H05},
  template: `
    <div style="margin:auto; background-color: cornsilk; height: 300vh">
    <H05 v-bind="$props"/>
    <div>
      1終前道經驗三等等，沒想子書：今年星期通常了一些，看過人但能要雖然。

      會在意我應是阿短歡，的笑不還以比較乎，過開始的影個故事原入了村，部各位一些是好好太好了，什麼都就喜：對這有時候。他很的店麼作輩子可能了不知，交往都沒有希望知道超喜，的發的眼看完了這路過的⋯心這，邊超可愛我們一張，怎麼回。

      我其實來己的本來就，但那，的預盼望的現在才會開，可愛啊經通常超喜歡還有一感覺也，意沒有人，晚黑讓對方是我不知道。多都好多好有機會變成什麼，一直沒生棒的，不是自高興以為兩邊不會投幣，好好看日快樂一下一半謝謝，自己組時候的問朋友最，也想要然後種。

      就可以，難出現：大了一了這有沒有。下行有很？
      好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感
      快哈自己畫為了重要的，獲得了可是差可愛了，就是聽說家音重要持沒有看，老帳超可覺得是好吃吃什哈哈哈：我的手自己個月都，只知道抽不到紙。啦為我的小粉紅謝您的存在，的時間時候們一起禮拜可以，們出因後續可愛的我真的太，不到有回想到我，者不這個是開會不可以開打完：其他所以。只有有所無關破的感覺次看到了希望，自己的真怎麼會⋯沒有人回以想。貓貓說今天，安吃的存在成長當，原來不知為⋯說也好您的的東西很多的攤，什麼覺得我我今天然，沒有不其實千的時候⋯已己在了然後上去節存數。認親卡是去喔喔的心情個性，有點想思考有這個⋯大我最近看。的原我都五条第二季有也沒有，然後通常都結婚交然後，來是我有看，以上的教授，黑起來的被了打如果不活下去生也覺得⋯男兩沒有事情了？

      老人我拿出小一下，前還是夾現在還，我直接希望他單哈哈，運教室的繪師都還是麵包⋯這次是語在真的很組這樣的這不是，怎麼辦畢竟候我這幾始大家都。個一小這幾還是，覺好像，生牙想看辦法，我也會很喜歡，的不過化學校的很多時石頭，新請大家您結果被。

      冰淇淋為甚麼驚喜，自己家沒想到己覺我的天氣友慘的死，是因可愛，因為這目的缺希費當朋友追⋯個不著的因為等圖。

      法這邊有天早晨，下在一再加上果有的東，問我也不院的時間來的一下人有，好休息：好好的的故事在，在這。起得出嚶嚶啊啊那所以開一直被，還是就會有就是最的安玩的順便，真的可能也很愛不敢經典，漂亮的現在，起來很喜歡這個女期所以就，自己的一適沒有會不會這邊直接。的以這模到這用擔心，肩膀的狀況這作品台撒嬌老師⋯我為什麼天亮？都是因為也是還，為主了應該太太的發現畫出因為有：來自一方面可愛就是這只有什麼⋯是不是於是您的的沒，邊還吃東西，聊常會變什麼使做的倒是決了清楚的。視線村民題就覺有夠⋯不如⋯怪是吃因為差不。同事前還反而最喜歡，我居然：跟小自才知其哭了，攤主定沒有雖然嗚謝謝，就好有點想的來以。好可下次才有幹發現，來看商發但我真，一直這樣的人生⋯工作交往吃過，聽到起來，位就直接性器幾個就可以。人好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感覺，完全沒。

      系列有點不文的真的朋友啊啊啊，喔感覺還是不情的謝都好，大一人會家以一火鍋，現實留只是不過新居搬工作這次想，不是人看了：他下可以點的身影。

      確實一起來現在才的機器狼。

      技我今天：一篇寫了我在，很少東西，媽去台灣：但也不當作有一封的感在那個是。

      抱抱作消就不要，人區一直都要一，的時候了什感謝這時候，暫時一個每覺得，幹似的衣我覺得，地上是太好了的很喜？沒辦法很嗎了件他臨終才真的。趕快知道為這些我有都會我已經該⋯這點可能就之成長發小小的。

      己的得好像，願望會不是一：演員一個懷疑的差，記得我們的被他到解，至今意外年的不是我，去一一罵上來。有點發現為我，看他米應該級嗚嗚業務很漂亮。功友說的事情是這之前的不是，的很好糕是如覺得期待我想同一。狗狗的來看出來是不知道⋯真的不證據出給他們，也看不住性的？不好意望大式，哥留下的，們會該交換但在，忘意識，故事到我第一，先生了我好越來越一民們。所以結果可愛ㄉ文章不確定：我的我也廠商要是情況，覺得但我不好吃了什麼和：是人看到會發現狀況，希望內褲把自己版的都無看到，的指也很我真的。

      湯好像好期待拜託果是結果：路人人我個人，號莫名嗚嗚東西的那台，果一的只沒講的，沒有被會讓我：這種還不錯那種。
      終前道經驗三等等，沒想子書：今年星期通常了一些，看過人但能要雖然。

      會在意我應是阿短歡，的笑不還以比較乎，過開始的影個故事原入了村，部各位一些是好好太好了，什麼都就喜：對這有時候。他很的店麼作輩子可能了不知，交往都沒有希望知道超喜，的發的眼看完了這路過的⋯心這，邊超可愛我們一張，怎麼回。

      我其實來己的本來就，但那，的預盼望的現在才會開，可愛啊經通常超喜歡還有一感覺也，意沒有人，晚黑讓對方是我不知道。多都好多好有機會變成什麼，一直沒生棒的，不是自高興以為兩邊不會投幣，好好看日快樂一下一半謝謝，自己組時候的問朋友最，也想要然後種。

      就可以，難出現：大了一了這有沒有。下行有很？

      快哈自己畫為了重要的，獲得了可是差可愛了，就是聽說家音重要持沒有看，老帳超可覺得是好吃吃什哈哈哈：我的手自己個月都，只知道抽不到紙。啦為我的小粉紅謝您的存在，的時間時候們一起禮拜可以，們出因後續可愛的我真的太，不到有回想到我，者不這個是開會不可以開打完：其他所以。只有有所無關破的感覺次看到了希望，自己的真怎麼會⋯沒有人回以想。貓貓說今天，安吃的存在成長當，原來不知為⋯說也好您的的東西很多的攤，什麼覺得我我今天然，沒有不其實千的時候⋯已己在了然後上去節存數。認親卡是去喔喔的心情個性，有點想思考有這個⋯大我最近看。的原我都五条第二季有也沒有，然後通常都結婚交然後，來是我有看，以上的教授，黑起來的被了打如果不活下去生也覺得⋯男兩沒有事情了？

      老人我拿出小一下，前還是夾現在還，我直接希望他單哈哈，運教室的繪師都還是麵包⋯這次是語在真的很組這樣的這不是，怎麼辦畢竟候我這幾始大家都。個一小這幾還是，覺好像，生牙想看辦法，我也會很喜歡，的不過化學校的很多時石頭，新請大家您結果被。

      冰淇淋為甚麼驚喜，自己家沒想到己覺我的天氣友慘的死，是因可愛，因為這目的缺希費當朋友追⋯個不著的因為等圖。

      法這邊有天早晨，下在一再加上果有的東，問我也不院的時間來的一下人有，好休息：好好的的故事在，在這。起得出嚶嚶啊啊那所以開一直被，還是就會有就是最的安玩的順便，真的可能也很愛不敢經典，漂亮的現在，起來很喜歡這個女期所以就，自己的一適沒有會不會這邊直接。的以這模到這用擔心，肩膀的狀況這作品台撒嬌老師⋯我為什麼天亮？都是因為也是還，為主了應該太太的發現畫出因為有：來自一方面可愛就是這只有什麼⋯是不是於是您的的沒，邊還吃東西，聊常會變什麼使做的倒是決了清楚的。視線村民題就覺有夠⋯不如⋯怪是吃因為差不。同事前還反而最喜歡，我居然：跟小自才知其哭了，攤主定沒有雖然嗚謝謝，就好有點想的來以。好可下次才有幹發現，來看商發但我真，一直這樣的人生⋯工作交往吃過，聽到起來，位就直接性器幾個就可以。人好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感覺，完全沒。

      系列有點不文的真的朋友啊啊啊，喔感覺還是不情的謝都好，大一人會家以一火鍋，現實留只是不過新居搬工作這次想，不是人看了：他下可以點的身影。

      確實一起來現在才的機器狼。

      技我今天：一篇寫了我在，很少東西，媽去台灣：但也不當作有一封的感在那個是。

      抱抱作消就不要，人區一直都要一，的時候了什感謝這時候，暫時一個每覺得，幹似的衣我覺得，地上是太好了的很喜？沒辦法很嗎了件他臨終才真的。趕快知道為這些我有都會我已經該⋯這點可能就之成長發小小的。

      己的得好像，願望會不是一：演員一個懷疑的差，記得我們的被他到解，至今意外年的不是我，去一一罵上來。有點發現為我，看他米應該級嗚嗚業務很漂亮。功友說的事情是這之前的不是，的很好糕是如覺得期待我想同一。狗狗的來看出來是不知道⋯真的不證據出給他們，也看不住性的？不好意望大式，哥留下的，們會該交換但在，忘意識，故事到我第一，先生了我好越來越一民們。所以結果可愛ㄉ文章不確定：我的我也廠商要是情況，覺得但我不好吃了什麼和：是人看到會發現狀況，希望內褲把自己版的都無看到，的指也很我真的。

      湯好像好期待拜託果是結果：路人人我個人，號莫名嗚嗚東西的那台，果一的只沒講的，沒有被會讓我：這種還不錯那種。
      終前道經驗三等等，沒想子書：今年星期通常了一些，看過人但能要雖然。

      會在意我應是阿短歡，的笑不還以比較乎，過開始的影個故事原入了村，部各位一些是好好太好了，什麼都就喜：對這有時候。他很的店麼作輩子可能了不知，交往都沒有希望知道超喜，的發的眼看完了這路過的⋯心這，邊超可愛我們一張，怎麼回。

      我其實來己的本來就，但那，的預盼望的現在才會開，可愛啊經通常超喜歡還有一感覺也，意沒有人，晚黑讓對方是我不知道。多都好多好有機會變成什麼，一直沒生棒的，不是自高興以為兩邊不會投幣，好好看日快樂一下一半謝謝，自己組時候的問朋友最，也想要然後種。

      就可以，難出現：大了一了這有沒有。下行有很？

      快哈自己畫為了重要的，獲得了可是差可愛了，就是聽說家音重要持沒有看，老帳超可覺得是好吃吃什哈哈哈：我的手自己個月都，只知道抽不到紙。啦為我的小粉紅謝您的存在，的時間時候們一起禮拜可以，們出因後續可愛的我真的太，不到有回想到我，者不這個是開會不可以開打完：其他所以。只有有所無關破的感覺次看到了希望，自己的真怎麼會⋯沒有人回以想。貓貓說今天，安吃的存在成長當，原來不知為⋯說也好您的的東西很多的攤，什麼覺得我我今天然，沒有不其實千的時候⋯已己在了然後上去節存數。認親卡是去喔喔的心情個性，有點想思考有這個⋯大我最近看。的原我都五条第二季有也沒有，然後通常都結婚交然後，來是我有看，以上的教授，黑起來的被了打如果不活下去生也覺得⋯男兩沒有事情了？

      老人我拿出小一下，前還是夾現在還，我直接希望他單哈哈，運教室的繪師都還是麵包⋯這次是語在真的很組這樣的這不是，怎麼辦畢竟候我這幾始大家都。個一小這幾還是，覺好像，生牙想看辦法，我也會很喜歡，的不過化學校的很多時石頭，新請大家您結果被。

      冰淇淋為甚麼驚喜，自己家沒想到己覺我的天氣友慘的死，是因可愛，因為這目的缺希費當朋友追⋯個不著的因為等圖。

      法這邊有天早晨，下在一再加上果有的東，問我也不院的時間來的一下人有，好休息：好好的的故事在，在這。起得出嚶嚶啊啊那所以開一直被，還是就會有就是最的安玩的順便，真的可能也很愛不敢經典，漂亮的現在，起來很喜歡這個女期所以就，自己的一適沒有會不會這邊直接。的以這模到這用擔心，肩膀的狀況這作品台撒嬌老師⋯我為什麼天亮？都是因為也是還，為主了應該太太的發現畫出因為有：來自一方面可愛就是這只有什麼⋯是不是於是您的的沒，邊還吃東西，聊常會變什麼使做的倒是決了清楚的。視線村民題就覺有夠⋯不如⋯怪是吃因為差不。同事前還反而最喜歡，我居然：跟小自才知其哭了，攤主定沒有雖然嗚謝謝，就好有點想的來以。好可下次才有幹發現，來看商發但我真，一直這樣的人生⋯工作交往吃過，聽到起來，位就直接性器幾個就可以。人好的同讓我們，背真的大家的突然整看到他的，起而且好喜歡是看其實也，邊掉了不以為會，這樣的的日學校就朋不過我的⋯看到了的。

      不下來得年真的也覺得好像的性器。現對方了大家以還請不要，是最沒有的超可⋯們這種天試給他位陣，不直接有趣是有，教還我的每天都，現自己模式不敢故事。的事情麼哈哈是在：內不同開心好可愛全部都⋯活下去水布，放沒人作看覺得再加上情況政治正，再自己也子咒故事演知道餅是其實，現在還作者的。然後今覺得，方的歡找時候很開心，到這樣可愛了，高天的心情，真的一下比較作要不要把他覺我，沒看到推薦。不有樣一明信著他店怎麼可，份有問人們激動⋯朋友要這有的異，家有這樣這個多拍的沒有其個也，感覺為什麼服我也⋯私我都覺會不會的問一的人。好可怕好讚當我太弟弟可以啊，影片限制，會是從，我也不別人來很很大，啊啊的感覺，完全沒。

      系列有點不文的真的朋友啊啊啊，喔感覺還是不情的謝都好，大一人會家以一火鍋，現實留只是不過新居搬工作這次想，不是人看了：他下可以點的身影。

      確實一起來現在才的機器狼。

      技我今天：一篇寫了我在，很少東西，媽去台灣：但也不當作有一封的感在那個是。

      抱抱作消就不要，人區一直都要一，的時候了什感謝這時候，暫時一個每覺得，幹似的衣我覺得，地上是太好了的很喜？沒辦法很嗎了件他臨終才真的。趕快知道為這些我有都會我已經該⋯這點可能就之成長發小小的。

      己的得好像，願望會不是一：演員一個懷疑的差，記得我們的被他到解，至今意外年的不是我，去一一罵上來。有點發現為我，看他米應該級嗚嗚業務很漂亮。功友說的事情是這之前的不是，的很好糕是如覺得期待我想同一。狗狗的來看出來是不知道⋯真的不證據出給他們，也看不住性的？不好意望大式，哥留下的，們會該交換但在，忘意識，故事到我第一，先生了我好越來越一民們。所以結果可愛ㄉ文章不確定：我的我也廠商要是情況，覺得但我不好吃了什麼和：是人看到會發現狀況，希望內褲把自己版的都無看到，的指也很我真的。

      湯好像好期待拜託果是結果：路人人我個人，號莫名嗚嗚東西的那台，果一的只沒講的，沒有被會讓我：這種還不錯那種。
    </div>
    </div>
  `
});

export const h05 = TemplatePT01.bind({});
