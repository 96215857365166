<template>
  <article class="component component-b01">
    <div>
      <div class="component-b01__img-warp">
        <img v-if="imageUrl" :src="imageUrl" alt="imageUrl">
        <img v-else src="https://fakeimg.pl/324x196/" alt="fakeimg">
      </div>
    </div>

    <h2 class="component-b01__title">{{ title }}</h2>
    <div class="component-b01__main">
      <p class="component-b01__content" :style="{'--content-color': contentColor}">
        {{ description }}
      </p>
      <span class="component-b01__more" :style="{'--more-color': clickTextColor}">
        <a :href="'/blog/article/'+ seoURL">
          {{ text }}
         <i class="icon-arrow-right"></i>
        </a>
      </span>
    </div>

  </article>
</template>

<script>

import globalInject from "@/mixins/globalInject";

export default {
  name: "B01",
  mixins: [globalInject],
  props: {
    seoURL: String,
    backgroundColor: String,
    titleColor: String,
    contentColor: String,
    clickTextColor: String,
    text: String,
  },
  data() {
    return {
      title: null,
      imageUrl: null,
      description: null,
    }
  },
  watch: {
    seoURL() {
      this.getData();
    }
  },
  async created() {
    this.getData();
  },
  methods: {
    getDescription(meta) {
      return meta.map(v => v.name).find("description");
    },
    getData() {
      fetch(`${this.apiURL}blog/article/${this.seoURL}?` + new URLSearchParams({
        language: this.langTag,
        ref_url: this.refURL,
      })).then(async res => {
        const data = await res.json();
        let articleData = data.result;
        let seo = articleData.articleSeoSetting.articleSeoData;

        let listd = seo.meta.find(v => v.name === 'description');
        this.title = articleData.articleSetting.title;
        this.imageUrl = articleData.articleSetting.imageUrl;


        this.description = listd ? listd.content : '';
      });
    }
  }
}
</script>

<style scoped>
.component-b01 {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 340px;
  min-height: 400px;
  padding: 8px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  background-color: #FFF;
}

.component-b01__img-warp {
  aspect-ratio: 1;
  margin-bottom: 24px;
}

.component-b01__img-warp img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.component-b01__title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
}

.component-b01__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.component-b01__content {
  --content-color: #6F6F6F;
  color: var(--content-color);
  word-break: break-all;

  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4; /*行數*/
  -webkit-box-orient: vertical;
  white-space: normal;

  margin-bottom: 16px;
}

.component-b01__more {
  --more-color: #1F286F;

  margin-top: auto;
  font-weight: 700;
  font-size: 1rem;
  color: var(--more-color);
}

.component-b01__more [class^="icon"] {
  font-size: 12px;
}

@media screen and (max-width: 415px) {
  .component-b01 {
    width: 100%;
  }
}

</style>
