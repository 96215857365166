<template>
  <div class="component-b01-1" :style="componentColor">
    <base-hooper hooperWidth="80%" :items="items" #default="item">
      <article class="component component-b01">
        <div>
          <div class="component-b01__img-warp">
            <img v-if="item.imageUrl" :src="item.imageUrl" alt="imageUrl">
            <img v-else src="https://fakeimg.pl/324x196/" alt="fakeimg">
          </div>
        </div>

        <h2 class="component-b01__title">
          {{ item.articleTitle }}
        </h2>
        <div class="component-b01__main">
          <p class="component-b01__content">
            {{ getDescription(item.articleSeoData) }}
          </p>
          <span class="component-b01__more">
            <a :href="'/blog/article/'+item.articleSeoUrl">
              {{ text }}
             <i class="icon-arrow-right"></i>
            </a>
          </span>
        </div>
      </article>
    </base-hooper>
  </div>
</template>

<script>
import globalInject from "@/mixins/globalInject";
import BaseHooper from "@/components/base/hooper/BaseHooper";
import autoColor from "@/mixins/autoColor";

export default {
  name: "B01_1",
  components: {BaseHooper},
  mixins: [globalInject, autoColor],
  props: {
    classId: String,
    backgroundColor: String,
    titleColor: String,
    contentColor: String,
    clickTextColor: String,
    arrowColor: String,
    arrowIconColor: String,
    text: String,
  },
  async created() {
    this.getData();
  },
  data() {
    return {
      items: []
    }
  },
  watch: {
    classId() {
      this.getData();
    }
  },
  methods: {
    getDescription(seo) {
      if (!seo) return;
      let findIndex = seo.meta.map(v => v.name).indexOf("description");
      return seo.meta[findIndex].content
    },
    getData() {
      fetch(`${this.apiURL}blog/article/list?` + new URLSearchParams({
        class_id: this.classId,
        language: this.langTag,
        ref_url: this.refURL,
        current_page: 1,
        per_page: 10
      })).then(async res => {
        const data = await res.json();
        let articleData = data.result;
        this.items = articleData.data;
        // let seo = articleData.articleSeoData;
        // this.title = articleData.articleSetting.title;
        // this.imageUrl = articleData.articleSetting.imageUrl;
        // this.description = seo.meta.map(v => v.name).find("description");
      });
    }
  }
}
</script>

<style scoped>

.component-b01-1 {
  --backgroundColor: #FFF;
  --titleColor: #000;
  --contentColor: #000;
  --clickTextColor: #000;
  --arrowColor: #000;
  --arrowIconColor: #FFF;

  position: relative;
  padding: 8px 0;
}

.component-b01 {
  position: relative;
  z-index: 2;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 340px;
  height: 100%;
  padding: 8px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  background-color: var(--backgroundColor);
}

.component-b01__img-warp {
  aspect-ratio: 1;
  margin-bottom: 24px;
}

.component-b01__img-warp img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.component-b01__title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
}

.component-b01__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.component-b01__content {
  --content-color: #6F6F6F;
  color: var(--contentColor);
  word-break: break-all;

  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4; /*行數*/
  -webkit-box-orient: vertical;
  white-space: normal;

  margin-bottom: 16px;
}

.component-b01__more {
  margin-top: auto;
  font-weight: 700;
  font-size: 1rem;
  color: var(--clickTextColor);
}

.component-b01__more [class^="icon"] {
  font-size: 12px;
}

@media screen and (max-width: 415px) {
  .component-b01 {
    width: 100%;
  }
}


</style>
