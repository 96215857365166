<template>
  <div class="mobile-list">
    <button class="nav-list-mobile" @click="onClick">
      <i class="icon-bars"></i>
    </button>
    <div class="nav-mask" :class="{'open': count}">
      <div class="nav-mask-bg">
        <span class="nav-mask-bg__close" @click="onClick">
          <i class="icon-close"></i>
        </span>
        <ul class="mobile-nav-list">
          <template v-for="(innerItem, index) of items">
            <MobileRecursiveNavItem :key="index"
                                    :item="innerItem"
                                    :is-root-item="true"
                                    :is-root-active="isActive"
                                    :active-instance="active"
                                    @active="onActive"
            />
          </template>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import MobileRecursiveNavItem from "./MobileRecursiveNavItem";
import {mobileActive} from "@/models/headerColorSetting";
import {mixin as disabledBodyScroll} from "@/mixins/headerDisabledBodyScroll";

export default {
  name: "MobileRecursiveNavList",
  components: {MobileRecursiveNavItem},
  mixins: [mobileActive, disabledBodyScroll],
  props: {
    items: {type: Array, default: () => []}
  },
  watch: {
    count(bool) {
      // this.disabledBodyScroll(bool);
      if (bool === false) {
        this.active = null;
      }
    }
  },
  data() {
    return {
      count: 0
    }
  },
  methods: {
    onClick() {
      // if (this.hasChildren && !this.isActive) {
      //   this.onActiveTrigger();
      // } else {
      //   window.location = url;
      //   this.onActiveTrigger();
      // }
      this.count = !this.count;
    }
  },
}
</script>

<style src="./mobile.css" scoped></style>
