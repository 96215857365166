<template>
  <div class="header-operate">
    <a v-if="activeURL" :href="activeURL" class="operate__action-button">{{ activeText }}</a>
    <a href="" class="operate-button">
      <i class="icon-user"></i>
    </a>
    <a href="" class="operate-button">
      <i class="icon-shopping-cart"></i>
    </a>
    <button class="mobile-button" @click="onActive">
      <i class="icon-bars"  v-if="!mobileActive"></i>
      <i class="icon-close" v-else></i>
    </button>
    <slot v-if="mobileActive" name="other"/>
  </div>
</template>

<script>
import {operateProps} from "@/models/headerColorSetting";
import {mixin as disabledBodyScroll} from "@/mixins/headerDisabledBodyScroll";

export default {
  name: "HeaderOperate",
  mixins: [operateProps, disabledBodyScroll],
  data() {
    return {
      mobileActive: false
    }
  },
  methods: {
    onActive() {
      this.mobileActive = !this.mobileActive;
      // this.disabledBodyScroll(this.mobileActive)
    }
  }
}
</script>

<style scoped>
.header-operate {
  color: var(--button-color);
  display: flex;
  gap: 15px;
  align-items: center;
}

.header-operate .operate__action-button {
  font-weight: 700;
  transition: ease .2s;
  color: var(--button-text-color);
  background-color: var(--button-color);
  display: inline-block;
  border-radius: 100px;
  padding: 2px 8px;
}

.header-operate .operate__action-button:active {
  opacity: .7;
}

.header-operate .operate-button {
  font-size: 1.5rem;
  //display: none;
}

.mobile-button {
  font-size: 1.5rem;
}

@media screen and (min-width: 1200px) {
  .mobile-button {
    display: none;
  }
}

</style>
