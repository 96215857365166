<template>
  <div class="component component-t03" :style="cssProps">
    <h2 class="t03__title">
      <base-text block-name="title" content-name="title" color-name="titleColor" v-bind="$props"/>
      <small>
        <base-text block-name="subTitle" content-name="subTitle" color-name="subTitleColor" v-bind="$props"/>
      </small>
    </h2>
  </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import {generateTextProps} from "@/components/base/javaScript/baseTest";

export default {
  name: "T03",
  components: {BaseText},
  props: {
    //------標題------
    title: String,
    titleColor: String,
    ...generateTextProps('title', {
      color: 'titleColor'
    }),
    //------副標題------
    subTitle: String,
    subTitleColor: String,
    ...generateTextProps('subTitle', {
      color: 'subTitleColor'
    }),
    //------位置------
    position: String
  },
  computed: {
    cssProps() {
      return {
        '--titleColor': this.titleColor,
        '--subTitleColor': this.subTitleColor,
        '--position': this.position
      }
    }
  }
}
</script>

<style scoped>
.component-t03 {
  --titleColor: #BD9B7B;
  --subTitleColor: #6F6F6F;
  --position: left;
  font-family: 'Arial', serif;
}

.t03__title {
  text-align: var(--position);
  margin: 48px 48px 0;
}

.t03__title > span {
  font-weight: 700;
  line-height: 140%;
  display: block;
}

.t03__title > small {
  font-weight: 400;
}

@media screen and (max-width: 414px) {
    .t03__title{
        margin: 24px 24px 0;
    }
}
</style>
