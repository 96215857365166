export default {
  name: 'layout',
  props: {
    gap: null
  },
  provide() {
    return {
      gridName: this.$options.name
    }
  }
}
