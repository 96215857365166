<template>
  <div class="component component-form01 form-group">
    <label class="form-group-label" :class="{'error': invalid}">
      <span class="label-title">
        <span v-if="required">*</span>
        {{ title }}
      </span>
      <span class="label-content">
        <textarea :disabled="false"
                  :placeholder="placeholder"
                  :name="keyName"
                  :rows="rows"
                  @blur="checkValue"
                  v-model="syncValue">
        </textarea>
        <span class="label-error">此為必填</span>
      </span>
    </label>
  </div>
</template>

<script>
import FormField from "@/components/functional/FormField";
import "@/assets/css/form.css";
import {controlType} from "@/models/controlType";

export default {
  name: "ControlTextarea",
  mixins: [FormField],
  data() {
    return {
      controlType: controlType.TEXTAREA
    }
  },
  props: {
    rows: {
      type: Number,
      default: 0
    }
  },
  computed: {
    syncValue: {
      set(val) {
        this.setValue(val, controlType.TEXTAREA);
      },
      get() {
        return this.value;
      }
    }
  }
}
</script>

<style scoped>
.label-content textarea {
  padding: 8px;
  background: #EEEEEE;
  border-radius: 8px;
  width: 100%;
}
</style>
