<template>
    <div class="component component-t07_1" :style="style">
        <!-- left-->
        <div class="t07_1__left">
            <h2 v-if="titleContent !== ''" class="t07_1__titleContainer">
                <base-text block-name="title" v-bind="$props" />
            </h2>
            <hr  v-if="titleContent !== ''" class="t07_1__line" />
            <div  v-if="subTitleContent !== ''" class="t07_1__subTitleContainer">
                <base-text block-name="subTitle" v-bind="$props" />
            </div>
        </div>
        <!-- right -->
        <div class="t07_1__right">
            <div class="t07_1__content-wrapper">
                <!-- img-->
                <div v-if="imgUrl" class="t07_1__img-wrapper">
                    <img :src="imgUrl" alt="icon" />
                </div>
                <!-- content-->
                <p v-if="contentContent !== ''" class="t07_1__contentContainer">
                <base-text block-name="content" v-bind="$props" />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "Text07_1",
    components: {
        BaseText,
    },
    props: {
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        ...generateTextProps("content"),
        // line color
        verticalLineColor: String,
        horizontalLineColor: String,
        // line width
        verticalLineWidth: Number,
        horizontalLineWidth: Number,
        // img url
        imgUrl: String,
    },
    computed: {
        style() {
            return {
                "--vertical-line-color": this.verticalLineColor,
                "--horizontal-line-color": this.horizontalLineColor,
                "--vertical-line-width": `${this.verticalLineWidth}px`,
                "--horizontal-line-width": `${this.horizontalLineWidth}px`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-t07_1 {
    max-width: 1360px;
    display: flex;
    justify-content: center;
    margin: 5% auto;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.t07_1__left,
.t07_1__right {
    width: 50%;
}

/* left */
.t07_1__left {
    border-right: var(--vertical-line-width) solid var(--vertical-line-color);
    margin-left: 50px;
}

.t07_1__titleContainer {
    font-weight: 700;
}

.t07_1__line {
    height: var(--horizontal-line-width);
    width: 48px;
    background-color: var(--horizontal-line-color);
    border: none;
    border-radius: 5px;
    margin: 24px 0;
}

.t07_1__subTitleContainer {
    font-weight: 400;
}

/* right */
.t07_1__right {
    display: flex;
    justify-content: center;
}

.t07_1__content-wrapper{
    display: flex;
    max-width: 430px;
}

.t07_1__img-wrapper {
    width: 40px;
    height: 40px;
}

.t07_1__contentContainer {
    max-width: 354px;
    text-align: justify;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 10px;
}

@media screen and (max-width: 900px) {
    .t07_1__contentContainer{
        max-width: 205px;
    }
}

@media screen and (max-width: 600px) {
    .component-t07_1 {
        flex-direction: column;
        align-items: center;
    }

    .t07_1__left,
    .t07_1__right {
        width: 80%;
        margin-left: 20px;
        margin-right: 20px;
    }

    .t07_1__left {
        border: none;
        margin-bottom: 48px;
    }

    .t07_1__right {
        justify-content: space-between;
        padding: 0;
    }

    .t07_1__contentContainer {
        max-width: 257px;
    }
}

@media screen and (max-width: 400px){
    .t07_1__contentContainer{
        max-width: 200px;
    }
}

@media screen and (max-width: 325px){
    .t07_1__contentContainer{
        max-width: 180px;
    }
}
</style>
