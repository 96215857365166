import {baseFormField} from "@/components/functional/key";

export default {
  name: "Form",
  provide() {
    return {
      [baseFormField]: this
    }
  },
  data() {
    return {
      formData: {},
      dataCheck: {}
    }
  },
  methods: {
    setObject(key, value) {
      let oldData = this.formData[key] ? this.formData[key] : {};
      this.formData = {...this.formData, [key]: {...oldData, ...value}};
    },
    //接收Field驗證是否有通過
    checkFieldInvalid(key, value) {
      this.dataCheck = {...this.dataCheck, [key]: value}
    },
    submit() {
      this.$emit('submit', 'QAQa');
      let post = new FormData();
      for (let key in this.value) {
        post.append(key, this.value[key].value);
      }


      console.log('submit', post);
    }
  }

}
