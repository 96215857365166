import PT16 from "@/components/web-page/PictureText/PT16/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/PictureText/PT16",
    argTypes: {
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
        },
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "大標題",
            sizeDefault: "36px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "met,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sitfulpa duis.",
            sizeDefault: "16px",
            colorDefault: "#000",
            spacingDefault: "1px",
        }),
        dotColor: {
            control: "color",
            defaultValue: "blue",
        },
        lineColor: {
            control: "color",
            defaultValue: "blue",
        },
    },
};

// PT016
const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT16 },
    template: `<PT16 v-bind="$props"/>`,
});

export const pt16 = Template.bind({});
