<template>
    <div class="component component-pt18" :style="style">
        <img :src="getImage" alt="background-img" />
        <div class="pt18__content">
            <div class="pt18__titleContainer" >
                <h2 v-for="item of brNextLine(title)" :key="item">{{ item }}</h2>
            </div>
            <div class="pt18__subTitleContainer">
                <span v-for="item of brNextLine(subTitle)" :key="item">
                    {{item}}
                    <br/>
                </span>
            </div>
            <div class="pt18__buttonWrapper">
                <a class="pt18__button" :href="link">{{ buttonText }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PictureText18",
    props: {
        imgUrl: String,
        mobileImgUrl: String,
        // title
        title: String,
        titleFontSize: Number,
        titleColor: String,
        titleSpacing: Number,
        // subTitle
        subTitle: String,
        subTitleFontSize: Number,
        subTitleColor: String,
        subTitleSpacing: Number,
        // button
        buttonText: String,
        buttonTextColor: String,
        buttonColor: String,
        buttonClickColor: String,
        buttonTextSpacing: Number,
        link: String,
    },
    computed: {
        style() {
            return {
                // title
                "--titleFontSize": `${this.titleFontSize}px`,
                "--titleColor": this.titleColor,
                "--titleSpacing": `${this.titleSpacing}px`,
                // subTitle
                "--subTitleFontSize": `${this.subTitleFontSize}px`,
                "--subTitleColor": this.subTitleColor,
                "--subTitleSpacing": `${this.subTitleSpacing}px`,
                // button
                "--buttonTextColor": this.buttonTextColor,
                "--buttonColor": this.buttonColor,
                "--buttonClickColor": this.buttonClickColor,
                "--buttonTextSpacing": `${this.buttonTextSpacing}px`,
            };
        },
        getImage(){
            if(this.isMobile){
                return this.mobileImgUrl
            }
            return this.imgUrl
        },
    },
    mounted() {
        window.addEventListener('resize', this.resize, false);
    },
    destroyed() {
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        resize() {
            let w = window.innerWidth;
            this.isMobile = w <= 415
        },
        brNextLine(str){
            return str?.split("<br>") || "";
        }
    },
    data() {
        return {
            isMobile: false
        }
    },
};
</script>

<style lang="scss" scoped>
.component-pt18 {
    position: relative;
    text-align: center;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pt18__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pt18__titleContainer {
    font-weight: 700;
    font-size: var(--titleFontSize);
    color: var(--titleColor);
    margin-bottom: 16px;
    letter-spacing: var(--titleSpacing);
}

.pt18__subTitleContainer {
    font-weight: 400;
    font-size: var(--subTitleFontSize);
    color: var(--subTitleColor);
    margin-bottom: 56px;
    letter-spacing: var(--subTitleSpacing);
}

.pt18__button {
    text-align: center;
    font-weight: 400;
    color: var(--buttonTextColor);
    background: var(--buttonColor);
    border-radius: 16px;
    padding: 8px 12px;
    letter-spacing: var(--buttonTextSpacing);
}

.pt18__button:hover {
    cursor: pointer;
    color: var(--buttonColor);
    background: var(--buttonTextColor);
    border: 1.5px solid var(--buttonColor);
}

.pt18__button:active {
    color: var(--buttonClickColor);
}
</style>
