import Element from "./index.vue";
import {ControlEnum} from "@/models/Enum/ControlEnum";
import {generateHeaderButtonsArgTypes} from "@/mixins/header/HeaderButtonsMixin";
import {testData} from "@/models/headerColorSetting";

console.log('generateHeaderButtonsArgTypes', {...generateHeaderButtonsArgTypes()})
export default {
  title: 'web-page/Header/H02',
  argTypes: {
    logoURL: {
      control: ControlEnum.IMG,
      defaultValue: 'https://fakeimg.pl/205x40/eaeaea/eae0d0',
    },
    list: {
      control: ControlEnum.ARRAY,
      defaultValue: testData
    },
    headerBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
    headerMobileBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
    headerMobileActiveBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#0065F2',
    },
    menuTextColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#000',
    },
    menuMobileTextColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
    menuTextHoverColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#0065F2',
    },
    ...generateHeaderButtonsArgTypes()
  }
}

const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
    <div style="margin:auto; height: 300vh;">
    <Element v-bind="$props"/>
    
    <section style="height: 100vh; background-color: #f0f0f0;">
      <h1>Section 1</h1>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aliquam aperiam asperiores aspernatur, assumenda atque aut dolores fugit ipsa, iste iusto maxime minima molestias natus numquam officiis quasi quia quibusdam rerum sequi unde ut voluptas? A alias corporis, cum deserunt ducimus eius et excepturi facere hic iusto laborum, magni necessitatibus quis quo similique vitae voluptate! Animi consequatur, delectus dicta error nisi optio soluta? Blanditiis dolor mollitia repellendus sapiente tempore. Accusamus ad aliquid architecto autem beatae blanditiis culpa delectus deserunt dicta dignissimos earum fugiat illo, inventore itaque laborum laudantium maiores maxime nam necessitatibus odit placeat soluta suscipit tenetur unde voluptas voluptatem.
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias distinctio exercitationem itaque odit, optio quo tempora? Aliquid fuga magnam maiores tempore vel. Asperiores fugiat illo ipsam minus non optio reprehenderit sit totam. Accusamus, aut deserunt eaque ipsam officiis rem voluptates! Aperiam eligendi eos expedita facilis inventore laudantium minus nobis quam quis tempore! Alias dolorem doloremque, error est excepturi harum maxime minima praesentium quis repellat. Accusantium ad animi consequatur consequuntur corporis eius eos expedita ipsam laboriosam libero minima, sint vitae voluptatum. Asperiores ea explicabo facilis illo magni mollitia omnis? Doloremque dolores exercitationem ipsum obcaecati, quas qui rerum tempore temporibus unde velit.
    </section>
    </div>
  `
});

export const element = TemplatePT01.bind({});
