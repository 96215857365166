<template>
  <li :class="moreClassName">
    <div class="nav-list__color">
      <a class="nav-list__link" :href="item.url">{{ item.contentName }}</a>
      <button v-if="hasChildren" class="nav-list__more">
        <i v-if="isRootItem" class="icon-arrow-down"></i>
        <template v-else>
          <i class="icon-arrow-right"></i>
        </template>
      </button>
    </div>
    <div class="nav-list" v-show="hasChildren">
      <ul class="nav-list--bg">
        <template v-for="(innerItem, index) of item.children">
          <RecursiveNavItem :item="innerItem" :key="index"/>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "RecursiveNavItem",
  props: {
    isRootItem: Boolean,
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    hasChildren() {
      return this.item.children && this.item.children.length > 0
    },
    moreClassName() {
      return {
        'nav-list__item': !this.hasChildren,
        'nav-list__item--more': this.hasChildren,
      }
    }
  }
}
</script>

<style src="./index.css" scoped>
/*@import "./index.css";*/
</style>
