import component from './index.vue';
import stories from './index.stories.js';
import {componentEnum} from "@/models/componentType";
import {G1} from "@/models/gridType";

export default {
  componentGroup: componentEnum.Product,
  grids: {...G1},
  name: 'Product04',
  icon: 'mdi-view-dashboard',
  type:'container',
  component: component,
  argTypes: stories.argTypes
}
