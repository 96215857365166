<template>
  <ul class="nav-list--root">
    <template v-for="(item, index) of items">
      <RecursiveNavItem :item="item" :is-root-item="true" :key="index"/>
    </template>
  </ul>
</template>

<script>
import RecursiveNavItem from '@/components/web-page/Header/H06/RecursiveNavItem';

export default {
  name: "RecursiveNavList",
  components: {RecursiveNavItem},
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style src="./index.css" scoped>
</style>
