<template>
  <footer class="footer footer-f03" :style="styleColor">
    <div class="footer-f03_warp">
      <div class="footer-f03__logo-box footer-f03__block-end--mobile">
        <img :src="logoURL" alt="logo">
      </div>
      <div class="footer-f03__info footer-f03__block-end--mobile" :class="{'single': singleRow}" :style="singlePositionStyle">
        <div v-for="item of items" :key="item.key" class="info-card">
          <div class="info-card__warp">
          <span class="info-card__icon">
             <img :src="item.iconURL" alt="icon">
          </span>
            <h4 class="info-card__title">{{ item.subTitle }}</h4>
          </div>
          <span v-for="(subItem, index) of item.subItems.split(',')" :key="index"
                class="info-card__item">{{ subItem.trim() }}</span>
        </div>
      </div>

      <div class="footer-f03__other-info other-info">
        <div class="other-info__title">
          <h2 class="other-info__title">{{ title }}</h2>
          <span class="other-info__title other-info__block-end"
          style="color: var(--sub-title)">{{ subTitle }}</span>

          <embedded-google-map  v-bind="$props"  class="other-info__block-end"/>

          <div class="footer-f03__social">
            <social-btn-group v-bind="$props"/>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-f03__bottom">
      <span>{{ bottomText }}</span>
      <a v-for="item of bottomLink" :key="item.text" class="footer-f03__link" :href="item.link">{{ item.text }}</a>
    </div>
  </footer>
</template>

<script>
import social from "@/models/social";
import SocialBtnGroup from "@/components/base/SocialBtnGroup";
import EmbeddedGoogleMap from "@/components/base/EmbeddedGoogleMap";
import embeddedGoogleMapProps from "@/models/embeddedGoogleMap";


export default {
  name: "F03",
  components: {SocialBtnGroup, EmbeddedGoogleMap},
  props: {
    singleRow: Boolean,
    singlePosition: {
      type: String,
      default: 'center'
    },
    logoURL: String,
    title: String,
    subTitle: String,
    bottomText: String,
    //color start
    bgColor: String,
    bottomBgColor: String,
    subTitleColor: String,
    textColor: String,
    bottomTextColor: String,
    //color end
    //radio
    textRadio: String,
    bottomTextRadio: String,
    //radio
    items: {
      type: Array,
      default: () => []
    },
    bottomLink: {
      type: Array,
      default: () => []
    },
    ...social,
    ...embeddedGoogleMapProps
  },
  computed: {
    singlePositionStyle() {
      console.log(this.singlePosition)
      let pro = '';
      switch (this.singlePosition) {
        case 'center':
          pro = 'center'
          break
        case 'right':
          pro = 'end'
          break
        default:
          pro = ''
      }

      return {
        'justify-content': pro
      }
    },
    styleColor() {
      return {
        '--bg-color': this.bgColor,
        '--sub-title': this.subTitleColor,
        '--bottom-bg-color': this.bottomBgColor,
        '--text-color': this.textColor,
        '--bottom-text-color': this.bottomTextColor,
      }
    }
  }
}
</script>

<style scoped>
.footer-f03 {
  --bg-color: #000;
  --bottom-bg-color: #F08300;
  --text-color: #FFF;
  --sub-title: #FFF;
  --sub-tlte-color: #6F6F6F;
  --bottom-text-color: #FFF;
}

.footer-f03_warp {
  display: flex;
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 50px 0;
}

.footer-f03__logo-box {
  width: 29.19%;
}

.footer-f03__logo-box img {
  margin: 0 auto;
  width: 140px;
}

.footer-f03__info {
  width: 45.80%;
  display: grid;
  gap: 40px 56px;
  grid-template-columns: repeat(2, 226px);
}

.footer-f03__info.single {
  grid-template-columns: repeat(1, 226px);
}

.footer-f03__other-info {
  width: 16.69%;
}

.other-info__title {
  font-family: 'Arial';
  display: flex;
  flex-direction: column;
  width: 188px;
  align-items: end;
}

.other-info__title h2 {
  font-weight: 700;
  line-height: 140%;
  font-size: 36px;
  margin-bottom: 10px;
}

span.other-info__title {
  color: var(--sub-tlte-color);
  font-weight: 400;
  font-size: 24px;
}


.other-info__block-end {
  margin-bottom: 50px;
}

.footer-f03__social {
  width: 188px;
}

.footer-f03__bottom {
  background-color: var(--bottom-bg-color);
  color: var(--bottom-text-color);
  font-size: 12px;
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

.footer-f03__bottom span {
  margin-right: 4px;
}

.footer-f03__link {
  color: var(--bottom-text-color);
}

.footer-f03__link:before {
  content: "| ";
}


.info-card__warp {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-card__icon {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
}

.info-card__icon img {
  width: 100%;
}

.info-card__title {
  font-size: 24px;
}

.info-card__item {
  display: block;
  font-size: 14px;
  font-weight: 400;
}

@container edit-layout (max-width: 840px) {
  .footer-f03__logo-box img {
    margin: 0;
  }

  .footer-f03__logo-box,
  .footer-f03__info,
  .footer-f03__other-info {
    width: 100%;
  }

  .footer-f03_warp {
    flex-direction: column;
    align-items: start;
    padding: 50px 24px;
  }

  .footer-f03__info {
    grid-template-columns:  repeat(1, 226px);
  }

  .other-info__title {
    display: none;
  }

  .footer-f03__block-end--mobile {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 840px) {
  .footer-f03__logo-box img {
    margin: 0;
  }

  .footer-f03__logo-box,
  .footer-f03__info,
  .footer-f03__other-info {
    width: 100%;
  }

  .footer-f03_warp {
    flex-direction: column;
    align-items: start;
    padding: 50px 24px;
  }

  .footer-f03__info {
    grid-template-columns:  repeat(1, 226px);
  }

  .other-info__title {
    display: none;
  }

  .footer-f03__block-end--mobile {
    margin-bottom: 50px;
  }
}
</style>
