import PT24 from "@/components/web-page/PictureText/PT24/index.vue";

export default {
    title: "web-page/PictureText/PT24",
    argTypes: {
        linkList: {
            keyType: {
                imgUrl: "image",
                title: "text",
            },
            control: "array",
            template: {
                imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                title: "簽訂留學",
            },
            defaultValue: [
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "高中留學、交換學生說明會",
                },
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "海外教中文說明會",
                },
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "大專院校海外留學說明會",
                },
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "大專院校",
                },
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "test test test test",
                },
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "testtesttesttesttesttesttest",
                },
            ],
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT24 },
    template: `
    <div>
      <PT24 v-bind="$props"/>
    </div>
  `,
});

export const pt24 = Template.bind({});

