<template>
  <header class="main-header header-h05 header-color" :style="styleColor">
    <a class="header-h05__logo" href="/">
      <img :src="logoURL" alt="logo">
    </a>
    <nav class="header__nav-list header-h05__nav-list">
      <RecursiveNavList v-bind="$attrs" :items="list"/>
    </nav>
    <header-buttons/>
  </header>
</template>

<script>
import RecursiveNavList from "./RecursiveNavList";
import HeaderButtons from "./components/HeaderButtons.vue";
import {headerColor, testData} from "@/models/headerColorSetting";
import headerButtonsMixin from "@/mixins/header/HeaderButtonsMixin";

export default {
  name: "Header05",
  components: {RecursiveNavList, HeaderButtons},
  mixins: [headerColor, headerButtonsMixin],
  props: {
    logoURL: String,
    list: {
      type: Array,
      default: () => testData
    }
  }
}
</script>

<style scoped src="@/assets/css/variable/headerColor.css"></style>
<style scoped>
.main-header {
  background-color: var(--bg-color);
}

.header-h05 {
  display: flex;
  padding: 0 48px;
  //height: 57px;
  z-index: 999;
  opacity: 0.9;
}


.header-h05::after {
  content: "";
  height: 6px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

}

.header-h05__logo {
  display: block;
  width: 16.64%;
  min-width: 115px;
  padding: 12px 0;
}

.header-h05__logo img {
  width: 115px;
}

.header-h05__nav-list {
  margin-left: auto;
  font-size: 14px;
}

.header-h05__btn-group {
  flex-shrink: 0;
  display: flex;
  font-size: 20px;
  color: #C8161D;
}

.header-h05__btn-item {
  padding: 0 10px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: inherit;
}

.buttons-container {
  color: var(--button-color)
}
@media screen and (max-width: 1199px) {
  .header-h05 {
    opacity: 1;
  }

  .header-h05__logo {
    /*width: 100%;*/
  }

  .buttons-container {
    margin-left: auto;
  }

  .header__nav-list {
    order: 1;
    width: 20px;
    margin: unset;
  }

  .main-header {
    justify-content: space-between;
    padding: 0 16px;
  }
}

</style>
