import BT03 from "./BT03.vue";
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";

export default {
  name:"ButtonText BT03",
  componentGroup: componentEnum.ButtonText,
  grids: {...GridEnum},
  icon: 'mdi-view-dashboard', // 先複製一樣的就好
  component: BT03,
  argTypes: {
    title : {
      control: 'text',
      defaultValue: "title",
    },
    buttonText: {
      control: 'text',
      defaultValue: "按鈕"
    },
    color: {
      control: 'color',
      defaultValue: '#333'
    },
    large: {
      control: 'boolean',
      defaultValue: false
    },
    small: {
      control: 'boolean',
      defaultValue: false
    },
    xLarge: {
      control: 'boolean',
      defaultValue: false
    },
    xSmall: {
      control: 'boolean',
      defaultValue: false
    },
  }
}
