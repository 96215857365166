export const mixin = {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isNav: {
      type: Boolean,
      default: true
    },
    isRootItem: Boolean,
  },
  computed: {
    hasChildren() {
      if (!this.isNav) {
        return this.item.children && this.item.children.length > 0
      }
      return false;
    }
  }
}
