export default {
  name: 'autoColor',
  props: {
    color: {
      type: String,
      default: '#bcbcbc'
    }
  },
  computed: {
    componentColor() {
      let data = {};
      Object.keys(this.$props)
        .filter(v => new RegExp('[a-zA-Z0-9]*Color').test(v))
        .forEach(v => {
          data['--'+v] = this.$props[v];
        })
      return data
    }
  }
}
