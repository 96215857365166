import Element from "./index.vue";
import {generateTextArgTypes} from "@/components/base/javaScript/baseTest";
import {ControlEnum} from "@/models/Enum/ControlEnum";

export default {
  title: "web-page/QuestionAnswer/Q01",
  argTypes: {
    listItem: {
      control: "array",
      defaultValue: [
        {
          id: 1,
          iconName: "icon-arrow-up",
          title: "列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一列表一",
          content: `
            <ul>
                <li><span>列表一</span></li>
                <li><span>內容</span></li>
            </ul>
            `,
        },
        {
          id: 2,
          iconName: "icon-arrow-up",
          title: "列表二",
          content: `
            <ul>
                <li><span>列表二</span></li>
                <li><span>內容</span></li>
            </ul>
            `,
        },
      ],
      template: {
        id: 1,
        iconName: "icon-arrow-up",
        title: "標題",
        content: `
            <ul>
                <li><span>測試1</span></li>
                <li><span>測試2</span></li>
                <li><span>測試3</span></li>
            </ul>
        `,
      },
    },
    borderColor: {
      control: ControlEnum.COLOR,
      defaultValue: "#000"
    },
    arrowColor: {
      control: ControlEnum.COLOR,
      defaultValue: "#000"
    },
    ...generateTextArgTypes('qa', {
      hiddeContent: true
    })
  },
};

// PT01
const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
  <div >
    <Element v-bind="$props"/>
  </div>
  `,
});

export const element = Template.bind({});
