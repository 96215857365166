import BT06 from "@/components/web-page/ButtonText/BT06/index.vue";

export default {
  title: 'web-page/ButtonText/BT06',
  argTypes: {
    toURL: {
      control: 'text',
      defaultValue: "#"
    },
    icon: {
      control: 'text',
      defaultValue: "arrow-circular-left"
    },
    content: {
      control: 'text',
      defaultValue: "回上一頁"
    },
    iconColor: {
      control: 'color',
      defaultValue: "#6F6F6F"
    },
    contentColor: {
      control: 'color',
      defaultValue: "#BD9B7B"
    },
    position: {
      control: 'select',
      options: ["left", "center", "right"],
      defaultValue: "left"
    }

  }
}
// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {BT06},
  template: `
    <div style="width: 100%;height: 400px; margin:auto;">
    <BT06 v-bind="$props"/>
    </div>
  `
});

export const bt06 = TemplatePT01.bind({});
