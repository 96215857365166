<template>
  <header class="main-header header-h03" :class="{active: isActive}" :style="componentColor">
    <header-logo :logo="logoURL" :alt="logoAlt"/>
    <header-menu @active="isActive = $event" :items="list"/>
    <header-buttons/>
  </header>
</template>

<script>
//Components
import HeaderLogo from "./components/HeaderLogo.vue";
import HeaderMenu from "./components/HeaderMenu.vue";
import HeaderButtons from "./components/HeaderButtons.vue";
//Mixins
import HeaderBaseMixin from "@/mixins/header/HeaderBaseMixin";
import HeaderButtonsMixin from "@/mixins/header/HeaderButtonsMixin";

export default {
  name: "H03",
  mixins: [HeaderBaseMixin, HeaderButtonsMixin],
  components: {HeaderLogo, HeaderMenu, HeaderButtons},
  data() {
    return {
      isActive: false
    }
  },
  mounted() {
  }
}
</script>

<style scoped src="./css/header.css"></style>
