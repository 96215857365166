<template>
  <div class="component component-form01 form-group">
    <div class="form-group-label"
           :class="{'error': invalid}">
      <span class="label-title">
        <span v-if="required">*</span>
        {{ title }}
      </span>
      <div class="label-content">
        <div class="dropdown-menu">
          <input style="display: none" :name="keyName" type="text" :value="value">
          <button class="toggle" @click.prevent="onOpenMenu" ref="toggle"  >
            <span> {{ syncValue || placeholder }}</span>
            <i></i>
          </button>
          <div class="dropdown-list-outside"
               ref="outside"
               :style="{'--outsideHeight': outsideHeight}">
            <ul class="dropdown-list" ref="list" >
              <template v-for="(item, index) of items">

                <li class="list-item"
                    :tabindex="index"
                    :key="item.name + '' +item.value"
                    @blur="checkValue"
                    @focus="onSetOption(item.value)">
                  {{ item.name }}
                </li>
              </template>

            </ul>
          </div>

        </div>

        <span class="label-error">此為必填</span>
      </div>
    </div>
  </div>
</template>

<script>
import FormField from "@/components/functional/FormField";
import "@/assets/css/form.css";
import {controlType} from "@/models/controlType";

export default {
  name: "ControlSelect",
  mixins: [FormField],
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    syncValue: {
      set(val) {
        this.setValue(val);
      },
      get() {
        return this.items.find(el => el?.value === this.value)?.name;
      }
    }
  },
  methods: {
    onOpenMenu() {
      console.log()
      this.$refs.toggle.focus();
      this.outsideHeight = this.$refs.list.offsetHeight + 'px';
    },
    onSetOption(val) {
      this.syncValue = val;
    }
  },
  data() {
    return {
      controlType: controlType.SELECT,
      outsideHeight: 0 + 'px'
    }
  },
}
</script>

<style scoped>

.dropdown-menu {
  position: relative;
  border-radius: 8px;
  max-width: 474px;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #EEEEEE;
}

.toggle {
  display: flex;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
}

.toggle span {
  padding: 0 8px;
  margin-right: auto;
}

.toggle i {
  flex-shrink: 0;
  width: 40px;
  height: 100%;
  margin-left: auto;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(0deg, #868585 0%, #CBCBCB 100%);
}

.toggle i:after {
  content: '';
  display: inline-block;
  position: relative;
  top: -2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #ffffff transparent transparent transparent;

}

.dropdown-list-outside {
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: #FFFFFF;
  height: 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  transition: height 0.3s;
}

.dropdown-menu .toggle:focus ~ .dropdown-list-outside{
  height: var(--outsideHeight);
  background-color: crimson;
}

.dropdown-list {
  padding: 0 !important;
}

.dropdown-list .list-item {
  user-select: none;
  background-color: #FFFFFF;
  padding-left: 8px;
  padding-right: 8px;
  color: #6F6F6F;
  cursor: pointer;
  border-bottom: 1px solid #EEEEEE;
}

.dropdown-list .list-item:hover {
  color: #FFFFFF;
  background: #008EDD;
}

.dropdown-list .list-item:last-child {
  border-bottom: 0;
}
</style>
