export default {
  inject: {
    quantity: {
      default: () => () => 0
    }
  },
  computed: {
    cartQuantity() {
      return this.padNumber(this.quantity());
    }
  },
  methods: {
     padNumber(number) {
        return number.toString().padStart(2, '0');
      }
  }
}
