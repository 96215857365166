import H06 from "@/components/web-page/Header/H06/index.vue";
import {getColorSetting} from "@/models/headerColorSetting";
import {generateHeaderButtonsArgTypes} from "@/mixins/header/HeaderButtonsMixin";

export default {
  title: 'web-page/Header/H06',
  argTypes: {
    logoURL:  {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/105x35/eaeaea/eae0d0',
    },
    ...generateHeaderButtonsArgTypes(),
    ...getColorSetting(
      {
        bgColor: '#FFF',
        textColor: '#000',
        buttonColor: '#C8161D',
        hoverLevel1Color: '#FFF',
        hoverBgLevel1Color: '#C8161D',
        activeBgLevel1Color: '#F08300',
        activeLevel3Color: '#F08300',
        activeBgLevel3Color: '#C8161D'
      }
    )
  }
}

const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {H06},
  template: `
    <div style="margin:auto; background-color: cornsilk; height: 100vh;">
    <H06 v-bind="$props"/>
    </div>
  `
});

export const h06 = TemplatePT01.bind({});
