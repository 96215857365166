<template>
  <div class="component component-List01">
    <div class="imgBlock">
      <img :src="imgUrl" @click="redirect" />
      <div class="iconBlock">
        <i
          :class="iconName"
          :style="{ color: iconColor, fontSize: '24px' }"
        ></i>
      </div>
    </div>
    <div class="textBlock">
      <p :style="{ color: titleColor }" class="title">{{ title }}</p>
      <p :style="{ color: subTitleColor }" class="subTitle">{{ subTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PictureText13",
  props: {
    imgUrl: String,
    title: String,
    titleColor: String,
    subTitle: String,
    subTitleColor: String,
    path: String,
    iconName: String,
    iconColor: String,
  },

  methods: {
    redirect() {
      location.href = this.$props.path;
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.component-List01 {
  .imgBlock {
    position: relative;
    cursor: pointer;
    aspect-ratio: 252 / 297;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    img {
      height: 100%;
    }
    &:hover {
      .iconBlock {
        opacity: 1;
      }
    }
  }

  .iconBlock {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 33px 0;
    transition: 0.3s;
    text-align: center;
    opacity: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  .textBlock {
    width: 100%;
    margin-top: 24px;
    padding-bottom: 12px;
    text-align: center;
    color: #000;

    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 140%;
    }

    .subTitle {
      font-size: 14px;
      line-height: 140%;
    }
  }
}
</style>
