<template>
    <div
        class="component component-pt25"
        :style="style"
        ref="component"
        @click="toPage"
    >
        <div class="img-wrapper">
            <img :src="imgUrl" alt="card" />
        </div>
        <h2 class="pt25__title">
            <base-text block-name="title" v-bind="$props" />
        </h2>
        <p class="pt25__subTitle">
            <base-text block-name="subTitle" v-bind="$props" />
        </p>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "PictureText25",
    components: {
        BaseText,
    },
    props: {
        imgUrl: String,
        link: String,
        // magic
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        // border color
        borderColor: String,
    },
    methods: {
        toPage() {
            window.open(this.link, "_blank");
        },
    },
    computed: {
        style() {
            return {
                "--border-color": this.borderColor,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-pt25 {
    width: fit-content;
    padding: 10px;
    cursor: pointer;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
}

.component-pt25:hover .pt25__title{
    border-left: 12px solid var(--border-color);
}

.img-wrapper {
    border-radius: 8px;
    max-width: 260px;
    max-height: 260px;
    aspect-ratio: 1;
    overflow: hidden;
}

.img-wrapper:hover img {
    transform: scale(1.5);
}


.pt25__title {
    font-weight: 700;
    border-left: 6px solid var(--border-color);
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 16px;
}

.pt25__subTitle {
    padding-left: 22px;
}
</style>
