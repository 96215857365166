<template>
  <div :class="classes" :style="styless">
    <h3 class="base__subTile" v-if="subTile">{{ subTile }}</h3>
    <h1 class="base__title" v-if="title">{{ title }}</h1>
    <div class="base__block" v-if="text" style="width: 100%; min-width: 100px; max-width: 400px; margin: 0 auto;">
      <p class="base__text">{{ text }}</p>
    </div>
    <div class="base__action">
      <slot/>
    </div>

  </div>
</template>

<script>
import color from "@/mixins/color";
import sizeable from "@/mixins/sizeable";

export default {
  name: "BBase",
  mixins: [color, sizeable],
  computed: {
    classes() {
      return {
        base: true,
        'base-center': this.center,
        ...this.sizeableClasses
      }
    },
    styless() {
      return {
        color: this.color
      }
    }
  },
  props: {
    title: String,
    text: String,
    subTile: String,
    center: Boolean
  }
}
</script>

<style scoped>
.base__title {
  margin-top: .5rem;
  margin-bottom: 1rem;

}
/*sizeable*/
.size--x-small .base__title{
  @apply tw-text-xs;
}
.size--small .base__title {
  @apply tw-text-xl;
}
.size--default .base__title {
  @apply tw-text-2xl;
}
.size--large .base__title {
  @apply tw-text-3xl;
}

.base {
  width: 100%;
  margin-bottom: 1.5rem;
  @apply tw-flex tw-justify-center tw-flex-col tw-items-center
}

.base__block {
  margin-bottom: 1.5rem;
  @apply tw-flex tw-justify-center tw-items-center
}

.base__text {
  word-break: break-all;
  @apply tw-min-w-[500px];
}
.base-center .base__text {
  text-align: center;
}


.base__action {

}
</style>
