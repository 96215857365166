<template>
    <div class="component component-pt20" :class="{ reverse: reverse }">
        <div class="pt20__text-wrapper">
            <!-- subTitle -->
            <div class="pt20__subTitleContainer">
                <base-text block-name="subTitle" v-bind="$props" />
            </div>
            <!-- title -->
            <div class="pt20__titleContainer">
                <base-text block-name="title" v-bind="$props" />
            </div>
            <!-- content-->
            <div class="pt20__contentContainer">
                <base-text block-name="content" v-bind="$props" />
            </div>
        </div>
        <div class="pt20__picture-wrapper">
            <img :src="imgUrl" alt="block-image" />
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "PictureText20",
    components: {
        BaseText,
    },
    props: {
        imgUrl: String,
        reverse: Boolean,
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        ...generateTextProps("content"),
    },
};
</script>

<style lang="scss" scoped>
.component-pt20 {
    max-width: 1360px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.component-pt20.reverse {
    flex-direction: row-reverse;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* text wrapper */
.pt20__text-wrapper {
    max-width: 468px;
    width: 40%;
}

/* subTitle */
.pt20__subTitleContainer {
    position: relative;
    font-weight: 700;
    margin-bottom: 16px;
}

/* title */
.pt20__titleContainer {
    font-weight: 700;
    margin-bottom: 16px;
}

/* content */
.pt20__contentContainer {
    font-weight: 400;
    margin-bottom: 16px;
    text-align: justify;
}

/* picture wrapper */
.pt20__picture-wrapper {
    align-self: end;
    max-width: 468px;
    width: 40%;
    height: 100%;
}

.pt20__picture-wrapper img {
    border-radius: 50% 50% 0% 0%;
}

@media screen and (min-width: 1360px) {
    .component-pt20 {
        margin: 0 auto;
        margin-top: 50px;
    }
}

@media screen and (max-width: 800px) {
    .component-pt20,
    .component-pt20.reverse {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pt20__text-wrapper {
        min-width: 316px;
        margin: 0;
    }

    .pt20__picture-wrapper {
        align-self: center;
        min-width: 316px;
        max-height: 270px;
    }
}
</style>
