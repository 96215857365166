import B01 from "@/components/web-page/Blog/B01/index.vue";

export default {
  title: 'web-page/Blog/B01',
  argTypes: {
    seoURL: {
      control: 'text',
      defaultValue: "aaa"
    },
    contentColor: {
      control: 'color',
      defaultValue: null
    },
    clickTextColor: {
      control: 'color',
      defaultValue: null
    },
    text: {
      control: 'text',
      defaultValue: "關於我們"
    }
  }
}
// PT01
  const TemplatePT01 = (args, {argTypes}) => ({
    props: Object.keys(argTypes),
    components: {B01},
    template: `
      <div style="width: 100%;">
        <B01 v-bind="$props"/>
      </div>
    `
  });

  export const b01 = TemplatePT01.bind({});
