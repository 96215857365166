<template>
  <LayoutGrid v-bind="$props" columns="9" class="g4_8">
    <slot/>
  </LayoutGrid>
</template>

<script>
import LayoutGrid from "@/layout/grid/LayoutGrid.vue";
import layout from "@/layout/mixins/layout";

export default {
  name: "G4_8",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style scoped>

.g4_8 > *:nth-child(1) {
  grid-row: 1/3;
  grid-column: 1/6;
}
.g4_8 > *:nth-child(2) {
  grid-row: 1/1;
  grid-column: 6/10;
}
.g4_8 > *:nth-child(3) {
  grid-row: 2/2;
  grid-column: 6/8;
}

.g4_8 > *:nth-child(4) {
  grid-row: 2/2;
  grid-column: 8/10;
}
</style>
