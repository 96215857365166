<template>
  <div class="component component-pt15" :style="style">
    <div class="pt15__container-lg">
      <div class="pt15__image">
        <img
          src="https://official-image.s3.ap-northeast-1.amazonaws.com/shopinn-banner-image.svg"
        />
      </div>
      <div class="pt15__content">
        <div class="pt15__container-md">
          <div class="pt15__image">
            <img
              src="https://official-image.s3.ap-northeast-1.amazonaws.com/shopinn-banner-image.svg"
            />
          </div>
          <div class="pt15__container-sm">
            <div class="pt15__title">
              <span>輕鬆</span>
              <span>
                一鍵開店
                <img
                  src="https://official-image.s3.ap-northeast-1.amazonaws.com/Group+22.svg"
                />
              </span>
            </div>
            <div class="pt15__image">
              <img
                src="https://official-image.s3.ap-northeast-1.amazonaws.com/shopinn-banner-image-mobile.svg"
              />
            </div>
            <div class="pt15__labels">
              <div class="pt15__label__container">
                <div class="pt15__label">標準版型客製</div>
                <div class="pt15__label">專業SEO架構</div>
              </div>
              <div class="pt15__label__container">
                <div class="pt15__label">系統維護</div>
                <div class="pt15__label">完整客服協助</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt15__price">
          <span>38000</span>
        </div>
        <div class="pt15__button">
          <a href="#">
            <span>立即</span>
            <span>試用</span>
          </a>
        </div>
      </div>
      <div class="pt15__copyright">
        <span>© 2022 Shopinn . Web Design</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PictureText15',
  props: {
    titleImgUrl: String,
    titleTopImgUrl: String,
    titleBottomImgUrl: String,
    title: String,
    titleColor: String,
    list: Array,
    outerCircleColor: String,
    innerCircleColor: String,
    cardThemeColor1: String,
    cardThemeColor2: String,
    cardCount: Number,
  },
  methods: {
    redirect() {
      location.href = this.$props.path;
    },
  },
  computed: {
    style() {
      return {};
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';
@keyframes titleMove {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes imageMove {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes labelMove {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes priceMove {
  from {
    transform: rotate3d(0, 0.5, 0, 90deg);
  }
  to {
    transform: rotate3d(0, 0, 0, 0);
  }
}
@keyframes buttonMove {
  // from {
  //   transform: translateX(test(100px)) translateY(100%);
  // }
  // to {
  //   transform: translateX(0) translateY(0);
  // }
  @for $i from 0 through 100 {
    #{$i}% {
      // left: lerp(100px, 200px, spring-wobbly($i / 100));
    }
  }
}
.component-pt15 {
  overflow: hidden;
  position: relative;
  width: 100%;

  padding: 56px 24px;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;
    //z-index: -1;
    background: #fac93e;
    border-radius: 0 0 0 100px;
  }

  .pt15__title {
    margin: 0 auto 24px;
    animation: titleMove 2s ease;
    width: fit-content;

    span {
      display: block;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-weight: 500;
      font-size: 56px;
      line-height: 1.2;
      text-align: center;
      position: relative;
      // width: fit-content;
      // margin: auto;
    }
    img {
      position: absolute;
      right: 0.9em;
      top: 0.1em;
      transform: translateX(100%);
      vertical-align: top;
    }
  }
  .pt15__image {
    width: 100%;
    aspect-ratio: 440/509;
    animation: imageMove 2s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  .pt15__labels {
    .pt15__label__container {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      gap: 16px;

      + .pt15__label__container {
        margin-top: 16px;
      }
    }
    .pt15__label {
      box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      padding: 8px 10px;
      flex-shrink: 0;

      font-weight: 500;
      font-size: 20px;
      line-height: 160%;

      &:nth-child(odd) {
        background: #1f286f;
        color: #fff;
      }
      &:nth-child(even) {
        background: #fff;
        color: #1f286f;
      }
    }
  }

  .pt15__price {
    margin-bottom: 24px;
    span {
      font-weight: 500;
      font-size: 56px;
      line-height: 1.2;
      text-align: center;

      color: #ffffff;
      display: block;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      -webkit-box-reflect: below -16px linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    }
    span:before {
      content: '$';
    }
    span:after {
      content: ' /年';
      font-size: 36px;
    }
  }
  .pt15__button {
    width: fit-content;
    margin: auto;
    a {
      position: relative;
      display: block;
      width: 104px;
      aspect-ratio: 1;
      border: #920783 solid 6px;
      border-radius: 50%;

      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);

      transition: 0.3s ease;

      font-weight: 500;
      font-size: 24px;
      line-height: 115%;

      letter-spacing: 0.1em;
      color: #fff;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      background: linear-gradient(180deg, #ac109b 0%, #3f0052 100%);
      background-size: 100% 200%;
      background-position: 0% 0%;

      span {
        display: block;
      }
    }
    &:hover a {
      background-position: 0% 100%;
    }
  }
  .pt15__content {
    width: 100%;
  }
  .pt15__container-sm {
    flex: 1;
    & > .pt15__image {
      margin-bottom: 24px;
    }
  }
  .pt15__container-lg,
  .pt15__container-md {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > .pt15__image {
      display: none;
    }
  }
  .pt15__container-md {
    margin-bottom: 24px;
  }
  .pt15__container-lg {
    max-width: 1096px;
    margin: auto;
    position: relative;
  }
  .pt15__copyright {
    position: absolute;
    writing-mode: vertical-lr;
    right: -24px;
    bottom: 0;
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .component-pt15 {
    // grid-template-columns: 45% 55%;
    .pt15__container-sm {
      flex: 1;
      & > .pt15__image {
        display: none;
      }
    }
    .pt15__container-md {
      & > .pt15__image {
        display: block;
        width: 45%;
      }
    }

    .pt15__title {
    }
    .pt15__image {
    }
    .pt15__labels {
      animation: labelMove 2s ease;
    }
    .pt15__price {
      animation: priceMove 2s ease;
    }
    .pt15__button {
    }
  }
}
@media screen and (min-width: 1024px) {
  .component-pt15 {
    .pt15__container-md {
      & > .pt15__image {
        display: none;
      }
    }
    .pt15__container-lg {
      & > .pt15__image {
        display: block;
        width: 40%;
      }
    }
    .pt15__content {
      width: 60%;
    }
    .pt15__title {
      span {
        font-size: 80px;
        display: inline-block;
      }
      img {
        height: 80px;
      }
    }
    .pt15__image {
    }
    .pt15__labels {
      display: flex;
      justify-content: center;
      gap: 16px;
      .pt15__label__container + .pt15__label__container {
        margin: 0;
      }
    }
    .pt15__price {
      margin-bottom: 100px;
    }
    .pt15__button {
      animation: buttonMove 2s ease-in-out;
    }
    .pt15__copyright {
      display: block;
    }
  }
}
</style>
