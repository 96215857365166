import { render, staticRenderFns } from "./RecursiveNavList.vue?vue&type=template&id=1acd9c17&scoped=true&"
import script from "./RecursiveNavList.vue?vue&type=script&lang=js&"
export * from "./RecursiveNavList.vue?vue&type=script&lang=js&"
import style0 from "./index.css?vue&type=style&index=0&id=1acd9c17&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1acd9c17",
  null
  
)

export default component.exports