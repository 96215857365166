import PT14 from '@/components/web-page/PictureText/PT14/index.vue';

export default {
  title: 'web-page/PictureText/PT14',
  argTypes: {
    titleImgUrl: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    titleTopImgUrl: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    titleBottomImgUrl: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    title: {
      control: 'text',
      defaultValue: '為您專屬打造',
    },
    titleColor: {
      control: 'color',
      defaultValue: '#fff',
    },
    list: {
      control: 'array',
      defaultValue: [
        { text: '品牌企劃文字稿' },
        { text: '網站架構表' },
        { text: '網站風格設定稿' },
        { text: '網頁試排版<br>品牌企劃完整版' },
        { text: '商家定稿' },
        { text: '工程建置' },
        { text: '正式上線<br>教育訓練' },
        { text: '網路行銷' },
      ],
    },
    outerCircleColor: {
      control: 'color',
      defaultValue: '#1F286F',
    },
    innerCircleColor: {
      control: 'color',
      defaultValue: '#fac93e',
    },
    cardThemeColor1: {
      control: 'color',
      defaultValue: '#5D0C75',
    },
    cardThemeColor2: {
      control: 'color',
      defaultValue: '#920783',
    },
    cardCount: {
      control: 'select',
      options: [6, 8],
      defaultValue: 6,
    },
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PT14 },
  template: `<PT14 v-bind="$props"/>`,
});

export const pt14 = Template.bind({});
