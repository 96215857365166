<template>
  <div class="component component-c07" :style="{ ...styleSetting }">
    <hooper ref="carousel" :settings="settings">
      <template #default="{ block }">
        <slide
          class="componentContainer"
          :style="computedAspectRatio"
          v-for="(item, index) of items"
          :key="block + index"
        >
          <div class="c07__title-container">
            <div class="c07__title">
              <img :src="item.imgUrl" :alt="item.alt" />
              <base-text
                block-name="title"
                v-bind="{ ...$props, titleContent: item.title }"
              />
            </div>
          </div>
          <div class="c07__content">
            <base-text
              block-name="content"
              v-bind="{ ...$props, contentContent: item.content }"
            />
          </div>
        </slide>
      </template>

      <template #hooper-addons>
        <Navigation />
      </template>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import hooperCarousel from '@/mixins/hooperCarousel';
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';
import aspectRatio from '@/mixins/aspectRatio';
import Navigation from './Navigation.vue';

export default {
  name: 'C07',
  components: { BaseText, Hooper, Slide, Navigation },
  mixins: [hooperCarousel, aspectRatio],
  props: {
    coverColor: String,
    hoverIconColor: String,
    iconColor: String,
    contentPosition: String,

    ...generateTextProps('title'),
    ...generateTextProps('content'),
    items: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    settings() {
      this.$nextTick(() => {
        console.log('settings');
        if (this.$refs.carousel.timer) {
          this.$refs.carousel.timer.stop();
        }
        this.$refs.carousel.initDefaults();
        this.$refs.carousel.update();
        this.$refs.carousel.initEvents();
      });
    },
  },
  computed: {
    settings() {
      return {
        autoPlay: true,
        playSpeed: 3000,
        wheelControl: false,
        itemsToShow: 3,
        centerMode: true,
        infiniteScroll: true,
      };
    },
    styleSetting() {
      return {
        '--coverColor': this.coverColor,
        '--hoverIconColor': this.hoverIconColor,
        '--iconColor': this.iconColor,
        '--contentPosition': this.contentPosition,
      };
    },
    dotsArray: function () {
      return Array.from(Array(this.items.length).keys());
    },
  },
  data() {
    return {
      slidesToShow: 1,
      slidesToScroll: 1,
      isShow: true,
      isMobile: false,

      itemList: [
        {
          imgUrl:
            'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          title: 'title',
          content: 'content',
        },
        {
          imgUrl:
            'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          title: 'title',
          content: 'content',
        },
        {
          imgUrl:
            'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          title: 'title',
          content: 'content',
        },
        {
          imgUrl:
            'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          title: 'title',
          content: 'content',
        },
        {
          imgUrl:
            'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          title: 'title',
          content: 'content',
        },
        {
          imgUrl:
            'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          title: 'title',
          content: 'content',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.hooper {
  height: unset;
}
.c07__title-container {
  // width: 345px;
  height: 51.93vw;
  // aspect-ratio: 1;
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  margin-bottom: 24px;
}
.c07__title {
  // width: 216px;
  width: 85.5%;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;

  margin: auto;
  transition: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  overflow: hidden;

  -webkit-mask-image: -webkit-radial-gradient(white, black);

  span {
    position: relative;
    z-index: 2;
  }
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: 0.3s;
  }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--coverColor);
    border-radius: 50%;
    z-index: 1;
  }
}
.c07__content {
  // width: 345px;
  width: 51.93vw;

  margin: auto;
  // background: #aaa;
  opacity: 0;
  transition: 0.3s;
  text-align: var(--contentPosition);
}
.is-active {
  .c07__title {
    background: #55a;
  }
}
.is-current {
  z-index: 3;
  .c07__title-container {
    width: 51.93vw;
    transform: translateX(calc(16.667vw - 50%));
    z-index: 2;
  }
  .c07__title {
    background: #55a;
    // width: 345px;
    width: 100%;

    font-size: 28px;
    line-height: 32px;

    &::before {
      background: #0000;
    }

    &:hover {
      img {
        scale: 1.2;
      }
    }
  }
  .c07__content {
    opacity: 1;
    // width: 345px;
    transform: translateX(calc(16.667vw - 50%));
  }
}
@media screen and (min-width: 768px) {
  .c07__title-container {
    height: 43.12vw;
  }
  .c07__title {
    width: 81.2%;

    font-size: 24px;
    line-height: 28px;
  }
  .c07__content {
    width: 43.12vw;
  }
  .is-current {
    .c07__title-container {
      width: 43.12vw;
    }
    .c07__title {
      font-size: 36px;
      line-height: 41px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .c07__title-container {
    height: 345px;
  }
  .c07__title {
    width: 216px;
  }
  .c07__content {
    width: 345px;
  }
  .is-current {
    .c07__title-container {
      width: 345px;
      transform: translateX(0);
    }
    .c07__title {
      width: 345px;
    }
    .c07__content {
      transform: translateX(0);
    }
  }
}
</style>
