import BT02 from "@/components/web-page/ButtonText/BT02/index.vue";

export default {
    title: "web-page/ButtonText/BT02",
    argTypes: {
        buttonGroup: {
            keyType: {
                type: {
                    editType: 'select',
                    options: ["small", "medium", "large"],
                },
                buttonText: "text",
                link: "text",
            },
            control: "array",
            template: {
                type: "medium",
                buttonText: "BUTTON-1",
                link: "https://google.com.tw",
            },
            defaultValue: [
                {
                    type: "small",
                    buttonText: "BUTTON-1",
                    link: "https://google.com.tw",
                },
                {
                    type: "medium",
                    buttonText: "BUTTON-2",
                    link: "https://google.com.tw",
                },
                {
                    type: "large",
                    buttonText: "BUTTON-3",
                    link: "https://google.com.tw",
                },
            ],
        },
        buttonColor: {
            control: "color",
            defaultValue: "#1F286F",
        },
        buttonTextColor: {
            control: "color",
            defaultValue: "white",
        },
        buttonClickColor: {
            control: "color",
            defaultValue: "grey",
        },
    },
};
// BT02
const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { BT02 },
    template: `<BT02 v-bind="$props"/>`,
});

export const bt02 = Template.bind({});
