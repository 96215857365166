<template>
    <div
        class="component component-pt19"
        :class="{ reverse: reverse }"
        :style="style"
    >
        <!--content-->
        <div class="pt19__content-wrapper">
            <div class="pt19__text-wrapper">
                <h2 class="pt19__title">
                    <base-text block-name="title" v-bind="$props" />
                </h2>
                <p class="pt19__subTitle">
                    <base-text block-name="subTitle" v-bind="$props" />
                </p>
                <div class="pt19__slash-wrapper">
                    <hr class="pt19__slash" />
                </div>
                <a class="pt19__btn" :href="link">{{ buttonText }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "PictureText19",
    components: {
        BaseText,
    },
    props: {
        // img
        imgUrl: String,
        // magic
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        ...generateTextProps("content"),
        // reverse
        reverse: Boolean,
        // background color
        bgColor: String,
        // slash color
        borderColor: String,
        width: Number,
        // button
        buttonText: String,
        buttonFontSize: Number,
        buttonColor: String,
        buttonTextColor: String,
        buttonClickColor: String,
        buttonTextSpacing: Number,
        link: String,
    },
    computed: {
        style() {
            return {
                // background image
                "--bgImgUrl": `url(${this.imgUrl})`,
                // background color hex code, 60代表opacity=60%
                "--bgColor": `${this.bgColor}60`,
                // slash color
                "--border-color": this.borderColor,
                "--line-width": `${this.width}px`,
                // button
                "--buttonFontSize": `${this.buttonFontSize}px`,
                "--buttonColor": this.buttonColor,
                "--buttonTextColor": this.buttonTextColor,
                "--buttonClickColor": this.buttonClickColor,
                "--buttonTextSpacing": `${this.buttonTextSpacing}px`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-pt19 {
    display: flex;
    justify-content: flex-end;
    background-image: var(--bgImgUrl);
    background-size: cover;
    background-position: center center;
    width: 100%;
}

.component-pt19.reverse,
.component-pt19.reverse .pt19__text-wrapper .pt19__slash-wrapper {
    justify-content: flex-start;
}

/* content */
.pt19__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 900px;
    width: 34%;
    background: var(--bgColor);
    padding-top: 10px;
    padding-bottom: 10px;
}

.pt19__text-wrapper {
    text-align: right;
}

.component-pt19.reverse .pt19__text-wrapper {
    text-align: left;
}

.pt19__title {
    margin-bottom: 8px;
}

/* slash */
.pt19__slash-wrapper {
    display: flex;
    justify-content: flex-end;
}

.pt19__slash {
    width: 104px;
    border-width: var(--line-width);
    border-color: var(--border-color);
    transform: rotate(150deg);
    margin-top: 50px;
    margin-bottom: 50px;
}

/* btn */
.pt19__btn {
    cursor: pointer;
    background: var(--buttonColor);
    color: var(--buttonTextColor);
    font-size: var(--buttonFontSize);
    letter-spacing: var(--buttonTextSpacing);
    font-weight: 700;
    padding: 8px 12px;
    margin-bottom: 56px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
}

.pt19__btn:hover {
    background: var(--buttonTextColor);
    color: var(--buttonColor);
    border: 1.5px solid var(--buttonColor);
}

.pt19__btn:active {
    color: var(--buttonClickColor);
}

@media screen and (max-width: 800px) {
    .component-pt19 {
        position: relative;
    }

    .pt19__text-wrapper {
        position: absolute;
        right: 50px;
        /* 置中元素 */
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>
