import PT15 from '@/components/web-page/PictureText/PT15/index.vue';

export default {
  title: 'web-page/PictureText/PT15',
  argTypes: {
    titleImgUrl: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    titleTopImgUrl: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    titleBottomImgUrl: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    title: {
      control: 'text',
      defaultValue: '為您專屬打造',
    },
    titleColor: {
      control: 'color',
      defaultValue: '#fff',
    },
    list: {
      control: 'array',
      defaultValue: [
        { text: '內容1' },
        { text: '內容2' },
        { text: '內容3' },
        { text: '內容4' },
        { text: '內容5' },
        { text: '內容6' },
        { text: '內容7' },
        { text: '內容8' },
      ],
    },
    outerCircleColor: {
      control: 'color',
      defaultValue: '#1F286F',
    },
    innerCircleColor: {
      control: 'color',
      defaultValue: '#fac93e',
    },
    cardThemeColor1: {
      control: 'color',
      defaultValue: '#5D0C75',
    },
    cardThemeColor2: {
      control: 'color',
      defaultValue: '#920783',
    },
    cardCount: {
      control: 'select',
      options: [6, 8],
      defaultValue: 8,
    },
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PT15 },
  template: `<PT15 v-bind="$props"/>`,
});

export const pt15 = Template.bind({});
