<template>
  <div class="component component-qa01" :style="componentColor">
    <div class="qa01-container-inner">
      <Collapse
          v-model="activeTab"
          accordion
          class="collapseContainer"
          @change="handleChange"
          :style="{'--borderColor': iconColor}"
      >
        <CollapseItem
            v-for="(item, index) in listItem"
            :key="item.id"
            :id="'qa-'+item.id"
            :name="String(item.id)"
        >
          <template slot="title">
            <div class="titleBlock">
              <base-text block-name="qa"
                         style=" text-indent: calc(-1rem - 24px); padding-left: calc(1rem + 24px);"
                         v-bind="{...$props, qaContent: item.title}">
                <template #text-header>
                  <span class="order">{{ index + 1 }}.</span>
                </template>
                <template #default="{text}">
                  {{ text }}
                </template>

              </base-text>
            </div>
          </template>
          <div
              :style="{ '--dot-color': contentColor }"
              class="contentBlock"
              v-html="item.content"
          ></div>

        </CollapseItem>
      </Collapse>
    </div>
  </div>
</template>

<script>
import {Collapse, CollapseItem} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BaseText from "@/components/base/BaseText.vue";
import {generateTextProps} from "@/components/base/javaScript/baseTest";
import autoColor from "@/mixins/autoColor";

export default {
  name: "QuestionAnswer01",
  components: {
    BaseText,
    Collapse,
    CollapseItem,
  },
  mixins: [autoColor],
  props: {
    listItem: Array,
    borderColor: String,
    arrowColor: String,
    ...generateTextProps('qa')
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  methods: {
    handleChange(name) {
      if (name) {
        const newUrl = location.href.split("#")[0];
        location.replace(`${newUrl}#${name}`);
      }
    },
  },
  mounted() {
    const formatUrl = location.href;
    this.activeTab = formatUrl.split("#")[1];

    const objControl = document.getElementById('qa-' + this.activeTab);
    if (objControl) {
      window.scrollTo(0, objControl.getBoundingClientRect().bottom);
    }
  },
};
</script>

<style lang="scss">
.component-qa01 {
  --arrowColor: #bd9b7b;
  --borderColor: #EBEEF5;
  max-width: 316px;
  margin: 0 auto;

  .qa01-container-inner {
    .collapseContainer {
      border: none;

      .titleBlock {
        display: flex;
        align-items: center;

        .order {
          font-weight: 700;
          margin-right: 24px;
        }
      }

      .el-collapse-item {
        margin-bottom: 24px;

        .el-collapse-item__header {
          line-height: 1.4;
          min-height: 48px;
          height: auto;
          font-size: 16px;
          width: 100%;
          color: #000;
          max-width: 522px;
          margin-bottom: 0;
          border-bottom: 1px solid var(--borderColor);
          background-color: transparent;

          &.is-active {
            font-weight: 700;
            //border-bottom-color: 1px solid var(--borderColor);
          }
        }
      }

      .el-collapse-item__wrap {
        border: 0;
        //border-top: 1px solid #EBEEF5;
        border-bottom-color: #d2d2d2;
        background-color: transparent;
      }

      .el-collapse-item__content {
        max-width: 532px;
        margin: 0 auto;
        padding: 32px 16px 48px;
        background-color: transparent;
      }

      .el-collapse-item__arrow {
        font-family: "icomoon", serif !important;
        color: var(--arrowColor);

        &:before {
          content: "\e90f";
        }

        &.is-active:before {
          content: "\e924";
        }

        &.is-active {
          transform: rotate(180deg);
        }
      }

      .listTitle {
        font-size: 24px;
        line-height: 28px;
        margin-left: 16px;
      }

      .contentBlock {
        --dot-color: #000;

        li {
          font-size: 16px;
          line-height: 22px;
          position: relative;
          margin-left: 16px;

          &,
          span,
          p {
            color: var(--dot-color);
          }

          &:before {
            position: absolute;
            top: 50%;
            left: -16px;
            width: 5px;
            height: 5px;
            content: "";
            transform: translateY(-50%);
            border-radius: 9999px;
            background-color: var(--dot-color);
          }
        }
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .component-qa01 {
    max-width: 522px;
  }
}


</style>
