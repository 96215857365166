import BT01 from "./index.vue";
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";
import stories from './index.stories.js';

export default {
  componentGroup: componentEnum.ButtonText,
  grids: {...GridEnum},
  name:"ButtonText BT01",
  icon: 'mdi-view-dashboard', // 先複製一樣的就好
  component: BT01,
  argTypes: stories.argTypes
}
