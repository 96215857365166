<template>
  <div class="component component-form01 form-group"
       :class="{'upload-enter': enter}"
       @drop.stop.prevent="drop"
       @dragenter.stop.prevent="dragenter"
       @dragleave.stop.prevent="dragleave"
       @dragover.stop.prevent="dragover">
    <div class="form-group-label"
         :class="{'error': invalid}">
      <span class="label-title">
        <span v-if="required">*</span>
        {{ title }}
      </span>
      <div class="label-content">
        <input style="display: none" :name="keyName" type="file" ref="file" @change="onFileUpload">
        <button class="toggle" @click.prevent="onUploadFile" ref="toggle">
          <i></i>
          <span> {{ placeholder }}</span>
        </button>
        <div class="content-name" v-if="fileName">
          <span>{{ fileName }}</span>
          <button class="content-name-close" @click="onClearFile"></button>
        </div>
        <span class="label-error">{{ errorMassage || '此為必填' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FormField from "@/components/functional/FormField";
import "@/assets/css/form.css";
import {controlType} from "@/models/controlType";

export default {
  name: "ControlFile",
  mixins: [FormField],
  props: {
    onlyCertainFileTypes: {
      type: Boolean,
      default: true
    },
    // 依賴onlyCertainFileTypes
    //圖片
    imageTypes: {
      type: Boolean,
      default: false
    },
    //影片
    videoTypes: {
      type: Boolean,
      default: false
    },
    //文件
    docTypes: {
      type: Boolean,
      default: false
    },
    //音訊
    audioTypes: {
      type: Boolean,
      default: false
    },
    //PDF
    pdfTypes: {
      type: Boolean,
      default: false
    },
    // 簡報
    // 試算表
    // 繪圖
  },
  watch: {
    value(val) {
      if (val == undefined) {
        this.onClearFile();
      }
    }
  },
  computed: {},
  methods: {
    dragenter() {
      this.enter = true;
    },
    dragleave() {
      this.enter = false;
    },
    dragover() {
      this.enter = true;
    },
    drop(e) {
      const dt = e.dataTransfer;
      const [file] = dt.files;
      console.log(file);
      this.enter = false;
      return;
    },
    handleFile(file) {
      let fileElem = this.$refs.file;
      let filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
      //超過10MB時拒絕
      if (filesize > 10) {
        fileElem.value = null;
        this.errorMassage = "檔案大小超過10MB";
        return;
      }

      this.fileName = file.name;
      this.errorMassage = null;
      this.setValue(file);
    },
    onFileUpload(event) {
      const target = event.target;
      const [file] = target.files;
      this.handleFile(file);
    },
    onClearFile() {
      let file = this.$refs.file;
      file.value = null;
      this.fileName = null;
      this.setValue(null);
    },
    //上傳前處理
    onUploadFile() {
      let file = this.$refs.file;
      //增加可接受的檔案類型
      this.addType(file);
      file.click();
    },
    addType(file) {
      // file.accept = "image/*,video/*"
      if (this.onlyCertainFileTypes) {
        file.accept = ".some,";
        if (this.imageTypes) file.accept = file.accept + "image/*,"
        if (this.videoTypes) file.accept = file.accept + "video/*,"
        if (this.audioTypes) file.accept = file.accept + "audio/*,"
        if (this.docTypes) file.accept = file.accept + "application/vnd.google-apps.document,application/vnd.ms-word,application/vnd.ms-word.document.macroEnabled.12,application/vnd.ms-word.document.macroenabled.12,application/vnd.ms-word.template.macroEnabled.12,application/vnd.ms-word.template.macroenabled.12,application/vnd.oasis.opendocument.text,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,text/plain,application/msword,"
        if (this.pdfTypes) file.accept = file.accept + "application/pdf,"
      }
    }
  },
  data() {
    return {
      enter: false,
      errorMassage: "",
      controlType: controlType.FILE,
      fileName: ""
    }
  },
}
</script>

<style scoped>
.form-group {

}

.form-group.upload-enter,
.form-group.upload-enter .label-title{
  color: #FFFFFF;
  background-color: #008EDD;
}

.toggle {
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(0deg, #868585 0%, #CBCBCB 100%);
  padding: 8px;
  color: #FFFFFF;
}

.toggle span {
  margin-right: auto;
}

.content-name {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.label-content .content-name {
  padding-top: 16px;
  font-size: 16px;
}

.content-name-close {
  position: relative;
  width: 20px;
  height: 20px;
  padding: 0 15px;
}


.content-name-close::before, .content-name-close::after {
  content: " ";
  position: absolute;
  height: 20px;
  width: 2px;
  top: 1px;
  background-color: #d2d2d2;
}

.content-name-close:hover::before,
.content-name-close:hover::after {
  background-color: #008EDD;
}

.content-name-close::before {
  transform: rotate(45deg);
}

.content-name-close::after {
  transform: rotate(-45deg);
}
</style>
