<template>
  <div class="component component-t02 fullWidthWrapper">
    <div class="t02" :style="cssProps" v-bind:class="{ contentMiddle: isContentMiddle }">
      <h3 class="t02__subTitle">{{ subTitle }}</h3>
      <h1 class="t02__title" v-bind:class="{ titleBold: isTitleBold }">{{ title }}</h1>
      <template>
        <p class="t02__content" v-for="text of content.split('<br>')" :key="text.key">{{ text }}</p>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  name: "T02",
  props: {
    title: String,
    subTitle: String,
    content: String,
    titleColor: String,
    subTitleColor: String,
    contentColor: String,
    borderColor: String,
    margin: Number,
    padding: Number,
    isContentMiddle: Boolean,
    isTitleBold: Boolean
  },
  computed: {
    cssProps() {
      return {
        '--titleColor': this.titleColor,
        '--subTitleColor': this.subTitleColor,
        '--contentColor': this.contentColor,
        '--borderColor': this.borderColor,
        '--margin': `${this.margin}px`,
        '--padding': `${this.padding}px`,
      }
    }
  }
}
</script>

<style scoped>
.fullWidthWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.component-t02 {
}

.t02 {
  --borderColor: #F08300;
  max-width: 540px;
}

.t02 .t02__title {
  color: var(--titleColor);
  font-size: 40px;
  margin-bottom: 22px;
  border-bottom: solid 4px var(--borderColor);
}

.t02 .titleBold {
  font-weight: 700;
}

.t02 .t02__subTitle {
  font-size: 16px;
  color: var(--subTitleColor);
  margin-bottom: 15px;
}

.t02 .t02__content {
  font-size: 16px;
  color: var(--contentColor);
}

.contentMiddle {
  text-align: center;
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 32px;
  }
}

@media screen and (max-width: 414px) {
  .title {
    font-size: 24px;
  }
}

</style>
