import PIC07 from "@/components/web-page/Picture/pic07/index.vue";
import {ControlEnum} from "@/models/Enum/ControlEnum";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/Picture/PIC07",
    argTypes: {
        bigPic: {
            keyType: { imgUrl: "image", title: "text", link: "text", hoverImgBgColor: "text" },
            control: ControlEnum.OBJECT,
            defaultValue: {
                imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                // title
                titleContent: '大標題',
                titleFontColor: 'black',
                titleFontSize: '36px',
                titlePadFontSize: null,
                titleDesktopFontSize: null,
                titleLineSpacing: null,
                // ...generateTextArgTypes("title", {
                //     contentDefault: "大標題",
                //     sizeDefault: "36px",
                //     colorDefault: "black",
                //     spacingDefault: "1px",
                // }),
                link:"https://google.com.tw",
                hoverImgBgColor: "blue",
            },
        },
        smallUpPic: {
            keyType: { imgUrl: "image", title: "text", link: "text", hoverImgBgColor: "text" },
            control: ControlEnum.OBJECT,
            defaultValue: {
                imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                // title
                titleContent: '大標題',
                titleFontColor: 'black',
                titleFontSize: '36px',
                titlePadFontSize: null,
                titleDesktopFontSize: null,
                titleLineSpacing: null,
                // ...generateTextArgTypes("title", {
                //     contentDefault: "大標題",
                //     sizeDefault: "36px",
                //     colorDefault: "black",
                //     spacingDefault: "1px",
                // }),
                link:"https://google.com.tw",
                hoverImgBgColor: "blue",
            },
        },
        smallDownPic: {
            keyType: { imgUrl: "image", title: "text", link: "text", hoverImgBgColor: "text" },
            control: ControlEnum.OBJECT,
            defaultValue: {
                imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                // title
                titleContent: '大標題',
                titleFontColor: 'black',
                titleFontSize: '36px',
                titlePadFontSize: null,
                titleDesktopFontSize: null,
                titleLineSpacing: null,
                // ...generateTextArgTypes("title", {
                //     contentDefault: "大標題",
                //     sizeDefault: "36px",
                //     colorDefault: "black",
                //     spacingDefault: "1px",
                // }),
                link:"https://google.com.tw",
                hoverImgBgColor: "blue",
            },
        },
        reverse: {
            control: ControlEnum.BOOL,
            defaultValue: false,
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PIC07 },
    template: `
    <div>
      <PIC07 v-bind="$props"/>
    </div>
  `,
});

export const pic07 = Template.bind({});

