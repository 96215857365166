<template>
  <div class="hooper-pagination">
    <ol class="hooper-indicators">
      <li v-for="(_, index) of totalCount" :key="index"
          :class="{'active': currentSlide === index}">
        <button :class="dotClass" @click="onClickDot(index)"></button>
      </li>
    </ol>
  </div>
</template>

<script>

function normalizeSlideIndex(index, slidesCount) {
  var realIndex;
  if (index < 0) {
    realIndex = (index + slidesCount) % slidesCount;
  } else {
    realIndex = index % slidesCount;
  } // Test for NaN
  if (realIndex !== realIndex) {
    return 0;
  }

  return realIndex;
}

export default {
  inject: ['$hooper'],
  name: 'PaginationTest',
  props: {
    /**
     * round
     * square
     */
    mode: {
      default: 'round',
      type: String
    }
  },
  computed: {
    currentSlide: function currentSlide() {
      console.log( this.$hooper.slidesCount)
      return normalizeSlideIndex(this.$hooper.currentSlide, this.$hooper.slidesCount);
    },
    totalCount: function slides() {
      return this.$hooper.slidesCount;
    },
    dotClass() {
      return [
        "c-dot",
        "c-dot-" + this.mode
      ]
    }
  },
  methods: {
    onClickDot: function onClickDot(index) {
      this.$hooper.slideTo(index);
    }
  }
}
</script>

<style scoped>
.hooper-pagination .c-dot {
  margin: 0 4px;
  border: 2px solid transparent;
}

.hooper-pagination .active > .c-dot{
  background-color: #FFF;
}
.hooper-pagination .c-dot-round {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  padding: 0;
  border: 2px solid #fff;
  background-color: transparent;
  cursor: pointer;
}

</style>
