import Element from './index.vue';
import { generateTextArgTypes } from '@/components/base/javaScript/baseTest';
import { ControlEnum } from '@/models/Enum/ControlEnum';

export default {
  title: 'web-page/Footer/F02',
  paramGroup: {
    copyrightGroup: [
      'copyrightText',
      'copyrightLink',
      'copyrightBackgroundColor',
      'copyrightTextColor',
    ],
  },
  argTypes: {
    logoUrl: {
      control: {
        type: ControlEnum.TEXT,
        editType: ControlEnum.IMG,
      },
      defaultValue:
        'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
    },

    ...generateTextArgTypes('description', {
      contentDefault: '預設文字',
      sizeDefault: '14px',
      colorDefault: '#000',
    }),
    items: {
      control: ControlEnum.ARRAY,
      keyType: {
        htmlText: 'html',
      },
      template: {
        htmlText: '預設文字',
      },
      defaultValue: [
        {
          htmlText: '預設文字',
        },
        {
          htmlText: '預設文字',
        },
        {
          htmlText: '預設文字',
        },

      ],
    },
    links: {
      control: ControlEnum.ARRAY,
      keyType: {
        color: 'color',
      },
      template: {
        icon: '',
        href: '#',
        color: '#000',
      },
      defaultValue: [
        {
          icon: 'facebook',
          href: '#',
          color: '#000',
        },
        {
          icon: 'instagram',
          href: '#',
          color: '#000',
        },
        {
          icon: 'youtube',
          href: '#',
          color: '#000',
        },
      ],
    },
    backgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: 'white',
    },
    copyrightText: {
      control: ControlEnum.TEXT,
      defaultValue: '© 2022 百順實業 bai shun . Powered By ShopInn',
    },
    copyrightLink: {
      control: ControlEnum.ARRAY,
      template: { link: '#', text: '輸入文字' },
      defaultValue: [{ link: '#', text: '服務條款' }],
    },
    copyrightBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#F08300',
    },
    copyrightTextColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Element },
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `,
});

export const element1 = Template.bind({});
