<template>
  <div class="buttons-desktop-lang buttons-lang">
    <!--  -->
    <div class="lange-btn" @click="onLangeClick">
      <i class="icon-language"></i>
      <span class="desktop-lang">語言</span>
      <span class="mobile-lang">{{nowLangeShow}}</span>
    </div>
    <!--  -->
    <div class="list-item" :class="{active}">
      <language-list/>
    </div>
  </div>
</template>

<script>
import LanguageList from "./LanguageList.vue";
import headerLangMixin from "@/mixins/header/headerLangMixin";

export default {
  name: "LanguageBtn",
  mixins: [headerLangMixin],
  components: {LanguageList},
  methods: {
    onLangeClick() {
      this.active = !this.active;
      this.$emit('click', this.active);
    }
  },
  data() {
    return {
      active: false
    }
  },
  mounted() {
  }
}
</script>


<style scoped>
.buttons-lang {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.list-item {
  position: relative;
  height: 100%;
}

.lange-btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .list-item {
    display: none;
  }

  .lange-btn {
    font-size: 20px;
    justify-content: space-between;
    padding: 38px 0 10px 16px;
  }

  .desktop-lang {
    display: none;
  }
  .mobile-lang {
    order: -1;
  }
}

@media screen and (min-width: 1200px) {
  .desktop-lang {
    display: block;
  }
  .mobile-lang {
    display: none;
  }
}

</style>
