<template>
  <div class="component component-form01 form-group">
    <label class="form-group-label" :class="{'error': invalid}">
      <span class="label-title">
        <span v-if="required">*</span>
        {{ title }}
      </span>
      <span class="label-content">
        <input  :disabled="false"
                type="text"
                :placeholder="placeholder"
                :name="keyName"
                @blur="checkValue"
                v-model="syncValue">
        <span class="label-error">此為必填</span>
      </span>
    </label>
  </div>
</template>

<script>
import FormField from "@/components/functional/FormField";
import "@/assets/css/form.css";

export default {
  name: "ControlInput",
  mixins: [FormField],
  computed: {
    syncValue: {
      set(val) {
        this.setValue(val);
      },
      get() {
        return this.value;
      }
    }
  }
}
</script>

<style scoped>
.label-content input{
  width: 100%;
  padding: 8px;
  background: #EEEEEE;
  border-radius: 8px;
  max-width: 474px;
}
</style>
