import PT04 from "@/components/web-page/PictureText/PT04/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/PictureText/PT04",
    argTypes: {
        imgURL: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/image_circle(1-1).png",
        },
        iconURL: {
            control: "text",
            defaultValue: "https://fakeimg.pl/32/ffffff/000",
        },
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "標題",
            sizeDefault: "25px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault: "ShopInn五分鐘開店，快速網站建立平台",
            sizeDefault: "16px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        isReverse: {
            control: "boolean",
            defaultValue: false,
        },
    },
};

// PT01
const TemplatePT01 = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT04 },
    template: `
    <div style="width: 900px; margin:auto;">
      <PT04 v-bind=$props />
    </div>
  `,
});

export const pt04 = TemplatePT01.bind({});
