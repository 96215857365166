<template>
  <BBase center large :title="title" :text="text" :sub-tile="subtitle" :color="color">
    <BaseButton plain :color="color">
      <span>{{buttonText1}}</span>
    </BaseButton>
    <BaseButton plain :color="color">
      <span>{{buttonText2}}</span>
    </BaseButton>
  </BBase>
</template>

<script>
import color from "@/mixins/color";
import BBase from "@/components/web-page/ButtonText/component/BBase";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "B04",
  components: {BaseButton, BBase},
  mixins: [color],
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: String,
    text: String,
    buttonText1: String,
    buttonText2: String,
  }
}
</script>

<style scoped>

</style>
