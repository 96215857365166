<template>
  <div class="componentContainer" :style="setStyle">
    <slot>
      <div></div>
    </slot>
  </div>
</template>

<script>

export default {
  name: "ComponentContainer",
  computed: {
    setStyle() {
      if (!this.$attrs.order) return {}
      return {
        '--component-order': this.$attrs.order
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
@media screen and (max-width: 800px) {
  .componentContainer {
    order: var(--component-order);
  }
}
.componentContainer {
  width: 100%;
  position: relative;
}
</style>
