<template>
  <ul class="list inner-list">
    <li class="list-item" v-for="lang of langListShow" :key="lang.code">
      <a class="item-link" href="#" @click.prevent="headerButtonsEvent.onLanguage(lang.code)">
        {{ lang.name }}
      </a>
    </li>
  </ul>
</template>

<script>
//顯示側選單時用的
import headerLangMixin from "@/mixins/header/headerLangMixin";

export default {
  name: "LanguageList",
  mixins: [headerLangMixin],
  inject: ['headerButtonsEvent'],
}
</script>

<!--<style src="../../../css/header.menu.css" scoped></style>-->
<style scoped>

.list {
  position: absolute;
  top: 100%;
  width: 115px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  background-color: var(--bg-color);
  border-radius: 0px 0px 24px 24px;
  padding: 8px 0;
}

.list .list-item {
  padding: 4px;
  text-align: center;
}

.active > .list {
  display: block;
}

</style>
