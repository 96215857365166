import Table01 from "@/components/web-page/Table/Table01/index.vue";

export default {
  title: "web-page/Table/Table01",
  argTypes: {
    options: {
      control: "array",
      template: {
        name: "選項名稱",
        value: "選項Key",
      },
      defaultValue: [
        {
          name: "充電/耳機孔/震動",
          value: "first",
        },
        {
          name: "選項2",
          value: "second",
        },
        {
          name: "選項3",
          value: "third",
        },
        {
          name: "選項4",
          value: "fourth",
        },
      ],
    },
    optionActiveColor: {
      control: "color",
      defaultValue: "#0050D1",
    },
    tableBackgroundColor: {
      control: "color",
      defaultValue: "#000000",
    },
    tableTextColor: {
      control: "color",
      defaultValue: "#FFFFFF",
    },
    tableDataList: {
      control: "array",
      template: {
        name: "first",
        value:
          '[{"label":"第一個","value":"$ 1,500"},{"label":"第一個","value":"$ 1,500"}]',
      },
      defaultValue: [
        {
          name: "first",
          value:
            '[{"label":"第一個a","value":"$ 1,500"},{"label":"第一個","value":"$ 1,500"}]',
        },
        {
          name: "second",
          value:
            '[{"label":"第二個","value":"$ 1,500"},{"label":"第二個","value":"$ 1,500"}]',
        },
      ],
    },
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Table01 },
  template: `<Table01 v-bind="$props" />`,
});

export const table01 = Template.bind({});
