import Element from './index.vue';
import { generateTextArgTypes } from '@/components/base/javaScript/baseTest';
import { ControlEnum } from '@/models/Enum/ControlEnum';

export default {
  title: 'web-page/Carousel/C09',
  argTypes: {
    items: {
      control: ControlEnum.ARRAY,
      keyType: {
        imgUrl: 'image',
      },
      template: {
        imgUrl: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
        alt: "",
        title: '標題',
      },
      defaultValue: [
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題一',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題二',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題三',
        },
      ],
    },
    blockColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#000",
    },
    paginationColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#000",
    },

    
    ...generateTextArgTypes('title', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '20px',
      colorDefault: '#fff',

    }),
    aspectRatio: {
      control: 'select',
      options: ['auto', '1:1', '3:4', '4:3', '2:1'],
      defaultValue: 'auto',
    },
    
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Element },
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `,
});

export const element1 = Template.bind({});
