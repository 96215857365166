export const controlType = {
  CHECKBOX: "Checkbox",
  INPUT: "Input",
  NUMBER: "Number",
  RADIO: "Radio",
  SELECT: "Select",
  TEXTAREA: "Textarea",
  FILE: "File",
  DATE: "Date"
}
