<template>
    <div class="component component-bt01" :style="style">
        <div
            v-for="button of buttonGroup"
            :key="button.buttonText"
            :class="{
                small: button.type === 'small',
                medium: button.type === 'medium',
                large: button.type === 'large',
            }"
        >
            <a
                class="bt01__btn"
                :class="{
                    'small-btn': button.type === 'small',
                    'medium-btn': button.type === 'medium',
                    'large-btn': button.type === 'large',
                }"
                :href="button.link"
                >{{ button.buttonText }}</a
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "BT01",
    props: {
        buttonGroup: Array,
        buttonColor: String,
        buttonTextColor: String,
        buttonClickColor: String,
    },
    computed: {
        style() {
            return {
                "--button-color": this.buttonColor,
                "--button-text-color": this.buttonTextColor,
                "--button-click-color": this.buttonClickColor,
            };
        },
    },
};
</script>

<style scoped lang="scss">
/* btn group contianer */
.component-bt01 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

/* btn style */
.bt01__btn {
    /* position */
    display: inline-block;
    width: fit-content;
    /* color */
    color: var(--button-text-color);
    background-color: var(--button-color);
    /* border */
    border-radius: 10px;
    /* margin and padding */
    padding: 6px 10px;
    margin: auto;
}

.bt01__btn:hover {
    background: var(--button-text-color);
    color: var(--button-color);
    border: 2.5px solid var(--button-color);
}

.bt01__btn:active {
    color: var(--button-click-color);
}

/* small medium large  */
.small, .medium, .large {
    font-size: 16px;
    font-weight: 700;
}

.medium {
    font-size: 20px;
}

.large {
    font-size: 28px;
}

@media screen and (max-width:600px){
    .component-bt01 {
        flex-direction: column;
    }
}
</style>
