<template>
    <div class="pt03">
        <img class="pt03__img" :src="imgURL" alt="" />
        <div class="pt03__wrap">
            <img class="pt03__wrap--icon" :src="iconURL" alt="icon" />
            <h2 class="pt03__wrap--title">
                <base-text block-name="content" v-bind="$props"></base-text>
            </h2>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "PT03",
    components: {BaseText},
    props: {
        imgURL: String,
        iconURL: String,
        // magic
        ...generateTextProps("content"),
    },
};
</script>

<style scoped>
.pt03 {
    position: relative;
    aspect-ratio: 1;
    width: 100%;
    color: #fff;
    font-size: 48px;
}

.pt03__img {
    width: 100%;
}

.pt03__wrap {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    inset: 0;
}

.pt03__wrap--icon {
    max-width: 45px;
}

.pt03__wrap--title {
    font-size: inherit;
    font-weight: 900;
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.4);
}

@media screen and (min-width: 415px) {
    .pt03 {
        font-size: 56px;
    }
}
</style>
