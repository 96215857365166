<template>
  <LayoutGrid v-bind="$props" columns="2" class="g3_4">
    <slot/>
  </LayoutGrid>
</template>

<script>
import LayoutGrid from "@/layout/grid/LayoutGrid";
import layout from "@/layout/mixins/layout";

export default {
  name: "G3_4",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style  lang="scss">
.g3_4 > *:nth-child(1) {
  grid-column: 1 / 1;
  grid-row: 1 / 3;
}
</style>
