import Element from "./index.vue";
import ControlCheckbox from "@/components/web-page/Controls/Checkbox/index.vue";
import ControlRadio from "@/components/web-page/Controls/Radio/index.vue";
import ControlInput from "@/components/web-page/Controls/Input/index.vue";
import ControlSelect from "@/components/web-page/Controls/Select/index.vue";
import ControlTextarea from "@/components/web-page/Controls/Textarea/index.vue";
import ControlFile from "@/components/web-page/Controls/File/index.vue";
import ControlDate from "@/components/web-page/Controls/Date/index.vue";
import {ControlEnum} from "@/models/Enum/ControlEnum";

export default {
  components: {ControlDate, ControlFile, ControlTextarea, ControlSelect, ControlInput, ControlRadio, ControlCheckbox},
  title: 'web-page/Form/form',
  argTypes: {
    formSelect: {
      control: ControlEnum.FORM,
      defaultValue: null
    },
    sendSuccessURL: {
      control: ControlEnum.TEXT,
      defaultValue: null
    },

    formId: {
      control: ControlEnum.TEXT,
      hide: true,
      defaultValue: null
    },
    formS3URL: {
      control: ControlEnum.TEXT,
      hide: true,
      defaultValue: null
    },

    questionTitleSize: {
      control: ControlEnum.SELECT,
      options: ["16", "18", "19", "20", "21", "22", "23", "24"],
      defaultValue: "18"
    },
    formBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: "#D2D2D2"
    },
    mainColor: {
      control: ControlEnum.COLOR,
      defaultValue: "#1F286F"
    },
    mainColor2: {
      control: ControlEnum.COLOR,
      defaultValue: "#FBEFC5"
    },
  }
}

const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {
    Element,
    ControlTextarea,
    ControlSelect,
    ControlCheckbox,
    ControlRadio,
    ControlInput,
    ControlFile,
    ControlDate
  },
  template: `
    <div>
    <Element v-bind="$props">
      <template #body>
        <ControlTextarea/>
        <ControlSelect :items="[
            {name: '回答一', value: 1}, 
            {name: '回答二', value: 2}, 
            {name: '回答三', value: 3},
            {name: '回答4', value: 777}]"/>
        <ControlCheckbox :items=" [
          {name: '多選項一', value: 1},
          {name: '多選項二', value: 2},
          {name: '多選項三', value: 3}
        ]"/>
        <ControlInput/>
        <ControlDate/>
        <ControlRadio :items="[
            {name: '選項一', value: 1}, 
            {name: '選項二', value: 2}, 
            {name: '選項三', value: 3}]"/>
        <ControlFile onlyCertainFileTypes image-types/>
      </template>
    </Element>
    </div>
  `
});

export const FormRoot = Template.bind({});
