import component from './index.vue';
import stories from './index.stories.js';

export default {
  name: 'Footer04',
  icon: 'mdi-view-dashboard',
  type: 'footer',
  component: component,
  argTypes: stories.argTypes,
  paramGroup: stories.paramGroup
};
