<template>
  <div class="pictureTextContainer" :style="cssProps">
    <div class="imgContainer">
      <img :src="imgURL" :alt="imgAlt"/>
    </div>
    <div class="content">
      <template v-for="(text, index) of contentText.split('<br>')">
        <p :key="index">{{text}}</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PT02",
  props: {
    imgURL: String,
    imgAlt: String,
    contentText:String,
    contentColor:String,
  },
  data(){
    return{

    }
  },
  computed: {
    cssProps(){
      return{
        '--contentColor': this.contentColor,
      }
    }
  },
}
</script>

<style scoped>
.pictureTextContainer{
  width:100%;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width:90px;
  height:90px;

}

.content{
  max-width:540px;
  font-size:16px;
  color:var(--contentColor);
  margin-top:40px;
}

@media(max-width:290px){
  .content{
    max-width:290px;
  }

}
</style>
