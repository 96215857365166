import PT25 from "@/components/web-page/PictureText/PT25/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/PictureText/PT25",
    argTypes: {
        // img
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/demo.png",
        },
        // link
        link: {
            control: "text",
            defaultValue: "https://google.com.tw"
        },
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "我是大標題我",
            sizeDefault: "16px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "我是小標題我",
            sizeDefault: "10px",
            colorDefault: "#6F6F6F",
            spacingDefault: "1px",
        }),
        // border color
        borderColor: {
            control: "color",
            defaultValue: "#5D0C75",
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT25 },
    template: `
    <div>
      <PT25 v-bind="$props"/>
    </div>
  `,
});

export const pt25 = Template.bind({});
