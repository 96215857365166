<template>
  <div class="component component-bt05" :style="{...componentColor, ...styles}">
    <h2 class="component-bt05__title">
      <base-text block-name="title" content-name="title" v-bind="$props" />
    </h2>
    <p class="component-bt05__content">
      <base-text block-name="content" content-name="content" v-bind="$props" />
    </p>
    <a class="component-bt05__more" :href="moreUrl">
      <base-text block-name="more" content-name="moreText" v-bind="$props"/>
      <span class="component-bt05__icon"><i class="icon-arrow-right"></i></span></a>
  </div>
</template>

<script>

import autoColor from "@/mixins/autoColor";
import BaseText from "@/components/base/BaseText.vue";
import {generateTextProps} from "@/components/base/javaScript/baseTest";

export default {
  name: "BT05",
  components: {BaseText},
  mixins: [autoColor],
  props: {
    //--------標題相關---------
    //--------v1.0--------
    title: String,
    borderColor: String,
    //--------v1.1--------
    borderColorSize: String,
    ...generateTextProps('title'),


    //--------內容相關---------
    content: String,
    ...generateTextProps('content'),


    //--------更多相關---------
    moreText: String,
    ...generateTextProps('more'),
    moreUrl: String,
    iconColor: String,
  },
  computed: {
    styles() {
      return {
        '--border-color-size': this.borderColorSize
      }
    }
  }
}
</script>

<style scoped>
.component-bt05 {
  --borderColor: #F08300;
  --iconColor: #C8161D;
  --border-color-size: 5px;


  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.component-bt05__title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 24px;
}

.component-bt05__title > span{
  border-bottom: var(--border-color-size) solid var(--borderColor);
}

.component-bt05__content {
  color: #6f6f6f;
  margin-bottom: 48px;
}
.component-bt05__more {
  display: flex;
  align-items: center;
}
.component-bt05__icon {
  color: var(--iconColor);
  font-size: 12px;
  margin-left: 12px;
}
</style>
