<template>
    <div
        class="component component-pt08"
        :class="{ reverse: isReverse }"
        :style="componentColor"
    >
        <div class="content-box content-box--between">
            <div>
                <div
                    class="content-box__title-box content-box__title-box--line"
                >
                    <img :src="iconURL" alt="icon" />
                    <h4 class="content-box__title">
                        <base-text block-name="title" v-bind="$props" />
                    </h4>
                </div>
                <div class="content--text">
                    <p class="content-box__content">
                        <base-text block-name="content" v-bind="$props" />
                    </p>
                </div>
            </div>
        </div>
        <div class="component-pt08__img">
            <img :src="imgURL" alt="" />
        </div>
    </div>
</template>

<script>
import autoColor from "@/mixins/autoColor";
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "PT08",
    mixins: [autoColor],
    components: {
        BaseText,
    },
    props: {
        imgURL: String,
        iconURL: String,
        // magic
        ...generateTextProps("title"),
        ...generateTextProps("content"),
        isReverse: Boolean,
        borderColor: String,
    },
};
</script>

<style scoped>
.component-pt08 {
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin: auto;
}

.component-pt08__warp {
    display: flex;
    max-width: 520px;
    margin: auto;
}

.reverse {
    flex-direction: row-reverse;
}

.component-pt08__img {
    /*aspect-ratio: 1;*/
    display: flex;
    flex-shrink: 0;
}

.pt08__content-box__title img {
    max-width: 32px;
}

.pt08__content-box__title h4 {
    padding-left: 16px;
    font-weight: 700;
    font-size: 24px;
}

.content-box {
    justify-content: end;
    flex-grow: 1;
}

.content-box > div {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.component-pt08__img {
    flex-grow: 1;
    /*flex-basis: 50%;*/
}

.content-box__title-box--line:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 150%;
    margin-top: 60px;
    border-bottom: var(--borderColor) 2px solid;
    right: 0;
}

.reverse .component-pt08__img {
    justify-content: end;
}

.reverse .content-box {
    justify-content: start;
    margin-right: 0;
    margin-left: 16px;
}

.reverse .content-box__title-box--line:before {
    left: 0;
}

/*@media screen and (min-width: 800px ){*/
/*  .component-pt08 {*/
/*    width: 60%;*/
/*  }*/
/*}*/

/*@media screen and (min-width: 1200px ){*/
/*  .component-pt08 {*/
/*    width: 40%;*/
/*  }*/
/*}*/

@media screen and (max-width: 414px) {
    .component-pt08 {
        flex-direction: column;
    }
    .component-pt08__img {
        margin: 24px auto;
        order: -1;
    }

    .content-box.content-box {
        margin: 0;
        justify-content: center;
    }

    .content-box__title-box {
        position: relative;
        justify-content: flex-end;
    }
}
</style>
