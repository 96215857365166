<template>
  <label class="buttons-login" @click="headerButtonsEvent.onLogin">
    <i class="icon-user4"></i>
  </label>
</template>

<script>
export default {
  name: "Login",
  inject: ['headerButtonsEvent'],
}
</script>

<style scoped>
.buttons-login {
  display: flex;
  align-items: center;
}

.buttons-login i {
  font-size: 28px;
}

@media screen and (min-width: 1200px) {
  .buttons-login i {
    font-size: 21px;
  }
}
</style>
