<template>
  <div class="component component-a01" :style="componentColor">
    <div :class="{ isReverse: isReverse }" class="ContainerBlock">
      <div class="LeftContainer" :class="[positionClass]">
        <div class="imgBlock">
          <img :src="imgUrl" />
          <span :style="{ color: aboutColor }" class="aboutSubTitle">
            {{ aboutTextInImage }}
          </span>
        </div>
      </div>

      <div class="RightContainer">
        <div class="contentBlock">
          <h2 :style="{ color: titleColor }" class="aboutTitle">
            {{ aboutText }}
          </h2>

          <div class="introduceBlock">
            <img :src="logoUrl" class="logoBlock" />
            <div class="introduceList" :style="introducePositionStyle">
              <p
                v-for="(item, index) in introduceTextShow"
                :key="index"
                :style="{ color: introduceColor }"
              >
                {{ item }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import autoColor from "@/mixins/autoColor";

export default {
  name: "PictureText11",
  mixins: [autoColor],
  props: {
    imgUrl: String,
    logoUrl: String,
    isReverse: Boolean,
    introduceText: String,
    aboutText: String,
    titleColor: String,
    aboutTextInImage: String,
    aboutColor: String,
    lineColor: String,
    introduceColor: String,
    position: String,
    introducePosition: String
  },
  methods: {
    redirect() {
      location.href = this.$props.path;
    },
  },
  computed:{
    introduceTextShow() {
      console.log("introduceText.split", this.introduceText)
      return this.introduceText?.split('<br>') || ""
    },
    positionClass() {
      switch (this.position) {
        case 'top':
          return 'position-top';
        case 'center':
          return 'position-center';
        case 'bottom':
          return 'position-bottom';
        default:
          return 'top';
      }
    },
    introducePositionStyle(){
      switch (this.introducePosition) {
        case 'left':
          return  {textAlign: this.introducePosition};
        case 'center':
          return  {textAlign: this.introducePosition};
        case 'right':
          return {textAlign: this.introducePosition};
        default:
          return  null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.component .position-top {
}
.component .position-center {
  margin-top: auto;
  margin-bottom: auto;
}
.component .position-bottom {
  margin-top: auto;
}

.component-a01 {
  --aboutColor: #000;
  --titleColor: #000;
  --introduceColor: #000;
  --lineColor: #000;

  .ContainerBlock {
    display: flex;
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;

    @media (max-width: 414px) {
      flex-direction: column;
    }

    &.isReverse {
      @media (min-width: 415px) {
        flex-direction: row-reverse;
        .LeftContainer {
          //202
          //display: flex;
          //justify-content: flex-end;
        }

        .RightContainer {
          display: flex;
          justify-content: flex-end;
          .aboutTitle {
            text-align: left;
          }

          .introduceBlock {
            margin-right: 119px;
            padding-left: 109px !important;
            border-right: 0 !important;
            border-left: 1px solid var(--lineColor);
          }
        }
      }
    }

    .aboutTitle {
      font-size: 36px;
      font-weight: 700;
      text-align: right;
    }
    .LeftContainer,
    .RightContainer {
      @media (min-width: 415px) {
        width: 50%;
      }
    }

    .LeftContainer {
      .imgBlock {
        position: relative;
        @media (max-width: 414px) {
          margin-right: 49px;
        }
        .aboutSubTitle {
          font-size: 36px;
          position: absolute;
          top: 50%;
          right: 40px;
          transform: translateY(-50%);
          writing-mode: vertical-lr;
          @media (max-width: 414px) {
            font-size: 24px;
            right: auto;
            width: 100%;
          }
        }
        img {
          width: 100%;
          max-width: 670px;
          height: 670px;
          object-fit: cover;
          @media (max-width: 414px) {
            max-width: 365px;
            height: 365px;
            margin-top: 78px;
          }
        }
      }
    }

    .RightContainer {
      .contentBlock {
        max-width: 532px;
        height: 100%;
        .aboutTitle {
          padding-bottom: 64px;
          @media (max-width: 414px) {
            position: absolute;
            width: 414px;
            top: 0;
            left:0;
            padding-bottom: 0;
            transform: translateX(-50%);
          }
        }

        .introduceBlock {
          display: flex;
          flex-direction: column;
          height: calc(100% - 64px);
          padding-top: 50px;
          padding-bottom: 48px;
          padding-left: 118px;
          border-right: 1px solid var(--lineColor);

          @media (max-width: 414px) {
            display: flex;
            align-items: center;
            margin-top: 48px;
            padding: 0;
            text-align: center;
            border: none;
          }

          .introduceList {
            max-width: 304px;
            font-size: 16px;
            line-height: 32px;
            @media (max-width: 414px) {
              margin-top: 48px;
            }
          }
          .logoBlock {
            width: 100%;
            max-width: 224px;
            //height: 100%;
            //max-height: 224px;
            margin-bottom: auto;
            @media screen and (min-width: 415px){
                margin-bottom: 100px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 415px){
    .RightContainer .contentBlock .logoBlock {
      margin-bottom: 100px;
    }
  }
}
</style>
