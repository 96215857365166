<template>
    <div :class="['component pt04', { reverse: isReverse }]">
        <div class="pt04__content-box">
            <div class="pt04__content-box__title">
                <img v-if="iconURL" :src="iconURL" alt="icon" />
                <h4>
                    <base-text block-name="title" v-bind="$props" />
                </h4>
            </div>
            <p class="pt04__content-box__content">
                <base-text block-name="content" v-bind="$props" />
            </p>
        </div>
        <div class="pt04__img">
            <img :src="imgURL" alt="icon" />
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "PT04",
    components: {
        BaseText,
    },
    props: {
        imgURL: String,
        iconURL: String,
        ...generateTextProps("title"),
        ...generateTextProps("content"),
        isReverse: Boolean,
    },
};
</script>

<style scoped>
.pt04 {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.pt04.reverse {
    flex-direction: column;
}

.pt04__img {
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    order: -1;
    margin: auto;
    flex-basis: 40%;
}

.pt04__img img {
    width: 100%;
}

.pt04__img.square {
    border-radius: 0;
}

.pt04__content-box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 16px;

    justify-content: center;
}

.pt04.reverse .pt04__content-box {
    margin-left: 16px;
}

.pt04__content-box.between {
    justify-content: space-between;
}

.pt04__content-box__title {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.pt04__content-box__title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline-block;
    width: 100%;
}

.pt04__content-box__content {
    color: #6f6f6f;
}

.pt04__content-box__title img {
    max-width: 32px;
    padding-right: 16px;
}

.pt04__content-box__title h4 {
    font-weight: 700;
    font-size: 24px;
}

@media screen and (min-width: 415px) {
    .pt04 {
        flex-direction: row;
    }
    .pt04.reverse {
        flex-direction: row-reverse;
    }
    .pt04__img {
        order: unset;
    }
}
@media screen and (min-width: 800px) {
    .pt04 {
        width: 60%;
    }
}

@media screen and (min-width: 1200px) {
    .pt04 {
        width: 40%;
    }
}
</style>
