import Vue from "vue";
import PROD03 from "@/components/web-page/Product/PD03/index.vue";

import ProdCard01 from "@/components/web-page/Product/component/ProdCard01.vue";
import ProdCard02 from "@/components/web-page/Product/component/ProdCard02.vue";


Vue.component('ProdCard01', ProdCard01);
Vue.component('ProdCard02', ProdCard02);


export default {
  title: 'web-page/Product/PD03',
  argTypes: {
    classId: {
      control: "text",
      defaultValue: null
    },
    arrowColor: {
      control: "color",
      defaultValue: null
    },
    buttonColor: {
      control: "color",
      defaultValue: null
    },
    itemNum: {
      control: "text",
      defaultValue: 4
    },
    selectProd: {
      control: "select",
      options: ["ProdCard01", "ProdCard02"],
      defaultValue: 'ProdCard01'
    },
  }
}
// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {PROD03},
  template: `
    <div>
    <PROD03 v-bind="$props"/>
    </div>
  `
});

export const element = TemplatePT01.bind({});
