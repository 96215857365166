<template>
    <div class="component component-t09" :style="style">
        <div class="t09__wrapper">
            <div v-if="imgUrl" class="t09__img-wrapper">
                <img :src="imgUrl" alt="big-pic" />
            </div>
            <h2 v-if="titleContent" class="t09__title">
                <base-text block-name="title" v-bind="$props" />
            </h2>
            <p v-if="contentContent" class="t09__content">
                <base-text block-name="content" v-bind="$props" />
            </p>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "T09",
    components: {
        BaseText,
    },
    props: {
        imgUrl: String,
        position: String,
        ...generateTextProps("title"),
        ...generateTextProps("content"),
    },
    computed: {
        positionCss(){
            let position
            switch (this.position) {
                case 'left':
                    position = 'flex-start'
                    break;
                case 'center':
                    position = 'center'
                    break;
                case 'right':
                    position = 'flex-end'
                    break;
                default:
                    break;
            }
            return position
        },
        style() {
            return {
                "--position": this.positionCss,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-t09 {
    display: flex;
    justify-content: var(--position);
}

.t09__img-wrapper {
    width: 40px;
    height: 40px;
    margin: auto;
    margin-bottom: 16px;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.t09__wrapper {
    max-width: 1064px;
    width: fit-content;
    /* margin: auto; */
}

.t09__title {
    text-align: center;
    margin-bottom: 48px;
}

@media screen and (max-width: 800px) {
}
</style>
