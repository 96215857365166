import PT18 from "@/components/web-page/PictureText/PT18/index.vue";

export default {
    title: "web-page/PictureText/PT18",
    argTypes: {
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895215329.png",
        },
        mobileImgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895066858.png",
        },
        // title
        title: {
            control: "text",
            defaultValue: "我是大標題",
        },
        titleFontSize: {
            control: "number",
            defaultValue: 40,
        },
        titleColor: {
            control: "color",
            defaultValue: "black",
        },
        titleSpacing: {
            control: "number",
            defaultValue: 5 
        },
        // subtitle
        subTitle: {
            control: "text",
            defaultValue: "我是副標題",
        },
        subTitleFontSize: {
            control: "number",
            defaultValue: 20,
        },
        subTitleColor: {
            control: "color",
            defaultValue: "black",
        },
        subTitleSpacing: {
            control: "number",
            defaultValue: 5
        },
        // button
        buttonText: {
            control: "text",
            defaultValue: "BUTTON",
        },
        buttonTextColor: {
            control: "color",
            defaultValue: "white",
        },
        buttonColor: {
            control: "color",
            defaultValue: "#7E1C7E",
        },
        buttonClickColor: {
            control: "color",
            defaultValue: "#7E1C7E",
        },
        buttonTextSpacing: {
            control: "number",
            defaultValue: 5, 
        },
        link: {
            control: "text",
            defaultValue: "https://google.com.tw"
        }
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT18 },
    template: `
    <div>
      <PT18 v-bind="$props"/>
    </div>
  `,
});

export const pt18 = Template.bind({});
