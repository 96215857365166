<template>
    <div class="component-pic05" @click="redirect" :style="style">
        <img class="bg-mask" :src="getImage" alt="" />
        <div class="pic05__content">
            <div class="pic05__titleContainer">
                <h2 v-for="item of brNextLine(title)" :key="item">
                    {{ item }}
                </h2>
            </div>
        </div>
        <div class="pic05__overlay"></div>
    </div>
</template>

<script>
export default {
    name: "picture05",
    props: {
        imgUrl: String,
        mobileImgUrl: String,
        title: String,
        titleFontSize: String,
        titleColor: String,
        borderColor: String,
        borderSize: String,
        path: String,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        resize() {
            let w = window.innerWidth;
            this.isMobile = w <= 415;
        },
        brNextLine(str) {
            return str?.split("<br>") || "";
        },
        redirect() {
            if (this.$props.path !== "" && this.$props.path !== undefined) {
                window.open(this.$props.path);
            }
        },
    },
    computed: {
        style() {
            return {
                "--cardHoverColor": this.cardHoverColor,
                "--titleColor": this.titleColor,
                "--titleFontSize": this.titleFontSize,
                "--borderColor": this.borderColor,
                "--borderSize": this.borderSize,
            };
        },
        getImage() {
            if (this.isMobile) {
                return this.mobileImgUrl;
            }
            return this.imgUrl;
        },
    },
    mounted() {
        window.addEventListener("resize", this.resize, false);
    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    },
};
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    display: block;
}
.component-pic05 {
    width: 100%;
    position: relative;
    text-align: center;
}
.pic05__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pic05__titleContainer {
    font-weight: 700;
    font-size: var(--titleFontSize);
    color: var(--titleColor);
    margin-bottom: 16px;
    letter-spacing: var(--titleSpacing);
    border: var(--borderSize) var(--borderColor) solid;
    padding: 0px 20px 0px 20px;
    box-shadow: 4px 4px 2px 0px #00000040;
}
.pic05__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(255, 255, 255, 0); /* 设置遮罩颜色和透明度 */
    transition: background-color 0.5s ease;
}
.component-pic05:hover .pic05__overlay {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); /* 鼠标悬停时的透明度为0.5 */
}
</style>
