export default {
  props: {
    prodId: Number,
    imageURL: String,
    title: String,
    content: String,
    seoUrl: String,
    price: Number,
    sellingPrice: Number,
    data: {
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    onProductClick() {
      if (this.$router) {
        this.$router.push({
          path: `/products/${encodeURIComponent(this.seoUrl)}`
        })
      } else {
        window.location.href = `/products/${encodeURIComponent(this.seoUrl)}`
      }
    }
  }
}
