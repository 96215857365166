import component from "./index.vue";
import stories from './index.stories.js';
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";
export default {
  componentGroup: componentEnum.Text,
  grids: {...GridEnum},
  icon: 'mdi-view-dashboard',
  name: 'Text T03',
  type:'container',
  component: component,
  argTypes: stories.argTypes
}
