<template>
  <ul class="list inner-list">
    <li class="list-item" v-for="lang of langListShow" :key="lang.code">
      <a class="item-link" href="#" @click.prevent="headerButtonsEvent.onLanguage(lang.code)">
        {{ lang.name }}
      </a>
    </li>
  </ul>
</template>

<script>
//顯示側選單時用的
import headerLangMixin from "@/mixins/header/headerLangMixin";

export default {
  name: "LanguageList",
  mixins: [headerLangMixin],
  inject: ['headerButtonsEvent'],
}
</script>

<style src="../../../css/header.menu.css" scoped></style>
<style scoped>

</style>
