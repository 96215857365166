<template>
    <div class="component component-pic09" :style="style">
        <div class="pic09__grid-container" :class="{ reverse: reverse }">
            <!-- big-pic -->
            <div
                class="pic09__big-pic"
                @mouseover="setHoverVariable('bigPic')"
                @mouseleave="setHoverVariable('bigPic')"
                @click="redirect(bigPic.link)"
            >
                <img :src="bigPic.imgUrl" alt="big-pic" />
                <div class="pic09__cover"></div>
                <base-text block-name="title" v-bind="bigPic" />
            </div>
            <!-- small-up-pic -->
            <div
                class="pic09__small-up-pic"
                @mouseover="setHoverVariable('smallUpPic')"
                @mouseleave="setHoverVariable('smallUpPic')"
                @click="redirect(smallUpPic.link)"
            >
                <img :src="smallUpPic.imgUrl" alt="small-up-img" />
                <div class="pic09__cover"></div>
                <base-text block-name="title" v-bind="smallUpPic" />
            </div>
            <!-- small-down-left-pic -->
            <div
                class="pic09__small-down-left-pic"
                @mouseover="setHoverVariable('smallDownLeftPic')"
                @mouseleave="setHoverVariable('smallDownLeftPic')"
                @click="redirect(smallDownLeftPic.link)"
            >
                <img :src="smallDownLeftPic.imgUrl" alt="small-down-left-img" />
                <div class="pic09__cover"></div>
                <base-text block-name="title" v-bind="smallDownLeftPic" />
            </div>
            <!-- small-down-right-pic -->
            <div
                class="pic09__small-down-right-pic"
                @mouseover="setHoverVariable('smallDownRightPic')"
                @mouseleave="setHoverVariable('smallDownRightPic')"
                @click="redirect(smallDownRightPic.link)"
            >
                <img
                    :src="smallDownRightPic.imgUrl"
                    alt="small-down-right-img"
                />
                <div class="pic09__cover"></div>
                <base-text block-name="title" v-bind="smallDownRightPic" />
            </div>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";

export default {
    name: "picture09",
    components: {
        BaseText,
    },
    props: {
        bigPic: Object,
        smallUpPic: Object,
        smallDownLeftPic: Object,
        smallDownRightPic: Object,
        reverse: Boolean,
    },
    data() {
        return {
            isHoverBigPic: false,
            isHoverSmallUpPic: false,
            isHoverSmallDownLeftPic: false,
            isHoverSmallDownRightPic: false,
        };
    },
    methods: {
        objectFlatten(obj) {
            let result = {};

            for (const key in obj) {
                let type = typeof obj[key];
                if (type === "object") {
                    result[key] = obj[key].defaultValue;
                } else {
                    result[key] = obj[key];
                }
            }
            return result;
        },
        setHoverVariable(target) {
            switch (target) {
                case "bigPic":
                    this.isHoverBigPic = !this.isHoverBigPic;
                    break;

                case "smallUpPic":
                    this.isHoverSmallUpPic = !this.isHoverSmallUpPic;
                    break;

                case "smallDownLeftPic":
                    this.isHoverSmallDownLeftPic =
                        !this.isHoverSmallDownLeftPic;
                    break;
                case "smallDownRightPic":
                    this.isHoverSmallDownRightPic =
                        !this.isHoverSmallDownRightPic;
                    break;
                default:
                    console.log("target incorrect");
                    break;
            }
        },
        redirect(path) {
            if (path) {
                window.open(path);
            } else {
                console.log("there are no path");
            }
        },
    },
    computed: {
        style() {
            return {
                "--big-pic-hover": this.bigPic.hoverImgBgColor,
                "--small-up-pic-hover": this.smallUpPic.hoverImgBgColor,
                "--small-down-left-pic-hover":
                    this.smallDownLeftPic.hoverImgBgColor,
                "--small-down-right-pic-hover":
                    this.smallDownRightPic.hoverImgBgColor,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-pic09 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 50px auto;
}

.pic09__grid-container {
    max-width: 1096px;
    /* max-height: 650px; */
    display: grid;
    grid-auto-columns: minmax(auto, 735px);
    grid-auto-rows: minmax(auto, 315px);
    gap: 20px;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pic09__big-pic,
.pic09__small-up-pic,
.pic09__small-down-left-pic,
.pic09__small-down-right-pic {
    grid-column: 1/4;
    grid-row: 1/3;
    position: relative;
    cursor: pointer;
    z-index: 1;
    .base-text {
        width: max-content;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
    }
    .pic09__cover {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 127px;
        background: white;
        opacity: 0;
        z-index: 5;
    }
}

.pic09__small-up-pic {
    grid-column: 4/6;
    grid-row: 1/2;
}

.pic09__small-down-left-pic {
    grid-column: 4/5;
    grid-row: 2/3;
}

.pic09__small-down-right-pic {
    grid-column: 5/6;
    grid-row: 2/3;
}
/* reverse */
.pic09__grid-container.reverse .pic09__big-pic {
    grid-column: 3/6;
}

.pic09__grid-container.reverse .pic09__small-up-pic {
    grid-column: 1/3;
}

.pic09__grid-container.reverse .pic09__small-down-left-pic {
    grid-column: 1/2;
}

.pic09__grid-container.reverse .pic09__small-down-right-pic {
    grid-column: 2/3;
}

.pic09__big-pic:hover,
.pic09__small-up-pic:hover,
.pic09__small-down-left-pic:hover,
.pic09__small-down-right-pic:hover {
    .pic09__cover {
        opacity: 0.5;
    }
}

.pic09__big-pic:hover .base-text {
    color: var(--big-pic-hover);
}

.pic09__small-up-pic:hover .base-text {
    color: var(--small-up-pic-hover);
}

.pic09__small-down-left-pic:hover .base-text {
    color: var(--small-down-left-pic-hover);
}

.pic09__small-down-right-pic:hover .base-text {
    color: var(--small-down-right-pic-hover);
}

@media screen and (max-width: 800px) {
    .component-pic09{
        margin: 0;
    }
    .pic09__big-pic,
    .pic09__small-up-pic,
    .pic09__small-down-left-pic,
    .pic09__small-down-right-pic,
    .pic09__grid-container.reverse .pic09__big-pic,
    .pic09__grid-container.reverse .pic09__small-up-pic,
    .pic09__grid-container.reverse .pic09__small-down-left-pic,
    .pic09__grid-container.reverse .pic09__small-down-right-pic {
        grid-column: 1/6;
        grid-row: span 1;
    }
}
</style>
