<template>

  <div class="component component-pt01"
       :class="{ contentMiddle: isContentMiddle }"
       :style="{...cssProps, ...contentPositionStyle}">

    <div class="imageContainer">
      <img :src="imgURL" :alt="imgAlt"/>
    </div>

    <h4 class="title" :class="{boldHeader: isBoldHeader}">{{title}}</h4>

    <div class="content">
      <template v-for="(text, index) of content.split('<br>')">
        <p :key="index">{{text}}</p>
      </template>
    </div>

  </div>

</template>

<script>
export default {
  name: "PT01",
  props: {
    title: String,
    content: String,
    titleColor: String,
    contentColor: String,
    margin: Number,
    padding: Number,
    isContentMiddle: Boolean,
    isBoldHeader: Boolean,
    imgURL: String,
    imgAlt: String,
    contentPosition: String
  },
  computed: {
    cssProps() {
      return {
        '--titleColor': this.titleColor,
        '--contentColor': this.contentColor,
        '--margin':`${this.margin}px`,
        '--padding':`${this.padding}px`,
      }
    },
    contentPositionStyle(){
      switch (this.contentPosition) {
        case 'left':
          return  {textAlign: this.contentPosition};
        case 'center':
          return  {textAlign: this.contentPosition};
        case 'right':
          return {textAlign: this.contentPosition};
        default:
          return  null;
      }
    }
  }
}
</script>

<style scoped>
.component-pt01{
  --margin: 0;
  --padding: 0;
  --contentColor: #000;

  width: 100%;
}

.imageContainer{
  width: 100%;
}

img {
  width: 100%;
  object-fit: cover;
}

.title{
  color: var(--titleColor);
  font-size:20px;
  margin: var(--margin);
  padding: var(--padding);
}

.content{
  color: var(--contentColor);
  margin: var(--margin);
  padding: var(--padding);
}

.contentMiddle {
  text-align: center;
}

.boldHeader{
  font-weight:700;
}

</style>
