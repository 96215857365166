<template>
  <div class="v01">
    <BaseYoutube :video-src="videoUrl"/>
  </div>
</template>

<script>
import BaseYoutube from "@/components/base/BaseYoutube.vue";
export default {
  name: "V01",
  components: {BaseYoutube},
  props: {
    videoUrl: String
  }
}
</script>

<style scoped>
  .v01 {
    width: 100%;
  }
</style>
