export const weekOptions = [
    {
        label: "星期一",
        value: 1,
    },
    {
        label: "星期二",
        value: 2,
    },
    {
        label: "星期三",
        value: 3,
    },
    {
        label: "星期四",
        value: 4,
    },
    {
        label: "星期五",
        value: 5,
    },
    {
        label: "星期六",
        value: 6,
    },
]


export const serviceTimeOptions = [
    {
        label: "AM 09:00~12:00",
        value: "morning",
    },
    {
        label: "PM 13:00~17:00",
        value: "afternoon",
    },
    {
        label: "PM 18:00~21:00(不含週六)",
        value: "night",
    },
]


export const subscriptionOptions = [
    {
        label: "1個月",
        value: 1,
    },
    {
        label: "3個月",
        value: 3,
    },
    {
        label: "半年",
        value: 6,
    },
]
