import T06 from "@/components/web-page/Text/T06/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/Text/T06",
    argTypes: {
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "大標題",
            sizeDefault: "36px",
            colorDefault: "black",
            spacingDefault: "1px",
            lineSpacingDefault: "200px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "小標題",
            sizeDefault: "16px",
            colorDefault: "black",
            spacingDefault: "1px",
            lineSpacingDefault: "99px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sitfulpa duis.",
            sizeDefault: "16px",
            colorDefault: "#000",
            spacingDefault: "1px",
            lineSpacingDefault: "200px",
        }),
        // line color
        lineColor: {
            control: "color",
            defaultValue: "black",
        },
        // alignOption
        horizontalAlignOption: {
            control: "select",
            options: ["left", "center", "right"],
            defaultValue: "center",
        },
        verticalAlignOption: {
            control: "select",
            options: ["top", "center", "bottom"],
            defaultValue: "center",
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { T06 },
    template: `
    <div>
      <T06 v-bind="$props"/>
    </div>
  `,
});

export const t06 = Template.bind({});
