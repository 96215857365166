import Element from "./index.vue";

export default {
  title: 'web-page/Controls/Textarea',
  argTypes: {
    title: {
      control: 'text',
      defaultValue: "文字標題"
    },
    placeholder: {
      control: 'text',
      defaultValue: "你的回答"
    },
    rows: {
      control: 'number',
      defaultValue: 3
    },
    required: {
      control: "boolean",
      defaultValue: false
    },
  }
}

const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
    <div>
      <Element v-bind="$props"/>
      <Element v-bind="$props"/>
    </div>
  `
});

export const element1 = Template.bind({});
