import PT07 from "@/components/web-page/PictureText/PT07/index.vue";

export default {
  title: 'web-page/PictureText/PT07',
  argTypes: {
    imgURL: {
      control: {
        type: "text",
        editType: "image"
      },
      defaultValue: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/image(large).png"
    },
    title1: {
      control: "text",
      defaultValue: "ShopInn"
    },
    title1FontColor: {
      control: "color",
      defaultValue: "#000"
    },
    title2: {
      control: "text",
      defaultValue: "網站開發界的樂高"
    },
    title2FontColor: {
      control: "color",
      defaultValue: "#000"
    },
    title3: {
      control: "text",
      defaultValue: "自由組建"
    },
    title3FontColor: {
      control: "color",
      defaultValue: "#000"
    },
    icon: {
      control: "text",
      defaultValue: "icon-circle-arrow-down"
    },
    iconColor: {
      control: "color",
      defaultValue: "#000"
    },
    isReverse: {
      control: "boolean",
      defaultValue: false,
    },
  }
}

// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {PT07},
  template: `
    <div style="width: 100%;margin:auto;">
    <PT07 v-bind="$props"/>
    </div>
  `
});

export const pt07 = TemplatePT01.bind({});
