import T09 from "@/components/web-page/Text/T09/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/Text/T09",
    argTypes: {
        // img
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/demo.png",
        },
        position: {
            control: "select",
            options: ['left', 'center', 'right'],
            defaultValue: 'center'
        },
        ...generateTextArgTypes("title", {
            contentDefault: "我是標題",
            sizeDefault: "40px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文",
            sizeDefault: "14px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { T09 },
    template: `
    <div>
      <T09 v-bind="$props"/>
    </div>
  `,
});

export const t09 = Template.bind({});
