<template>
  <div class="base base-hooper" :style="styleVar">
    <hooper ref="hooper" :mouseDrag="false" :playSpeed="3000" :items-to-show="3" :settings="{
        mouseDrag: false,
        keysControl: false,
        breakpoints: {
        1360: {
          itemsToShow: 3,
          itemsToSlide: 3
        },
        415: {
          itemsToShow: 2,
          itemsToSlide: 2
        },
        0: {
          itemsToShow: 1,
          itemsToSlide: 1
        }
      }
      }">
      <template #default>
        <slide v-for="item of items" :key="item.key">
          <slot v-bind="item" />
        </slide>
      </template>

      <template #hooper-addons>
        <navigation />
      </template>
    </hooper>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
} from 'hooper';
import Navigation from "./Navigation";

export default {
  name: "BaseHooper",
  components: {Hooper, Slide, Navigation},
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    hooperWidth: String,
    hooperWidthMobile: String
  },
  computed: {
    styleVar() {
      return {
      '--hooper-width': this.hooperWidth ? this.hooperWidth : '75.33%',
      '--hooper-width-mobile': this.hooperWidthMobile ? this.hooperWidthMobile : '75.33%'
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.$refs.hooper.update();
    }, false);
  }
}
</script>

<style scoped>
.base {
  --hooper-width: 75.33%;
  --hooper-width-mobile: 75.33%;
}
.base ul {
  padding: 0;
}

:deep(.hooper) {
  position: static;
  box-sizing: border-box;
  width: var(--hooper-width);
  margin: 0 auto;
  color: inherit;
  height: auto;
}

:deep(.hooper-slide) {
  height: auto;
  padding: 5px 0;
}

:deep(.hooper .hooper-list .hooper-track){
  padding: 0;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 415px) {
  .hooper {
    width: var(--hooper-width-mobile);
  }
}
</style>
