<template>
  <div class="component component-f02" :style="style">
    <div class="footer__main">
      <div class="footer__description">
        <div class="footer__logo">
          <img :src="logoUrl" alt="logo" />
        </div>
        <div class="footer__description__content footer__description-md">
          <base-text block-name="descriptionTitle" v-bind="{ ...$props }" />
          <base-text block-name="descriptionSubtitle" v-bind="{ ...$props }" />
        </div>
      </div>
      <div :class="['footer__info', `footer-col-${col}`]">
        <div class="footer__card" v-for="(item, index) in items" :key="index">
          <div v-html="item.htmlText"></div>
        </div>
      </div>
      <div class="footer__description">
        <div class="footer__description__content footer__description-lg">
          <base-text block-name="descriptionTitle" v-bind="{ ...$props }" />
          <base-text block-name="descriptionSubtitle" v-bind="{ ...$props }" />
        </div>
        <div class="footer__map">
          <embedded-google-map v-bind="$props" />
        </div>
        <div class="footer__links">
          <a
            v-for="(link, index) in links"
            :key="index"
            class="footer__link"
            target="_blank"
            :href="link.href"
          >
            <i :class="`icon-${link.icon}`" :style="`color:${link.color}`"></i>
          </a>
        </div>
      </div>
    </div>
    <footer-copyright
      :copyrightText="copyrightText"
      :copyrightLink="copyrightLink"
      :copyrightBackgroundColor="copyrightBackgroundColor"
      :copyrightTextColor="copyrightTextColor"
    />
  </div>
</template>

<script>
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';
import EmbeddedGoogleMap from '@/components/base/EmbeddedGoogleMap';
import embeddedGoogleMapProps from '@/models/embeddedGoogleMap';
import FooterCopyright from '@/components/base/FooterCopyright/FooterCopyright';

export default {
  name: 'F05',
  components: { BaseText, EmbeddedGoogleMap, FooterCopyright },
  props: {
    logoUrl: String,
    items: () => [],
    links: () => [],
    copyrightText: String,
    copyrightLink: () => [],
    copyrightBackgroundColor: String,
    copyrightTextColor: String,
    backgroundColor: String,
    ...generateTextProps('descriptionTitle'),
    ...generateTextProps('descriptionSubtitle'),

    ...embeddedGoogleMapProps,
  },
  computed: {
    col() {
      return this.items.length > 2 ? 3 : this.items.length % 3;
    },
    style() {
      return {
        '--backgroundColor': this.backgroundColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.footer__main {
  display: flex;
  gap: 48px;
  position: relative;
  justify-content: space-between;
  background-color: var(--backgroundColor);

  .footer__description {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__content {
      & > * {
        display: block;
        text-align: right;
      }
      & > *:nth-child(1) {
        margin-bottom: 10px;
      }
    }

    .footer__links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: fit-content;

      gap: 16px;

      .footer__link {
        line-height: 1;
        i {
          font-size: 24px;
        }
      }
    }
    .footer__map {
      width: 188px;
      aspect-ratio: 1;
    }
  }

  .footer__info {
    display: flex;
    flex-wrap: wrap;

    .footer__card {
    }
  }
  .footer__logo {
    width: 140px;
    img {
      width: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: 767px) {
  .component-f02 {
  }
  .footer__main {
    padding: 48px 11.83%;

    flex-direction: column;

    .footer__description {
      &:nth-child(1) {
        flex-direction: row;
        justify-content: space-between;
      }

      .footer__description-lg {
        display: none;
      }
    }
    .footer__info {
      gap: 20px 5%;

      &.footer-col-2 .footer__card {
        width: 47.5%;
      }
      &.footer-col-1 .footer__card {
        width: 100%;
      }
      .footer__card {
        width: 47.5%;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .component-f02 {
  }
  .footer__main {
    padding: 64px 10.88%;

    align-items: flex-start;

    .footer__description {
      &:nth-child(1) {
        width: 140px;
      }
      &:nth-child(3) {
        width: 188px;
      }
      .footer__description-md {
        display: none;
      }
    }
    .footer__info {
      width: 0;
      flex: 1;
      max-width: 59.22%;

      gap: 24px 3.1%;

      &.footer-col-2 .footer__card {
        width: 48.45%;
      }
      &.footer-col-1 .footer__card {
        width: 100%;
      }
      .footer__card {
        width: 48.45%;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .footer__main {
    .footer__info {
      &.footer-col-3 .footer__card {
        width: 31.26%;
      }
      &.footer-col-2 .footer__card {
        width: 48.45%;
      }
      &.footer-col-1 .footer__card {
        width: 100%;
      }
    }
  }
}
</style>
