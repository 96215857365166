const apiURL = Symbol('apiURL');
const langTag = Symbol('apiURL');
const addCart = Symbol('addCart');

const token = Symbol('token');
const refURL = Symbol('refURL');

const successMsgFn = Symbol('smsg');
const errorMsgFn = Symbol('emsg');




export {
  apiURL,
  langTag,
  addCart,
  token,
  refURL,
  errorMsgFn,
  successMsgFn
}
