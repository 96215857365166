<template>
  <LayoutGrid v-bind="$props" columns="3" class="g4_3">
    <slot/>
  </LayoutGrid>
</template>

<script>
import LayoutGrid from "@/layout/grid/LayoutGrid";
import layout from "@/layout/mixins/layout";

export default {
  name: "G4_3",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style lang="scss">
.g4_3 > *:nth-child(2n + 1) {
  grid-column: 1 / 3;
}
.g4_3 > *:nth-child(2n) {
  grid-column: 3 / 3;
}
</style>
