<template>
  <div class="component component-qa02" :style="componentColor">

    <header class="q2-header">
      <h2 class="q2-title">
        <base-text block-name="title" v-bind="$props"></base-text>
      </h2>

      <p class="q2-content" v-html="contentHTML"></p>
    </header>


    <article class="qa02-container-inner">
      <Collapse
          v-model="activeTab"
          accordion
          class="collapseContainer"
          @change="handleChange"
          :style="{'--borderColor': iconColor}"
      >
        <CollapseItem
            v-for="item in listItem"
            :key="item.id"
            :id="'qa-'+item.id"
            :name="String(item.id)"
            :style="borderWidthStyle"
        >
          <template #title>
            <div class="titleBlock">
              <base-text block-name="subTitle"
                         v-bind="{...$props, subTitleContent: item.title}">
              </base-text>
            </div>
          </template>
          <div
              :style="{ '--dot-color': contentColor }"
              class="contentBlock"
              v-html="item.content"
          ></div>

        </CollapseItem>
      </Collapse>
    </article>
  </div>
</template>

<script>
import {Collapse, CollapseItem} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BaseText from "@/components/base/BaseText.vue";
import {generateTextProps} from "@/components/base/javaScript/baseTest";
import autoColor from "@/mixins/autoColor";

export default {
  name: "QuestionAnswer02",
  components: {
    BaseText,
    Collapse,
    CollapseItem,
  },
  mixins: [autoColor],
  props: {
    listItem: Array,
    borderColor: String,
    arrowColor: String,
    bgColor: String,
    contentColor: String,
    contentHTML: String,
    borderWidth: Number,
    ...generateTextProps('title'),
    ...generateTextProps('subTitle')
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  methods: {
    handleChange(name) {
      if (name) {
        const newUrl = location.href.split("#")[0];
        location.replace(`${newUrl}#${name}`);
      }
    },
  },
  computed: {
    borderWidthStyle() {
      return {'--border-width': this.borderWidth + "%"}
    }
  },
  mounted() {
    const formatUrl = location.href;
    this.activeTab = formatUrl.split("#")[1];

    const objControl = document.getElementById('qa-' + this.activeTab);
    if (objControl) {
      window.scrollTo(0, objControl.getBoundingClientRect().bottom);
    }
  },
};
</script>

<style lang="scss">
.component-qa02 {
  /*變數宣告*/
  --arrowColor: #bd9b7b;
  --borderColor: #EBEEF5;

  max-width: 316px;
  margin: 0 auto;

  .q2-header {
    padding-bottom: 49px;

    .q2-title {
      font-weight: 700;
      padding-bottom: 48px;
      text-align: center;
    }

    .q2-content {
    }

  }

  .qa02-container-inner {

    .collapseContainer {
      border: none;

      .titleBlock {
        display: flex;
        align-items: center;

        .order {
          font-weight: 700;
          margin-right: 24px;
        }
      }

      .el-collapse-item {
        margin-bottom: 24px;

        .el-collapse-item__header {
          line-height: 1.4;
          min-height: 24px;
          height: auto;
          font-size: 16px;
          width: 100%;
          color: #000;
          max-width: 522px;
          margin-bottom: 0;
          border-bottom-color: transparent;
          background-color: transparent;
          position: relative;


          &::after {
            content: "";
            position: absolute;
            padding-top: 8px;
            top: 100%;
            display: block;
            width: 0;
            transition: width .3s ease;
            border-bottom: 2px solid var(--borderColor);
          }


          &.is-active::after {
            width: var(--border-width);
          }

          //border-bottom: 1px solid var(--borderColor);

          &.is-active {
            font-weight: 700;
            //border-bottom-color: 1px solid var(--borderColor);
          }
        }
      }

      .el-collapse-item__wrap {
        border: 0;
        //border-top: 1px solid #EBEEF5;
        border-bottom-color: #d2d2d2;
        background-color: transparent;
      }

      .el-collapse-item__content {
        max-width: 532px;
        margin: 0 auto;
        padding-top: 17px;
        background-color: transparent;
      }

      .el-collapse-item__arrow {
        font-family: "icomoon", serif !important;
        color: #FFF;

        &:before {
          content: "\e907";
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.5));
        }

        &.is-active:before {
          content: "\e901";
        }

        &.is-active {
          color: var(--arrowColor);
          transform: rotate(360deg);
        }
      }

      .listTitle {
        font-size: 24px;
        line-height: 28px;
        margin-left: 16px;
      }

      .contentBlock {
        --dot-color: #000;
        background-color: var(--bgColor);
        border-radius: 4px;
        padding: 8px;

        li {
          font-size: 16px;
          line-height: 22px;
          position: relative;
          margin-left: 16px;

          &,
          span,
          p {
            color: var(--dot-color);
          }

          &:before {
            position: absolute;
            top: 50%;
            left: -16px;
            width: 5px;
            height: 5px;
            content: "";
            transform: translateY(-50%);
            border-radius: 9999px;
            background-color: var(--dot-color);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .component-qa02 {
    max-width: 522px;
  }
}
</style>
