<template>
  <span class="base-text" :style="styles">
    <slot name="text-header"/>
    <template v-for="(text, index) of content.split('<br>')">
      <slot v-bind="{text}">
        <span :key="index" class="inner"> {{ text }} </span>
      </slot>
    </template>
  </span>
</template>

<script>
export default {
  name: "BaseText",
  props: {
    //-------------------
    blockName: {
      type: String,
      default: "",
      required: true
    },
    //---------內容別名----------
    contentName: String,
    //---------樣式別名----------
    sizeName: String,
    padSizeName: String,
    desktopSizeName: String,
    lineSpacingName: String,
    colorName: String,
    letterSpacingName: String
  },
  methods: {
    makeStylesName(propsName, attrName) {
      return propsName ?
          this.$attrs[propsName] !== undefined ?
              this.$attrs[propsName] : this.$attrs[`${this.blockName}${attrName}`]
          : this.$attrs[`${this.blockName}${attrName}`];
    }
  },
  computed: {
    content() {
      let content = this.$attrs[`${this.blockName}Content`] ? this.$attrs[`${this.blockName}Content`] : "";
      return this.contentName ?
          this.$attrs[this.contentName] !== undefined ?
              this.$attrs[this.contentName] : content
          : content;
    },
    styles() {
      //------文字顏色------
      let color = this.makeStylesName(this.colorName, 'FontColor');
      //------文字大小------
      let size = this.makeStylesName(this.sizeName, 'FontSize');
      let padSize = this.makeStylesName(this.padSizeName, 'PadFontSize');
      let desktopSize = this.makeStylesName(this.desktopSizeName, 'DesktopFontSize');
      //-----文字間距------
      let spacing = this.makeStylesName(this.letterSpacingName, 'Spacing');
      //-----文字行距------
      let lineSpacing = this.makeStylesName(this.lineSpacingName, 'LineSpacing');
      return {
        '--font-color': color,
        '--font-size': size,
        '--pad-font-size': padSize || size,
        '--desktop-font-size': desktopSize || padSize || size,
        '--letter-spacing': spacing ,
        '--line-spacing': lineSpacing ? lineSpacing : 0
      }
    }
  }
};
</script>

<style scoped>
.base-text {
  font-family: Arial, sans-serif, serif;
  display: inline-block;

  color: var(--font-color);
  font-size: var(--font-size);
  letter-spacing: var(--letter-spacing);
}

.base-text .inner {
  display: block;
  line-height: 1.4;
  padding-bottom: var(--line-spacing);
}

.base-text .inner:last-child {
  padding-bottom: 0;
}

@media screen and (min-width: 768px) {
  .base-text {
    font-size: var(--pad-font-size);
  }
}

@media screen and (min-width: 1200px) {
  .base-text {
    font-size: var(--desktop-font-size);
  }
}
</style>
