const colorSetting = {
  bgColor: {
    control: "color",
    defaultValue: null
  },
  textColor: {
    control: "color",
    defaultValue: null
  },
  buttonTextColor: {
    control: "color",
    defaultValue: null
  },
  buttonColor: {
    control: "color",
    defaultValue: null
  },
  hoverLevel1Color: {
    control: "color",
    defaultValue: null
  },
  hoverBgLevel1Color: {
    control: "color",
    defaultValue: null
  },
  hoverLevel2Color: {
    control: "color",
    defaultValue: null
  },
  hoverBgLevel2Color: {
    control: "color",
    defaultValue: null
  },
  hoverLevel3Color: {
    control: "color",
    defaultValue: null
  },
  hoverBgLevel3Color: {
    control: "color",
    defaultValue: null
  },
  activeLevel1Color: {
    control: "color",
    defaultValue: null
  },
  activeBgLevel1Color: {
    control: "color",
    defaultValue: null
  },
  activeLevel2Color: {
    control: "color",
    defaultValue: null
  },
  activeBgLevel2Color: {
    control: "color",
    defaultValue: null
  },
  activeLevel3Color: {
    control: "color",
    defaultValue: null
  },
  activeBgLevel3Color: {
    control: "color",
    defaultValue: null
  },
}
export const testData = [
  {
    "contentId": 1,
    "sequence": 1,
    "contentType": 1,
    "action": 1,
    "url": "www.google.com",
    "contentName": "賓士轎車",
    "children": []
  },
  {
    "contentId": 2,
    "sequence": 1,
    "contentType": 1,
    "action": 1,
    "url": "www.google.com",
    "contentName": "關於我們",
    "children": [
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "產品",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "A商品",
            "children": []
          },
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "B商品",
            "children": []
          },
        ]
      },
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "公司成員",
            "children": []
          }
        ]
      },
    ]
  },
  {
    "contentId": 2,
    "sequence": 1,
    "contentType": 1,
    "action": 1,
    "url": "#",
    "contentName": "預約進廠保養",
    "children": [
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "產品",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "A商品",
            "children": []
          },
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "B商品",
            "children": []
          },
        ]
      },
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "公司故事",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "公司成員",
            "children": []
          }
        ]
      },
    ]
  },
  {
    "contentId": 2,
    "sequence": 1,
    "contentType": 1,
    "action": 1,
    "url": "#",
    "contentName": "關於我們",
    "children": [
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "產品",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "A商品",
            "children": []
          },
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "B商品",
            "children": []
          },
        ]
      },
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "公司故事",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "公司成員",
            "children": []
          }
        ]
      },
    ]
  },
  {
    "contentId": 2,
    "sequence": 1,
    "contentType": 1,
    "action": 1,
    "url": "#",
    "contentName": "關於我們",
    "children": [
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "產品",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "A商品",
            "children": []
          },
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "B商品",
            "children": []
          },
        ]
      },
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "公司故事",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "公司成員",
            "children": []
          }
        ]
      },
    ]
  },{
    "contentId": 2,
    "sequence": 1,
    "contentType": 1,
    "action": 1,
    "url": "#",
    "contentName": "關於我們",
    "children": [
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "產品",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "A商品",
            "children": []
          },
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "B商品",
            "children": []
          },
        ]
      },
      {
        "contentId": 1,
        "sequence": 1,
        "contentType": 1,
        "action": 1,
        "url": "#",
        "contentName": "公司故事",
        "children": [
          {
            "contentId": 1,
            "sequence": 1,
            "contentType": 1,
            "action": 1,
            "url": "#",
            "contentName": "公司成員",
            "children": []
          }
        ]
      },
    ]
  }
]
export const headerColor = {
  props: {
    bgColor: String,
    textColor: String,
    buttonTextColor: String,
    buttonColor: String,
    //hover
    hoverLevel1Color: String,
    hoverBgLevel1Color: String,
    hoverLevel2Color: String,
    hoverBgLevel2Color: String,
    hoverLevel3Color: String,
    hoverBgLevel3Color: String,
    // active
    activeLevel1Color: String,
    activeBgLevel1Color: String,
    activeLevel2Color: String,
    activeBgLevel2Color: String,
    activeLevel3Color: String,
    activeBgLevel3Color: String
  },

  computed: {
    styleColor() {
      return {
        "--bg-color": this.bgColor,
        "--text-color": this.textColor,
        "--button-text-color": this.buttonTextColor,
        "--button-color": this.buttonColor,
        //hover
        "--hover-level1-color": this.hoverLevel1Color,
        "--hover-bg-level1-color": this.hoverBgLevel1Color,
        "--hover-level2-color": this.hoverLevel2Color,
        "--hover-bg-level2-color": this.hoverBgLevel2Color,
        "--hover-level3-color": this.hoverLevel3Color,
        "--hover-bg-level3-color": this.hoverBgLevel3Color,
        //active
        "--active-level1-color": this.activeLevel1Color,
        "--active-bg-level1-color": this.activeBgLevel1Color,
        "--active-level2-color": this.activeLevel2Color,
        "--active-bg-level2-color": this.activeBgLevel2Color,
        "--active-level3-color": this.activeLevel3Color,
        "--active-bg-level3-color": this.activeBgLevel3Color,
      }
    }
  }
}
export const mobileActive = {
  props: {
    //parents
    isRootItem: Boolean,
    //parents
    isRootActive: Boolean,
    //parents
    activeInstance: Object,
  },
  watch: {
    isRootActive(bool) {
      //if parents is close，child close to
      if (bool === false) {
        this.$emit('active', null);
      }
    }
  },
  computed: {
    //child
    isActive() {
      return this === this.activeInstance;
    },
    hasChildren() {
      return this.item.children && this.item.children.length > 0
    },
  },
  methods: {
    //parents
    onActive(instance) {
      // active to activeInstance
      this.active = instance;
    },
    //child
    onActiveTrigger() {
      this.$emit('active', this === this.activeInstance ? null : this);
    },
  },
  data() {
    return {
      active: null
    }
  },
}

export function getColorSetting(object) {
  let setting = JSON.parse(JSON.stringify(colorSetting));
  for (let key in object) {
    if (setting[key]) {
      setting[key].defaultValue = object[key];
    }
  }
  Object.keys(setting).forEach(key => {
    if (setting[key].defaultValue === null) {
      delete setting[key];
    }
  });
  return setting;
}


export const operateProps = {
  props: {
    activeURL: String,
    activeText: String,
  }
}
export function operateVarSetting() {
  return {
    activeURL: {
      control: "text",
      defaultValue: "#"
    },
    activeText: {
      control: "text",
      defaultValue: "前往註冊！！"
    },
  }
}
