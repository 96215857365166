<template>
  <div class="footer-container">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "FooterContainer",
}
</script>

<style scoped>

</style>
