import PIC02 from '@/components/web-page/Picture/pic02/index.vue';
import {generateTextArgTypes} from "@/components/base/javaScript/baseTest";
import {ControlEnum} from "@/models/Enum/ControlEnum";

export default {
  title: 'web-page/Picture/PIC02',
  argTypes: {
    imgUrl: {
      control: ControlEnum.IMG,
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    title: {
      control: ControlEnum.TEXT,
      defaultValue: '標題',
    },
    subTitle: {
      control: ControlEnum.TEXT,
      defaultValue: '副標題',
    },
    path: {
      control: ControlEnum.TEXT,
      defaultValue: 'https://www.google.com.tw',
    },
    aspectRatio: {
      control: ControlEnum.SELECT,
      options: ['auto', '1:1', '3:4', '2:1'],
      defaultValue: 'auto',
    },


    textBlockColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#000000'
    },
    ...generateTextArgTypes('title', {
      hiddeContent: true,
    }),
    ...generateTextArgTypes('subTitle', {
      hiddeContent: true,
    }),
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PIC02 },
  template: `<PIC02 v-bind="$props"/>`,
});

export const pic02 = Template.bind({});
