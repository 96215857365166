import PIC01 from "@/components/web-page/Picture/pic01/pic01.vue";

export default {
  title: "web-page/Picture/PIC01",
  argTypes: {
    imgSrc: {
      control: {
        type: "text",
        editType: "image",
      },
      defaultValue: "https://picsum.photos/200",
    },
    alt: { control: "text", defaultValue: "picsum" },
    width: { control: "text", defaultValue: "100%" },
    link: { control: "text", defaultValue: null },
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PIC01 },
  template: `<PIC01 v-bind="$props" />`,
});

export const pic01 = Template.bind({});
