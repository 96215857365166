import "@/assets/css/index.css"
import "@/assets/css/tailwind.css"
import * as components from "@/components";
import * as grids from "@/layout/grid";
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";
import {ControlEnum} from "@/models/Enum/ControlEnum";


const componentConfig = {};
const componentGroup = {};
const componentGrids = {};

const gridList = Object.keys(grids).map(k => grids[k]);
console.log(components)

const componentList = Object.keys(components).map(key => {
  console.log(key)
  // 這是真的 component
  const {component, ...args} = components[key];
  const group = args.componentGroup || null;
  const grids = args.grids || null;

  if (!component && !component.name) return;

  componentConfig[component.name] = args;
  componentConfig[component.name].ShowType = componentConfig[component.name];

  if (args && grids && group) {
    for (let key in grids) {
      if (!componentGrids[key]) componentGrids[key] = {};
      if (!componentGrids[key][group]) componentGrids[key][group] = [];
      componentGrids[key][group].push(component);
    }
  }


  if (args && group && componentEnum[group]) {
    if (!componentGroup[group])
      componentGroup[group] = []

    componentGroup[group] = [
      ...componentGroup[group],
      component
    ];
  }

  return component ? component : components[key];
});

const installComponents = function (Vue) {
  componentList.forEach(component => {
    if (component.name) {
      Vue.component(component.name, component)
    }
  })
}

const installLayouts = function (Vue) {
  gridList.forEach(component => {
    if (component.name) {
      Vue.component(component.name, component)
    }
  })
}

export {
  installComponents,
  installLayouts,
  componentConfig,
  componentGroup,
  componentGrids,
  componentEnum,
  ControlEnum,
  GridEnum
};
export * from '@/injectKey.js';
