<template>
  <div class="component component-pt26">
    <div class="pt26-cards">
      <div class="pt26__card" v-for="card of items" :key="card.title">
        <div class="pt26-wrapper">
          <div class="pt26__titleContainer">
            <div class="pt26__img-wrapper">
              <img :src="card.imgUrl" alt="cardImg" class="pt26__card-img" />
            </div>
            <p class="title"><base-text block-name="title" v-bind="card" /></p>
            <p class="subTitle">
              <base-text block-name="subTitle" v-bind="card" />
            </p>
          </div>
          <a class="pt26__btn" :href="card.link">
            <span class="btn-icon">
              <i :class="[card.icon || 'icon-circle-arrow-down']"></i>
            </span>
            <span class="btn-circle"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateTextProps } from "@/components/base/javaScript/baseTest";
import BaseText from "@/components/base/BaseText.vue";

export default {
  name: "PictureText26",
  components: { BaseText },
  props: {
    items: Array,
    ...generateTextProps("title"),
    ...generateTextProps("subTitle"),
    btnIconColor: String,
    btnBackgroundColor: String,
    btnBackgroundHoverColor: String,
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul {
  list-style-type: none;
}

.component-pt26 {
  max-width: 1064px;
  margin: 0 auto;
}

.pt26-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 48px 20px;
  justify-content: start;
}

.pt26__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 35px 0 27.6px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  transition: all 0.3s ease;
}

.pt26__card:hover {
  transform: translate(0, -5px);
}

.pt26-wrapper {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
}

.pt26__img-wrapper {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.pt26__titleContainer {
  margin-bottom: auto;
}

.pt26__titleContainer .title {
  color: v-bind(titleFontColor);
  font-size: v-bind(titleFontSize);
  font-weight: v-bind(titleFontWeight);
}

.pt26__titleContainer .subTitle {
  color: v-bind(subTitleFontColor);
  font-size: v-bind(subTitleFontSize);
  font-weight: v-bind(subTitleFontWeight);
}

.pt26__btn {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  margin: auto;
}

.pt26__btn .btn-icon {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: v-bind(btnIconColor);
  background-color: v-bind(btnBackgroundColor);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  z-index: 1;
}

.pt26__card .btn-circle {
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  transform: scale(1);
  background-color: v-bind(btnBackgroundHoverColor);
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.pt26__card:hover .btn-circle {
  content: "";
  display: inline-block;
  transform: scale(1.45);
}

@media screen and (min-width: 765px) {
  .pt26__card {
    width: calc(50% - 10px);
  }

  .pt26__img-wrapper {
    max-width: 180px;
    max-height: 180px;
  }
}

@media screen and (min-width: 1065px) {
  .pt26__card {
    width: calc(25% - 20px);
    max-width: 251px;
  }
  .pt26__img-wrapper {
    max-width: 88px;
    max-height: 88px;
  }
}
</style>
