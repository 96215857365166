<template>
    <div class="component component-pt24" :style="style" ref="component">
        <ul>
            <li
                v-for="(item, idx) of linkList"
                :key="item.pt24__title"
                :class="{
                    pt24__active: activeIdx > idx,
                    pt24__currentStepBold: activeIdx === idx,
                }"
                @click="setActive(idx)"
            >
                <!--upper elment and lower element是重複的元素，為了實現進度條上下交錯出現的DOM元素 -->
                <!--透過判斷idx，用opacity為0來隱藏不須出現的元素-->

                <!-- upper element -->
                <div
                    class="pt24__text"
                    @mouseenter="setActive(idx)"
                    :class="{ pt24__hide: isHideUpper(idx) }"
                >
                    <img :src="item.imgUrl" alt="card-img" />
                    <div class="pt24__title">{{ item.title }}</div>
                </div>

                <!-- circle-->
                <div
                    @mouseenter="setActive(idx)"
                    :class="{
                        pt24__progress: idx !== linkList.length - 1,
                        pt24__one: idx === 0,
                        pt24__active: activeIdx > idx,
                    }"
                ></div>

                <!-- lower element-->
                <div
                    class="pt24__text"
                    @mouseenter="setActive(idx)"
                    :class="{ pt24__hide: isHideLower(idx) }"
                >
                    <img :src="item.imgUrl" alt="card-img" />
                    <div class="pt24__title">{{ item.title }}</div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "PictureText24",
    props: {
        linkList: Array,
    },
    data() {
        return {
            activeIdx: 1,
            componentWidth: null,
        };
    },
    mounted() {
        this.calItemWidth();
    },
    beforeUpdate() {
        this.calItemWidth();
    },
    methods: {
        isHideUpper(idx) {
            const residual = idx % 2;
            return residual;
        },
        isHideLower(idx) {
            const residual = idx % 2;
            return residual ? false : true;
        },
        setActive(idx) {
            this.activeIdx = idx;
        },
        calItemWidth() {
            this.$nextTick(() => {
                let totalWidth = this.$refs.component.clientWidth;
                let itemWidth;
                const itemAmount = this.linkList.length;

                if (totalWidth > 1064) {
                    itemWidth = 1064 / itemAmount;
                } else {
                    itemWidth = totalWidth / itemAmount;
                }
                this.componentWidth = itemWidth;
                console.log(itemWidth);
            });
        },
    },
    computed: {
        style() {
            return {
                "--item-width": `${this.componentWidth}px`,
                "--last-element-width": `${this.componentWidth / 2}px`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-pt24 {
    max-width: 1064px;
    margin: 0 auto;
}

img {
    width: 50px;
    height: 50px;
}

ul {
    display: flex;
    margin-left: var(--last-element-width);
}

ul li {
    display: flex;
    flex-direction: column;
    width: var(--item-width);
    list-style: none;
}

ul li .icon {
    font-size: 35px;
    color: #ff4732;
    margin: 0 60px;
}

ul li .pt24__text {
    font-size: 14px;
    font-weight: 600;
    color: #6f6f6f;
    height: 110px;
    margin: 10px 0;
}

.pt24__text .pt24__title {
    margin-top: 5px;
    width: 128px;
    overflow-wrap: break-word;
}

/* hide the element */
ul li .pt24__text.pt24__hide {
    opacity: 0;
}

/* Progress Div Css  */
ul li .pt24__progress {
    position: relative;
    width: var(--item-width);
    border-bottom: 2px solid #c6c6c6;
    transition: all 1s linear;
}

ul li .pt24__progress::after {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    border: 5px solid #7e1c7e;
    color: #fff;
    position: absolute;
    right: 0px;
    bottom: -9px;
    cursor: pointer;
}

.pt24__one::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    border: 5px solid #7e1c7e;
    color: #fff;
    position: absolute;
    right: var(--item-width);
    bottom: -9px;
    cursor: pointer;
}

ul li .pt24__progress .uil {
    display: none;
}

ul li .pt24__progress p {
    font-size: 13px;
}

/* Active Css  */

ul li.pt24__active .pt24__text {
    color: black;
    font-weight: 400;
    transition: all 1s linear;
}

ul li.pt24__currentStepBold .pt24__text {
    color: black;
    font-weight: 700;
    transition: all 1s linear;
}

li .pt24__progress.pt24__active {
    border-bottom: 2px solid #7e1c7e;
    transition: all 1s linear;
}

/* Responsive Css  */

@media (max-width: 980px) {
    ul {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    }

    ul li {
        flex-direction: row;
        justify-content: center;
        width: 128px;
        height: 128px;
    }

    ul li .pt24__progress {
        /* change the vertical line heigth */
        height: 121.2px;
        border-left: 2px solid #c6c6c6;
        z-index: 0;
        right: 0.7px;
    }

    .pt24__progress::after {
        width: 2.5px;
        height: 125px;
        bottom: 10px;
        left: 50%;
        /* right: -6px; */
        transform: translateX(-50%);
        z-index: 1;
    }

    .pt24__progress.pt24__active {
        border-left: 2px solid #7e1c7e;
        transition: all 1s linear;
    }

    ul li .pt24__text {
        margin: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .pt24__one::before {
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: white;
        border: 5px solid #7e1c7e;
        color: #fff;
        position: absolute;
        right: -7px;
        top: 0;
        cursor: pointer;
    }
}
</style>
