<template>
    <div class="component component-pt23" :style="style">
        <div class="pt23__titleContainer">
            <h2 v-for="item of brNextLine(title)" :key="item">{{ item }}</h2>
        </div>
        <a class="pt23__btn" :href="link">{{buttonText}}</a>
    </div>
</template>

<script>
export default {
    name: "PictureText23",
    props: {
        // img
        imgUrl: String,
        link: String,
        // title
        title: String, 
        titleFontSize: Number,
        titleColor: String,
        titleSpacing: Number,
        // button
        buttonText: String,
        buttonFontSize: Number,
        buttonColor: String,
        buttonTextColor: String,
        buttonClickColor: String,
        buttonTextSpacing: Number,
        position: String
    },
    computed: {
        style() {
            return {
                // img
                // "--imgUrl": `url(${this.imgUrl})`,
                // title
                "--titleFontSize": `${this.titleFontSize}px`,
                "--titleColor": this.titleColor,
                "--titleSpacing": `${this.titleSpacing}px`,
                // button
                "--buttonFontSize": `${this.buttonFontSize}px`,
                "--buttonColor": this.buttonColor,
                "--buttonTextColor": this.buttonTextColor,
                "--buttonClickColor": this.buttonClickColor,
                "--buttonTextSpacing": `${this.buttonTextSpacing}px`,
                "--wordPosition": `${this.position}`,
                "--flexPosition": `${this.setFlexPosition(this.position)}`,
            };
        },
    },
    methods: {
        setFlexPosition(position){
            switch (position) {
                case 'left':
                return 'flex-start';
                case 'center':
                return 'center';
                case 'right':
                return 'flex-end';
                default:
                return 'center';
            }
        },
        brNextLine(str){
            return str?.split("<br>") || "";
        }
    }
};
</script>

<style lang="scss">
.component-pt23{
    display: flex;
    flex-direction: column;
    align-items: var(--flexPosition); // 需要靠左靠右
    max-width: 100%;
    text-align: center;
    // background-image: var(--imgUrl);
    margin: 0 auto;
    @media (max-width: 415px) {
        align-items: center;
    }
}
.pt23__titleContainer {
    font-weight: 700;
    font-size: var(--titleFontSize);
    letter-spacing: var(--titleSpacing);
    line-height: 140%;
    text-align: var(--wordPosition);  // 需要靠左靠右
    color: var(--titleColor);
    margin-top: 48px;
    margin-bottom: 32px;
    @media (max-width: 415px) {
        text-align: center;
    }
}

.pt23__btn{
    /* width: 124px; */
    cursor: pointer;
    background: var(--buttonColor);
    color: var(--buttonTextColor);
    font-size: var(--buttonFontSize);
    letter-spacing: var(--buttonTextSpacing);
    font-weight: 700;
    border-radius: 16px;
    padding: 8px 12px;
    margin-bottom: 56px;
}

.pt23__btn:hover{
    background: var(--buttonTextColor);
    color: var(--buttonColor);
    border: 1.5px solid var(--buttonColor);
}

.pt23__btn:active{
    color: var(--buttonClickColor);
}
</style>
