import component from './index.vue';
import stories from './index.stories.js';
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";

let {G1_1} = GridEnum;
export default {
  componentGroup: componentEnum.Blog,
  grids: {G1_1},
  name: 'Blog01_1',
  icon: 'mdi-view-dashboard',
  type:'container',
  component: component,
  argTypes: stories.argTypes
}
