import BT05 from "@/components/web-page/ButtonText/BT05/index.vue";
import {generateTextArgTypes} from "@/components/base/javaScript/baseTest";
import {ControlEnum} from "@/models/Enum/ControlEnum";


export default {
  title: 'web-page/ButtonText/BT05',
  argTypes: {
    title: {
      control: ControlEnum.TEXT,
      defaultValue: "公司簡介"
    },
    content: {
      control: ControlEnum.TEXT,
      defaultValue: " 公司以市場需求為導向，以科技創新為手段，以BS8品牌建設為目標，不斷的構築企業的核心競爭力。"
    },
    moreText: {
      control: ControlEnum.TEXT,
      defaultValue: "更多關於我們"
    },
    moreUrl: {
      control: ControlEnum.TEXT,
      defaultValue: "#"
    },
    borderColor:  {
      control: ControlEnum.COLOR,
      defaultValue: null
    },
    iconColor: {
      control: ControlEnum.COLOR,
      defaultValue: null
    },

    borderColorSize:  {
      control: ControlEnum.TEXT,
      defaultValue: null
    },
    ...generateTextArgTypes('title'),
    ...generateTextArgTypes('content'),
    ...generateTextArgTypes('more')
  }
}
// PT01
  const TemplatePT01 = (args, {argTypes}) => ({
    props: Object.keys(argTypes),
    components: {BT05},
    template: `
      <div style="width: 100%;height: 400px; margin:auto;">
      <BT05 v-bind="$props"/>
      </div>
    `
  });

  export const bt05 = TemplatePT01.bind({});
