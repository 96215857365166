import Element from './index.vue';
import { generateTextArgTypes } from '@/components/base/javaScript/baseTest';
import { ControlEnum } from '@/models/Enum/ControlEnum';

export default {
  title: 'web-page/Carousel/C08',
  argTypes: {
    items: {
      control: ControlEnum.ARRAY,
      template: {
        title1: '標題',
        title2: '標題',
        subtitle: '標題',
      },
      defaultValue: [
        {
          title1: 'title1',
          title2: 'title2',
          subtitle: 'subtitle',
        },
        {
          title1: '標題2',
          title2: '標題2',
          subtitle: '標題2',
        },
        {
          title1: '標題3',
          title2: '標題3',
          subtitle: '標題3',
        },
      ],
    },
    blockColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#000",
    },

    
    ...generateTextArgTypes('title1', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '24px',
      colorDefault: '#000',

    }),
    ...generateTextArgTypes('subtitle', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '12px',
      colorDefault: '#000',
    }),
    ...generateTextArgTypes('title2', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '40px',
      colorDefault: '#000',
    }),
    
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Element },
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `,
});

export const element1 = Template.bind({});
