<template>
    <div class="component component-pt21" :style="style">
        <div class="pt21__card" v-for="card of items" :key="card.title">
            <div class="pt21__img-wrapper">
                <img :src="card.imgUrl" alt="cardImg" class="pt21__card-img" />
            </div>
            <div class="pt21__titleContainer">
                <span v-for="item of brNextLine(card.title)" :key="item.key">
                    {{ item }}
                    <br />
                </span>
            </div>
            <a class="pt21__btn" :href="card.link">{{ card.buttonText }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "PictureText21",
    props: {
        items: Array,
        // title
        titleColor: String,
        titleFontSize: Number,
        titleSpacing: Number,
        // button
        buttonColor: String,
        buttonTextColor: String,
        buttonClickColor: String,
        buttonFontSize: Number,
        buttonTextSpacing: Number,
    },
    computed: {
        style() {
            return {
                // title
                "--titleColor": this.titleColor,
                "--titleFontSize": `${this.titleFontSize}px`,
                "--titleSpacing": `${this.titleSpacing}px`,
                // button
                "--buttonColor": this.buttonColor,
                "--buttonTextColor": this.buttonTextColor,
                "--buttonClickColor": this.buttonClickColor,
                "--buttonFontSize": `${this.buttonFontSize}px`,
                "--buttonTextSpacing": `${this.buttonTextSpacing}px`,
            };
        },
    },
    methods: {
        brNextLine(str) {
            return str?.split("<br>") || "";
        },
    },
};
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

ul {
    list-style-type: none;
}

.component-pt21 {
    display: flex;
    justify-content: space-around;
    max-width: 1360px;
    margin: 0 auto;
}

.pt21__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    padding: 46px 16px;
}

.pt21__card:hover {
    transform: translate(0, -5px);
}

.pt21__img-wrapper {
    max-width: 120px;
    max-height: 120px;
    margin-bottom: 8px;
}

.pt21__titleContainer {
    max-width: 273px;
    color: var(--titleColor);
    font-size: var(--titleFontSize);
    letter-spacing: var(--titleSpacing);
    margin-bottom: 22px;
}

.pt21__btn {
    min-width: 72px;
    min-height: 40px;
    display: inline-block;
    width: fit-content;
    cursor: pointer;
    background: var(--buttonColor);
    color: var(--buttonTextColor);
    font-size: var(--buttonFontSize);
    font-weight: 700;
    letter-spacing: var(--buttonTextSpacing);
    padding: 6px 16px;
    border-radius: 12px;
}

.pt21__btn:hover {
    background: var(--buttonTextColor);
    color: var(--buttonColor);
    border: 1px solid var(--buttonColor);
}

.pt21__btn:active {
    color: var(--buttonClickColor);
}

@media screen and (max-width: 990px) {
    .pt21__card {
        width: 33%;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 800px) {
    .component-pt21 {
        flex-direction: column;
        align-items: center;
    }
    .pt21__card {
        width: 300px;
        margin-bottom: 15px;
    }
}
</style>
