import PT23 from "@/components/web-page/PictureText/PT23/index.vue";

export default {
    title: "web-page/PictureText/PT23",
    argTypes: {
        // img
        // imgUrl: {
        //     control: {
        //         type: "text",
        //         editType: "image",
        //     },
        //     defaultValue:
        //         "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
        // },
        link: {
            control: "text",
            defaultValue: "https://google.com.tw",
        },
        // title
        title: {
            control: "text",
            defaultValue: "一切準備完畢準備出發囉！免費諮詢，依照您的情況貼身設計留學大事．",
        },
        titleFontSize: {
            control: "number",
            defaultValue: 36,
        },
        titleColor: {
            control: "color",
            defaultValue: "black",
        },
        titleSpacing: {
            control: "number",
            defaultValue: 2,
        },
        // button
        buttonText: {
            control: "text",
            defaultValue: "BUTTON",
        },
        buttonFontSize: {
            control: "number",
            defaultValue: 20,
        },
        buttonColor: {
            control: "color",
            defaultValue: "#7E1C7E",
        },
        buttonTextColor: {
            control: "color",
            defaultValue: "white",
        },
        buttonClickColor: {
            control: "color",
            defaultValue: "#7E1C7E",
        },
        buttonTextSpacing: {
            control: "number",
            defaultValue: 2,
        },
        position: {
            control: 'select',
            options: ["left", "center", "right"],
            defaultValue: "center"
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT23 },
    template: `
    <div>
      <PT23 v-bind="$props"/>
    </div>
  `,
});

export const pt23 = Template.bind({});

