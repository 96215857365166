import QA01 from "@/components/web-page/QuestionAnswer/Q03/index.vue";

export default {
  title: "web-page/QuestionAnswer/Q03",
  argTypes: {
    titleColor: {
      control: "color",
      defaultValue: "#000",
    },
    contentColor: {
      control: "color",
      defaultValue: "#6F6F6F",
    },
    iconColor: {
      control: "color",
      defaultValue: "#BD9B7B",
    },
    arrowColor: {
      control: "color",
      defaultValue: null,
    },
    listItem: {
      control: "array",
      template: {
        id: 1,
        iconName: "icon-arrow-up",
        title: "標題",
        content: `
            <ul>
                <li><span>測試1</span></li>
                <li><span>測試2</span></li>
                <li><span>測試3</span></li>
            </ul>
        `,
      },
      defaultValue: [
        {
          id: 1,
          iconName: "icon-arrow-up",
          title: "列表一",
          content: `
            <ul>
                <li><span>列表一</span></li>
                <li><span>內容</span></li>
            </ul>
            `,
        },
        {
          id: 2,
          iconName: "icon-arrow-up",
          title: "列表二",
          content: `
            <ul>
                <li><span>列表二</span></li>
                <li><span>內容</span></li>
            </ul>
            `,
        },
      ],
    },
  },
};

// PT01
const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {QA01},
  template: `
  <div >
    <QA01 v-bind="$props"/>
  </div>
  `,
});

export const qa03 = Template.bind({});
