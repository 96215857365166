import PIC04 from '@/components/web-page/Picture/pic04/index.vue';

export default {
  title: 'web-page/Picture/PIC04',
  argTypes: {
    imgUrl: {
      control:  {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    title: {
      control: 'text',
      defaultValue: '標題',
    },
    subTitle: {
      control: 'text',
      defaultValue: '副標題',
    },
    cardColor: {
      control: 'color',
      defaultValue: '#5D0C75',
    },
    cardHoverColor: {
      control: 'color',
      defaultValue: '#1F286F',
    },
    titleColor: {
      control: 'color',
      defaultValue: '#fff',
    },
    cardBorderRadius: {
      control: 'text',
      defaultValue: '16px',
    },
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PIC04 },
  template: `<PIC04 v-bind="$props" />`,
});

export const pic04 = Template.bind({});
