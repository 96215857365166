import Element from "./index.vue";
import {ControlEnum} from "@/models/Enum/ControlEnum";

export default {
  title: 'web-page/Product/PD02',
  argTypes: {
    classId: {
      control: "text",
      defaultValue: null
    },
    arrowColor: {
      control: "color",
      defaultValue: null
    },
    buttonColor: {
      control: "color",
      defaultValue: null
    },
    itemNum: {
      control: ControlEnum.NUMBER,
      defaultValue: 3
    },
    selectProd: {
      control: "select",
      options: ["ProdCard01", "ProdCard02"],
      defaultValue: 'ProdCard01'
    },
  }
}
// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `
});

export const element = TemplatePT01.bind({});
