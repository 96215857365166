import component from './index.vue';
import stories from './index.stories.js';

export default {
  name: 'Header01',
  icon: 'mdi-view-dashboard',
  type:'header',
  isNew: true,
  component: component,
  argTypes: stories.argTypes
}
