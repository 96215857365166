<template>
  <article :class="classes"
           :style="styless">
    <div class="side" :style="sideStyles">
      <slot v-bind="$props" gap="12"/>
    </div>
  </article>
</template>

<script>
import themeable from "@/mixins/themeable";

export default {
  name: "LayoutContainer",
  data() {
    return {
      isMobile: false
    }
  },
  mixins: [themeable],
  props: {
    backgroundImage: String,
    backgroundMobile: String,
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    top: {
      type: String,
      default: "60"
    },
    bottom: {
      type: String,
      default: "60"
    },
    isFullScreen: {
      type: Boolean,
      default: false
    },
    order: {
      type: String,
      default: null
    },
    gap: {
      type: String,
      default: "0"
    },
    left: {
      type: String,
      default: "0"
    },
    right: {
      type: String,
      default: "0"
    },
    gearingTopBottom: {
      type: Boolean,
      default: true
    },
    gearing: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      let w = window.innerWidth;
      this.isMobile = w <= 576
    }
  },
  computed: {
    backgroundSet() {
      let background = this.backgroundImage ? `url("${encodeURI(this.backgroundImage)}") scroll  no-repeat center / cover` : "";
      let backgroundMobile = this.backgroundMobile ? `url("${encodeURI(this.backgroundMobile)}") scroll  no-repeat top center / 100% auto` : ""
      let color = this.backgroundColor || "";
      return this.isMobile && this.backgroundMobile ? `${backgroundMobile} ${color}` : `${background} ${color}`
    },
    classes() {
      return {
        'layout': true,
        'layout--full': this.isFullScreen
      }
    },
    styless() {
      return {
        background: this.backgroundSet
      }
    },
    sideStyles() {
      return {
        marginTop: this.top ? this.top + 'px' : 0,
        paddingLeft: this.left ? this.left + 'px' : 0,
        paddingRight: this.right ? this.right + 'px' : 0,
        marginBottom: this.bottom ? this.bottom + 'px' : 0,
      }
    }
  },
}
</script>
<style scoped>

.layout {
  display: flow-root;
  margin: 0 auto;
  position: relative;
  transition: .2s ease;
}

.layout:not(.layout--full) .side > * {
  max-width: 1360px;
  margin: 0 auto;
}

.layout.layout--full .side{
  max-width: 100%;
}

.layout.layout--full{
  padding: 0;
}

.layout {
  padding: 0 16px;
}

/*手機以上套用*/
@media screen and (min-width: 415px) {
  .layout {
    padding: 0 32px;
  }
}
/*平板以上套用*/
@media screen and (min-width: 801px) {
  .layout {
    padding: 0 40px;
  }
}


/*平板以下套用*/
@media screen and (max-width: 800px) {
  .side {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

</style>

