<template>
  <label class="buttons-desktop-search buttons-search" for="search-input" :class="{active}">
    <i class="icon-search2" @click="active = !active"></i>
    <input id="search-input"
           type="search"
           placeholder="Search"
           v-model="query"
           @keydown.enter="headerButtonsEvent.onSearch(query)">
  </label>
</template>

<script>
export default {
  name: "Search",
  inject: ['headerButtonsEvent'],
  data() {
    return {
      query: "",
      active: false
    };
  }
}
</script>

<style src="../../css/header.search.css" scoped></style>
