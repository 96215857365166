<template>
  <li :class="{...moreClassName, active: isActive}" >
    <div class="item__bg" @click.stop.prevent="onItemClick(item.url)">
      <a class="item__link" :href="item.url">{{ item.contentName }}</a>
      <button v-if="hasChildren" class="item__more">
        <i v-if="isRootItem" class="icon-arrow-down"></i>
        <template v-else>
          <i class="icon-plus"></i>
        </template>
      </button>
    </div>
    <div class="item__next" v-if="hasChildren">
      <ul class="nav-list">
        <template v-for="(innerItem, index) of item.children">
          <RecursiveNavItem :item="innerItem"
                            :key="index"
                            :is-root-item="false"
                            :is-root-active="isActive"
                            :active-instance="active"
                            @active="onActive"
                        />
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
import {mobileActive} from "@/models/headerColorSetting";

export default {
  name: "RecursiveNavItem",
  mixins: [mobileActive],
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    onItemClick(url) {
      if (this.hasChildren && !this.isActive) {
        this.onActiveTrigger();
      } else {
        window.location = url;
        this.onActiveTrigger();
      }
    }
  },
  computed: {
    hasChildren() {
      return this.item.children && this.item.children.length > 0
    },
    moreClassName() {
      return {
        'nav-list__item .item': !this.hasChildren,
        'nav-list__item .item .more': this.hasChildren,
      }
    }
  },
}
</script>

<style scoped src="./index.css">
</style>
