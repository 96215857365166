<template>
  <div class="component component-prod03">
    <hooper ref="hooper" :settings="settings">
      <template #default>
        <slide style="" v-for="item of items" :key="item.key">
          <component
              class="pd03-item"
              :is="selectProd"
              :key="item.key"
              :style="componentColor"
              v-bind="dataToComponentProps(item)"
          />
        </slide>
      </template>

      <template #hooper-addons>
        <navigation-test :style="componentColor"/>
      </template>
    </hooper>

  </div>
</template>

<script>
import {
  Hooper,
  Slide,
} from 'hooper';
import NavigationTest from "./NavigationTest";
import globalInject from "@/mixins/globalInject";
import autoColor from "@/mixins/autoColor";
import selectProd from "@/mixins/product/selectProd";

export default {
  name: "PROD03",
  components: {Hooper, Slide, NavigationTest},
  mixins: [selectProd, globalInject, autoColor],
  props: {
    showItemNum: String,
    arrowColor: String,
    buttonColor: String,
    itemNum: String,
  },
  data() {
    return {
      items: []
    }
  },
  watch: {
    classId() {
      this.getDate();
    },
    showItemNum() {
      this.getDate();
    },
    settings() {
      this.$nextTick(() => {
        this.$refs.hooper.initDefaults();
        this.$refs.hooper.update();
      });
    }
  },
  computed: {
    settings() {
      return {
        wheelControl: false,
        itemsToShow: 1,
        infiniteScroll: false,
        playSpeed: 3000,
        breakpoints: {
          1024: {
            itemsToShow: this.itemNum ? parseInt(this.itemNum) : 4,
            itemsToSlide: this.itemNum ? parseInt(this.itemNum) : 4,
          },
          768: {
            itemsToShow: 2,
            itemsToSlide: 2
          },
          0: {
            itemsToShow: 1,
            itemsToSlide: 1
          }
        }
      }
    },
  },
  created() {
    this.getDate();
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.$refs.hooper.update();
    }, false);
  }
}
</script>

<style scoped>
.component-prod03 {
  color: #000;
  z-index: 998;
}

.component-prod03 ul {
  padding: 0;
}

.component-prod03 ul.hooper-track {
  padding: 0;
}

:deep(.hooper) {
  position: static;
  box-sizing: border-box;
  width: 75.33%;
  margin: 0 auto;
  color: inherit;
  height: auto;
}

:deep(.hooper .hooper-list .hooper-slide) {
  height: unset;
}

:deep(.hooper .hooper-list .hooper-track) {
  padding: 0;
}

.component-prod03 .pd03-item {
  max-width: 90%;
  margin: auto;
  padding: 12px 0;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .hooper {
    width: 83.33%;
  }
}
</style>
