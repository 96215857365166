<template>
  <LayoutGrid v-bind="$props" columns="3" class="g5_4">
    <slot/>
  </LayoutGrid>
</template>

<script>
import LayoutGrid from "@/layout/grid/LayoutGrid";
import layout from "@/layout/mixins/layout";

export default {
  name: "G5_4",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style lang="scss">
.g5_4 {
  grid-auto-rows: minmax(100px, auto);
}

.g5_4 > *:nth-child(1) {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.g5_4 > *:nth-child(2)  {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
}

.g5_4 > *:nth-child(3)  {
  grid-column: 3 / 3;
  grid-row: 1 / 1;
}

.g5_4 > *:nth-child(4)  {
  grid-column: 1 / 3;
  grid-row: 2 / 2;
}

.g5_4 > *:nth-child(5)  {
  grid-column: 3 / 4;
  grid-row: 2 / 2;
}
</style>
