import Element from "./index.vue";

export default {
  title: 'web-page/Controls/Select',
  argTypes: {
    title: {
      control: 'text',
      defaultValue: "選項標題"
    },
    placeholder: {
      control: 'text',
      defaultValue: "你的回答"
    },
    items: {
      control: 'array',
      defaultValue:  [
        {name: '選項一', value: 1},
        {name: '選項二', value: 2},
        {name: '選項三', value: 3}
      ]
    },
    required: {
      control: "boolean",
      defaultValue: false
    },
  }
}

const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
    <div style="padding: 10px">
      <Element v-bind="$props"/>
    </div>
  `
});

export const element1 = Template.bind({});
