<template>
  <li class="mobile-nav-list__item"
      :class="{'active': isActive}"
      @click.stop.prevent="onItemClick(item.url)">
    <a class="mobile-nav-list__link" :href="item.url">
      {{ item.contentName }}
      <span class="mobile-nav-list__btn" v-if="hasChildren">
        <i class="icon-arrow-up"></i>
      </span>
    </a>
    <ul class="mobile-nav-list" v-if="hasChildren">
      <template v-for="(innerItem, index) of item.children">
        <MobileRecursiveNavItem :key="index" :item="innerItem"
                                :is-root-item="false"
                                :is-root-active="isActive"
                                :active-instance="active"
                                @active="onActive"/>
      </template>
    </ul>
  </li>
</template>

<script>
import {mobileActive} from "@/models/headerColorSetting";

export default {
  name: "MobileRecursiveNavItem",
  mixins: [mobileActive],
  props: {
    isRootItem: Boolean,
    item: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      count: false
    }
  },
  computed: {
    hasChildren() {
      return this.item.children && this.item.children.length > 0
    },
  },
  methods: {
    onItemClick(url) {
      if (this.hasChildren && !this.isActive) {
        this.onActiveTrigger();
      } else {
        window.location = url;
        this.onActiveTrigger();
      }
    }
  }
}
</script>

<style src="./mobile.css" scoped></style>
