<template>
    <div class="component component-pt16" :style="style">
        <div class="pt16__wrapper">
            <!-- description -->
            <div class="pt16__description">
                <div class="pt16__title">
                    <base-text block-name="title" v-bind="$props" />
                </div>
                <div class="pt16__content">
                    <base-text block-name="content" v-bind="$props" />
                </div>
                <div class="pt16__dot"></div>
            </div>
            <!-- image -->
            <div class="pt16__image"><img :src="imgUrl" alt="cardImage" /></div>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "PictureText16",
    components: {
        BaseText,
    },
    props: {
        imgUrl: String,
        lineColor: String,
        dotColor: String,
        ...generateTextProps("title"),
        ...generateTextProps("content"),
    },
    methods: {},
    computed: {
        style() {
            return {
                "--line-color": this.lineColor,
                "--dot-color": this.dotColor,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-pt16 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .pt16__wrapper {
        display: flex;
        position: relative;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 75px;
        max-width: 845px;
        width: 100%;
        border-left: 2px dashed var(--line-color);
        margin-left: 58px;
        margin-right: 48px;
        padding-top: 48px;
    }

    .pt16__description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 250px;
        text-align: justify;
        flex-shrink: 1;
    }

    .pt16__dot {
        width: 20px;
        height: 20px;
        background: var(--dot-color);
        border-radius: 50%;
        position: absolute;
        left: -11px;
        bottom: -2px;
    }

    .pt16__title {
        font-weight: 700;
        margin-bottom: 24px;
    }

    .pt16__content {
        font-weight: 400;
    }

    .pt16__image {
        width: 250px;
        height: 250px;
        flex-shrink: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 700px) {
    .component-pt16 {
        .pt16__wrapper {
            flex-direction: column-reverse;
            border-left: none;
            align-items: center;
            gap: 0;
        }
        .pt16__title{
            margin-top: 24px;
        }
        .pt16__description {
            position: relative;
            height: 100%;
            border-left: 2px dashed #6f6f6f;
            justify-content: initial;
            margin-left: 17px;
            padding-left: 50px;
        }
        .pt16__image {
            width: 320px;
            height: 320px;
        }
    }
}
</style>
