<template>
  <div class="component component-c08" :style="{ ...styleSetting }">
    <hooper ref="carousel" :settings="settings">
      <template #default="{ block }">
        <slide
          class="componentContainer"
          v-for="(item, index) of items"
          :key="block + index"
        >
          <div class="c08__title-container">
            <div class="c08__title1">
              <base-text
                block-name="title1"
                v-bind="{ ...$props, title1Content: item.title1 }"
              />
              <base-text
                block-name="subtitle"
                v-bind="{ ...$props, subtitleContent: item.subtitle }"
              />
            </div>
            <div class="c08__title2">
              <base-text
                block-name="title2"
                v-bind="{ ...$props, title2Content: item.title2 }"
              />
            </div>
          </div>
        </slide>
      </template>

      <template #hooper-addons> </template>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import hooperCarousel from '@/mixins/hooperCarousel';
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';
import aspectRatio from '@/mixins/aspectRatio';

export default {
  name: 'C08',
  components: { BaseText, Hooper, Slide },
  mixins: [hooperCarousel, aspectRatio],
  props: {
    blockColor: String,

    ...generateTextProps('title1'),
    ...generateTextProps('title2'),
    ...generateTextProps('subtitle'),
    items: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    settings() {
      this.$nextTick(() => {
        // console.log('settings');
        if (this.$refs.carousel.timer) {
          this.$refs.carousel.timer.stop();
        }
        this.$refs.carousel.initDefaults();
        this.$refs.carousel.update();
        this.$refs.carousel.initEvents();
      });
    },
  },
  computed: {
    settings() {
      return {
        autoPlay: true,
        playSpeed: 3000,
        wheelControl: false,
        itemsToShow: 1,
        centerMode: true,
        infiniteScroll: true,
        vertical: true,
      };
    },
    styleSetting() {
      return {
        '--blockColor': this.blockColor,
      };
    },
    dotsArray: function () {
      return Array.from(Array(this.items.length).keys());
    },
  },
  data() {
    return {
      slidesToShow: 1,
      slidesToScroll: 1,
      isShow: true,
      isMobile: false,
    };
  },
};
</script>

<style scoped lang="scss">
.component-c08 {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 48px);
    right: 0;
    left: 0;
    margin: auto;
    width: 250px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--blockColor);
  }
}
.hooper {
  height: unset;
}
.c08__title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.c08__title1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  & > span:nth-child(1) {
    font-weight: 700;
    // font-size: 24px;
  }
  & > span:nth-child(2) {
    font-weight: 400;
    // font-size: 12px;
  }
}
.c08__title2 {
  flex: 1;
  & > span {
    font-weight: 700;
    // font-size: 40px;
    display: block;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  :deep(.hooper.is-vertical .hooper-track) {
    height: 230px;
  }
  .c08__title-container {
    flex-direction: column;
    padding: 24px 40px 72px;
  }
}
@media screen and (min-width: 768px) {
  :deep(.hooper.is-vertical .hooper-track) {
    height: 150px;
  }
  .c08__title-container {
    height: 100%;
    align-items: center;
    padding: 0 16px;
  }
  .c08__title1 {
    & > span:nth-child(1) {
      order: 1;
    }
  }
}
@media screen and (min-width: 1200px) {
  .c08__title-container {
    padding: 0 40px;
  }
}
</style>
