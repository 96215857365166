<template>
  <LayoutGrid v-bind="$props" columns="2">
    <slot />
  </LayoutGrid>
</template>

<script>
import LayoutGrid from "@/layout/grid/LayoutGrid";
import layout from "@/layout/mixins/layout";

export default {
  name: "G4_2",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style scoped lang="scss">
</style>
