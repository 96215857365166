import T01 from '@/components/web-page/Text/T01/index.vue';


export default {
  title: 'web-page/Text/T01',
  argTypes: {
    content: {
      control: "text",
      defaultValue: "<p>可以填寫Html</p>"
    }
  }
}

// T03
const TemplateT03 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {T01},
  template: `<T01 v-bind="$props" />`
});

export const t01 = TemplateT03.bind({});

