<template>
    <div class="component component-t07_2" :style="style">
        <!-- left-->
        <div class="t07_2__left">
            <!-- img-->
            <div class="t07_2__img-wrapper">
                <img :src="imgUrl" alt="icon" />
            </div>
            <!-- content-->
            <p class="t07_2__contentContainer">
                <base-text block-name="content" v-bind="$props" />
            </p>
        </div>
        <!-- right -->
        <div class="t07_2__right">
            <h2 class="t07_2__titleContainer">
                <base-text block-name="title" v-bind="$props" />
            </h2>
            <hr class="t07_2__line" />
            <div class="t07_2__subTitleContainer">
                <base-text block-name="subTitle" v-bind="$props" />
            </div>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "Text07_2",
    components: {
        BaseText,
    },
    props: {
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        ...generateTextProps("content"),
        // line color
        verticalLineColor: String,
        horizontalLineColor: String,
        // line width
        verticalLineWidth: Number,
        horizontalLineWidth: Number,
        // img url
        imgUrl: String,
    },
    computed: {
        style() {
            return {
                "--vertical-line-color": this.verticalLineColor,
                "--horizontal-line-color": this.horizontalLineColor,
                "--vertical-line-width": `${this.verticalLineWidth}px`,
                "--horizontal-line-width": `${this.horizontalLineWidth}px`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-t07_2 {
    max-width: 1360px;
    display: flex;
    justify-content: center;
    margin: 5% auto;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.t07_2__left,
.t07_2__right {
    width: 50%;
}

/* left */
.t07_2__left {
    display: flex;
    justify-content: center;
    border-right: var(--vertical-line-width) solid var(--vertical-line-color);
    margin-left: 20px;
}

.t07_2__img-wrapper {
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
}

.t07_2__contentContainer {
    max-width: 354px;
    text-align: justify;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 10px;
}

/* right */
.t07_2__right {
    padding-left: 120px;
}

.t07_2__titleContainer {
    font-weight: 700;
}

.t07_2__line {
    height: var(--horizontal-line-width);
    width: 48px;
    background-color: var(--horizontal-line-color);
    border: none;
    border-radius: 5px;
    margin: 24px 0;
}

.t07_2__subTitleContainer {
    font-weight: 400;
}
@media screen and (max-width: 900px) {
    .t07_2__contentContainer{
        max-width: 205px;
    }
}

@media screen and (max-width: 600px) {
    .component-t07_2 {
        flex-direction: column;
        align-items: center;
    }

    .t07_2__left {
        width: initial;
        border: none;
        margin-bottom: 48px;
        margin-left: 0;
    }

    .t07_2__right {
        justify-content: space-between;
        padding: 0;
    }

    .t07_2__contentContainer {
        max-width: 200px;
    }
}

@media screen and (max-width: 400px){
    .t07_2__contentContainer{
        max-width: 200px;
    }
}

@media screen and (max-width: 325px){
    .t07_2__contentContainer{
        max-width: 180px;
    }
}
</style>

