<template>
  <div class="component component-f02" :style="style">
    <div class="footer__main">
      <div class="footer__logo footer__logo-md">
        <img :src="logoUrl" alt="logo-md" />
      </div>
      <div class="footer__description">
        <div class="footer__logo footer__logo-lg">
          <img :src="logoUrl" alt="logo-lg" />
        </div>
        <div class="footer__description__content">
          <base-text block-name="description" v-bind="{ ...$props }" />
        </div>
        <div class="footer__description__links">
          <a
            v-for="(link, index) in links"
            :key="index"
            class="footer__description__link"
            target="_blank"
            :href="link.href"
          >
            <i :class="`icon-${link.icon}`" :style="`color:${link.color}`"></i>
          </a>
        </div>
      </div>
      <div :class="['footer__info', `footer-col-${col}`]">
        <div class="footer__card" v-for="(item, index) in items" :key="index">
          <div v-html="item.htmlText"></div>
        </div>
      </div>
    </div>
    <footer-copyright
      :copyrightText="copyrightText"
      :copyrightLink="copyrightLink"
      :copyrightBackgroundColor="copyrightBackgroundColor"
      :copyrightTextColor="copyrightTextColor"
    />
  </div>
</template>

<script>
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';
import FooterCopyright from '@/components/base/FooterCopyright/FooterCopyright';

export default {
  name: 'F02',
  components: { BaseText, FooterCopyright },
  props: {
    logoUrl: String,
    items: () => [],
    links: () => [],
    copyrightText: String,
    copyrightLink: () => [],
    copyrightBackgroundColor: String,
    copyrightTextColor: String,
    backgroundColor: String,
    ...generateTextProps('description'),
  },
  computed: {
    col() {
      return this.items.length > 3 ? 4 : this.items.length % 4;
    },
    style() {
      return {
        '--backgroundColor': this.backgroundColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.footer__main {
  display: flex;
  gap: 48px;
  position: relative;
  justify-content: space-between;
  background-color: var(--backgroundColor);

  .footer__description {
    // width: 18.79%;
    width: 200px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    .footer__description__links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: fit-content;

      gap: 16px;

      .footer__description__link {
        line-height: 1;
        i {
          font-size: 24px;
        }
      }
    }
  }

  .footer__info {
    display: flex;
    flex-wrap: wrap;

    .footer__card {
    }
  }
  .footer__logo {
    img {
      width: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: 767px) {
  .component-f02 {
  }
  .footer__main {
    padding: 48px 11.83%;

    flex-direction: column;
    .footer__description {
      order: 1;
    }

    .footer__info {
      gap: 20px 5%;
      width: 100%;

      &.footer-col-1 .footer__card {
        width: 100%;
      }
      .footer__card {
        width: 47.5%;
      }
    }
    .footer__logo-md {
      width: 200px;
    }
    .footer__logo-lg {
      display: none;
    }
  }
}
@media screen and (min-width: 768px) {
  .component-f02 {
  }
  .footer__main {
    padding: 64px 10.88%;

    align-items: flex-start;
    .footer__info {
      width: 0;
      flex: 1;
      max-width: 59.22%;

      gap: 24px 3.1%;

      &.footer-col-1 .footer__card {
        width: 100%;
      }
      .footer__card {
        width: 48.45%;
      }
    }
    .footer__logo-md {
      display: none;
    }
  }
}
@media screen and (min-width: 1200px) {
  .footer__main {
    .footer__info {
      &.footer-col-4 .footer__card {
        width: 22.675%;
      }
      &.footer-col-3 .footer__card {
        width: 31.26%;
      }
      &.footer-col-2 .footer__card {
        width: 48.45%;
      }
      &.footer-col-1 .footer__card {
        width: 100%;
      }
    }
  }
}
</style>
