<template>
  <div class="component component-c09" :style="{ ...styleSetting }">
    <hooper ref="carousel" :settings="settings">
      <template #default="{ block }">
        <slide
          class="componentContainer"
          v-for="(item, index) of items"
          :key="block + index"
        >
          <img
            :src="item.imgUrl"
            :alt="item.alt"
            :style="computedAspectRatio"
          />
          <div class="c09__title-container">
            <base-text
              block-name="title"
              v-bind="{ ...$props, titleContent: item.title }"
            />
          </div>
        </slide>
      </template>

      <template #hooper-addons>
        <Pagination />
      </template>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import hooperCarousel from '@/mixins/hooperCarousel';
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';
import Pagination from './Pagination.vue';

export default {
  name: 'C09',
  components: { BaseText, Hooper, Slide, Pagination },
  mixins: [hooperCarousel],
  props: {
    blockColor: String,
    paginationColor: String,
    aspectRatio: String,

    ...generateTextProps('title'),
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;

      this.itemsToShow = this.windowWidth < 768 ? 2 : 3.25;
    },
  },
  watch: {
    settings() {
      this.$nextTick(() => {
        // console.log('settings');
        if (this.$refs.carousel.timer) {
          this.$refs.carousel.timer.stop();
        }
        this.$refs.carousel.initDefaults();
        this.$refs.carousel.update();
        this.$refs.carousel.initEvents();
      });
    },
  },
  computed: {
    settings() {
      return {
        autoPlay: false,
        playSpeed: 3000,
        wheelControl: false,
        itemsToShow: this.itemsToShow,
        centerMode: false,
        infiniteScroll: true,
      };
    },
    styleSetting() {
      return {
        '--blockColor': this.blockColor,
        '--paginationColor': this.paginationColor,
      };
    },
    dotsArray: function () {
      return Array.from(Array(this.items.length).keys());
    },
    computedAspectRatio() {
      let res = '';
      switch (this.aspectRatio) {
        case 'auto':
          res = null;
          break;
        case '1:1':
          res = '1/1';
          break;
        case '3:4':
          res = '3/4';
          break;
        case '4:3':
          res = '4/3';
          break;
        case '2:1':
          res = '2/1';
          break;
      }
      return { 'aspect-ratio': res };
    },
  },
  data() {
    return {
      slidesToShow: 1,
      slidesToScroll: 1,
      isShow: true,
      isMobile: false,

      itemsToShow: 3.25,
      windowWidth: 0,
    };
  },
  mounted() {
    this.windowWidth = window.innerWidth;

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
:deep(.componentContainer) {
  position: relative;
  padding: 0 0.91%;

  img {
    width: 100%;
    object-fit: cover;
    vertical-align: top;
  }
  &::before {
    content: '';
    width: 8px;
    height: 23.33%;
    background: var(--blockColor);

    position: absolute;
    left: 2.73%;
    top: 0;

    transition: 0.3s;
  }
  &::after {
    content: '';
    width: 94.54%;
    height: 0;

    position: absolute;
    left: 2.73%;
    right: 0;
    bottom: 0;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

    transition: 0.3s;
  }

  &:hover {
    &::before {
      height: 46.66%;
    }
    &::after {
      height: 31.6%;
    }
    .c09__title-container {
      opacity: 1;
    }
  }
}
.c09__title-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;

  text-align: center;

  padding: 24px;
  opacity: 0;
  transition: 0.3s;
}
.hooper {
  height: unset;
}

@media screen and (max-width: 767px) {
  .component-c09 {
    padding-top: 60px;
  }
}
@media screen and (min-width: 768px) {
  .component-c09 {
    padding-top: 108px;
  }
}
@media screen and (min-width: 1200px) {
}
</style>
