<template>
  <div
    class="component component-c02"
    :style="{ ...styleSetting }"
    ref="carousel02"
  >
    <hooper ref="carousel" :settings="settings">
      <template #default="{ block }">
        <slide
          class="componentContainer"
          :style="computedAspectRatio"
          v-for="(item, index) of items"
          :key="block + index"
        >
          <div class="c02__description__container">
            <div class="c02__description__image">
              <img :src="item.imgUrl" :alt="item.alt" />
            </div>
            <div class="c02__description">
              <base-text
                class="c02__description__title"
                block-name="title"
                v-bind="{ ...$props, titleContent: item.title }"
              />
              <base-text
                class="c02__description__subTitle"
                block-name="subTitle"
                v-bind="{ ...$props, subTitleContent: item.subTitle }"
              />
            </div>
          </div>
          <div class="c02__content">
            <base-text
              block-name="content"
              v-bind="{ ...$props, contentContent: item.content }"
            />
          </div>
        </slide>
      </template>

      <template #hooper-addons>
        <Navigation />
      </template>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import hooperCarousel from '@/mixins/hooperCarousel';
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';
import aspectRatio from '@/mixins/aspectRatio';
import Navigation from './Navigation.vue';

export default {
  name: 'C02',
  components: { BaseText, Hooper, Slide, Navigation },
  mixins: [hooperCarousel, aspectRatio],
  props: {
    autoPlay: Boolean,
    playSpeed: Number,
    infinite: Boolean,
    buttonColor: String,
    buttonIconColor: String,
    contentDisplay: String,

    ...generateTextProps('title'),
    ...generateTextProps('subTitle'),
    ...generateTextProps('content'),
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;

      this.itemsToShow = this.windowWidth < 768 ? 1.25 : 3;
    },
  },
  watch: {
    settings() {
      this.$nextTick(() => {
        if (this.$refs.carousel.timer) {
          this.$refs.carousel.timer.stop();
        }
        this.$refs.carousel.initDefaults();
        this.$refs.carousel.update();
        this.$refs.carousel.initEvents();
      });
    },
  },
  computed: {
    settings() {
      return {
        autoPlay: this.autoPlay,
        playSpeed: this.playSpeed,
        wheelControl: false,
        itemsToShow: this.itemsToShow,
        centerMode: true,
        infiniteScroll: this.infinite,
      };
    },
    styleSetting() {
      return {
        '--buttonColor': this.buttonColor,
        '--buttonIconColor': this.buttonIconColor,
        '--contentOrder': this.contentDisplay == 'reverse' ? 'unset' : 1,
      };
    },
    dotsArray: function () {
      return Array.from(Array(this.items.length).keys());
    },
  },
  data() {
    return {
      itemsToShow: 3,
      slidesToShow: 1,
      slidesToScroll: 1,
      isShow: true,
      isMobile: false,
      windowWidth: 0,
    };
  },
  mounted() {
    this.windowWidth = window.innerWidth;

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
.hooper {
  height: unset;
}
:deep(.hooper-slide) {
  height: unset;
}
:deep(.componentContainer) {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 2.1%;
  justify-content: space-between;

  transition: 0.3s;
}
.c02__description__container {
  order: var(--contentOrder);

  display: flex;
  gap: 24px;
  align-items: center;
  .c02__description__image {
    width: 64px;
    aspect-ratio: 1;
    img {
      width: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  .c02__description__title {
    display: block;
  }
  .c02__description__subTitle {
    display: block;
  }
}
.c02__content {
}
.is-active {
  .c02__title {
  }
}
.is-current {
  .c02__content {
  }
}
.is-current ~ :deep(.componentContainer) {
  opacity: 0.5;
}
@media screen and (min-width: 768px) {
  :deep(.componentContainer) {
    padding: 0 2.9%;
  }
}
@media screen and (min-width: 1200px) {
}
</style>
