import PT21 from "@/components/web-page/PictureText/PT21/index.vue";

export default {
    title: "web-page/PictureText/PT21",
    argTypes: {
        items: {
            keyType: {
                imgUrl: "image",
                title: "text",
                buttonText: "text",
            },
            control: "array",
            template: {
                imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                title: "高中留學、交換學生說明會",
                buttonText: "button",
                link: "https://www.google.com/",
            },
            defaultValue: [
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "高中留學、交換學生說明會",
                    buttonText: "報名",
                    link: "https://www.google.com/",
                },
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "海外教中文說明會",
                    buttonText: "報名",
                    link: "https://www.google.com/",
                },
                {
                    imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
                    title: "大專院校海外留學說明會",
                    buttonText: "報名",
                    link: "https://www.google.com/",
                },
            ],
        },
        titleColor: {
            control: "color",
            defaultValue: "black",
        },
        titleFontSize: {
            control: "number",
            defaultValue: 20,
        },
        titleSpacing: {
            control: "number",
            defaultValue: 2,
        },
        buttonColor: {
            control: "color",
            defaultValue: "#0065F2",
        },
        buttonTextColor: {
            control: "color",
            default: "white",
        },
        buttonClickColor: {
            control: "color",
            defaultValue: "#7E1C7E",
        },
        buttonFontSize: {
            control: "number",
            defaultValue: 20,
        },
        buttonTextSpacing: {
            control: "number",
            defaultValue: 2,
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT21 },
    template: `
    <div>
      <PT21 v-bind="$props"/>
    </div>
  `,
});

export const pt21 = Template.bind({});
