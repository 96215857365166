<template>
    <div class="component component-t10" :style="style">
        <div class="t10__wrapper">
            <h2 v-if="titleContent" class="t10__title">
                <base-text block-name="title" v-bind="$props" />
            </h2>
            <p v-if="contentContent" class="t10__content">
                <base-text block-name="content" v-bind="$props" />
            </p>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "T10",
    components: {
        BaseText,
    },
    props: {
        position: String,
        ...generateTextProps("title"),
        ...generateTextProps("content"),
    },
    computed: {
        positionCss(){
            let position
            switch (this.position) {
                case 'left':
                    position = 'flex-start'
                    break;
                case 'center':
                    position = 'center'
                    break;
                case 'right':
                    position = 'flex-end'
                    break;
                default:
                    break;
            }
            return position
        },
        style() {
            return {
                "--position": this.positionCss,
                "--content-position": this.position,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-t10 {
    height: 100%;
    display: flex;
    justify-content: var(--position);
}

.t10__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: var(--position);
    max-width: 1064px;
}

.t10__content{
    text-align: var(--content-position);
}


@media screen and (max-width: 800px) {
}
</style>

