<template>
  <div class="navigation">
    <span class="hooper-prev" @click="slidePrev">
      <slot name="prev">
        <i class="icon-arrow-left"></i>
      </slot>
    </span>
    <span class="hooper-next" @click="slideNext">
      <slot name="next">
        <i class="icon-arrow-right"></i>
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  inject: ['$hooper'],
  name: 'Navigation',
  props: {
    disabledPrevIconUrl: String,
    prevIconUrl: String,
    disabledNextIconUrl: String,
    nextIconUrl: String,
  },
  computed: {
    prevURL: function () {
      return this.isInfinite
        ? this.prevIconUrl
        : this.isPrevDisabled
        ? this.disabledPrevIconUrl
        : this.prevIconUrl;
    },
    nextURL: function () {
      return this.isInfinite
        ? this.nextIconUrl
        : this.isNextDisabled
        ? this.disabledNextIconUrl
        : this.nextIconUrl;
    },
    isPrevDisabled: function isPrevDisabled() {
      if (this.$hooper.config.infiniteScroll) {
        return false;
      }
      return this.$hooper.currentSlide === 0;
    },
    isNextDisabled: function isNextDisabled() {
      if (this.$hooper.config.infiniteScroll) {
        return false;
      }
      if (this.$hooper.config.trimWhiteSpace) {
        return (
          this.$hooper.currentSlide ===
          this.$hooper.slidesCount -
            Math.min(this.$hooper.config.itemsToShow, this.$hooper.slidesCount)
        );
      }

      return this.$hooper.currentSlide === this.$hooper.slidesCount - 1;
    },
    isInfinite() {
      return this.$hooper.config.infiniteScroll;
    },
  },
  methods: {
    slideNext: function slideNext() {
      this.$hooper.slideNext();
      this.$hooper.restartTimer();
    },
    slidePrev: function slidePrev() {
      this.$hooper.slidePrev();
      this.$hooper.restartTimer();
    },
  },
};
</script>

<style scoped lang="scss">
.hooper-next,
.hooper-prev {
  width: 48px;
  aspect-ratio: 1;
  padding: 0;

  border-radius: 50%;

  background: var(--buttonColor);
  color: var(--buttonIconColor);

  transition: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 24px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.hooper-prev {
  left: 48px;
}
.hooper-next {
  right: 48px;
}
</style>
