import BT04 from "./BT04.vue";
import {componentEnum} from "@/models/componentType";
import {G1, G2, G3} from "@/models/gridType";

export default {
  name:"ButtonText BT04",
  componentGroup: componentEnum.ButtonText,
  grids: {...G1, ...G2, ...G3},
  icon: 'mdi-view-dashboard', // 先複製一樣的就好
  component: BT04,
  argTypes: {
    title : {
      control: 'text',
      defaultValue: "title",
    },
    subtitle: {
      control: 'text',
      defaultValue: "subtitle",
    },
    text: {
      control: 'text',
      defaultValue: "Cras libero mauris, faucibus nec nisl vel, laoreet cursus est. In dapibus volutpat felis elementum consequat. In hac habitasse platea dictumst. Sed tristique elit sed quam feugiat lobortis. Aliquam quis augue in nisi pellentesque faucibus. Donec dictum massa in metus facilisis, a sagittis urna ultricies. Duis purus dolor, mollis non erat nec, mattis euismod metus. Aliquam fermentum odio sem, varius efficitur tortor commodo eget. Sed arcu tortor, gravida ut est vel, ultrices molestie nulla. Praesent sollicitudin dapibus leo, eget tincidunt nisl elementum in. Nullam consectetur sit amet ipsum in congue. Integer pellentesque finibus justo quis gravida. Suspendisse luctus eget tellus in egestas. Fusce feugiat pellentesque turpis, ac posuere nulla pellentesque id. Vestibulum sed mauris vestibulum, aliquet purus in, efficitur nulla.",
    },
    buttonText1: {
      control: 'text',
      defaultValue: "按鈕1"
    },
    buttonText2: {
      control: 'text',
      defaultValue: "按鈕2"
    },
    color: {
      control: 'color',
      defaultValue: '#333'
    }
  }
}
