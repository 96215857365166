<template>
  <button :class="classes" :style="styles" @click="click">
    <slot/>
  </button>
</template>

<script>
import color from "@/mixins/color";
import sizeable from "@/mixins/sizeable";
import entry from "@/mixins/entry";
import font from "@/mixins/font";
import themeable from "@/mixins/themeable";

export default {
  name: "BaseButton",
  mixins: [entry, themeable, font, color, sizeable],
  props: {
    block: Boolean,
    outlined: Boolean,
    plain: Boolean,
    textColor: String,
    buttonColor: String,
  },
  computed: {
    classes() {
      return {
        'b01': true,
        'b01--block': this.block,
        'b01--disabled': this.disabled,
        'b01--outlined': this.outlined,
        'b01--plain': this.plain,
        ...this.sizeableClasses,
        ...this.themeClasses
      }
    },
    styles() {
      return {
        [this.hasBg ? 'background-color' : 'color']: this.buttonColor,
        ...(this.hasBg ?  {color: this.textColor} : {}),
        'font-size': this.fontSize ? this.fontSize : null
      }
    },
    hasBg() {
      return !this.plain && !this.outlined
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    },
  }
}
</script>

<style scoped>
/*theme*/
.theme--light{
  color: #000000;
}

.theme--dark{
  color: #FFFFFF;
}

/*sizeable*/
.size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
}

.size--small {
  height: 28px;
  min-width: 50px;
  padding: 0 12.4444444444px;
}

.size--large {
  height: 44px;
  min-width: 78px;
  padding: 0 19.5555555556px;
}

/*b01*/
.b01 {
  --textColor: #FFF;
  --buttonColor: #000;


  position: relative;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.b01:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .2s cubic-bezier(.4, 0, .6, 1);
}

.b01:hover:before {
  opacity: .08;
}

.b01:focus:before {
  opacity: .24;
}

.b01--outlined {
  border: thin solid;
}

.b01--plain{

}

.b01--disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, .26) !important;
  background-color: rgba(0, 0, 0, .12) !important;
}

.b01--block {
  display: flex;
  min-width: 100%;
}

.b01.size--small {
  font-size: .75rem;
}

.b01.size--default, .b01.size--large {
  font-size: .875rem;
}
</style>
