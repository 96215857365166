import PT01 from "@/components/web-page/PictureText/PT01/index.vue";

export default {
  title: 'web-page/PictureText/PT01',
  paramGroup: {
    imgGroup : ['imgURL', 'imgAlt'],
  },
  argTypes: {
    imgURL: {
      control:  {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    imgAlt: {
      control: 'text'
    },
    title: {
      control: "text",
      defaultValue: "default title"
    },
    content: {
      control: "text",
      defaultValue: "default content"
    },
    isBoldHeader: {
      control: "boolean",
      defaultValue: false
    },
    margin: {
      control: "number",
      defaultValue: 5
    },
    padding: {
      control: "number",
      defaultValue: 0
    },
    isContentMiddle:{
      control: "boolean",
      defaultValue: false
    },
    titleColor:{
      control: "color",
      defaultValue:null
    },
    contentColor:{
      control: "color",
      defaultValue: null
    },
    contentPosition: {
      control: 'select',
      options: ["left", "center", "right"],
      defaultValue: "left"
    }
  }
}

// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {PT01},
  template: `
    <div style="width: 453px; margin:auto;">
    <PT01 v-bind="$props"/>
    </div>
  `
});

export const pt01 = TemplatePT01.bind({});
