import component from './index.vue';
import stories from './index.stories.js';

export default {
  name: 'Header06',
  icon: 'mdi-view-dashboard',
  type:'header',
  
  component: component,
  argTypes: stories.argTypes
}
