<template>
  <div class="warp">
    <a class="component prod-card" @click.prevent="onProductClick">
      <div class="prod-card-image">
        <img :src="imageURL[0]" :alt="imageURL">
      </div>
      <div class="prod-card-content">
        <div class="content">
          <h3 class="prod-card-title">{{ title }}</h3>
          <div class="content-price">
            <del class="price price-off" v-if="sellingPrice !== price">
              <span>NT$</span>{{ price }}
            </del>
            <ins class="price price-show">
              <span>NT$</span>{{ sellingPrice }}
            </ins>
          </div>
          <p class="prod-card-text">
            {{ content }}
          </p>
        </div>
        <button class="content-add-cart"
                :class="{empty: data.amount < 1}"
                @click.stop.prevent="onAddCart">加入購物
        </button>
      </div>
    </a>
  </div>

</template>

<script>
import autoColor from "@/mixins/autoColor";
import globalInject from "@/mixins/globalInject";
import productCardMixin from "@/mixins/product/productCardMixin";

export default {
  name: "ProdCard02",
  mixins: [autoColor, globalInject, productCardMixin],
  methods: {
    async onAddCart() {
      this.addCart(this.data);
    }
  }
}
</script>

<style scoped>
.warp {
  --buttonColor: #1F286F;
  display: flex;
  padding: 4px;
}

.component.prod-card {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.prod-card-image {
  width: 100%;
}

.prod-card-image img {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prod-card-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}

.content-price {
  font-weight: 700;
}

.prod-card .prod-card-content {
  /*margin-top: auto;*/
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex: 1;
}

.prod-card .prod-card-text {
  margin-top: 8px;
  color: #6F6F6F;
}

.prod-card .content-price {
  margin-top: 8px;
}

.content-price .price {
  display: block;
}

.content-price .price-off {
  font-size: 16px;
}

.content-price .price-show {
  font-size: 28px;
  text-decoration: none;
}

.prod-card .content-add-cart {
  margin-top: auto;
  width: 100%;
  text-align: center;
  color: #FFF;
  background: var(--buttonColor);
  border-radius: 12px;
  padding: 8px 10px;
}

.prod-card .content-add-cart.empty {
  opacity: .5;
  cursor: not-allowed;
}
</style>
