import v01 from "./V01.vue";
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";

export default {
  componentGroup: componentEnum.Video,
  grids: {...GridEnum},
  icon: 'mdi-view-dashboard', //  顯示在列表上的圖示
  name: 'v01',  // 元件名稱
  component: v01,
  argTypes: {
    videoUrl: { control: "text", defaultValue: 'https://www.youtube.com/watch?v=Hi-kQn3ze4o' },
  },  // 元件參數
}
