<template>
  <div class="footer04" :style="style">
    <div class="footer__main">
      <div class="footer__logo">
        <div class="logo__container">
          <img :src="logoURL" />
        </div>
      </div>
      <div class="footer__content">
        <div class="links">
          <ul>
            <li v-for="(link, index) in linkList" :key="index">
              <a href="#">{{ link.name }}</a>
            </li>
          </ul>
        </div>
        <div class="footer-info">
          <span v-for="(item, index) in contentTextList" :key="index">
            {{ item.text }}
          </span>
        </div>
        <div class="icons">
          <ul>
            <li>
              <a :href="facebook">
                <i class="icon-facebook" />
              </a>
            </li>
            <li>
              <a :href="ins">
                <i class="icon-instagram" />
              </a>
            </li>
            <li>
              <a :href="youtube">
                <i class="icon-youtube" />
              </a>
            </li>
            <li>
              <a :href="line">
                <i class="icon-line" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__others">
        <a :href="item.url" v-for="(item, index) in itemList" :key="index">
          <span>{{ item.text }}</span>
        </a>
      </div>
    </div>
    <div class="footer__copyright">
      <span>
        {{ bottomText }}
        <a
          class="copyright__link"
          :href="item.link"
          v-for="(item, index) in bottomLink"
          :key="index"
        >
          {{ item.text }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import social from '@/models/social';
import embeddedGoogleMapProps from '@/models/embeddedGoogleMap';

export default {
  name: 'F04',
  props: {
    singleRow: Boolean,
    singlePosition: {
      type: String,
      default: 'center',
    },
    logoURL: String,
    bottomText: String,
    bgColor: String,
    bottomBgColor: String,
    textColor: String,
    bottomTextColor: String,
    textHoverColor: String,
    linkList: {
      type: Array,
      default: () => [],
    },
    contentTextList: {
      type: Array,
      default: () => [],
    },
    itemList: {
      type: Array,
      default: () => [],
    },
    bottomLink: {
      type: Array,
      default: () => [],
    },

    facebook: String,
    ins: String,
    youtube: String,
    line: String,
    ...social,
    ...embeddedGoogleMapProps,
  },
  computed: {
    style() {
      return {
        '--backgroundColor': this.bgColor,
        '--bottomBgColor': this.bottomBgColor,
        '--textColor': this.textColor,
        '--bottomTextColor': this.bottomTextColor,
        '--textHoverColor': this.textHoverColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.footer__main {
  background: var(--backgroundColor);
  color: var(--textColor);

  padding: 48px 16px;
  .footer__logo {
    margin-bottom: 48px;
    .logo__container {
      max-width: 200px;
      width: 100%;
      margin: auto;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .footer__content {
    .footer-info {
      margin-bottom: 48px;

      span {
        display: block;
        text-align: center;
        margin-bottom: 12px;
      }
    }
    .icons {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      li {
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }
  .footer__others {
    display: none;
  }
}
.footer__copyright {
  text-align: center;
  background: var(--bottomBgColor);
  color: var(--bottomTextColor);
  padding: 16px;

  .copyright__link {
    &::before {
      content: '|';
    }
  }
}
@media screen and (max-width: 768px) {
  .footer__main {
    .footer__content {
      .links {
        margin-bottom: 48px;

        li {
          // display: block;
          width: fit-content;
          margin: auto;

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .footer__main {
    display: flex;
    .footer__logo,
    .footer__others {
      display: block;
      width: 30%;
      padding: 0 5%;
      a {
        display: block;
        margin-bottom: 12px;
        text-align: right;
      }
    }
    .footer__content {
      width: 0;
      flex: 1;
      padding: 0 16px;

      .links {
        margin-bottom: 48px;

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 8px 16px;
        }

        a {
          display: block;
          padding: 4px;
          width: fit-content;
          transition: 0.3s ease;
        }
        li:hover {
          color: var(--textHoverColor);
        }
      }
    }

    .footer__logo {
      .logo__container {
        max-width: 200px;
        width: 100%;
      }
    }
  }
}
</style>
