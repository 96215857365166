<template>
  <a class="component component-pic04" :ref="`pic04`" :style="style">
    <div class="imgBlock">
      <img :src="imgUrl"/>
    </div>
    <div class="textBlock" ref="txt">
      <h3>
        <span class="title">{{ title }}</span>
        <span class="subTitle">{{ subTitle }}</span>
      </h3>
    </div>
  </a>
</template>

<script>
export default {
  name: 'picture04',
  props: {
    imgUrl: String,
    title: String,
    subTitle: String,
    cardColor: String,
    cardHoverColor: String,
    titleColor: String,
    cardBorderRadius: String,
  },
  data() {
    return {
      scrollY: 0,
      innerHeight: 0,
      txtTop: 0,
      cmpTop: 0,
      cmpHeight: 0,
    };
  },
  methods: {
    onScroll() {
      // console.log('window.scrollY', window.scrollY)
      this.scrollY = window.scrollY;
    },
  },
  computed: {
    cmpShowPercent() {
      let showHeight =
          (Math.min(
                  this.innerHeight + this.scrollY - this.cmpTop,
                  this.cmpHeight
              ) /
              this.cmpHeight) *
          100;
      return showHeight;
    },
    style() {
      return {
        '--cardColor': this.cardColor,
        '--cardHoverColor': this.cardHoverColor,
        '--titleColor': this.titleColor,
        '--cardBorderRadius': this.cardBorderRadius,
        '--transformY': 100 - this.cmpShowPercent + '%',
        '--opacity': (0.6 * this.cmpShowPercent) / 100,
      };
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', () => {
      this.txtTop = this.$refs[`txt`].offsetTop;
      this.cmpTop = this.$refs[`pic04`].getBoundingClientRect().y + this.scrollY;
      this.cmpHeight = this.$refs[`pic04`].offsetHeight;
      this.innerHeight = window.innerHeight - this.cmpHeight * 0.3;
    });

    // console.log(this.$refs[`pic04`].getBoundingClientRect().y, this.$refs[`pic04`].offsetTop)
    this.txtTop = this.$refs[`txt`].offsetTop;
    this.cmpTop = this.$refs[`pic04`].getBoundingClientRect().y + window.scrollY;
    this.cmpHeight = this.$refs[`pic04`].offsetHeight;
    this.innerHeight = window.innerHeight - this.cmpHeight * 0.3;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.component-pic04 {
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .imgBlock {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }

  .textBlock {
    border-radius: 100% 100% 0 0;
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    padding: 24px;
    transform: translateY(var(--transformY));
    z-index: 2;

    span {
      display: block;
      color: var(--titleColor);
      font-weight: 700;
      text-align: center;
    }

    .title {
      font-size: 20px;
      line-height: 27px;

      letter-spacing: 0.04em;
      margin-bottom: 4px;
    }

    .subTitle {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.08em;
    }

    &::before {
      content: '';
      width: 100%;
      aspect-ratio: 1;
      position: absolute;
      inset: 0;
      background: var(--cardColor);
      opacity: var(--opacity);
      border-radius: 50%;
      z-index: -1;
    }
  }
}

@media screen and (min-width: 768px) {
  .component-pic04 {
    .textBlock {
      inset: 0;
      transform: unset;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 8px;
      }

      .subTitle {
        font-size: 10px;
        line-height: 13px;
      }

      &::before {
        inset: 18%;
        width: auto;
        transition: 0.2s ease;
        background: var(--cardColor);
        opacity: 0.5;
      }
    }

    &:hover {
      .textBlock::before {
        inset: 0;
        border-radius: 0;
      }
    }

    &:active {
      .textBlock::before {
        background: var(--cardHoverColor);
        opacity: 0.5;
      }
    }
  }
}
</style>
