export default {
  name: 'themeable',
  props: {
    dark: {
      type: Boolean,
      default: null,
    },
    light: {
      type: Boolean,
      default: null,
    },
  },

  provide () {
    return {
      theme: this.themeableProvide,
    }
  },

  inject: {
    theme: {
      default: {
        isDark: false,
      },
    },
  },

  data () {
    return {
      themeableProvide: {
        isDark: false,
      },
    }
  },

  computed: {
    isDark () {
      if (this.dark === true) {
        // explicitly dark
        return true
      } else if (this.light === true) {
        // explicitly light
        return false
      } else {
        // inherit from parent, or default false if there is none
        return this.theme ? this.theme.isDark : false
      }
    },
    themeClasses () {
      return {
        'theme--dark': this.isDark,
        'theme--light': !this.isDark,
      }
    },
  }
}
