<template>
  <LayoutGrid v-bind="$props" columns="2" class="g4_7">
    <slot/>
  </LayoutGrid>
</template>

<script>
import LayoutGrid from './LayoutGrid';
import layout from "@/layout/mixins/layout";

export default {
  name: "G4_7",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style >

.g4_7 > *:nth-child(1) {
  grid-column: 1 / 1;
  grid-row: 1 / 3;
}

/*.g4_7 > *:nth-child(2)  {*/

/*}*/

/*.g4_7 > *:nth-child(3)  {*/

/*}*/

.g4_7 > *:nth-child(4)  {
  grid-column: 2 / 2;
  grid-row: 2 / 4;
}
</style>
