import PIC03 from '@/components/web-page/Picture/pic03/index.vue';

export default {
  title: 'web-page/Picture/PIC03',
  argTypes: {
    imgUrl: {
      control:  {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    title: {
      control: 'text',
      defaultValue: '標題',
    },
    subTitle: {
      control: 'text',
      defaultValue: '副標題',
    },
    path: {
      control: 'text',
      defaultValue: 'https://www.shopinnapp.com',
    },
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PIC03 },
  template: `<PIC03 v-bind="$props"/>`,
});

export const pic03 = Template.bind({});
