<template>
  <div class="basePagination">
    <ul>
      <li class="previous" :disabled="paging.currentPage == 1">
        <button @click="getPaging(paging.previousPage)">
          <span class="icon-left2"></span>
        </button>
      </li>
      <li
          :class="paging.currentPage == page ? 'active' : ''"
          v-for="(page, index) in pageList"
          :key="index"
      >
        <button v-if="typeof page == 'number'" @click="getPaging(page)">
          {{ page }}
        </button>
        <span v-else>{{ page }}</span>
      </li>
      <li class="next" :disabled="paging.currentPage == pageList">
        <button @click="getPaging(paging.nextPage)">
          <span class="icon-right2"></span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import autoColor from "@/mixins/autoColor";

export default {
  name: "BasePagination",
  mixins: [autoColor],
  props: {
    arrowColor: String,
    paging: { type: Object, default: () => ({}) },
  },
  methods: {
    getPaging(page) {
      this.$emit("getPaging", page);
    },
  },
  computed: {
    pageList() {
      let totalPages = this.paging.totalPages;
      let currentPage = this.paging.currentPage;
      let visibleLength = 7;

      if (totalPages > visibleLength - 1) {
        if (currentPage < 1 + visibleLength / 2) {
          let arr = Array.from(Array(visibleLength - 2).keys());
          let target = arr.map((i) => i + 2);

          return [1].concat(target).concat(["...", totalPages]);
        } else if (currentPage >= totalPages - visibleLength / 2) {
          let arr = Array.from(Array(visibleLength - 2).keys());
          let target = arr.map((i) => i + totalPages - visibleLength + 2);

          return [1, "..."].concat(target).concat(totalPages);
        } else {
          let arr = Array.from(Array(visibleLength - 2).keys());
          let target = arr.map(
              (i) => i + currentPage - Math.ceil((visibleLength - 2) / 2) + 1
          );

          return [1, "..."].concat(target).concat(["...", totalPages]);
        }
      }

      return totalPages;
    },
  },
};
</script>

<style scoped lang="scss">
.basePagination {
  margin: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
  }
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    margin: 0 6px;
    position: relative;
  }
  .active {
    font-weight: 700;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 4px;
      height: 4px;
      background: var(--arrowColor);
      border-radius: 50%;
    }
  }
  .previous button, .next button {
    color: var(--arrowColor);
  }
  .next {
    svg {
      transform: rotate(180deg);
    }
  }
  li[disabled] {
    svg {
      path {
        fill: #7d7d7d;
      }
    }
  }
}
</style>
