<template>
    <div class="component component-pt22" :style="style">
        <div class="pt22__img-wrapper">
            <img :src="imgUrl" alt="header-img" />
        </div>
        <div class="pt22__titleContainer">
            <h2 v-for="item of brNextLine(title)">{{ item }}</h2>
        </div>
        <div class="pt22__contentContainer">
            <p v-for="item of brNextLine(contentText)">{{ item }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "PictureText22",
    props: {
        // img
        imgUrl: String,
        aspectRatio: String,
        // title
        title: String,
        titleColor: String,
        titleFontSize: Number,
        titleSpacing: Number,
        // content
        contentText: String,
        contentColor: String,
        contentFontSize: Number,
        contentSpacing: Number,
    },
    computed: {
        style() {
            return {
                // img
                "--aspect-ratio": this.aspectRatio,
                // title
                "--titleColor": this.titleColor,
                "--titleFontSize": `${this.titleFontSize}px`,
                "--titleSpacing": `${this.titleSpacing}px`,
                // content
                "--contentColor": this.contentColor,
                "--contentFontSize": `${this.contentFontSize}px`,
                "--contentSpacing": `${this.contentSpacing}px`,
            };
        },
    },
    methods: {
        brNextLine(str){
            return str?.split("<br>") || "";
        }
    },
};
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.component-pt22 {
    max-width: 1064px;
    margin: 0 auto;
}
.pt22__img-wrapper {
    aspect-ratio: var(--aspect-ratio);
    max-width: 1064px;
    max-height: 1064px;
}
.pt22__titleContainer {
    font-size: var(--titleFontSize);
    color: var(--titleColor);
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight: 700;
    letter-spacing: var(--titleSpacing);
}

.pt22__contentContainer {
    font-size: var(--contentFontSize);
    color: var(--contentColor);
    letter-spacing: var(--contentSpacing);
}
@media screen and (max-width: 800px) {
    .component-pt22 {
        max-width: 625px;
    }
}
@media screen and (max-width: 414px) {
    .component-pt22 {
        max-width: 316px;
    }
    .pt22__content{
        max-width: 190px;
    }
}
</style>
