import PT20 from "@/components/web-page/PictureText/PT20/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/PictureText/PT20",
    argTypes: {
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
        },
        reverse: {
            control: "boolean",
            defaultValue: false,
        },
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "大標題",
            sizeDefault: "40px",
            colorDefault: "black",
            spacingDefault: "5px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "小標題",
            sizeDefault: "20px",
            colorDefault: "black",
            spacingDefault: "5px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sitfulpa duis.",
            sizeDefault: "20px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT20 },
    template: `
    <div>
      <PT20 v-bind="$props"/>
    </div>
  `,
});

export const pt20 = Template.bind({});
