<template>
  <div class="component component-pt17" :style="style">
    <div class="pt17__images">
      <div class="pt17__image-container">
        <img :src="imgUrl" />
      </div>
      <div class="pt17__image-container">
        <img :src="imgUrl2" />
      </div>
    </div>
    <p class="pt17__content">
      <base-text block-name="content" v-bind="$props" />
    </p>
  </div>
</template>

<script>
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';

export default {
  name: 'PictureText17',
  components: { BaseText },
  props: {
    blockColor: String,
    imgUrl: String,
    imgUrl2: String,
    ...generateTextProps('content'),
  },
  computed: {
    style() {
      return {
        '--blockColor': this.blockColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.component-pt17 {
  display: flex;
  padding: 0;
  gap: 20px 5%;
  align-items: center;
  flex-wrap: wrap;

  .pt17__content {
    position: relative;
    width: 0;
    flex: 1;
    padding: 0 9.6%;
  }
  .pt17__images {
    position: relative;
    display: flex;
    width: 100%;
    gap: 5.21%;
    position: relative;
    padding: 0 13.4% 0 9.6%;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      background: var(--blockColor);
      width: 9.4%;
      height: 100%;
    }
  }
  .pt17__image-container {
    width: 47.39;
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
}
@media screen and (min-width: 721px) {
  .component-pt17 {
    .pt17__images {
      gap: 2.77%;
      padding: 0 12.34% 0 6.65%;

      &::after {
        width: 10.9%;
      }
    }
    .pt17__image-container {
      width: 48.28%;
    }
    .pt17__content {
      padding: 0 6.65%;
    }
  }
}
@media screen and (min-width: 1211px) {
  .component-pt17 {
    padding: 0 10.88%;
    .pt17__content {
      padding: 0;
    }
    .pt17__images {
      width: 53.75%;
      order: 1;
      padding: 0;
      gap: 3.5%;

      &::after {
        left: 103.5%;
        width: 22.3%;
      }
    }
    .pt17__image-container {
      width: 48.25%;
    }
  }
}
</style>
