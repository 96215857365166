<template>
  <div style="height: 100%">
    <button class="nav-close">
      <i v-if="!activeButton" class="icon-bars" @click="activeButton = !activeButton"></i>
      <i v-else class="icon-close" @click="activeButton = !activeButton"></i>
    </button>
    <div class="main-nav" :class="{active: activeButton}" :style="stylevar">
      <ul class="nav-list">
        <template v-for="(item, index) of items">
          <RecursiveNavItem :item="item"
                            :key="index"
                            :is-root-item="true"
                            :is-root-active="isActive"
                            :active-instance="active"
                            @active="onActive"
          />
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import RecursiveNavItem from './RecursiveNavItem';
import {mobileActive} from "@/models/headerColorSetting";
import {mixin as disabledBodyScroll} from "@/mixins/headerDisabledBodyScroll";

export default {
  name: "RecursiveNavList",
  components: {RecursiveNavItem},
  mixins: [mobileActive, disabledBodyScroll],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    bgColor: String,
    textColor: String,
    hoverTextColor: String,
    activeColor: String,
    hoverBGColor: String,
    buttonColor: String
  },
  watch: {
    activeButton(val) {
      // this.disabledBodyScroll(val);
      if (val === false){
        this.active = null;
      }
    }
  },
  data() {
    return {
      activeButton: false
    }
  }
}
</script>

<style scoped src="./index.css">
</style>
