<template>
  <div class="component component-carousel01">
    <div class="ContainerInner">
      <Carousel
        id="carouselContainer"
        :autoplay="false"
        :class="{ isStart: isStart, isEnd: isEnd }"
        :loop="false"
        arrow="always"
        height="150px"
        indicator-position="none"
        type="card"
        @change="handleChange"
      >
        <CarouselItem v-for="(list, index) in newDynamicList" :key="index">
          <div class="itemContainer">
            <div
              v-for="(item, i) in list"
              :key="i"
              class="itemBlock"
              @click="redirect(item.path)"
            >
              <i :class="item.iconName" style="font-size: 56px" :style="{color: iconColor}"></i>
              <p :style="{ color: textColor }" class="itemLabel">
                {{ item.label }}
              </p>
            </div>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, CarouselItem } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
export default {
  name: "Carousel04",
  components: {
    Carousel,
    CarouselItem,
  },
  props: {
    imgUrl: String,
    textColor: String,
    iconColor: String,
    listItems: Array,
  },
  data() {
    return {
      currentWidth: 0,

      isStart: true,
      isEnd: false,
      newDynamicList: [],
    };
  },
  methods: {
    redirect(path) {
      location.href = path;
    },
    getContainerWidth() {
      this.currentWidth = document.querySelector(".ContainerInner").offsetWidth;
    },
    reloadList() {
      this.newDynamicList = [];
      let splitCount = this.currentWidth <= 800 ? 1 : 5;

      for (let i = 0; i < this.$props.listItems.length; i += splitCount) {
        this.newDynamicList.push(
          this.$props.listItems.slice(i, i + splitCount)
        );
      }
    },

    handleChange(current) {
      this.isStart = current == 0;
      this.isEnd = current == this.newDynamicList.length - 1;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.getContainerWidth();
      this.reloadList();
    });
    this.getContainerWidth();
    this.reloadList();
  },
};
</script>

<style lang="scss">
.component-carousel01 {
  .ContainerInner {
    max-width: 1360px;
    margin: 0 auto;
    @media (min-width: 801px) {
      padding: 0 147px 64px;
      border-right: 1px solid #6f6f6f;
    }
    @media (max-width: 414px) {
      padding: 0 18px;
    }

    //.el-carousel__item:nth-child(2n) {
    //  background-color: #99a9bf;
    //}
    //
    //.el-carousel__item:nth-child(2n + 1) {
    //  background-color: #d3dce6;
    //}

    .el-carousel__item--card {
      @media (min-width: 801px) {
        display: none;
        width: 100%;
        cursor: unset;
        transform: unset !important;
        &.is-active {
          display: block;
        }
      }
    }

    #carouselContainer {
      .el-carousel__arrow {
        width: 20px;
        height: 20px;
        background-color: #000;
        i {
          font-family: "icomoon", serif !important;
          font-size: 10px;
          font-weight: 600;
        }
        &.el-carousel__arrow--left i {
          margin-right: 2px;
          &:before {
            content: "\e900";
          }
        }
        &.el-carousel__arrow--right i {
          margin-left: 2px;
          &:before {
            content: "\e908";
          }
        }
      }
      //&:before {
      //  content: "\e901";
      //  }
      &.isStart {
        .el-carousel__arrow--left {
          display: inline-block !important;
          background-color: #6f6f6f;
        }
      }
      &.isEnd {
        .el-carousel__arrow--right {
          display: inline-block !important;
          background-color: #6f6f6f;
        }
      }
      .itemContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0 50px;

        .itemBlock {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          cursor: pointer;
          .itemLabel {
            font-size: 16px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
