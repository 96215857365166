<template>
  <iframe
    :width="width"
    :height="height"
    :src="'https://www.youtube.com/embed/' + id"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; "
    allowfullscreen
  ></iframe>
</template>

<script>
export default {
  name: "BaseYoutube",
  props: {
    width: { type: String, default: "100%" },
    height: { type: String, default: "350px" },
    videoSrc: String,
  },
  computed: {
    id() {
      return this.videoSrc.slice(this.videoSrc.indexOf("watch?v=") + 8);
    },
  },
};
</script>

<style lang="scss"></style>
