<template>
  <div class="component component-prod-card-1">
    <a class="prod-card-1-card" @click.prevent="onProductClick">
      <figure class="card--img">
        <img class="" :src="imageURL[0]" :alt="imageURL">
      </figure>
      <div class="card__warp">
        <h2 class="card__warp__title">{{ title }}</h2>
        <p v-if="content" class="card__warp__sub-title" >{{ content }}</p>

        <div class="care__warp__price">
          <del v-if="sellingPrice !== price" class="price--off">
            <span>NT$</span>{{ price }}
          </del>
          <ins class="price"><span>NT$</span>{{ sellingPrice }}</ins>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import productCardMixin from "@/mixins/product/productCardMixin";

export default {
  name: "ProdCard01",
  mixins: [productCardMixin],
}
</script>

<style scoped>
.component-prod-card-1 {
  display:flex;
  justify-content: center;

}

.component-prod-card-1 .prod-card-1-card {
  width: 100%;
  display: block;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  cursor: pointer;
  color: inherit;
  background-color: #FFF;
}

.prod-card-1-card .card--img {
  aspect-ratio: 1;
}

.prod-card-1-card .card--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prod-card-1-card .card__warp {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  padding: 24px 25px;
  height: 171px;

}

.card__warp__title {
  font-size: 18px;
  font-weight: 700;
}

.card__warp__sub-title {
  display: inline-block;
  padding-top: 4px;
}

.care__warp__price {
  color: #6F6F6F;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.prod-card-1-card .price {
  text-decoration: none;
}

</style>
