export default {
  name: 'font',
  props: {
    fontColor: {
      type: String,
      default: null
    },
    fontSize: {
      type: String,
      default: null
    }
  },
}
