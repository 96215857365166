<template>
  <div class="component c03" :style="{...style, ...componentColor}" ref="carousel03">
    <div :class="['carousel', `active-${isEnd() ? 'end' : currentSlide}`]">
      <ul>
        <li class="carousel__item" v-for="(item, index) in items" :key="index">
          <a :href="link" :style="computedAspectRatio">
            <img :src="item.imgSrc" :alt="item.alt" />
            <div class="carousel__item-hover">
              <i :class="`icon-${hoverIcon}`" />
            </div>
          </a>
        </li>
      </ul>

      <input v-model="currentSlide" class="currentSlide" />
      <button class="button toPrev" @click="toPrev">
        <i class="icon-arrow-left"></i>
      </button>
      <button class="button toNext" @click="toNext">
        <i class="icon-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import autoColor from "@/mixins/autoColor";

export default {
  name: 'C03',
  mixins: [autoColor],
  props: {
    link: String,
    items: {
      type: Array,
      default: () => [],
    },
    buttonColor: String,
    buttonIconColor: String,
    buttonClickColor: String,
    hoverBackgroundColor: String,
    hoverIcon: String,
    hoverIconColor: String,
    icon: String,
    aspectRatio: String,
  },
  data() {
    return {
      currentSlide: 0,
      intervalId: null,
      windowWidth: 0,
      componentWidth: this.$refs.carousel03?.clientWidth,
    };
  },
  methods: {
    toPrev() {
      // if (this.currentSlide == 0) return;

      if (this.isEnd()) this.currentSlide = Math.floor(this.maxSlide / 362);
      else
        this.currentSlide =
          (this.currentSlide - 1 + this.items.length) % this.items.length;
    },
    toNext() {
      // if (this.isEnd()) return;

      if (this.isEnd()) this.currentSlide = 0;
      else this.currentSlide = (this.currentSlide + 1) % this.items.length;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.componentWidth = this.$refs.carousel03?.clientWidth;
    },
    isEnd() {
      if (this.windowWidth > 768) {
        let slide = this.currentSlide * 362;
        return slide > this.maxSlide;
      } else return false;
    },
  },
  watch: {
    currentSlide: {
      handler() {
        clearInterval(this.intervalId);
        this.intervalId = setInterval(() => {
          if (this.isEnd()) this.currentSlide = 0;
          else this.currentSlide = (this.currentSlide + 1) % this.items.length;
        }, 5000);
      },
    },
  },
  computed: {
    style() {
      return {
        '--maxSlide': this.maxSlide,
      };
    },
    maxSlide() {
      let itemLength = 362 * this.items.length;
      let max = itemLength - this.componentWidth;

      return max;
    },
    computedAspectRatio() {
      let res = '';
      switch (this.aspectRatio) {
        case 'auto':
          res = null;
          break;
        case '1:1':
          res = '1/1';
          break;
        case '3:4':
          res = '3/4';
          break;
        case '4:3':
          res = '4/3';
          break;
        case '2:1':
          res = '2/1';
          break;
      }
      return {'aspect-ratio': res};
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.componentWidth = this.$refs.carousel03?.clientWidth;

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.intervalId = setInterval(() => {
      if (this.isEnd()) this.currentSlide = 0;
      else this.currentSlide = (this.currentSlide + 1) % this.items.length;
    }, 5000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
.c03 {
  --buttonIconColor: #FFF;
}
.carousel {
  --carousel-offset: 0px;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 6.28% 0; // 26/414
  ul {
    width: 100%;
    display: flex;
  }
  li {
    transition: 0.5s ease;
    flex-shrink: 0;
  }
  .carousel__item {
    width: 100%;
    // aspect-ratio: 1;
    padding: 0 3.865%; // 16/414
    cursor: pointer;

    a {
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-hover {
      display: none;
    }
  }
  .currentSlide {
    display: none;
  }
  .button {
    position: absolute;
    font-size: 24px;
    background: var(--buttonColor);
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: var(--buttonIconColor);
    }

    &.toNext {
      right: 32px;
    }
    &.toPrev {
      left: 32px;
    }

    &:active {
      i {
        opacity: 0.6;
        color: var(--buttonClickColor)
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .carousel {
    --carousel-offset: 36px;

    .carousel__item {
      width: 362px;
      padding: 0 12px;

      &:first-of-type {
        margin-left: 36px;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .carousel {
    .carousel__item {
      a {
        position: relative;
      }
      &-hover {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--hoverBackgroundColor);
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: 0.3s ease;

        i {
          color: var(--hoverIconColor);
          font-size: 48px;
        }
      }
      img {
        transition: 0.3s ease;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }

        .carousel__item-hover {
          opacity: 0.5;
        }
      }
    }
  }
}
@for $i from 0 through 30 {
  .carousel.active-#{$i} {
    li {
      @if $i==0 {
        transform: translateX(calc($i * -100%));
      } @else {
        transform: translateX(calc($i * -100% - var(--carousel-offset)));
      }
    }
  }
}
.carousel.active-end {
  li {
    transform: translateX(
      calc(var(--maxSlide) * -1px - var(--carousel-offset))
    );
  }
}
</style>
