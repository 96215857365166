import component from './index.vue';
import stories from './index.stories.js';
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";

let {G3_1, G4_1, G6_1} = GridEnum

export default {
  componentGroup: componentEnum.Blog,
  grids: {
    G3_1,
    G4_1,
    G6_1
  },
  name: 'Blog01',
  icon: 'mdi-view-dashboard',
  type: 'container',
  component: component,
  argTypes: stories.argTypes
}

class Component  {

}
class Blog01 extends Component {

}

new Blog01();
