import PT06 from "@/components/web-page/PictureText/PT06/index.vue";

export default {
  title: 'web-page/PictureText/PT06',
  argTypes: {
    textColor: {
      control: 'color',
      default: '#000'
    },
    items: {
      keyType: {
        iconURL: 'image',
        imgURL: 'image',
      },
      control: "array",
      template: {
        iconURL: "https://fakeimg.pl/32x32/",
        imgURL: "https://fakeimg.pl/250x360/",
        content: "請輸入文字",
      },
      defaultValue: [
        {
          iconURL: "https://fakeimg.pl/32x32/",
          imgURL: "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/jCNhhpoVhT/image/Frame 910.png",
          content: "網站架設界的樂高公司<br>，讓你自由組建網站"
        }
      ]
    }
  }
}

// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {PT06},
  template: `
    <div style="width: 100%;margin:auto;">
    <PT06 v-bind="$props"/>
    </div>
  `
});

export const pt06 = TemplatePT01.bind({});
