import component from './index.vue';
import stories from './index.stories.js';
import {TypeEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";
import {BlockEnum} from "@/models/Enum/BlockEnum";

let {G1_1} = GridEnum;

export default {
  componentGroup: TypeEnum.PictureText,
  type: BlockEnum.CONTAINER,
  grids: {G1_1},
  name: 'PictureText23',
  component: component,
  argTypes: stories.argTypes
}
