import T05 from "@/components/web-page/Text/T05/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/Text/T05",
    argTypes: {
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "大標題",
            sizeDefault: "36px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sitfulpa duis.",
            sizeDefault: "16px",
            colorDefault: "#000",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "小標題",
            sizeDefault: "16px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        // imgUrl
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
        },
        // alignOption
        horizontalAlignOption: {
            control: "select",
            options: ['left', 'center', 'right'],
            defaultValue: 'center',
        },
        verticalAlignOption: {
            control: "select",
            options: ['top', 'center', 'bottom'],
            defaultValue: 'center',
        },    
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { T05 },
    template: `
    <div>
      <T05 v-bind="$props"/>
    </div>
  `,
});

export const t05 = Template.bind({});
