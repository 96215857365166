import C04 from "@/components/web-page/Carousel/C04/index.vue";

export default {
  title: "web-page/Carousel/C04",
  argTypes: {
    imgUrl: {
      control: "text",
      defaultValue: "https://fakeimg.pl/252x297/eaeaea/ffd700",
    },
    textColor: {
      control: "color",
      defaultValue: "#6F6F6F",
    },
    iconColor: {
      control: "color",
      defaultValue: "#BD9B7B",
    },
    listItems: {
      control: "array",
      template: {
        iconName: "icon-arrow-up",
        label: "該選項名稱",
        path: "轉跳網址",
      },
      defaultValue: [
        {
          iconName: "icon-arrow-up",
          label: "該選項名稱",
          path: "https://www.google.com.tw",
        },
        {
          iconName: "icon-arrow-up",
          label: "該選項名稱",
          path: "https://www.google.com.tw",
        },
        {
          iconName: "icon-arrow-up",
          label: "該選項名稱",
          path: "https://www.google.com.tw",
        },
        {
          iconName: "icon-arrow-up",
          label: "該選項名稱",
          path: "https://www.google.com.tw",
        },
        {
          iconName: "icon-arrow-up",
          label: "該選項名稱",
          path: "https://www.google.com.tw",
        },
        {
          iconName: "icon-arrow-up",
          label: "該選項名稱",
          path: "https://www.google.com.tw",
        },
      ],
    },
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { C04 },
  template: `<C04 v-bind="$props"/>`,
});

export const c04 = Template.bind({});
