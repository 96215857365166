import component from "./index.vue";
import stories from './index.stories.js';
import {GridEnum} from "@/models/gridType";
import {TypeEnum} from "@/models/componentType";
import {BlockEnum} from "@/models/Enum/BlockEnum";

export default {
  componentGroup: TypeEnum.PictureText,
  type: BlockEnum.CONTAINER,
  grids: {...GridEnum},
  name: 'PT05',
  component: component,
  argTypes: stories.argTypes
}

