import PT19 from "@/components/web-page/PictureText/PT19/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/PictureText/PT19",
    argTypes: {
        // img
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/demo.png",
        },
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "我是大標題我",
            sizeDefault: "36px",
            colorDefault: "white",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "我是小標題我",
            sizeDefault: "16px",
            colorDefault: "white",
            spacingDefault: "1px",
        }),
        // background color
        bgColor: {
            control: "color",
            defaultValue: "#000000"
        },
        // slash color
        borderColor: {
            control: "color",
            defaultValue: "white"
        },
        width: {
            control: "number",
            defaultValue: 2,
        },
        // button
        buttonText: {
            control: "text",
            defaultValue: "BUTTON",
        },
        buttonFontSize: {
            control: "number",
            defaultValue: 20,
        },
        buttonColor: {
            control: "color",
            defaultValue: "#0065F2",
        },
        buttonTextColor: {
            control: "color",
            defaultValue: "white",
        },
        buttonClickColor: {
            control: "color",
            defaultValue: "#7E1C7E",
        },
        buttonTextSpacing: {
            control: "number",
            defaultValue: 2,
        },
        link: {
            control: "text",
            defaultValue: "https://google.com.tw"
        },
        // is div reverse
        reverse: {
            control: "boolean",
            defaultValue: false,
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT19 },
    template: `
    <div>
      <PT19 v-bind="$props"/>
    </div>
  `,
});

export const pt19 = Template.bind({});

