import Element from './index.vue';
import { generateTextArgTypes } from '@/components/base/javaScript/baseTest';
import { ControlEnum } from '@/models/Enum/ControlEnum';

export default {
  title: 'web-page/PictureText/PT17',
  argTypes: {
    imgUrl: {
          control: ControlEnum.IMG,
          defaultValue:
              "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
    },
    imgUrl2: {
        control: {
            type: "text",
            editType: "image",
        },
        defaultValue:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
    },
    blockColor:{
      control: "color",
      defaultValue: "#1F286F",
    },
    ...generateTextArgTypes('content', {
      contentDefault: '預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字預設文字',
      sizeDefault: '16px',
      colorDefault: '#000',
    }),
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Element },
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `,
});

export const element1 = Template.bind({});
