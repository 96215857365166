<template>
  <li class="list-item">
    <a :href="item.url" :class="{active: isActive}"  @click.prevent.stop.self="onItemClick(item.url)">
      {{ item.contentName }}
      <!-- 更多ICON -->
      <span  class="item-icon" v-if="hasChildren" >
        <!-- 第一層ICON -->
        <span class="desktop">
          <i class="icon-button-triangle-down"></i>
        </span>
        <!-- 第二層ICON -->
        <span class="mobile">
          <i class="icon-button-triangle-right inactive"></i>
          <i class="icon-button-triangle-left active"></i>
        </span>
      </span>
    </a>
    <!-- 子選單 -->
    <ul class="inner-list" v-if="hasChildren">
      <template v-for="(innerItem, index) of item.children">
        <HeaderMenuItem :item="innerItem"
                        :key="index"
                        :root-item="false"
                        :root-active="isActive"
                        :active-instance="active"
                        @active="onActive"
        />
      </template>
    </ul>
  </li>
</template>

<script>
import HeaderMenuMixin from "@/mixins/header/HeaderMenuMixin";

export default {
  name: "HeaderMenuItem",
  mixins: [HeaderMenuMixin],
  methods: {
    onItemClick(url) {
      if (this.hasChildren && !this.isActive) {
        this.onActiveTrigger();
      } else {
        window.location = url;
        this.onActiveTrigger();
      }
    }
  }
}
</script>

<style scoped src="../css/header.menu.css"></style>
