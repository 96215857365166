export default {
  inject: {
    langList: {
      default: () => () => [{name: "繁體中文", code: "zh-tw"}, {name: "English", code: "en-us"}]
    },
    nowLange: {
      default: () => () => "繁體中文-tw"
    }
  },
  computed: {
    langListShow() {
      return this.langList();
    },
    nowLangeShow() {
      return this.nowLange();
    }
  },
}
