import PT26 from "@/components/web-page/PictureText/PT26/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
  title: "web-page/PictureText/PT26",
  argTypes: {
    items: {
      keyType: {
        imgUrl: "image",
        title: "text",
        buttonText: "text",
      },
      control: "array",
      template: {
        imgUrl:
          "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
        titleContent: "蘋果",
        subTitleContent: "Apple",
        icon: "icon-arrow2-left1",
        link: "https://www.google.com/",
      },
      defaultValue: [
        {
          imgUrl:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          titleContent: "蘋果",
          subTitleContent: "Apple",
          icon: "icon-arrow2-left1",
          link: "https://www.yahoo.com/",
        },
        {
          imgUrl:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          titleContent: "iPhone 系列",
          subTitleContent: "",
          icon: "icon-arrow2-left1",
          link: "https://www.google.com/",
        },
        {
          imgUrl:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          titleContent: "iPad 系列",
          subTitleContent: "",
          icon: "icon-arrow2-left1",
          link: "https://www.google.com/",
        },
        {
          imgUrl:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          titleContent: "MacBook 系列系列",
          subTitleContent: "www.apple.com",
          icon: "icon-arrow2-left1",
          link: "https://www.google.com/",
        },

        {
          imgUrl:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          titleContent: "蘋果",
          subTitleContent: "Apple",
          icon: "icon-arrow2-left1",
          link: "https://www.yahoo.com/",
        },
        {
          imgUrl:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          titleContent: "iPhone 系列",
          subTitleContent: "",
          icon: "icon-arrow2-left1",
          link: "https://www.google.com/",
        },
        {
          imgUrl:
            "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          titleContent: "iPad 系列",
          subTitleContent: "",
          icon: "icon-arrow2-left1",
          link: "https://www.google.com/",
        },
      ],
    },
    ...generateTextArgTypes("title", {
      hiddeContent: true,
      sizeDefault: "20px",
      fontWeightDefault: "700",
    }),
    ...generateTextArgTypes("subTitle", {
      hiddeContent: true,
      sizeDefault: "14px",
      fontWeightDefault: "400",
      colorDefault: "#6F6F6F",
    }),
    btnIconColor: {
      control: "color",
      defaultValue: "#FFF",
    },
    btnBackgroundColor: {
      control: "color",
      defaultValue: "#A0E0F4",
    },
    btnBackgroundHoverColor: {
      control: "color",
      defaultValue: "#EAFAFF",
    },
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PT26 },
  template: `
    <div>
      <PT26 v-bind="$props"/>
    </div>
  `,
});

export const pt26 = Template.bind({});
