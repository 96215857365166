import Element from './index.vue';
import { generateTextArgTypes } from '@/components/base/javaScript/baseTest';
import { ControlEnum } from '@/models/Enum/ControlEnum';

export default {
  title: 'web-page/Carousel/C02',
  argTypes: {
    autoPlay: {
      control: ControlEnum.BOOL,
      defaultValue: false
    },
    playSpeed: {
      control: ControlEnum.NUMBER,
      defaultValue: 3000
    },
    infinite: {
      control: ControlEnum.BOOL,
      defaultValue: true
    },
    items: {
      control: ControlEnum.ARRAY,
      keyType: {
        imgUrl: 'image',
      },
      template: {
        imgUrl: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
        alt: "",
        title: '標題',
        subTitle: '副標題',
        content:'內文',
      },
      defaultValue: [
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題一',
          subTitle: '副標題',
          content:'內文',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題二',
          subTitle: '副標題',
          content:'內文',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題三',
          subTitle: '副標題',
          content:'內文',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題四',
          subTitle: '副標題',
          content:'內文',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題五',
          subTitle: '副標題',
          content:'內文',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題六',
          subTitle: '副標題',
          content:'內文',
        },
      ],
    },
    buttonColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#0005",
    },
    buttonIconColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#cccccc",
    },
    contentDisplay:{
      control: ControlEnum.SELECT,
      options: ['normal','reverse'],
      defaultValue: 'normal',
    },
    
    ...generateTextArgTypes('title', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '16px',
      colorDefault: '#000',
    }),
    ...generateTextArgTypes('subTitle', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '16px',
      colorDefault: '#000',
    }),
    ...generateTextArgTypes('content', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '16px',
      colorDefault: '#000',
    }),
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Element },
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `,
});

export const element1 = Template.bind({});
