import component from './index.vue';
import stories from './index.stories.js';
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";

let {G1_1, G2_1, G3_1, G4_1} = GridEnum;
export default {
  componentGroup: componentEnum.Controls,
  grids: {G1_1, G2_1, G3_1, G4_1},
  name: 'ControlFile',
  type: 'container',
  component: component,
  argTypes: stories.argTypes
}
