<template>
  <section class="page">
    <slot/>
  </section>
</template>

<script>
import themeable from "@/mixins/themeable";

export default {
  name: "Page",
  mixins: [themeable],
  props: {
    primary: {
      type: String,
      default: "#333333"
    },
    secondary: {
      type: String,
      default: "#000000"
    },
    basic1: {
      type: String,
      default: "#000000"
    },
    basic2: {
      type: String,
      default: "#333333"
    },
    basic3: {
      type: String,
      default: "#FFFFFF"
    },
    backgroundImage: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: "#FFFFFF"
    },
  },
  methods: {
    changeData() {
      this.themeableProvide.primary = this.primary;
      this.themeableProvide.secondary = this.secondary;
      this.themeableProvide.basic1 = this.basic1;
      this.themeableProvide.basic2 = this.basic2;
      this.themeableProvide.basic3 = this.basic3;

      this.themeableProvide.backgroundImage = this.backgroundImage;
      this.themeableProvide.backgroundColor = this.backgroundColor;
    }
  },
  created() {
    this.$set(this.themeableProvide, 'primary', this.primary);
    this.$set(this.themeableProvide, 'secondary', this.secondary);
    this.$set(this.themeableProvide, 'basic1', this.basic1);
    this.$set(this.themeableProvide, 'basic2', this.basic2);
    this.$set(this.themeableProvide, 'basic3', this.basic3);
    this.$set(this.themeableProvide, 'backgroundImage', this.backgroundImage);
    this.$set(this.themeableProvide, 'backgroundColor', this.backgroundColor);
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.changeData();
      }
    }
  }
}
</script>

<style scoped>

</style>
