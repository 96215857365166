import component from './index.vue';
import stories from './index.stories.js';
import {componentEnum} from "@/models/componentType";
import {G1, G2, G3} from "@/models/gridType";

export default {
  name: 'ButtonText05',
  componentGroup: componentEnum.ButtonText,
  grids: {...G1, ...G2, ...G3},
  icon: 'mdi-view-dashboard',
  type:'container',
  component: component,
  argTypes: stories.argTypes
}
