import B01 from "@/components/web-page/Blog/B01_1/index.vue";

export default {
  title: 'web-page/Blog/B01_1',
  argTypes: {
    classId: {
      control: 'text',
      defaultValue: "34"
    },
    backgroundColor: {
      control: 'color',
      defaultValue: '#FFF'
    },
    titleColor: {
      control: 'color',
      defaultValue: '#000'
    },
    contentColor: {
      control: 'color',
      defaultValue: null
    },
    clickTextColor: {
      control: 'color',
      defaultValue: '#000'
    },
    arrowColor:  {
      control: 'color',
      defaultValue: '#000'
    },
    arrowIconColor:  {
      control: 'color',
      defaultValue: '#FFF'
    },
    text: {
      control: 'text',
      defaultValue: "關於我們"
    }
  }
}
// PT01
  const TemplatePT01 = (args, {argTypes}) => ({
    props: Object.keys(argTypes),
    components: {B01},
    template: `
      <div>
        <B01 v-bind="$props"/>
      </div>
    `
  });

  export const b01_1 = TemplatePT01.bind({});
