export default {
  props: {
    // 節點資料
    items: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    },
    // 是否為根節點
    rootItem: Boolean,
    // 跟節點是否為啟用狀態
    rootActive: Boolean,
    // 父層節點實例
    activeInstance: Object,
  },
  watch: {
    rootActive(bool) {
      //if parents is close，child close to
      if (bool === false) {
        this.$emit('active', null);
      }
    }
  },
  computed: {
    //child
    isMobile() {
      return window.innerWidth <= 1199;
    },
    isActive() {
      return this === this.activeInstance;
    },
    hasChildren() {
      return this.item.children && this.item.children.length > 0
    },
  },
  methods: {
    //parents
    onActive(instance) {
      // active to activeInstance
      this.active = instance;
    },
    //child
    onActiveTrigger() {
      this.$emit('active', this === this.activeInstance ? null : this);
    }
  },
  data() {
    return {
      active: null
    }
  },
}
