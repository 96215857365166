<template>
  <BBase center :title="title" :color="color" v-bind="$props">
    <BaseButton dark plain :color="color">
      <span>{{buttonText}}</span>
    </BaseButton>
  </BBase>
</template>

<script>
import BBase from "@/components/web-page/ButtonText/component/BBase";
import BaseButton from "@/components/base/BaseButton";
import sizeable from "@/mixins/sizeable";
import color from "@/mixins/color";

export default {
  name: "BT03",
  components: {BaseButton, BBase},
  mixins: [color, sizeable],
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    buttonText: String,
  }
}
</script>

<style scoped>

</style>
