<template>
  <div class="component component-c01" @click="redirect" :style="style">
    <img :src="imgUrl" class="imgBlock" />
    <div class="textBlock">
      <p class="title">
        <base-text block-name="title" content-name="title" v-bind="$props" />
      </p>
      <p class="subTitle">
        <base-text block-name="subTitle" content-name="subTitle" v-bind="$props" />
      </p>
    </div>
  </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import {generateTextProps} from "@/components/base/javaScript/baseTest";

export default {
  name: 'picture02',
  components: {BaseText},
  props: {
    //------圖片-----
    imgUrl: String,
    path: String,
    aspectRatio: String,
    textBlockColor: String,
    //------標題------
    title: String,
    ...generateTextProps('title'),
    //------副標題------
    subTitle: String,
    ...generateTextProps('subTitle'),
  },
  methods: {
    redirect() {
      location.href = this.$props.path;
    },
  },
  computed: {
    computedAspectRatio() {
      let res = '';
      switch (this.aspectRatio) {
        case 'auto':
          res = 'auto';
          break;
        case '1:1':
          res = '1/1';
          break;
        case '3:4':
          res = '3/4';
          break;
        case '2:1':
          res = '2/1';
          break;
      }

      return res;
    },
    style() {
      return {
        '--aspectRatio': this.computedAspectRatio,
        '--textBlockColor': this.textBlockColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.component-c01 {
  position: relative;
  cursor: pointer;
  aspect-ratio: var(--aspectRatio);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow: hidden;

  .imgBlock {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .textBlock {
    position: absolute;
    bottom: 0;
    width: 100%;
    aspect-ratio: 2/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;

    .title {
      position: relative;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.4;
    }

    .subTitle {
      position: relative;
      font-size: 14px;
      line-height: 1.4;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(#000000, 0.5);
      border-radius: 50% 50% 0 0;
      transform: translateY(50%);
      transition: 0.3s ease;
      opacity: 0;
      width: 100%;
      aspect-ratio: 1;
    }
  }

  &:hover {
    .textBlock {
      &::before {
        transform: translateY(0%);
        opacity: 1;
      }
    }
  }
}
</style>
