import component from "./index.vue";
import stories from "./index.stories.js";
import {componentEnum} from "@/models/componentType";
import {G1, G2} from "@/models/gridType";

export default {
  componentGroup: componentEnum.Carousel,
  grids: {...G1, ...G2},
  icon: 'mdi-view-dashboard',
  name: 'Carousel C05',
  type:'container',
  component: component,
  argTypes: stories.argTypes
}
