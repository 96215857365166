<template>
  <div class="buttons-container">
    <!--收尋-->
    <search v-if="searchStatus"/>
    <!--登入-->
    <login v-if="loginStatus"/>
    <!--購物車-->
    <cart v-if="cartStatus"/>
    <!--按鈕-->
    <!--多國語系-->
    <language-btn v-if="languageStatus"/>
  </div>
</template>

<script>
import Search from "./buttons/Search.vue";
import Login from "./buttons/Login.vue";
import Cart from "./buttons/Cart.vue";
import LanguageBtn from "./buttons/language/LanguageBtn.vue";

export default {
  name: "Operate",
  inject: ['headerButtonsStatus'],
  components: {LanguageBtn, Cart, Login, Search},
  inheritAttrs: false,
  computed: {
    searchStatus() {
      return this.headerButtonsStatus().search;
    },
    loginStatus() {
      return this.headerButtonsStatus().login;
    },
    cartStatus() {
      return this.headerButtonsStatus().cart;
    },
    languageStatus() {
      return this.headerButtonsStatus().language;
    },
  }
}
</script>

<style scoped src="../css/header.buttons.css"></style>
<style>
.buttons-container {
  gap: 20.67px;
}

[class^="buttons-"] {
  cursor: pointer;
}

[class^="buttons-"]:last-child {
  padding-right: 20.67px;
}

@media screen and (max-width: 1199px) {
  .buttons-container [class^="buttons-desktop-"]{
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .buttons-container {
    gap: 18.33px;
  }

  [class^="buttons-"]:last-child {
    padding-right: 0;
  }
}
</style>
