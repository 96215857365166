<template>
  <div class="component component-pd01">
    <template v-for="item of items">
      <component
          class="pd01-item"
          :is="selectProdComponent"
          :key="item.id"
          :style="componentColor"
          v-bind="dataToComponentProps(item)"
      />
    </template>

  </div>
</template>

<script>
import selectProd from "@/mixins/product/selectProd";
import autoColor from "@/mixins/autoColor";

/**
 * 每行固定4個
 * 可條調整行數
 */
export default {
  name: "PD01",
  mixins: [selectProd, autoColor],
  props: {
    buttonColor: String,
    rows: {
      type: Number,
      default: 1
    },
  },
  watch: {
    async rows(val) {
      let quantity = 4 * val;
      await this.getDate(quantity);
    }
  },
  async created() {
    // 4 是常數，因為一排最多顯示 4 筆
    let quantity = 4 * this.rows;
    // 取得商品資料方法來自selectProd
    await this.getDate(quantity);
  }
}
</script>

<style scoped>
.component-pd01 .pd01-item {
  margin-left: auto;
  margin-right: auto;
  //padding-bottom: 24px;
  max-width: 248px;
}


/*手機以上*/
@media screen and (min-width: 768px) {

  .component-pd01 {
    max-width: 520px;
    margin: auto;

    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .component-pd01 .pd01-item {
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {

  .component-pd01 {
    padding: 25px 0;
    max-width: 1064px;
  }
  .component-pd01 .pd01-item {
    transition: transform .3s ease;
  }
  .component-pd01 .pd01-item:hover {
    transform: translateY(-25px);
  }
}

</style>
