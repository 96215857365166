<template>
  <div class="logo-container">
    <a href="/">
      <img :src="logo" :alt="alt">
    </a>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    logo: {
      type: String,
      default: ""
    },
    alt: {
      type: String,
      default: "Logo"
    }
  }
}
</script>

<style scoped src="../css/header.logo.css"></style>
