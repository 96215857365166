2<template>
  <div class="component component-pt14" :style="style">
    <div class="pt14__container">
      <div class="pt14__content">
        <div class="pt14__title__container">
          <div class="pt14__title__top-image">
            <img :src="titleTopImgUrl" />
          </div>
          <div class="pt14__title">
            <p>{{ title }}</p>
            <div class="pt14__title__bg">
              <img :src="titleImgUrl" />
            </div>
          </div>
          <div class="pt14__title__bottom-image">
            <img :src="titleBottomImgUrl" />
          </div>
        </div>
        <ul :class="['pt14__list', `pt14__list-count${cardCount}`]">
          <li v-for="(item, index) in list" :key="index" class="pt14__item">
            <div :class="['pt14__card', `pt14__card${index + 1}`]">
              <div class="card__container">
                <div class="card__index">{{ index + 1 }}</div>
                <div class="card__title">
                  <p
                    v-for="(text, index) in item.text.split('<br>')"
                    :key="index"
                  >
                    {{ text }}
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PictureText14',
  props: {
    titleImgUrl: String,
    titleTopImgUrl: String,
    titleBottomImgUrl: String,
    title: String,
    titleColor: String,
    list: Array,
    outerCircleColor: String,
    innerCircleColor: String,
    cardThemeColor1: String,
    cardThemeColor2: String,
    cardCount: Number,
  },

  methods: {
    redirect() {
      location.href = this.$props.path;
    },
    getItemName(index) {
      if (index < this.list.length / 2) return `pt14__item${index + 1}`;
      else
        return `pt14__item${
          this.list.length + this.list.length / 2 + 1 - index
        }`;
    },
  },
  computed: {
    style() {
      return {
        '--titleColor': this.titleColor,
        '--outerCircleColor': this.outerCircleColor,
        '--innerCircleColor': this.innerCircleColor,
        '--cardThemeColor1': this.cardThemeColor1,
        '--cardThemeColor2': this.cardThemeColor2,
        '--cardCount': this.cardCount,
        '--cardSize': this.cardCount == 8 ? '147px' : '190px',
      };
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
$itemSize: 147px;
$itemGap: 24px;

.component-pt14 {
  // background-image: linear-gradient(45deg, #fac93e 25%, transparent 25%);
  .pt14__container {
    max-width: 414px;
    width: 100%;
    margin: auto;
  }
  .pt14__content {
    height: 100%;
    position: relative;
    padding: 24px;
  }
  .pt14__title {
    width: 100%;
    aspect-ratio: 1;
    // width: 426px;
    // height: 426px;
    margin: auto;
    box-sizing: border-box;

    border-radius: 50%;

    border: 12px solid var(--innerCircleColor);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    // overflow: hidden;
    z-index: 1;

    p {
      font-weight: 700;
      font-size: 40px;
      line-height: 53px;
      text-align: center;
      text-decoration-line: underline;

      color: var(--titleColor);

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &__container {
      margin-bottom: 24px;
    }

    &__bg {
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }
    &__top-image {
      margin: 0 auto -30px;
      width: 190px;

      img {
        vertical-align: top;
      }
    }
    &__bottom-image {
      margin: -30px auto 0;
      width: 190px;

      img {
        vertical-align: top;
      }
    }
  }

  @mixin cardSize($width, $height: $width) {
    width: $width;
    height: $height;
  }

  .pt14__list {
    display: grid;
    grid-template-rows: repeat(calc(var(--cardCount) / 2), 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    grid-gap: 24px;
    margin: auto;
    width: 318px;
  }
  .pt14__item {
    width: fit-content;
  }

  .pt14__card {
    @include cardSize(147px);

    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    // position: absolute;

    .card__container {
      // width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .card__index {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
    }

    .card__title {
      text-align: center;
    }
  }

  .pt14__item:nth-child(n) .card__index {
    background: var(--cardThemeColor1);
  }
  .pt14__item:nth-child(2n) .card__index {
    background: var(--cardThemeColor2);
  }
  .pt14__list-count8 {
    .pt14__item:nth-child(n + 5) .card__index {
      background: var(--cardThemeColor1);
    }
    .pt14__item:nth-child(2n + 5) .card__index {
      background: var(--cardThemeColor2);
    }
    .pt14__item:nth-child(n + 9) {
      display: none;
    }
  }
  .pt14__list-count6 {
    .pt14__item:nth-child(n + 7) {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) {
  // $cardCount: calc(var(--cardCount);
  $perDeg: calc(360deg / (var(--cardCount) + 2));
  @mixin cardSize($width, $height: $width) {
    width: $width;
    height: $height;
  }
  @mixin itemPosition($index) {
    transform: translateX(-50%) rotate(calc(-1 * $index * $perDeg));
  }
  @mixin cardPosition($index) {
    transition: transform 0.2s ease;
    transform: translateY(-50%) rotate(calc($index * $perDeg));

    &:hover {
      transform: translateY(-50%) rotate(calc($index * $perDeg)) scale(1.2);
    }
  }

  .component-pt14 {
    padding: 24px 100px;
    // background-image: linear-gradient(45deg, #fac93e 40%, transparent 40%);

    .pt14__container {
      max-width: 800px;
      width: 100%;
      aspect-ratio: 1;
      box-sizing: border-box;
      border-left: 12px solid var(--outerCircleColor);
      border-right: 12px solid var(--outerCircleColor);
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;

      border-radius: 50%;
    }
    .pt14__content {
      padding: 21.875%;
    }
    .pt14__title {
      &__container {
        margin: 0;
        position: relative;
      }
      &__top-image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(calc(-100% + 30px));
      }
      &__bottom-image {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(calc(100% - 30px));
      }

      img {
        transition: 0.2s ease;
      }
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }

    .pt14__list {
      position: absolute;
      inset: 0;
      height: 100%;
      padding: 0;
      // transform: translateY(-50%);
    }
    .pt14__item {
      position: absolute;
      height: 50%;
      // background: #ccc;
      left: 50%;
      transform-origin: 50% 100%;
    }
    .pt14__card {
      @include cardSize(var(--cardSize));

      position: relative;
      z-index: 2;
    }

    .pt14__list-count8 {
      @for $index from 1 through 8 {
        @if $index < 5 {
          .pt14__item:nth-child(#{$index}) {
            @include itemPosition($index);
          }
          .pt14__item:nth-child(#{$index}) .pt14__card {
            @include cardPosition($index);
          }
        } @else {
          .pt14__item:nth-child(#{$index}) {
            @include itemPosition(calc(14 - $index));
          }
          .pt14__item:nth-child(#{$index}) .pt14__card {
            @include cardPosition(calc(14 - $index));
          }
        }
      }
    }

    .pt14__list-count6 {
      @for $index from 1 through 6 {
        @if $index < 4 {
          .pt14__item:nth-child(#{$index}) {
            @include itemPosition($index);
          }
          .pt14__item:nth-child(#{$index}) .pt14__card {
            @include cardPosition($index);
          }
        } @else {
          .pt14__item:nth-child(#{$index}) {
            @include itemPosition(calc(11 - $index));
          }
          .pt14__item:nth-child(#{$index}) .pt14__card {
            @include cardPosition(calc(11 - $index));
          }
        }
      }
    }
  }
}
</style>
