/**
 * @see { @link  https://shopinn.atlassian.net/wiki/spaces/SD/pages/2359326/Enum }
 */
export const ControlEnum = Object.freeze({
  OBJECT: 'object',
  ARRAY: 'array',
  FORM: 'form',
  SELECT: 'select',
  COLOR: 'color',
  TEXT: 'text',
  BOOL: 'boolean',
  NUMBER: 'number',
  IMG: {
    type: 'text',
    editType: 'image',
  },
  HTML: {
    type: 'text',
    editType: 'text',
  },
});
