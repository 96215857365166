<template>
  <div class="component component-c01" @click="redirect">
    <img :src="imgUrl" class="imgBlock" />
    <div class="textBlock">
      <p class="title">{{ title }}</p>
      <p class="subTitle">{{ subTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "picture03",
  props: {
    imgUrl: String,
    title: String,
    subTitle: String,
    path: String,
  },
  methods: {
    redirect() {
      location.href = this.$props.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.component-c01 {
  position: relative;
  cursor: pointer;
  aspect-ratio: 252 / 297;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .imgBlock {
    height: 100%;
    object-fit: cover;
  }

  .textBlock {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 12px;
    transition: 0.3s;
    text-align: center;
    opacity: 0;
    color: #fff;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 140%;
    }

    .subTitle {
      font-size: 14px;
      line-height: 140%;
    }
  }

  &:hover {
    .textBlock {
      opacity: 1;
    }
  }
}
</style>
