import T10 from "@/components/web-page/Text/T10/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/Text/T10",
    argTypes: {
        position: {
            control: "select",
            options: ["left", "center", "right"],
            defaultValue: "center",
        },
        ...generateTextArgTypes("title", {
            contentDefault: "我是標題",
            sizeDefault: "40px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文",
            sizeDefault: "14px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { T10 },
    template: `
    <div>
      <T10 v-bind="$props"/>
    </div>
  `,
});

export const t10 = Template.bind({});
