<template>
  <div class="component component-form"
       :style="{
          '--main-color': mainColor,
          '--main-color2': mainColor2,
          '--question-title-size': questionTitleSize + 'px',
          '--form-background-color': formBackgroundColor
        }">
    <form @submit.prevent="onSubmit" ref="form" class="form-root-content">
      <div class="content-items">
        <slot name="body">
          <RenderComponentsJS :items="apiData"/>
        </slot>
      </div>
      <div class="submit-group">
        <button class="form-submit">提交</button>
        <span>or</span>
        <button @click.prevent="clearData">clear</button>
      </div>
    </form>
  </div>
</template>

<script>

import Form from "@/components/functional/Form";
import globalInject from "@/mixins/globalInject";
import {sha256} from "js-sha256";

export default {
  name: "FormRoot",
  mixins: [Form, globalInject],
  props: {
    formId: {
      type: String,
      default: "1"
    },
    formS3URL: {
      type: String,
      default: ""
    },
    sendSuccessURL: String,
    questionTitleSize: {
      type: String,
      default: "16"
    },
    formBackgroundColor: {
      type: String,
      default: "#D2D2D2"
    },
    mainColor: {
      type: String,
      default: "#1F286F"
    },
    mainColor2: {
      type: String,
      default: "#FBEFC5"
    },
  },
  computed: {
    apiData() {
      return this.datas;
    },
  },
  methods: {
    clearData() {
      for (let key in this.formData) {
        this.formData[key].value = null;
      }
    },
    async onSubmit() {
      this.$emit('submit');
      const reg = new RegExp('localhost');
      const isLocalhost = reg.test(this.refURL);
      let data;
      let checkValue;
      let invalid = false;
      let url = new URL(`${this.apiURL}page/save_form/${this.formId}`);
      let formatData = Object.fromEntries(Object.keys(this.formData).map(k => {
        let object = this.formData[k];
        if (object.value === null && object.required) invalid = true;
        return [k, {type: object.type, name: object.name}];
      }));

      if (invalid) return this.errorMsgFn('必填資料，尚未填寫完成');
      //取得Form資料放入FormData
      data = new FormData(this.$refs.form);
      checkValue = `${this.formId}&${JSON.stringify(formatData)}&${isLocalhost ? 'localhost' : this.refURL}`;
      checkValue = sha256(encodeURI(checkValue).toLowerCase()).toUpperCase();
      data.set('format', JSON.stringify(formatData));

      let options = {
        body: data,
        method: 'POST',
      }
      //query
      let params = {
        check_value: checkValue
      };

      if (this.token) {
        options.headers = {
          'x-access-token': this.token
        }
      }

      if (!isLocalhost) {
        params['ref_url'] = this.refURL;
      }


      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      await fetch(url, options)
          .then(data => {
            this.successMsgFn('送出成功');

            this.clearData();

            if (this.sendSuccessURL) {
              window.location = this.sendSuccessURL;
            }
            return data;
          })
          .catch(() => this.errorMsgFn('送出失敗，請聯絡客服人員'));
    },
  },
  watch: {
    async formS3URL(val) {
      console.log('formS3URL', val)
      let res = await fetch(val);
      this.datas = await res.json();
    }
  },
  data() {
    return {
      datas: []
    }
  },
  async mounted() {
    if (this.formS3URL) {
      let res = await fetch(this.formS3URL);
      this.datas = await res.json();
    }
  }
}
</script>

<style scoped>
.component-form {
  --main-color: #1F286F;
  --main-color2: #FBEFC5;
  --question-title-size: 16px;
  --form-background-color: #d2d2d2;
  background-color: var(--form-background-color)
}

.form-root-content {
  width: 100%;
  max-width: 1064px;
  margin: auto;
  padding: 24px 0;
}

.form-root-content .content-items {
  border-radius: 24px;
  padding: 24px clamp(24px, 3.52vw, 48px);;
  background-color: #ffffff;
}

.submit-group {
  padding-top: 24px;
  display: flex;
  align-items: center;
  gap: 0 6px;
}

.submit-group button ~ button {
  color: #008EDD;
  text-decoration: underline;
}

.form-submit {
  border-radius: 8px;
  padding: 9px 0;
  width: 143px;
  text-align: center;
  color: #FFFFFF;
  background-color: var(--main-color);
}
</style>
