import T07_2 from "@/components/web-page/Text/T07_2/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/Text/T07_2",
    argTypes: {
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "大標題",
            sizeDefault: "36px",
            colorDefault: "black",
            spacingDefault: "1px",
            lineSpacingDefault: "99px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "小標題",
            sizeDefault: "16px",
            colorDefault: "black",
            spacingDefault: "1px",
            lineSpacingDefault: "99px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文",
            sizeDefault: "16px",
            colorDefault: "#000",
            spacingDefault: "1px",
            lineSpacingDefault: "99px",
        }),
        // line color
        verticalLineColor: {
            control: "color",
            defaultValue: "black",
        },
        horizontalLineColor: {
            control: "color",
            defaultValue: "black",
        },
        // line width
        verticalLineWidth: {
            control: "number",
            defaultValue: 2,
        },
        horizontalLineWidth: {
            control: "number",
            defaultValue: 2,
        },
        // img
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { T07_2 },
    template: `
    <div>
      <T07_2 v-bind="$props"/>
    </div>
  `,
});

export const t07_2 = Template.bind({});
