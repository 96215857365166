import {ControlEnum} from "@/models/Enum/ControlEnum";

export function generateAspectRatioArgTypes(name) {
  return {
    [name]: {
      control: ControlEnum.SELECT,
      options: ['auto', '1:1', '3:4', '2:1'],
      defaultValue: 'auto'
    }
  }
}
export default {
  name: 'aspectRatio',
  props: {
    aspectRatio:String
  },
  computed: {
    computedAspectRatio() {
      let res = '';
      switch (this.aspectRatio) {
        case 'auto':
          res = null;
          break;
        case '1:1':
          res = '1/1';
          break;
        case '3:4':
          res = '3/4';
          break;
        case '4:3':
          res = '4/3';
          break;
        case '2:1':
          res = '2/1';
          break;
        default:
          res = null;
          break;
      }
      return {'aspect-ratio': res};
    }
  }
}
