<template>
  <!--NAV LAYER START-->
  <nav class="main-nav" v-if="isNav">
    <ul class="nav-list">
      <MobileRecursiveNavList
          v-for="(item, index) of items"
          :is-nav="false"
          :key="index"
          :item="item"
          :is-root-item="true"
          :is-root-active="isActive"
          :active-instance="active"
          @active="onActive"
      />
    </ul>
    <div class="nav__info">
      <a class="info_button" :href="activeURL">
        {{ activeText }}
      </a>
      <span class="info_chop">
        {{ chop }}
      </span>
    </div>

  </nav>
  <!--NAV LAYER END-->
  <!--ITEM LAYER START-->
  <li class="nav-list__item item" :class="{more: hasChildren, active: isActive}" v-else>
    <div class="item__bg" @click.stop.prevent="onItemClick(item.url)">
      <a class="item__link" :href="item.url">{{ item.contentName }}</a>
      <button v-if="hasChildren" class="item__more">
        <i class="icon-plus" v-if="!isActive"></i>
        <i class="icon-minus" v-else></i>
      </button>
    </div>
    <div class="item__next dropdown_item-1">
      <ul class="nav-list ">
        <MobileRecursiveNavList
            v-for="(item, index) of item.children"
            :is-nav="false"
            :key="index"
            :item="item"
            :is-root-item="false"
            :is-root-active="isActive"
            :active-instance="active"
            @active="onActive"
        />
      </ul>
    </div>
  </li>
  <!--ITEM LAYER END-->
</template>

<script>
import {mixin as navSetting} from "@/mixins/headerNavSetting";
import {mobileActive as activeSetting, operateProps} from "@/models/headerColorSetting";

export default {
  name: "MobileRecursiveNavList",
  mixins: [navSetting, activeSetting, operateProps],
  props: {
    chop: String
  },
  methods: {
    onItemClick(url) {
      if (this.hasChildren && !this.isActive) {
        this.onActiveTrigger();
      } else {
        window.location = url;
        this.onActiveTrigger();
      }
    }
  }
}
</script>

<style src="./index.mobile.css" scoped></style>
<style>
.nav__info {
  margin-top: 48px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 16px;
  font-weight: 700;
  color: var(--button-text-color);
}

.info_button {
  display: inline-block;
  font-size: 20px;
  color: var(--button-text-color);
  background-color: var(--button-color);
  padding: 3px 10px;
  line-height: 1.4;
  border-radius: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.info_chop {
  margin-top: 24px;
  color: var(--text-color);
  font-size: 12px;
}
</style>
