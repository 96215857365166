import pic01 from "./pic01.vue";
import { componentEnum } from "@/models/componentType";
import { GridEnum } from "@/models/gridType";
import stories from "./index.stories.js";

export default {
  componentGroup: componentEnum.Picture,
  grids: { ...GridEnum },
  icon: "mdi-view-dashboard", //  顯示在列表上的圖示
  name: "pic01", // 元件名稱
  component: pic01,
  fake: false, //props 改變時不反應到畫面上編輯時永選用argTypes的資料
  argTypes: stories.argTypes,
};
