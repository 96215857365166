import H01 from "@/components/web-page/Header/H01/index.vue";
import {getColorSetting, operateVarSetting} from "@/models/headerColorSetting";
import {generateHeaderButtonsArgTypes} from "@/mixins/header/HeaderButtonsMixin";

export default {
  title: 'web-page/Header/H01',
  argTypes: {
    logoURL:  {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/105x40/eaeaea/eae0d0',
    },
    chop: {
      control: 'text',
      defaultValue: "© 2022 Shopinn . Web Design2"
    },
    ...generateHeaderButtonsArgTypes(),
    ...operateVarSetting(),
    ...getColorSetting(
      {
        bgColor: '#1F286F',
        textColor: '#FFF',
        buttonTextColor: '#1F286F',
        buttonColor: '#FAC93E',
        hoverLevel1Color: '#000',
        hoverBgLevel1Color: '#FFF',
        activeLevel1Color: '#000',
        activeBgLevel1Color: '#FFF',
      }
    )
  }
}

const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {H01},
  template: `
    <div style="margin:auto; background-color: cornsilk; height: 300vh;">
      <H01 v-bind="$props"/>
      2
    </div>
  `
});

export const h01 = TemplatePT01.bind({});
