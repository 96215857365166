<template>
  <div class="component component-dls-01" :class="{ secondPage: !isFirstPage }">
    <DlsFirstPage
      :carousel-img-list="carouselImgList"
      v-if="isFirstPage"
      :request="request"
      @onSave="currentPage = 'second'"
    />

    <DlsSecondPage v-else :request="request" />
  </div>
</template>

<script>
import "@/assets/css/form.css";
import DlsFirstPage from "@/components/web-page/DLS/Dls01/DlsFirstPage.vue";
import DlsSecondPage from "@/components/web-page/DLS/Dls01/DlsSecondPage.vue";

export default {
  name: "Dls01",
  components: {
    DlsSecondPage,
    DlsFirstPage,
  },
  props: {
    carouselImgList: Array,
  },
  data() {
    return {
      currentPage: "first",

      request: {
        weekTimes: 1,
        method: "point",
        subscription: 1,
        capacity: 15,
        collectDay: [1, 2, 3],
        serviceTime: "morning",
        address: "高雄市高雄區高雄路 28 號",
        location: "位置",
        note: "備註",
      },
    };
  },
  computed: {
    isFirstPage() {
      return this.currentPage === "first";
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
$MOBILE_SIZE: 768px;

.component-dls-01 {
  width: 100%;
  padding: 48px 24px;
  background-color: #d4e6ef;

  &.secondPage {
    background: linear-gradient(to bottom, #d4e6ef 70%, #ffffff 30%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
