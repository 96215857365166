import component from './index.vue';
import stories from './index.stories.js';
import { TypeEnum } from '@/models/componentType';
import { GridEnum } from '@/models/gridType';
import { BlockEnum } from '@/models/Enum/BlockEnum';

let { G1_1 } = GridEnum;
export default {
  componentGroup: TypeEnum.Carousel,
  type: BlockEnum.CONTAINER,
  grids: { G1_1 },
  name: 'Carousel02',
  component: component,
  argTypes: stories.argTypes,
}
