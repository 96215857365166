import T04 from "@/components/web-page/Text/T04/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/Text/T04",
    argTypes: {
        // magic
        ...generateTextArgTypes("title", {
            contentDefault: "大標題",
            sizeDefault: "36px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("content", {
            contentDefault:
                "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sit amet,Lorem ipsum dolor sitfulpa duis.",
            sizeDefault: "16px",
            colorDefault: "#000",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "小標題",
            sizeDefault: "16px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        // alignOption
        horizontalAlignOption: {
            control: "select",
            options: ['left', 'center', 'right'],
            defaultValue: 'center',
        },
        verticalAlignOption: {
            control: "select",
            options: ['top', 'center', 'bottom'],
            defaultValue: 'center',
        }
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { T04 },
    template: `
    <div>
      <T04 v-bind="$props"/>
    </div>
  `,
});

export const t04 = Template.bind({});
