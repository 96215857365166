<template>
  <div class="component component-form01 form-group" >
    <label class="form-group-label"
           :class="{'error': invalid}"
           @blur="checkValue" tabindex="0">
     <span class="label-title">
        <span v-if="required">*</span>
        {{ title }}
      </span>
      <span class="label-content">
        <div v-for="item of items" :key="item.name">
          <label class="inner-label">
           <input :disabled="false"
                  class="inner-label-checkbox"
                  type="checkbox"
                  :name="keyName"
                  :value="item.value" v-model="setTest">
            <div class="inner-label-button">
              <span class="button-box"></span>
              <span class="checkmark"></span>
            </div>
            <span>{{ item.name }}</span>
          </label>
        </div>
        <span class="label-error">此為必填</span>
      </span>
    </label>
  </div>
</template>

<script>
import FormField from "@/components/functional/FormField";
import "@/assets/css/form.css";
import {controlType} from "@/models/controlType";

export default {
  name: "ControlCheckbox",
  mixins: [FormField],
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    setTest: {
      set(val) {
        console.log(val)
        this.setValue(val);
      },
      get() {
        return this.value || [];
      }
    }
  },
  data() {
    return {
      controlType: controlType.CHECKBOX
    }
  }
}
</script>

<style scoped>

.label-content .inner-label {
  display: flex;
  padding: 6px;
  cursor: pointer;
}

.inner-label-checkbox[type="checkbox"] {
  display: none;
}

.inner-label-checkbox[type="checkbox"] ~ .inner-label-button {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 6px;
}
.inner-label-checkbox[type="checkbox"] ~ .inner-label-button .button-box {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #EEEEEE;
  transition: background-color .2s;
}

.checkmark:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 9px;
  background-color: transparent;
  left: 12px;
  top: 6px;
}

.checkmark {
  position: absolute;
  z-index: 2;
  left: 0;
  cursor: pointer;
  display: inline-block;
  width: 22px;
  height: 22px;
  transform: rotate(45deg);
}

.checkmark:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 2px;
  background-color: transparent;
  left: 9px;
  top: 13px;
}

.inner-label-checkbox[type="checkbox"]:checked + .inner-label-button .checkmark:before,
.inner-label-checkbox[type="checkbox"]:checked + .inner-label-button .checkmark:after {
  background-color: #FFFFFF;
}

.inner-label-checkbox[type="checkbox"]:checked ~ .inner-label-button > .button-box {
  padding-right: 8px;
  background-color: var(--main-color);
}

.inner-label .inner-label-button:before {
  content: '';
  padding-right: 8px;
  position: absolute;
  left: -6px;
  top: -6px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: var(--main-color2);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.inner-label .inner-label-button:before {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.inner-label:hover > .inner-label-button:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

</style>
