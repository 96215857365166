import {testData} from "@/models/headerColorSetting";
import autoColor from "@/mixins/autoColor";


export default {
  mixins: [autoColor],
  props: {
    //data
    logoURL: String,
    logoAlt: String,
    list: {
      type: Array,
      default: () => testData
    },
    //color
    headerBackgroundColor: String,
    headerMobileBackgroundColor: String,
    headerMobileActiveBackgroundColor: String,
    menuTextColor: String,
    menuMobileTextColor: String,
    menuTextHoverColor: String,
  },
}
