import Introduce from "@/components/web-page/PictureText/PT10/index.vue";

export default {
  title: "web-page/PictureText/PT10",
  argTypes: {
    imgUrl: {
      control: {
        type: "text",
        editType: "image"
      },
      defaultValue: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/image.png",
    },
    title: {
      control: "text",
      defaultValue: "標題",
    },
    titleColor: {
      control: "color",
      defaultValue: "#000",
    },
    subTitle: {
      control: "text",
      defaultValue: "副標題",
    },
    subTitleColor: {
      control: "color",
      defaultValue: "#000",
    },
    adornText: {
      control: "text",
      defaultValue: "LAWYER",
    },
    adornTextColor: {
      control: "color",
      defaultValue: "#000",
    },
    listIconColor: {
      control: "color",
      defaultValue: "#000",
    },
    listTitleColor: {
      control: "color",
      defaultValue: "#000",
    },
    listContentColor: {
      control: "color",
      defaultValue: "#6F6F6F",
    },
    reverse: {
      control: "boolean",
      defaultValue: false,
    },
    list: {
      control: "array",
      template: {
        iconName: "icon className",
        title: "學歷",
        items: "內容",
      },
      defaultValue: [
        {
          iconName: "icon-user",
          title: "標題",
          items: "內文內文內文內文內文內文內文內文",
        },
        {
          iconName: "icon-user",
          title: "標題",
          items: "內文內文內文內文內文內文內文內文",
        },
        {
          iconName: "icon-user",
          title: "標題",
          items: "內文內文內文內文內文內文內文內文",
        },
      ],
    },
    position: {
      control: 'select',
      options: ["top", "center", "bottom"],
      defaultValue: "top"
    }
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Introduce },
  template: `<Introduce v-bind="$props"/>`,
});

export const Introduct01 = Template.bind({});
