import List01 from "@/components/web-page/PictureText/PT13/index.vue";

export default {
  title: "web-page/PictureText/PT13",
  argTypes: {
    imgUrl: {
      control: {
        type: "text",
        editType: "image"
      },
      defaultValue: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
    },
    title: {
      control: "text",
      defaultValue: "標題",
    },
    titleColor: {
      control: "color",
      defaultValue: "#000",
    },
    subTitle: {
      control: "text",
      defaultValue: "副標題",
    },
    subTitleColor: {
      control: "color",
      defaultValue: "#000",
    },
    iconName: {
      control: "text",
      defaultValue: "icon-arrow-up",
    },
    iconColor: {
      control: "color",
      defaultValue: "#fff",
    },
    path: {
      control: "text",
      defaultValue: "https://www.shopinnapp.com",
    },
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { List01 },
  template: `<List01 v-bind="$props"/>`,
});

export const list01 = Template.bind({});
