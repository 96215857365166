<template>
    <div class="component component-pt05" :style="style">
        <div class="pt05">
            <div class="pt05__logo">
                <h6 v-if="subTitleContent !== ''">
                    <base-text  block-name="subTitle" v-bind="$props" />
                </h6>
                <h2 v-if="titleContent !== ''" class="pt05__title">
                    <base-text block-name="title" v-bind="$props" />
                </h2>
                <img v-if="logoURL" :src="logoURL" alt="logo" />
            </div>
            <div class="pt05__group">
                <div
                    v-for="(item, index) of showItems"
                    :key="index"
                    class="pt05__group__box"
                    :class="{ full: textFull }"
                >
                    <div class="pt05__group__img">
                        <div class="pt05__group__imgWrapper">
                            <img :src="item.iconURL" alt="icon" />
                        </div>
                        <span>
                            {{ item.itemTitle}}
                        </span>
                    </div>
                    <p>
                        <base-text  block-name="content" v-bind="item" />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";
export default {
    name: "PT05",
    components: { BaseText },
    props: {
        logoURL: String,
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        textFull: Boolean,
        verticalAlignOption: String,
        items: {
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {
            limit: 4,
        };
    },
    computed: {
        showItems() {
            return this.items.slice(0, this.limit);
        },
        verticalAlignOptionCss(){
            let cssStyle
            switch (this.verticalAlignOption) {
                case 'top':
                    cssStyle = 'flex-start'
                    break;
                case 'center':
                    cssStyle = 'center'
                    break;
                case 'bottom':
                    cssStyle = 'flex-end'
                    break;
                default:
                    console.log('type is not correct')
                    break;
            }
            return cssStyle
        },
        style() {
            return {
                "--align-position": this.verticalAlignOptionCss,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-pt05 {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: var(--align-position);
}

.pt05{
    width: 100%;
}

.pt05__logo {
    margin-bottom: 24px;
}

.pt05__title {
    font-size: 36px;
    font-weight: 700;
}

.pt05__group {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.pt05__group__box {
    p {
        margin-top: 15px;
    }
}

.pt05__group__img {
    display: flex;
    align-items: center;
}

.pt05__group__imgWrapper {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.pt05__group__box.full {
    width: auto;
}

@media screen and (max-width: 800px) {
    .component-pt05 {
        justify-content: center;
        align-items: center;
    }

    .component-pt05 .pt05__group {
        flex-direction: column;
    }
    .pt05{
        width: fit-content;
    }
}
</style>

