export const GridEnum = {
  G1_1: 'G1_1',
  G2_1: 'G2_1',
  G3_1: 'G3_1',
  G3_2: 'G3_2',
  G3_3: 'G3_3',
  G4_1: 'G4_1',
  G4_2: 'G4_2',
  G4_3: 'G4_3',
  G4_4: 'G4_4',
  G4_5: 'G4_5',
  G4_6: 'G4_6',
  G4_7: 'G4_7',
  G4_8: 'G4_8',
  G4_9: 'G4_9',
  G5_1: 'G5_1',
  G5_2: 'G5_2',
  G5_3: 'G5_3',
  G5_4: 'G5_4',
  G6_1: 'G6_1',
}

export const G1 = {
  G1_1: GridEnum.G1_1,
}
export const G2 = {
  G2_1: GridEnum.G2_1,
}
export const G3 = {
  G3_1: GridEnum.G3_1,
  G3_2: GridEnum.G3_2,
  G3_3: GridEnum.G3_3,
}

export const G4 = {
  G4_1: GridEnum.G4_1,
  G4_2: GridEnum.G4_2,
  G4_3: GridEnum.G4_3,
  G4_4: GridEnum.G4_4,
  G4_5: GridEnum.G4_5,
  G4_6: GridEnum.G4_6,
  G4_7: GridEnum.G4_7,
  G4_8: GridEnum.G4_8,
  G4_9: GridEnum.G4_9,

}

export const G5 = {
  G5_1: GridEnum.G5_1,
  G5_2: GridEnum.G5_2,
  G5_3: GridEnum.G5_3,
  G5_4: GridEnum.G5_4,
}

export const G6 = {
  G6_1: GridEnum.G6_1
}
