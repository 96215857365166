import T03 from '@/components/web-page/Text/T03/index.vue';
import {generateTextArgTypes} from "@/components/base/javaScript/baseTest";

export default {
  title: 'web-page/Text/T03',
  argTypes: {
    title: {
      control: "text",
      defaultValue: "主標題"
    },

    subTitle: {
      control: "text",
      defaultValue: "副標題"
    },

    subTitleColor:{
      control: "color",
      defaultValue: "#000"
    },
    icon: {
      control: "text",
      defaultValue: "服務項目"
    },
    iconColor: {
      control: "color",
      defaultValue: "#000"
    },


    position: {
      control: 'select',
      options: ["left", "center", "right"],
      defaultValue: "left"
    },

    ...generateTextArgTypes('title', {
      hiddeContent: true,
      color: 'titleColor',
      colorDefault: "#000",
      sizeDefault: '36px'
    }),
    ...generateTextArgTypes('subTitle', {
      hiddeContent: true,
      color: 'subTitleColor',
      colorDefault: "#000",
      sizeDefault: '24px'
    })
  }
}

// T03
const TemplateT03 = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { T03},
  template: `<T03 v-bind=$props />`
});

export const T03_Left = TemplateT03.bind({});

