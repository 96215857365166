<template>
  <div class="baseImageContainer" :style="style">
    <img class="base__image"
         :src="img.src|| imgSrc "
         :alt=" img.alt || alt "
    />
  </div>
</template>


<script>
export default {
  name: "BaseImage",
  components: {},
  props: {
    img: {type: Object, default: () => ({})},
    imgSrc: String,
    alt: String,
    width: String,
    height: String,
    aspectRatio: Number,
  },
  data() {
    return {}
  },
  computed: {
    style() {
      return {
        "--img-width": this.width,
        "--img-height": this.height,
        "--aspectRatio": 1 / this.aspectRatio,
      };
    },
  },
};
</script>

<style scoped>
.baseImageContainer {
  --aspectRatio: 50%;
  --img-width: 100%;
  --img-height: auto;


  position: relative;
  padding-top: var(--aspectRatio);

  width: var(--img-width);
  height: var(--img-height);
  overflow: hidden;
}

.base__image {
  object-fit: cover;
  width: 100%;
}

</style>
