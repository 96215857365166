<template>
    <div class="component component-pic07" :style="style">
        <div class="pic07__grid-container" :class="{ reverse: reverse }">
            <!-- big-pic -->
            <div
                class="pic07__big-pic"
                @mouseover="setHoverVariable('bigPic')"
                @mouseleave="setHoverVariable('bigPic')"
                @click="redirect(bigPic.link)"
            >
                <img :src="bigPic.imgUrl" alt="big-pic" />
                <base-text block-name="title" v-bind="bigPic" />
            </div>
            <!-- small-up-pic -->
            <div
                class="pic07__small-up-pic"
                @mouseover="setHoverVariable('smallUpPic')"
                @mouseleave="setHoverVariable('smallUpPic')"
                @click="redirect(smallUpPic.link)"
            >
                <img :src="smallUpPic.imgUrl" alt="small-up-img" />
                <base-text
                    block-name="title"
                    v-bind="smallUpPic"
                />
            </div>
            <!-- small-down-pic -->
            <div
                class="pic07__small-down-pic"
                @mouseover="setHoverVariable('smallDownPic')"
                @mouseleave="setHoverVariable('smallDownPic')"
                @click="redirect(smallDownPic.link)"
            >
                <img :src="smallDownPic.imgUrl" alt="small-down-img" />
                <base-text
                    block-name="title"
                    v-bind="smallDownPic"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";

export default {
    name: "picture07",
    components: {
        BaseText,
    },
    props: {
        bigPic: Object,
        smallUpPic: Object,
        smallDownPic: Object,
        reverse: Boolean,
    },
    data() {
        return {
            isHoverBigPic: false,
            isHoverSmallUpPic: false,
            isHoverSmallDownPic: false,
        };
    },
    methods: {
        setHoverVariable(target) {
            switch (target) {
                case "bigPic":
                    this.isHoverBigPic = !this.isHoverBigPic;
                    break;

                case "smallUpPic":
                    this.isHoverSmallUpPic = !this.isHoverSmallUpPic;
                    break;

                case "smallDownPic":
                    this.isHoverSmallDownPic = !this.isHoverSmallDownPic;
                    break;
                default:
                    console.log("target incorrect");
                    break;
            }
        },
        redirect(path) {
            if (path) {
                window.open(path);
            } else {
                console.log("there are no path");
            }
        },
    },
    computed: {
        style() {
            return {
                "--big-pic-hover": this.bigPic.hoverImgBgColor,
                "--small-up-pic-hover": this.smallUpPic.hoverImgBgColor,
                "--small-down-pic-hover": this.smallDownPic.hoverImgBgColor,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-pic07 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 50px auto;
}

.pic07__grid-container {
    max-width: 1064px;
    /* max-height: 522px; */
    display: grid;
    grid-auto-columns: minmax(auto, 522px);
    grid-auto-rows: minmax(auto, 252px);
    gap: 20px;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pic07__big-pic,
.pic07__small-up-pic,
.pic07__small-down-pic {
    grid-column: 1/3;
    grid-row: 1/3;
    position: relative;
    cursor: pointer;
    .base-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.pic07__small-up-pic {
    grid-column: 3/5;
    grid-row: 1/2;
}

.pic07__small-down-pic {
    grid-column: 3/5;
    grid-row: 2/3;
}

/* reverse */
.pic07__grid-container.reverse .pic07__big-pic {
    grid-column: 2/3;
}

.pic07__grid-container.reverse .pic07__small-up-pic {
    grid-column: 1/2;
}

.pic07__grid-container.reverse .pic07__small-down-pic {
    grid-column: 1/2;
}

.pic07__big-pic:hover,
.pic07__small-up-pic:hover,
.pic07__small-down-pic:hover {
    opacity: 0.5;
}

.pic07__big-pic:hover .base-text {
    color: var(--big-pic-hover);
}

.pic07__small-up-pic:hover .base-text {
    color: var(--small-up-pic-hover);
}

.pic07__small-down-pic:hover .base-text {
    color: var(--small-down-pic-hover);
}

@media screen and (max-width: 800px) {
    .component-pic07{
        margin: 0;
    }
    .pic07__big-pic,
    .pic07__small-up-pic,
    .pic07__small-down-pic,
    .pic07__grid-container.reverse .pic07__big-pic,
    .pic07__grid-container.reverse .pic07__small-up-pic,
    .pic07__grid-container.reverse .pic07__small-down-pic {
        grid-column: span 1;
        grid-row: span 1;
        grid-auto-columns: initial;
        grid-auto-rows: initial;
    }
}
</style>

