<template>
  <!--NAV LAYER-->
  <nav v-if="isNav" class="main-nav">
    <ul class="nav-list">

      <template v-for="(item, index) of items">
        <RecursiveNavList :is-nav="false" :key="index" :item="item" :is-root-item="true"/>
      </template>

    </ul>
  </nav>
  <!--ITEM LAYER-->
  <li class="nav-list__item item" :class="{more: hasChildren}" v-else>
    <div class="item__bg">
      <a class="item__link" :href="item.url">{{ item.contentName }}</a>
      <button v-if="hasChildren" class="item__more">
        <i v-if="isRootItem" class="icon-arrow-down"></i>
        <template v-else>
          <i class="icon-arrow-right"></i>
        </template>
      </button>
    </div>
    <div class="item__next">

      <ul class="nav-list">
        <template v-for="(innerItem, index) of item.children">
          <RecursiveNavList :is-nav="false" :key="index" :item="innerItem" :is-root-item="false"/>
        </template>
      </ul>
    </div>
  </li>
</template>
<script>


export default {
  name: "RecursiveNavList",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isNav: {
      type: Boolean,
      default: true
    },
    isRootItem: Boolean,
  },
  computed: {
    hasChildren() {
      if (!this.isNav) {
        return this.item.children && this.item.children.length > 0
      }
      return false;
    }
  }
}
</script>

<style src="./index.css" scoped></style>
