import component from './index.vue';
import stories from './index.stories.js';
import {TypeEnum} from "@/models/componentType";
import {G1} from "@/models/gridType";

export default {
  componentGroup: TypeEnum.Product,
  grids: {...G1},
  name: 'PD01',
  icon: 'mdi-view-dashboard',
  type:'container',
  component: component,
  argTypes: stories.argTypes
}
