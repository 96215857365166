import component from "./index.vue";
import stories from "./index.stories.js";
import {componentEnum} from "@/models/componentType";
import { GridEnum} from "@/models/gridType";

export default {
  componentGroup: componentEnum.PictureText,
  grids: {
    G3_1: GridEnum.G3_1,
    G4_1: GridEnum.G4_1,
    G6_1: GridEnum.G6_1
  },
  name: "PictureText13",
  icon: "mdi-view-dashboard",
  type: "container",
  isNew: true,
  component: component,
  argTypes: stories.argTypes,
};
