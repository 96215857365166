<template>
  <div>
    <iframe
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      frameborder="0"
      :src="srcURL"
      width="188px"
      height="188px"
    >
    </iframe>
  </div>
</template>

<script>
import embeddedGoogleMap from '@/models/embeddedGoogleMap';

export default {
  name: 'EmbeddedGoogleMap',
  props: embeddedGoogleMap,
  computed: {
    srcURL() {
      return encodeURI(
        `https://maps.google.com/maps?width=200&height=200&hl=${this.lang}&q=${this.address}(${this.title})&t=;z=14&ie=UTF8&iwloc=B&output=embed`
      );
    },
  },
};
</script>

<style scoped></style>
