import Element from "./index.vue";

export default {
  title: 'web-page/Controls/File',
  argTypes: {
    title: {
      control: 'text',
      defaultValue: "上傳檔案"
    },
    placeholder: {
      control: 'text',
      defaultValue: "新增檔案"
    },
    required: {
      control: "boolean",
      defaultValue: false
    },
    onlyCertainFileTypes: {
      control: "boolean",
      defaultValue: true
    },
    imageTypes: {
      control: "boolean",
      defaultValue: false
    },
    //影片
    videoTypes:  {
      control: "boolean",
      defaultValue: false
    },
    //文件
    docTypes:  {
      control: "boolean",
      defaultValue: false
    },
    //音訊
    audioTypes:  {
      control: "boolean",
      defaultValue: false
    },
    //PDF
    pdfTypes:  {
      control: "boolean",
      defaultValue: false
    },
  }
}

const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
    <div style="padding: 10px">
      <Element v-bind="$props"/>
    </div>
  `
});

export const element1 = Template.bind({});
