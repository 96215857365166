<template>
  <div style="overflow: hidden;">
    <div class="component component-pt06" :style="componentColor">
      <hooper ref="hooper" :mouseDrag="false" :playSpeed="3000" :items-to-show="4" :settings="settings">
        <slide v-for="(item, index) of showItem" :key="index">
          <div class="pt06-box">
            <div class="warp">
              <div class="warp-img--icon">
                <img :src="item.iconURL" alt="">
              </div>
              <div class="warp-img" :style="getImgURL(item)"></div>
            </div>
            <div class="warp-text">
              <template v-for="(text, index) of item.content.split('<br>')">
                <p :key="index">{{ text }}</p>
              </template>
            </div>
          </div>
        </slide>
        <template #hooper-addons>
          <navigation-test/>
        </template>
      </hooper>
    </div>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
} from 'hooper';
import NavigationTest from './NavigationTest.vue';
import autoColor from "@/mixins/autoColor";

export default {
  name: "PT06",
  components: {Hooper, Slide, NavigationTest},
  mixins: [autoColor],
  props: {
    textColor: String,
    items: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      showItem: [],
      settings: {
        mouseDrag: false,
        wheelControl: false,
        keysControl: false,
        breakpoints: {
          800: {
            itemsToShow: 4,
            itemsToSlide: 4
          },
          0: {
            itemsToShow: 1,
            itemsToSlide: 1
          }
        }
      }
    }
  },
  watch: {
    items: {
      handler(val) {
        for (let i = 0; i < 4; i++) {
          this.showItem[i] = val[i] ? val[i] : {
            iconURL: "https://fakeimg.pl/32x32/",
            imgURL: "https://fakeimg.pl/250x360/",
            content: "請輸入資料"
          }
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    getImgURL(item) {
      return {
        backgroundImage: `url("${item.imgURL}")`
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 800) {
        this.$refs.hooper.slideTo(0)
      }
      this.$refs.hooper.update();
    }, false);
  }
}
</script>

<style scoped>
:deep(.hooper) {
  height: auto;
}

:deep(.component-pt06.component .hooper-track) {
  position: relative;
  z-index: 2;
  padding: 0;
}

.component-pt06 {
  --textColor: #000;

  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.pt06-box {
  padding-right: 20px;
}


.warp-text p{
  font-size: 18px;
  color: var(--textColor);
}

.warp {
  position: relative;
  aspect-ratio: 43 / 72;
  overflow: hidden;

  margin-bottom: 24px;
}

.warp-img {
  position: relative;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}

.warp-img:before {
  content: "";
  position: absolute;
  background-image: inherit;
  background-position: center;
  background-size: cover;
  inset: 0px;
}




.warp-img--icon {
  transition: ease .3s;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}


.warp:hover .warp-img--icon {
  opacity: 1;
}

.warp:hover .warp-img:before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000000 100%);
  opacity: 0.8;
}

@media screen and (max-width: 414px) {
  .component-pt06 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .hooper {
    box-sizing: border-box;
    width: 75.33%;
    margin: 0 auto;
    color: inherit;
  }

  .component-pt06 .pt06-box {
    padding-right: 0;
  }
}

</style>
