<template>
  <div class="header-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "HeaderContainer",
}
</script>

<style scoped>
</style>
