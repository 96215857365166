<template>
  <div class="footer__copyright" :style="style">
    <span>{{ copyrightText }}</span>
    <a
      v-for="item of copyrightLink"
      :key="item.text"
      class="footer__copyright__link"
      :href="item.link"
      >{{ item.text }}</a
    >
  </div>
</template>

<script>
export default {
  props: {
    copyrightText: String,
    copyrightLink: () => [],
    copyrightBackgroundColor: String,
    copyrightTextColor: String,
  },
  computed: {
    style() {
      return {
        '--copyrightBackgroundColor': this.copyrightBackgroundColor,
        '--copyrightTextColor': this.copyrightTextColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.footer__copyright {
  background-color: var(--copyrightBackgroundColor);
  color: var(--copyrightTextColor);
  font-size: 12px;
  text-align: center;
}

.footer__copyright span {
  margin-right: 4px;
}

.footer__copyright__link {
  color: var(--copyrightTextColor);
}

.footer__copyright__link:before {
  content: '| ';
}

@media screen and (max-width: 767px) {
  .footer__copyright {
    padding: 16px 11.83%;
  }
}
@media screen and (min-width: 768px) {
  .footer__copyright {
    padding: 16px 10.88%;
  }
}
</style>
