import F04 from '@/components/web-page/Footer/F04/index.vue';
import {ControlEnum} from "@/models/Enum/ControlEnum";

export default {
  title: 'web-page/Footer/F04',
  paramGroup: {
    bottomGroup : ['bottomText', 'bottomLink','bottomBgColor','bottomTextColor'],
    iconsGroup:['facebook','ins','youtube','line']
  },
  argTypes: {
    logoURL: {
      control: ControlEnum.IMG,
      defaultValue: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
    },
    singleRow: {
      control: ControlEnum.BOOL,
      defaultValue: false,
    },
    singlePosition: {
      control: ControlEnum.SELECT,
      options: ['left', 'center', 'right'],
      defaultValue: 'center',
    },
    linkList: {
      control: ControlEnum.ARRAY,
      template: { name: '標題', url: '' },
      defaultValue: [
        { name: '標題', url: '' },
        { name: '標題', url: '' },
        { name: '標題', url: '' },
        { name: '標題', url: '' },
        { name: '標題', url: '' },
      ],
    },
    contentTextList:{
      control: ControlEnum.ARRAY,
      template: { text: '標題' },
      defaultValue: [
        { text: 'MON-FRI, 9:00-18:00' },
        { text: 'TEL:(02)2995-9996 / FAX:(02)2995-9978' },
        { text: 'service@queenshop.com.tw' },
      ],
    },
    itemList:{
      control: ControlEnum.ARRAY,
      template: { text: '標題' ,url:''},
      defaultValue: [
        { text: 'English version' ,url:''},
      ],
    },
    bottomText: {
      control: ControlEnum.TEXT,
      defaultValue: '© 2022 百順實業 bai shun . Powered By ShopInn',
    },
    bottomLink: {
      control: ControlEnum.ARRAY,
      template: { link: '#', text: '輸入文字' },
      defaultValue: [{ link: '#', text: '服務條款' }],
    },
    textColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#fff',
    },
    textHoverColor: {
      control:  ControlEnum.COLOR,
      defaultValue: '#fff',
    },
    bgColor: {
      control:  ControlEnum.COLOR,
      defaultValue: '#000',
    },
    bottomBgColor: {
      control:  ControlEnum.COLOR,
      defaultValue: '#F08300',
    },
    bottomTextColor: {
      control:  ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
    facebook: {
      control:  ControlEnum.TEXT,
      defaultValue: '#',
    },
    ins: {
      control: ControlEnum.TEXT,
      defaultValue: '#',
    },
    youtube: {
      control: ControlEnum.TEXT,
      defaultValue: '#',
    },
    line: {
      control: ControlEnum.TEXT,
      defaultValue: '#',
    },
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { F04 },
  template: `
    <div style="margin:auto;">
    <F04 v-bind="$props"/>
    </div>
  `,
});

export const f04 = Template.bind({});
