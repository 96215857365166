<template>
  <div class="component pt07" :class="{reverse: isReverse}" :style="componentColor">
    <div class="pt07-title-list">

      <h2>
        <span style="color:var(--title1FontColor)">{{ title1 }}</span>
        <span style="color:var(--title2FontColor)">{{ title2 }}</span>
        <span style="color:var(--title3FontColor)">{{ title3 }}</span>
      </h2>


      <span class="pt07-title-list--down">
         <i :class="[(icon ||'icon-circle-arrow-down')]"></i>
      </span>
    </div>
    <div class="pt07-img">
      <img :src="imgURL" :alt="imgURL">
    </div>
  </div>
</template>

<script>
import autoColor from "@/mixins/autoColor";

export default {
  name: "PT07",
  mixins: [autoColor],
  props: {
    imgURL: String,
    title1: String,
    title2: String,
    title3: String,

    title1FontColor: String,
    title2FontColor: String,
    title3FontColor: String,
    icon: {String},
    iconColor: String,
    isReverse: Boolean
  }
}
</script>

<style scoped>
.pt07 {
  --title1FontColor: #000;
  --title2FontColor: #000;
  --title3FontColor: #000;
  --iconColor: #000;
  
  display: flex;
}

.pt07.reverse {
  flex-direction: row-reverse;
}

.pt07-img {
  aspect-ratio: 47/ 22;
  flex-grow: 1;
}

.pt07-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.pt07-title-list {
  display: flex;
  flex-direction: column;
  font-size: 36px;
  font-weight: 700;
  margin-right: 57px;
  max-width: 300px;
  width: auto;

  width: 224px;
}

.pt07-title-list span {
  display: block;
  margin-bottom: 24px;
}

.pt07-title-list span:last-child {
  margin-bottom: 0;
}

.pt07-title-list--down {
  margin-top: auto;
  font-size: 33px;
  color: var(--iconColor);
}

@media screen and (min-width: 840px) {
  .pt07-title-list {
    width: 224px;
  }
}

@media screen and (max-width: 414px) {
  .pt07 {
    flex-direction: column;
  }

  .pt07-img {
    order: -1;
  }
}
</style>
