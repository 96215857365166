/**
 * ne Hooper vue2
 */
export default {
  name: 'carousel',
  props: {
    items: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize, false);
  },
  watch: {
    items: {
      handler: function (newValue, oldValue) {
        console.log("test change", newValue, oldValue);
        console.log('items()')
        this.$refs.carousel.update();
      },
      deep: true,
    }
  },
  methods: {
    onResize() {
      console.log('onResize()')
      this.$refs.carousel.update();
    }
  }
}
