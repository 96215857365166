import PT22 from "@/components/web-page/PictureText/PT22/index.vue";

export default {
    title: "web-page/PictureText/PT22",
    argTypes: {
        // img
        imgUrl: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
        },
        aspectRatio: {
            control: 'select',
            options: ["1/1", "1/2", "3/4"],
            defaultValue: "1/1",
        },
        // title
        title: {
            control: "text",
            defaultValue: "大標題",
        },
        titleFontSize: {
            control: "number",
            defaultValue: 40,
        },
        titleColor: {
            control: "color",
            defaultValue: "black",
        },
        titleSpacing: {
            control: "number",
            defaultValue: 2
        },
        // content 
        contentText: {
            control: "text",
            defaultValue: "內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文內文",
        },
        contentFontSize: {
            control: "number",
            defaultValue: 16,
        },
        contentColor: {
            control: "color",
            defaultValue: "black",
        },
        contentSpacing: {
            control: "number",
            defaultValue: 2
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT22 },
    template: `
    <div>
      <PT22 v-bind="$props"/>
    </div>
  `,
});

export const pt22 = Template.bind({});

