<template>
  <div :class="[{ isReverse: reverse }]" class="component component-introduce">
    <div class="LeftContainer">
      <div class="contentBlock" :class="[positionClass]">
        <div class="lawyerTitle">
          <span :style="{ color: adornTextColor }">{{ adornText }}</span>
        </div>
        <img :src="imgUrl" class="imgBlock" />
      </div>
    </div>
    <div class="RightContainer">
      <div class="contentBlock">
        <h2 class="titleBlock">
          <span :style="{ color: titleColor }" class="title">
            {{ title }}
          </span>
          <span :style="{ color: subTitleColor }" class="subTitle">
            {{ subTitle }}
          </span>
        </h2>

        <div class="listBlock">
          <div class="lawyerTitle">
            <span :style="{ color: lawyerColor }">LAWYER</span>
          </div>
          <ul>
            <li v-for="(item, index) in list" :key="index" class="Item">
              <i
                :class="item.iconName"
                :style="{ color: listIconColor, fontSize: '24px' }"
              >
              </i>
              <span :style="{ color: listTitleColor }" class="listTitle">
                {{ item.title }}
              </span>

              <div class="listContentBlock">
                <p
                  v-for="(d_item, d_index) in item.items.split('<br>')"
                  :key="d_index"
                  :style="{ color: listContentColor }"
                  class="listContent"
                >
                  {{ d_item }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PictureText10",
  props: {
    imgUrl: String,
    title: String,
    titleColor: String,
    subTitle: String,
    subTitleColor: String,
    adornText: String,
    adornTextColor: String,
    listIconColor: String,
    listTitleColor: String,
    listContentColor: String,
    reverse: Boolean,
    list: Array,
    position: String
  },
  computed: {
    positionClass() {
      switch (this.position) {
        case 'top':
          return 'position-top';
        case 'center':
          return 'position-center';
        case 'bottom':
          return 'position-bottom';
        default:
          return 'top';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.component .position-top {
}
.component .position-center {
  margin-top: auto;
  margin-bottom: auto;
}
.component .position-bottom {
  margin-top: auto;
}




.component-introduce {
  display: flex;
  width: 100%;
  &.isReverse {
    @media (min-width: 415px) {
      flex-direction: row-reverse;
      .LeftContainer {
        justify-content: flex-start;
        margin-right: 0;
        margin-left: 64px;
        .contentBlock {
          flex-direction: row-reverse;
        }
      }
      .RightContainer {
        display: flex;
        justify-content: flex-end;
        margin-right: 64px;
        margin-left: 0;
      }
    }
  }
  @media (max-width: 414px) {
    flex-direction: column-reverse;
  }
  .LeftContainer,
  .RightContainer {
    width: 50%;
    @media (max-width: 414px) {
      width: 100%;
    }

    .contentBlock {
      width: 100%;
      max-width: 468px;
    }
  }

  .lawyerTitle {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    writing-mode: vertical-lr;
  }

  .LeftContainer {
    display: flex;
    justify-content: flex-end;
    margin-right: 64px;

    @media (max-width: 414px) {
      margin-top: 47px;
      margin-right: 0;
      padding: 0 24px;
    }
    .contentBlock {
      display: flex;
      max-height: 487px;
      gap: 20px;
      .lawyerTitle {
        @media (max-width: 414px) {
          display: none;
        }
      }

      .imgBlock {
        width: 100%;
        max-width: 413px;
        height: 100%;
        max-height: 487px;
        filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
        object-fit: cover;
      }
    }
  }

  .RightContainer {
    position: relative;
    overflow: hidden;

    margin-left: 64px;
    @media (max-width: 414px) {
      margin-left: 0;
      padding: 0 24px;
      .lawyerTitle {
        position: absolute;
        right: 24px;
      }
    }
    @media (min-width: 415px) {
      .lawyerTitle {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: 74px;
      width: 100%;
      height: 1px;
      content: "";
      background-color: #6f6f6f;
    }

    .titleBlock {
      font-weight: 700;
      padding-bottom: 24px;
      .title {
        font-size: 36px !important;
        line-height: 50px !important;
      }
      .subTitle {
        font-size: 24px;
      }
    }

    .listBlock {
      padding-top: 24px;
      .Item {
        &:not(:last-child) {
          margin-bottom: 28px;
        }
        .listTitle {
          font-size: 18px;
          font-weight: 700;
          margin-left: 8px;
        }

        .listContentBlock {
          margin-top: 8px;
          .listContent {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
