<template>
  <LayoutGrid v-bind="$props" columns="2" class="g3_2">
    <slot/>
  </LayoutGrid>
</template>

<script>
import LayoutGrid from "@/layout/grid/LayoutGrid";
import layout from "@/layout/mixins/layout";

export default {
  name: "G3_2",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style lang="scss">
.g3_2 > *:nth-child(3) {
  grid-column: 1 / 3;
}
</style>
