import BT01 from "@/components/web-page/ButtonText/BT01/index.vue";

export default {
    title: "web-page/ButtonText/BT01",
    argTypes: {
        buttonGroup: {
            control: "array",
            keyType: {
                type: {
                    editType: 'select',
                    options: ["small", "medium", "large"],
                },
                buttonText: "text",
                link: "text",
            },
            template: {
                type: "medium",
                buttonText: "文字按鈕1",
                link: "https://google.com.tw",
            },
            defaultValue: [
                {
                    type: "small",
                    buttonText: "文字按鈕1",
                    link: "https://google.com.tw",
                },
                {
                    type: "medium",
                    buttonText: "文字按鈕2",
                    link: "https://google.com.tw",
                },
                {
                    type: "large",
                    buttonText: "文字按鈕3",
                    link: "https://google.com.tw",
                },
            ],
        },
        buttonColor: {
            control: "color",
            defaultValue: "#1F286F",
        },
        buttonTextColor: {
            control: "color",
            defaultValue: "white",
        },
        buttonClickColor: {
            control: "color",
            defaultValue: "grey",
        },
    },
};

// BT01
const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { BT01 },
    template: `<BT01 v-bind="$props"/>`,
});

export const bt01 = Template.bind({});
