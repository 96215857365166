import PT03 from "@/components/web-page/PictureText/PT03/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/PictureText/PT03",
    argTypes: {
        imgURL: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/image.png",
        },
        iconURL: {
            control: "text",
            defaultValue: "https://fakeimg.pl/45x45/ffffff/000/?retina=1",
        },
        // magic
        ...generateTextArgTypes("content", {
            contentDefault: "我是大標題我",
            sizeDefault: "36px",
            colorDefault: "white",
            spacingDefault: "1px",
        }),
        // content: {
        //     control: "text",
        //     defaultValue: "主標題",
        // },
    },
};

// PT03
const TemplatePT01 = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT03 },
    template: `
    <div style="width: 453px; margin:auto;">
      <PT03 v-bind="$props"/>
    </div>
  `,
});

export const pt03 = TemplatePT01.bind({});
pt03.args = {
    imgURL: "https://fakeimg.pl/453x453/eaeaea/eae0d0",
    iconURL: "https://fakeimg.pl/45x45/ffffff/000",
    content: "新價值",
};
