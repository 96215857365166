<template>
  <label class="buttons-cart" @click="headerButtonsEvent.onCart">
    <i class="icon-cart4"></i>
    <span class="quantity">{{cartQuantity}}</span>
  </label>
</template>

<script>
import headerCartMixin from "@/mixins/header/headerCartMixin";

export default {
  name: "cart",
  mixins: [headerCartMixin],
  inject: ['headerButtonsEvent'],
}
</script>

<style scoped>
.buttons-cart {
  display: flex;
  align-items: center;
}

.buttons-cart i {
  font-size: 28px;
}


@media screen and (min-width: 1200px) {
  .quantity {
    display: none;
  }
  .buttons-cart i {
    font-size: 21px;
  }
}
</style>
