import Dls01 from "@/components/web-page/DLS/Dls01/index.vue";

export default {
  title: "web-page/DLS/Dls01",
  argTypes: {
    carouselImgList: {
      control: "array",
      defaultValue: ['https://picsum.photos/1920/1080']
    },
  }
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Dls01 },
  template: `<Dls01 v-bind="$props" />`,
});

export const dls01 = Template.bind({});
