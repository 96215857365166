import Element from './index.vue';
import { generateTextArgTypes } from '@/components/base/javaScript/baseTest';
import { ControlEnum } from '@/models/Enum/ControlEnum';

export default {
  title: 'web-page/Carousel/C07',
  argTypes: {
    items: {
      control: ControlEnum.ARRAY,
      keyType: {
        imgUrl: 'image',
      },
      template: {
        imgUrl: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
        alt: "",
        title: '標題',
        content:'內文',
      },
      defaultValue: [
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題一',
          content:'內文',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題二',
          content:'內文',
        },
        {
          imgUrl: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
          alt:"product",
          title: '標題三',
          content:'內文',
        },
      ],
    },
    coverColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#0005",
    },
    hoverIconColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#cccccc",
    },
    iconColor:{
      control: ControlEnum.COLOR,
      defaultValue: "#18181b",
    },
    contentPosition:{
      control: ControlEnum.SELECT,
      options: ['center', 'right','left'],
      defaultValue: 'center',
    },
    
    ...generateTextArgTypes('title', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '16px',
      colorDefault: '#fff',
    }),
    ...generateTextArgTypes('content', {
      hiddeContent: true,
      contentDefault: '預設文字',
      sizeDefault: '16px',
      colorDefault: '#000',
    }),
  },
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { Element },
  template: `
    <div>
      <Element v-bind="$props"/>
    </div>
  `,
});

export const element1 = Template.bind({});
