export const mixin = {
  props: {
  },
  mounted() {
    let debounce = (function () {
      let timer;
      return (cd, time) => {
        clearTimeout(timer);
        timer = setTimeout(cd, time || 300);
      }
    })();
    window.addEventListener('resize', () => {
      debounce(() => {
        if (window.innerWidth >= 768) {
          document.body.style.overflow = '';
        }
      })
    })
  },
  methods: {
    disabledBodyScroll(bool) {
      if (bool) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }
}
