import PT02 from "@/components/web-page/PictureText/PT02/index.vue";

export default {
  title: 'web-page/PictureText/PT02',
  argTypes: {
    imgURL: {
      control: {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://fakeimg.pl/252x297/eaeaea/ffd700',
    },
    contentText: {
      control: "text",
      defaultValue: '丸紅文創事業群是個充滿創意、瘋狂、熱情的夢工廠以人為最初衷的本將每一位員工當作是家人打造自然放鬆的工作環境創立40餘年來秉持著傳遞感動的創意理念將熱情投注於產品的開發打造許多感動人心的經典設計'
    },
    contentColor: {
      control: "color",
      defaultValue: "#000000"
    }
  }
}

// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {PT02},
  template: `
    <div style="width: 453px; margin:auto;">
    <PT02 v-bind="$props"/>
    </div>
  `
});

export const pt02 = TemplatePT01.bind({});
