import component from './index.vue';
import stories from './index.stories.js';
import {TypeEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";
import {BlockEnum} from "@/models/Enum/BlockEnum";


export default {
  componentGroup: TypeEnum.PictureText,
  type: BlockEnum.CONTAINER,
  grids: {...GridEnum},
  name: 'PictureText24',
  component: component,
  argTypes: stories.argTypes
}

