import Element from "./index.vue";
import {ControlEnum} from "@/models/Enum/ControlEnum";
import {testData} from "@/models/headerColorSetting";
import {generateHeaderButtonsArgTypes} from "@/mixins/header/HeaderButtonsMixin";

export default {
  title: 'web-page/Header/H03',
  argTypes: {
    logoURL: {
      control: ControlEnum.IMG,
      defaultValue: 'https://fakeimg.pl/205x40/eaeaea/eae0d0',
    },
    list: {
      control: ControlEnum.ARRAY,
      defaultValue: testData
    },
    headerBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
    headerMobileBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
    headerMobileActiveBackgroundColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#0065F2',
    },
    menuTextColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#000',
    },
    menuMobileTextColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#FFF',
    },
    menuTextHoverColor: {
      control: ControlEnum.COLOR,
      defaultValue: '#0065F2',
    },
    ...generateHeaderButtonsArgTypes()
  }
}

const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {Element},
  template: `
    <div style="margin:auto; height: 300vh;">
      <Element v-bind="$props"/>
    </div>
  `
});

export const element = TemplatePT01.bind({});
