<template>
  <div class="component c05"
       :style="{...styleSetting}"
       >
    <hooper ref="carousel" :settings="settings" >
      <template #default="{block}">
        <slide class="componentContainer"
               :style="computedAspectRatio"
               v-for="(item, index) of items" :key="block + index">

          <p class="c05-title" v-if="item.title">
            <base-text block-name="title"
                       v-bind="{...$props, titleContent: item.title}"/>
          </p>
          <picture  class="c05__picture">
            <source :srcset="item.mobileImgUrl" media="(max-width: 415px)">
            <img class="c05__image"  :src="item.imgSrc" :alt="item.alt">
          </picture>
        </slide>
      </template>

      <template #hooper-addons>
        <navigation-test/>
        <pagination-test v-bind="$attrs"/>
      </template>
    </hooper>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
} from 'hooper';
import 'hooper/dist/hooper.css';
import NavigationTest from "@/components/web-page/Carousel/C05/NavigationTest";
import PaginationTest from "@/components/web-page/Carousel/C05/PaginationTest";
import hooperCarousel from "@/mixins/hooperCarousel";
import BaseText from "@/components/base/BaseText.vue";
import {generateTextProps} from "@/components/base/javaScript/baseTest";
import aspectRatio from "@/mixins/aspectRatio";

export default {
  name: "C05",
  components: {BaseText, PaginationTest, NavigationTest, Hooper, Slide},
  mixins: [hooperCarousel, aspectRatio],
  props: {
    ...generateTextProps('title'),
    autoPlay: Boolean,
    playSpeed: Number,
    onePhoto: Boolean,
    infinite: Boolean,
    aspectRatio: String,
    items: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    settings() {
      this.$nextTick(() => {
        if (this.$refs.carousel.timer) {
          this.$refs.carousel.timer.stop();
        }
        this.$refs.carousel.initDefaults();
        this.$refs.carousel.update();
        this.$refs.carousel.initEvents();
      });
    }
  },
  computed: {
    settings() {
      return {
        autoPlay: this.autoPlay,
        playSpeed: this.playSpeed,
        wheelControl: false,
        itemsToShow: this.onePhoto ? 1 : 1.3,
        centerMode: true,
        infiniteScroll: this.infinite
      }
    },
    styleSetting() {
      return {
        '--img-gap': this.onePhoto ? '0' : '12px'
      }
    },
    dotsArray: function () {
      return Array.from(Array(this.items.length).keys())
    }
  },
  data() {
    return {
      slidesToShow: 1,
      slidesToScroll: 1,
      isShow: true,
      isMobile: false
    }
  },

}
</script>

<style scoped>
.c05 {
  --img-gap: 12px;
}

.c05 :deep(.hooper-list .hooper-track), .c05 .hooper-list .hooper-track {
  padding: 0;
}

.c05 :deep(.hooper), .c05 .hooper {
  height: 100%;
}

.componentContainer {
  position: relative;
}

.c05-title {
  position: absolute;
  margin: auto;
  inset: 0;
  height: fit-content;
  text-align: center;
  vertical-align: middle;
  color: #FFFFFF;
}

.c05__picture {
  width: 100%;
  height: 100%;
}
.c05__image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  padding: 0 var(--img-gap);
}
</style>
