<template>
  <BaseHtmlText :content="content" />
</template>

<script>
import BaseHtmlText from "@/components/base/BaseHtmlText.vue";
export default {
  name: "T01",
  components: {BaseHtmlText},
  props: {
    content: String
  }
}
</script>

