<template>
  <div class="navigation">
    <span class="hooper-prev" @click="slidePrev">
      <slot name="prev">
        <i class="icon-arrow-narrow-left"></i>
      </slot>
    </span>
    <span class="hooper-next" @click="slideNext">
      <slot name="next">
        <i class="icon-arrow-narrow-right"></i>
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  inject: ['$hooper'],
  name: 'Navigation',
  props: {
    disabledPrevIconUrl: String,
    prevIconUrl: String,
    disabledNextIconUrl: String,
    nextIconUrl: String,
  },
  computed: {
    prevURL: function () {
      return this.isInfinite
        ? this.prevIconUrl
        : this.isPrevDisabled
        ? this.disabledPrevIconUrl
        : this.prevIconUrl;
    },
    nextURL: function () {
      return this.isInfinite
        ? this.nextIconUrl
        : this.isNextDisabled
        ? this.disabledNextIconUrl
        : this.nextIconUrl;
    },
    isPrevDisabled: function isPrevDisabled() {
      if (this.$hooper.config.infiniteScroll) {
        return false;
      }
      return this.$hooper.currentSlide === 0;
    },
    isNextDisabled: function isNextDisabled() {
      if (this.$hooper.config.infiniteScroll) {
        return false;
      }
      if (this.$hooper.config.trimWhiteSpace) {
        return (
          this.$hooper.currentSlide ===
          this.$hooper.slidesCount -
            Math.min(this.$hooper.config.itemsToShow, this.$hooper.slidesCount)
        );
      }

      return this.$hooper.currentSlide === this.$hooper.slidesCount - 1;
    },
    isInfinite() {
      return this.$hooper.config.infiniteScroll;
    },
  },
  methods: {
    slideNext: function slideNext() {
      this.$hooper.slideNext();
      this.$hooper.restartTimer();
    },
    slidePrev: function slidePrev() {
      this.$hooper.slidePrev();
      this.$hooper.restartTimer();
    },
  },
};
</script>

<style scoped lang="scss">
.navigation {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  // background: #336;
  padding-top: 24px;
}

.hooper-next,
.hooper-prev {
  width: 48px;
  aspect-ratio: 1;
  padding: 0;

  border-radius: 50%;
  border: transparent 4px solid;

  color: var(--iconColor);
  position: relative;
  top: 0;
  transform: translateY(0);
  transition: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 40px;
  }

  &:hover {
    border: var(--hoverIconColor) 4px solid;
  }
}
</style>
