<template>
    <div class="component component-t05" :style="style">
        <!-- title -->
        <div v-if="titleContent !== ''" class="t05__titleContainer">
            <base-text block-name="title" v-bind="$props" />
        </div>
        <!-- subTitle -->
        <div v-if="subTitleContent !== ''" class="t05__subTitleContainer">
            <base-text block-name="subTitle" v-bind="$props" />
        </div>
        <!-- img -->
        <div v-if="imgUrl" class="t05__imgWrapper">
            <img :src="imgUrl" alt="icon" />
        </div>
        <!-- content-->
        <div v-if="contentContent !== ''" class="t05__contentContainer">
            <base-text block-name="content" v-bind="$props" />
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "Text05",
    components: {
        BaseText,
    },
    props: {
        imgUrl: String,
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        ...generateTextProps("content"),
        horizontalAlignOption: String,
        verticalAlignOption: String,
    },
    methods: {
        alignPosition(val){
            let result
            switch (val) {
                case "left":
                    result = "flex-start" 
                    break;
                case "top":
                    result = "flex-start" 
                    break;
                case "center":
                    result = "center" 
                    break;
                case "right":
                    result = "flex-end" 
                    break;
                case "bottom":
                    result = "flex-end" 
                    break;
                default:
                    result="center"
                    break;
            }
            return result
        },
    },
    computed: {
        style(){
            return{
                "--horizontal-align-option": this.alignPosition(this.horizontalAlignOption),
                "--vertical-align-option": this.alignPosition(this.verticalAlignOption),
                "--content-align": this.horizontalAlignOption,
            }
        }
    },
};
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.component-t05 {
    display: flex;
    flex-direction: column;
    max-width: 1360px;
    height: 100%;
    justify-content: var(--vertical-align-option);
    align-items: var(--horizontal-align-option);
    margin: 0 auto;
}

.t05__titleContainer {
    font-weight: 700;
    line-height: 1.4;
}

.t05__subTitleContainer {
    font-weight: 400;
    margin: 8px 0;
    line-height: 1.4;
}

.t05__imgWrapper {
    width: 28px;
    height: 12px;
    margin-bottom: 24px;
}

.t05__contentContainer {
    max-width: 1064px;
    font-weight: 400;
    font-size: var(--contentFontSize);
    color: var(--contentColor);
    letter-spacing: var(--contentSpacing);
    text-align: var(--content-align);
}

@media screen and (max-width: 1100px) {
    .t05__contentContainer {
        width: 80%;
        text-align: justify;
    }
}
</style>
