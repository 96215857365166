<template>
  <div class="component component-f01" :style="style">
    <div class="footer__main">
      <div class="footer__logo">
        <img :src="logoUrl" alt="logo" />
      </div>
      <div :class="['footer__info', `footer-col-${col}`]">
        <a
          class="footer__card"
          v-for="(item, index) in items"
          :key="index"
          :href="item.href"
        >
          <base-text
            block-name="title"
            v-bind="{ ...$props, titleContent: item.title }"
          />
        </a>
      </div>
      <div class="footer__links">
        <a
          v-for="(link, index) in links"
          :key="index"
          class="footer__link"
          target="_blank"
          :href="link.href"
        >
          <i :class="`icon-${link.icon}`" :style="`color:${link.color}`"></i>
        </a>
      </div>
    </div>
    <footer-copyright
      :copyrightText="copyrightText"
      :copyrightLink="copyrightLink"
      :copyrightBackgroundColor="copyrightBackgroundColor"
      :copyrightTextColor="copyrightTextColor"
    />
  </div>
</template>

<script>
import BaseText from '@/components/base/BaseText.vue';
import { generateTextProps } from '@/components/base/javaScript/baseTest';
import FooterCopyright from '@/components/base/FooterCopyright/FooterCopyright';

export default {
  name: 'F01',
  components: { BaseText, FooterCopyright },
  props: {
    logoUrl: String,
    items: () => [],
    links: () => [],
    blockBackgroundColor: String,
    copyrightText: String,
    copyrightLink: () => [],
    copyrightBackgroundColor: String,
    copyrightTextColor: String,

    ...generateTextProps('title'),
  },
  computed: {
    col() {
      return this.items.length > 4 ? 5 : this.items.length % 5;
    },
    style() {
      return {
        '--blockBackgroundColor': this.blockBackgroundColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.footer__main {
  display: flex;
  gap: 48px;
  position: relative;
  justify-content: space-between;
  background-color: var(--blockBackgroundColor);

  .footer__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: fit-content;

    .footer__link {
      line-height: 1;
      i {
        vertical-align: top;
      }
    }
  }

  .footer__info {
    display: flex;
    flex-wrap: wrap;

    .footer__card {
    }
  }
  .footer__logo {
    width: 200px;
    img {
      width: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: 767px) {
  .component-f01 {
  }
  .footer__main {
    padding: 48px 11.83%;

    flex-direction: column;
    .footer__info {
      gap: 20px;

      // &.footer-col-1 .footer__card {
      //   width: 100%;
      // }
      .footer__card {
        width: 100%;
      }
    }
    .footer__links {
      gap: 10px 16px;

      .footer__link {
        i {
          font-size: 24px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .component-f01 {
  }
  .footer__main {
    padding: 64px 10.88%;

    align-items: flex-start;
    .footer__info {
      width: 0;
      flex: 1;
      max-width: 52.81%;

      gap: 24px 3.55%;
      // justify-content: space-between;

      .footer__card {
        width: 30.96%;
      }
      &.footer-col-3 .footer__card {
        width: 30.96%;
      }
      &.footer-col-2 .footer__card {
        width: 48.225%;
      }
      &.footer-col-1 .footer__card {
        width: 100%;
      }
    }
    .footer__links {
      gap: 10px 21px;
      max-width: 143px;

      .footer__link {
        i {
          font-size: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .footer__main {
    .footer__info {
      .footer__card {
        width: 17.15%;
      }
      &.footer-col-5 .footer__card {
        width: 17.15%;
      }
      &.footer-col-4 .footer__card {
        width: 22.337%;
      }
      &.footer-col-3 .footer__card {
        width: 30.96%;
      }
      &.footer-col-2 .footer__card {
        width: 48.225%;
      }
      &.footer-col-1 .footer__card {
        width: 100%;
      }
    }
  }
}
</style>
