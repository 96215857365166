import PIC05 from '@/components/web-page/Picture/pic05/index.vue';

export default {
  title: 'web-page/Picture/PIC05',
  argTypes: {
    imgUrl: {
      control:  {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895215329.png',
    },
    mobileImgUrl: {
      control:  {
        type: 'text',
        editType: 'image',
      },
      defaultValue: 'https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895066858.png',
    },
    title: {
      control: 'text',
      defaultValue: '標題',
    },
    titleColor: {
      control: 'color',
      defaultValue: '#000',
    },
    titleFontSize: {
      control: 'text',
      defaultValue: '62px'
    },
    borderColor: {
      control: 'color',
      defaultValue: '#000',
    },
    borderSize: {
      control: 'text',
      defaultValue: '5px'
    },
    path: {
      control: 'text',
      defaultValue: ''
    }
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PIC05 },
  template: `<PIC05 v-bind="$props" />`,
});

export const pic05 = Template.bind({});
