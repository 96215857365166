<template>

  <LayoutGrid v-bind="$props" columns="3">
    <slot/>
  </LayoutGrid>

</template>

<script>
import LayoutGrid from "@/layout/grid/LayoutGrid";
import layout from "@/layout/mixins/layout";

export default {
  name: "G3_1",
  components: {LayoutGrid},
  mixins: [layout]
}
</script>

<style scoped>

</style>
