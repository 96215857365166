<template>
  <div class="component">
    <span :class="['hooper-prev', {'disabled':isPrevDisabled}]" @click="slidePrev">
      <slot name="prev">
        <i class="icon-arrow-left"></i>
      </slot>
    </span>
    <span :class="['hooper-next', {'disabled':isNextDisabled}]" @click="slideNext">
      <slot name="next">
         <i class="icon-arrow-right"></i>
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  inject: ['$hooper'],
  name: "NavigationTest",
  props: {
    disabledPrevIconUrl: String,
    prevIconUrl: String,
    disabledNextIconUrl: String,
    nextIconUrl: String
  },
  computed: {
    prevURL: function () {
      return this.isInfinite ? this.prevIconUrl :
          this.isPrevDisabled ? this.disabledPrevIconUrl : this.prevIconUrl;
    },
    nextURL: function () {
      return this.isInfinite ? this.nextIconUrl :
          this.isNextDisabled ? this.disabledNextIconUrl : this.nextIconUrl;
    },
    isPrevDisabled: function isPrevDisabled() {
      if (this.$hooper.config.infiniteScroll) {
        return false;
      }
      return this.$hooper.currentSlide === 0;
    },
    isNextDisabled: function isNextDisabled() {
      if (this.$hooper.config.infiniteScroll) {
        return false;
      }
      if (this.$hooper.config.trimWhiteSpace) {
        return this.$hooper.currentSlide === this.$hooper.slidesCount - Math.min(this.$hooper.config.itemsToShow, this.$hooper.slidesCount);
      }

      return this.$hooper.currentSlide === this.$hooper.slidesCount - 1;
    },
    isInfinite() {
      return this.$hooper.config.infiniteScroll
    }
  },
  methods: {
    slideNext: function slideNext() {
      this.$hooper.slideNext();
      this.$hooper.restartTimer();
    },
    slidePrev: function slidePrev() {
      this.$hooper.slidePrev();
      this.$hooper.restartTimer();
    }
  },
}
</script>

<style scoped>
.component {
  --arrowColor: #C8161D;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.hooper-next > *, .hooper-prev > * {
  vertical-align: bottom;
}

.hooper-next > * {
  right: -2px;
  position: relative;
}

.hooper-prev > * {
  left: -2px;
  position: relative;
}

.hooper-next.disabled > *, .hooper-prev.disabled > * {
  opacity: .5;
}


.hooper-next, .hooper-prev {

  padding: 0;
  line-height: 1rem;
  color: var(--arrowColor);
  font-size: 24px;
  border-radius: 50%;
}

.hooper-next {
  right: 12px;
}

.hooper-prev {
  left: 12px
}


@media screen and (min-width: 1200px) {
  .hooper-next, .hooper-prev {
    color: #FFF;
    padding: 12px;
    font-size: 24px;
    background-color: var(--arrowColor);
  }

  .hooper-next {
    right: 48px;
  }

  .hooper-prev {
    left: 48px
  }

}

</style>
