import F03 from "@/components/web-page/Footer/F03/index.vue";
import {ControlEnum} from "@/models/Enum/ControlEnum";

export default {
  title: 'web-page/Footer/F03',
  paramGroup: {
    titleGroup: ['title'],
    subTitleGroup: ['subTitle', 'subTitleColor'],
    socialGroup: ['facebook', 'ins', 'youtube', 'line'],
    configGroup: ['singleRow', 'singlePosition'],
  },
  argTypes: {
    logoURL: {
      control: ControlEnum.IMG,
      defaultValue:"https://fakeimg.pl/105x205/eaeaea/eae0d0",
    },
    singleRow: {
      control: ControlEnum.BOOL,
      defaultValue: false
    },
    singlePosition: {
      control: ControlEnum.SELECT,
      options: ["left", "center", "right"],
      defaultValue: "center"
    },
    items: {
      control: ControlEnum.ARRAY,
      template: { iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0', subTitle: "小標題", subItems: '項目一, 項目二'},
      defaultValue: [
        {
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      },{
        iconURL: 'https://fakeimg.pl/32/eaeaea/eae0d0',
        subTitle: "營業時間",
        subItems: '週二至週日, 10:00am~19:00pm'
      }]
    },
    title: {
      control: ControlEnum.TEXT,
      defaultValue: "聯絡我們"
    },
    textColor: {
      control:  ControlEnum.COLOR,
      defaultValue: "#FFF"
    },
    subTitle: {
      control: ControlEnum.TEXT,
      defaultValue: "CONTACT US"
    },
    subTitleColor: {
      control:  ControlEnum.COLOR,
      defaultValue: "#000"
    },
    address: {
      control: ControlEnum.TEXT,
      defaultValue: " 大甲鎮瀾宮 "
    },
    bottomText: {
      control: ControlEnum.TEXT,
      defaultValue: "© 2022 百順實業 bai shun . Powered By ShopInn"
    },
    bottomLink: {
      control: ControlEnum.ARRAY,
      template: {link : "#", text: '輸入文字'},
      defaultValue: [
        {link : "#", text: '服務條款'}
      ]
    },
    bgColor: {
      control:  ControlEnum.COLOR,
      defaultValue: "#000"
    },
    bottomBgColor: {
      control:  ControlEnum.COLOR,
      defaultValue: "#F08300"
    },
    bottomTextColor: {
      control:  ControlEnum.COLOR,
      defaultValue: "#FFF"
    },
    textRadio: {
      control: 'radio',
      options: ['black', 'white'],
      defaultValue: 'white'
    },
    bottomTextRadio: {
      control: 'radio',
      options: ['black', 'white'],
      defaultValue: 'white'
    },
    facebook: {
      control: ControlEnum.TEXT,
      defaultValue: "#"
    },
    ins: {
      control: ControlEnum.TEXT,
      defaultValue: "#"
    },
    youtube: {
      control: ControlEnum.TEXT,
      defaultValue: "#"
    },
    line: {
      control: ControlEnum.TEXT,
      defaultValue: "#"
    },
  }

}
// PT01
const TemplatePT01 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {F03},
  template: `
    <div style="margin:auto;">
    <F03 v-bind="$props"/>
    </div>
  `
});

export const f03 = TemplatePT01.bind({});
