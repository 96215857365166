import PT11 from "@/components/web-page/PictureText/PT11/index.vue";

export default {
  title: "web-page/PictureText/PT11",
  argTypes: {
    imgUrl: {
      control: {
        type: "text",
        editType: "image"
      },
      defaultValue: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
    },
    logoUrl: {
      control: {
        type: "text",
        editType: "image"
      },
      defaultValue: "https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png",
    },
    aboutText: {
      control: "text",
      defaultValue: "關於我們",
    },
    aboutTextInImage: {
      control: "text",
      defaultValue: "About",
    },
    isReverse: {
      control: "boolean",
      defaultValue: false,
    },
    introduceText: {
      control: "text",
      defaultValue: `
        ShopInn，,<br>
        幫助顧客的價值被更多人看到,<br>
        當這世界都在休息的時候，,<br>
        霓虹燈依舊閃耀著，,<br>
        就像我們讓您發光發熱永不停歇。
      `,
    },
    aboutColor: {
      control: "color",
      defaultValue: "#fff",
    },
    titleColor: {
      control: "color",
      defaultValue: "#000",
    },
    lineColor: {
      control: "color",
      defaultValue: "#000",
    },
    introduceColor: {
      control: "color",
      defaultValue: "#6F6F6F",
    },
    position: {
      control: 'select',
      options: ["top", "center", "bottom"],
      defaultValue: "top"
    },
    introducePosition: {
      control: 'select',
      options: ["left", "center", "right"],
      defaultValue: "left"
    }
  },
};

// PT01
const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { PT11 },
  template: `<PT11 v-bind="$props"/>`,
});

export const pt111 = Template.bind({});
export const pt112 = Template.bind({});
pt112.args = {
  introduceText: '主廚MOBU在法國餐廳實習時期，跟隨當地五位主廚深耕研究法國各地傳統料理，將最道地的傳統料理發揮極致，回台後主廚將她最喜歡法國菜食譜帶回，在依台人喜愛口味進行改良，讓無論是觀光客或在地人都可以品嘗到最浪漫的美食口感。'
}
