<script>
import DlsOrderImage from "@/components/web-page/DLS/Dls01/DlsOrderImage.vue";
import { Input, Radio } from "element-ui";
import {
  weekOptions,
  serviceTimeOptions,
  subscriptionOptions,
} from "@/components/web-page/DLS/composables/Options";
import DlsEditIcon from "@/components/web-page/DLS/Dls01/DlsEditIcon.vue";

export default {
  name: "DlsSecondPage",
  components: { DlsEditIcon, DlsOrderImage, Input, Radio },
  props: {
    request: Object,
  },

  data() {
    return {
      weekOptions,
      serviceTimeOptions,
      subscriptionOptions,

      paymentMethod: "creditCard",
    };
  },

  computed: {
    methodText() {
      return this.$props.request.method === "point" ? "定時定點" : "定時面交";
    },
    subscriptionText() {
      return this.subscriptionOptions.find(
        (item) => item.value === this.$props.request.subscription
      ).label;
    },
    collectDayText() {
      const dayStringArray = [];
      this.weekOptions.forEach((item) => {
        const day = this.$props.request.collectDay.includes(item.value);
        if (day) {
          dayStringArray.push(item.label);
        }
      });

      return dayStringArray.join("、");
    },
    serviceTimeText() {
      return (
        this.serviceTimeOptions.find(
          (item) => item.value === this.$props.request.serviceTime
        ).label ?? ""
      );
    },
    getCorrectCollectDayData() {
      return (val) => {
        return this.weekOptions.find((item) => item.value === val);
      };
    },
    getCorrectServiceTime() {
      return (val) => {
        const serviceTimeOption = this.serviceTimeOptions.find(
          (item) => item.value === val
        );
        if (serviceTimeOption) {
          return serviceTimeOption.label.replace("~", " ➝ ");
        }
      };
    },
  },
};
</script>

<template>
  <div class="dls-second-page-container">
    <div class="card order-detail-block">
      <div class="title">訂單內容</div>

      <div class="content">
        <div class="order">
          <DlsOrderImage class="order-image" />

          <div class="order-detail">
            <div class="order-title">小家庭方案</div>
            <div class="order-list">
              <ul>
                <li>每週收取次數：{{ request.weekTimes }}次</li>
                <li>服務方式：{{ methodText }}</li>
                <li>訂購期間：{{ subscriptionText }}</li>
                <li>垃圾容量：{{ request.capacity }}公升</li>
                <li>收取星期：{{ collectDayText }}</li>
                <li>服務時間：{{ serviceTimeText }}</li>
                <li>放置位子：{{ request.location }}</li>
                <li>備註：{{ request.note }}</li>
              </ul>
            </div>
          </div>

          <div class="order-price">
            <div class="service-time-list">
              <ul>
                <li>
                  <span class="subTitle">收取時間</span>
                  <DlsEditIcon />
                </li>
                <li v-for="item in request.collectDay" :key="item">
                  <span class="list-title">
                    {{ getCorrectCollectDayData(item).label }}
                  </span>
                  <span class="bold">
                    {{ getCorrectServiceTime(request.serviceTime) }}
                  </span>
                </li>
              </ul>
            </div>

            <div class="price-detail">
              <ul>
                <li>
                  <span class="subTitle mb-8">價格詳情</span>
                </li>
                <li>
                  <span class="list-title"> {{ subscriptionText }} X 600 </span>
                  <span class="bold"> NT$ 1800</span>
                </li>
              </ul>
            </div>

            <div class="pay-amount">
              <ul>
                <li>
                  <span class="subTitle">付款金額</span>
                  <span class="total-amount"> NT$ 1800 </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-payment-method-block">
      <div class="card data-table-block">
        <div class="title">資料填寫</div>
        <div class="content">
          <div class="data-table">
            <div class="input-block">
              <label for="name">
                <span class="mark">＊</span>
                姓名
              </label>
              <Input placeholder="請輸入姓名" id="name" />
            </div>
            <div class="input-block">
              <label for="phone"> 市話 </label>
              <Input placeholder="請輸入市內電話" id="phone" />
            </div>
            <div class="input-block">
              <label for="address">
                <span class="mark">＊</span>
                地址
              </label>
              <Input
                placeholder="請輸入地址"
                id="address"
                v-model="request.address"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card payment-method-block">
        <div class="title">付款方式</div>
        <div class="content">
          <div class="payment-method">
            <div class="credit-card">
              <Radio label="creditCard" v-model="paymentMethod">
                信用卡一次付清
              </Radio>
            </div>

            <div class="cash-on-delivery">
              <Radio label="cashOn" v-model="paymentMethod"> 貨到付款 </Radio>
            </div>
            <div class="bank-transfer">
              <Radio label="bankTransfer" v-model="paymentMethod"> 轉帳 </Radio>
            </div>
            <div class="cash">
              <Radio label="cash" v-model="paymentMethod"> 現金 </Radio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-footer">
      <div class="submit-button">送出</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$MOBILE_SIZE: 768px;
$primary-100: #10205c;
$primary-200: #082844;
$gray-200: #f0f0f0;
$gray-700: #6f6f6f;
$checked-color: #07b8dd;

.dls-second-page-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  align-items: center;
  max-width: 1096px;

  .card {
    padding: 36px 0;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.15);
    width: 100%;

    .title {
      font-size: 24px;
      font-weight: 700;
      padding-left: 28px;
      border-left: 8px solid $checked-color;
    }
    .subTitle {
      font-size: 16px;
      font-weight: 700;
      &.mb-8 {
        margin-bottom: 8px;
      }
    }

    .content {
      padding: 24px 36px 0;
      .order {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1024px) {
          flex-direction: column;
          align-items: center;
          row-gap: 24px;
          .order-image {
            width: 100%;
          }
        }

        .order-detail {
          .order-title {
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 16px;
          }

          .order-list {
            padding-left: 24px;
            ul {
              list-style: disc;
              display: grid;
              gap: 8px;
              li {
                color: $gray-700;
                &::marker {
                  color: $gray-700;
                }
              }
            }
          }
        }

        .order-price {
          max-width: 298px;
          width: 100%;
          gap: 16px;

          .service-time-list {
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 2px dashed $gray-200;
          }
          .price-detail {
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 2px solid $gray-200;
          }
          .pay-amount {
            .total-amount {
              color: #0065f2;
              font-size: 20px;
              font-weight: 700;
            }
          }

          ul {
            display: grid;
            gap: 8px;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .list-title {
                color: $gray-700;
              }
              .bold {
                font-weight: 700;
              }
              &.mb-8 {
                margin-bottom: 8px;
              }
            }
          }
        }
      }

      .data-table {
        display: grid;
        gap: 16px;

        .input-block {
          display: grid;
          gap: 8px;
          label {
            font-size: 14px;
            .mark {
              color: #b3261e;
            }
          }
        }
      }

      .payment-method {
        display: grid;
        gap: 16px;
      }
    }
  }

  .data-payment-method-block {
    display: flex;
    width: 100%;
    gap: 24px;
    @media (max-width: $MOBILE_SIZE) {
      flex-direction: column;
    }

    :deep(.el-input) {
      .el-input__inner {
        border-radius: 100px;
        background-color: $gray-200;
        border: none;
        &::placeholder {
          color: $gray-700;
        }
      }
    }

    :deep(.el-radio) {
      &.is-checked {
        .el-radio__input {
          .el-radio__inner {
            border-color: $primary-100;
            background-color: $primary-100;
          }
        }
      }
      .el-radio__label {
        color: #000000;
        font-size: 16px;
      }
      .el-radio__input {
        .el-radio__inner {
          border-color: $gray-700;
          border-width: 2px;
        }
      }
    }
  }

  .page-footer {
    margin-top: 24px;
    .submit-button {
      cursor: pointer;
      padding: 10px 82px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      border-radius: 100px;
      border: 4px solid #ffffff;
      background-color: $checked-color;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
