import ProdCard01 from "@/components/web-page/Product/component/ProdCard01.vue";
import ProdCard02 from "@/components/web-page/Product/component/ProdCard02.vue";
import globalInject from "@/mixins/globalInject";


export const generateSelectProdArgTypes = () => ({
  classId: {
    control: "text",
    defaultValue: null
  },
  selectProd: {
    control: "select",
    options: ["ProdCard01", "ProdCard02"],
    defaultValue: 'ProdCard01'
  },
});

/**
 * 此 mixin 用於產品卡片的API共同處理
 */
export default {
  props: {
    selectProd: String,
    classId: Number
  },
  mixins: [globalInject],
  methods: {
    /**
     *
     * @param perPage 單頁要幾筆資料
     * @return {Promise<void>}
     */
    async getDate(perPage, page) {
      let sendParams = {
        current_page: page|| 1,
        per_page: perPage || 24,
        ref_url: this.refURL,
      }
      if (this.classId) {
        sendParams.class_id = this.classId;
      }
      let params = new URLSearchParams(sendParams);
      let req = await fetch(`${this.apiURL}item/v2/?${params.toString()}`);
      let {result} = await req.json();
      this.items = result ? result.data : [];
      this.paging = result ? result.paging : {};
    },
    formatValue(value) {
      return value ? {
        ...value,
        informationData: value.informationData['zh-TW'],
        price: value.prices[0],
        sellingPrice: value.finalPrice !== 0 ? value.prices[value.finalPrice] : value.prices[0],
      } : {}
    },
    dataToComponentProps(obj) {
      return {
        title: this.formatValue(obj).informationData.itemName,
        content: this.formatValue(obj).informationData.itemSummary,
        prodId: obj.itemId,
        imageURL: obj.imageUrl,
        seoUrl: obj.seoUrl,
        price: this.formatValue(obj).price,
        sellingPrice: this.formatValue(obj).sellingPrice,
        data: obj
      }
    }
  },
  computed: {
    selectProdComponent() {
      switch (this.selectProd) {
        case 'ProdCard01':
          return ProdCard01;
        case 'ProdCard02':
          return ProdCard02;
        default:
          return ProdCard01
      }
    }
  },
  watch: {
    classId() {
      this.getDate();
    }
  },
  data() {
    return {
      items: [],
      paging: {
        currentPage: 1,
        nextPage: null,
        perPage: null,
        previousPage: null,
        totalEntries: null,
        totalPages: null,
      }
    }
  }
}
