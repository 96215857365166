import T08 from "@/components/web-page/Text/T08/index.vue";

export default {
    title: "web-page/Text/T08",
    argTypes: {
        list: {
            keyType: {
                title: "text",
                content: "text",
            },
            control: "array",
            template: {
                title: "大學校院學生:",
                content:
                    "國內外尋找工作、教職之語言能力證明申請國外大學之語文能力證明畢業語文能力標準獎學金發放參考求職時語文能力證明自我評量",
            },
            defaultValue: [
                {
                    title: "大學校院學生:",
                    content:
                        "國內外尋找工作、教職之語言能力證明申請國外大學之語文能力證明畢業語文能力標準獎學金發放參考求職時語文能力證明自我評量",
                },
                {
                    title: "一般社會人士:",
                    content: "國內外尋找工作、教職之語言能力證明派遣、升遷時語文能力要求自我評量",
                },
                {
                    title: "公司企業:",
                    content: "招募時提供一可靠的語文能力評量檢核在職員工之外語能力教育訓練規劃測試",
                },
            ],
        },
    },
};

const Template = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { T08 },
    template: `
    <div>
      <T08 v-bind="$props"/>
    </div>
  `,
});

export const t08 = Template.bind({});
