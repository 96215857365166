import C05 from '@/components/web-page/Carousel/C05/index.vue'
import {generateTextArgTypes} from "@/components/base/javaScript/baseTest";
import {ControlEnum} from "@/models/Enum/ControlEnum";
import {generateAspectRatioArgTypes} from "@/mixins/aspectRatio";

export default {
  title: 'web-page/Carousel/C05',
  argTypes: {
    autoPlay: {
      control: ControlEnum.BOOL,
      defaultValue: false
    },
    playSpeed: {
      control: ControlEnum.NUMBER,
      defaultValue: 3000
    },
    infinite: {
      control: ControlEnum.BOOL,
      defaultValue: true
    },
    onePhoto: {
      control: ControlEnum.BOOL,
      defaultValue: false
    },
    items: {
      control: ControlEnum.ARRAY,
      keyType: {
        imgSrc: 'image',
        mobileImgUrl: 'image',
      },
      template: {
        imgSrc: 'https://fakeimg.pl/300x144/282828/eae0d0',
        mobileImgUrl: 'https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895066858.png',
        alt: "",
        paddingRight: "",
        title: '標題',
      },
      defaultValue: [
        {
          imgSrc: "https://fakeimg.pl/300x144/282828/eae0d0",
          mobileImgUrl: "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895066858.png",
          alt:"product",
          title: '標題一',
          paddingRight:"24px",
        },
        {
          imgSrc: "https://fakeimg.pl/300x144/282828/eae0d0",
          mobileImgUrl: "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895066858.png",
          alt:"product",
          title: '標題二',
          paddingRight:"24px",
        },
        {
          imgSrc: "https://fakeimg.pl/300x144/282828/eae0d0",
          mobileImgUrl: "https://develop-e-commerce-image-storage.s3.ap-northeast-1.amazonaws.com/effxND0apQ/image/1677895066858.png",
          alt:"product",
          title: '標題三',
          paddingRight:"24px",
        },
      ],
    },
    ...generateAspectRatioArgTypes('aspectRatio'),
    ...generateTextArgTypes('title', {
      hiddeContent: true,
      sizeDefault: '70px'
    })
  }
}

const C05Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {C05},
  template: `
    <div>
    <C05 v-bind="$props"/>
    </div>
  `,
});

export const c05 = C05Template.bind({})
c05.storyName = 'C05';
