<template>
  <div class="component component-qa03" :style="{'--arrowColor': arrowColor}">
    <div class="ContainerInner">
      <Collapse
          v-model="activeTab"
          accordion
          class="collapseContainer"
          @change="handleChange"
          :style="{'--borderColor': iconColor}"
      >
        <CollapseItem
            v-for="item in listItem"
            :key="item.id"
            :id="'qa-'+item.id"
            :name="String(item.id)"
        >
          <template slot="title">
            <div class="titleBlock">
              <i :class="item.iconName" style="font-size: 32px" :style="{color: iconColor}"></i>
              <span class="listTitle" :style="{color: titleColor}">
                {{ item.title }}
              </span>
            </div>
          </template>

          <div
              :style="{ '--dot-color': contentColor }"
              class="contentBlock"
              v-html="item.content"
          ></div>

        </CollapseItem>
      </Collapse>
    </div>
  </div>
</template>

<script>
import {Collapse, CollapseItem} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

export default {
  name: "QuestionAnswer03",
  components: {
    Collapse,
    CollapseItem,
  },
  props: {
    titleColor: String,
    contentColor: String,
    iconColor: String,
    arrowColor: String,
    listItem: Array,
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  methods: {
    handleChange(name) {
      if (name) {
        const newUrl = location.href.split("#")[0];
        location.replace(`${newUrl}#${name}`);
      }
    },
  },
  mounted() {
    const formatUrl = location.href;
    this.activeTab = formatUrl.split("#")[1];
    console.log(this.activeTab);

    const objControl = document.getElementById('qa-' + this.activeTab);
    if (objControl) {
      window.scrollTo(0, objControl.getBoundingClientRect().bottom);
    }
  },
};
</script>

<style lang="scss">
.component-qa03 {
  --arrowColor: #bd9b7b;

  .ContainerInner {
    max-width: 1360px;
    margin: 0 auto;
    @media (min-width: 801px) {
      padding: 0 148px;
    }

    .collapseContainer {
      border: none;

      .titleBlock {
        display: flex;
        align-items: center;
      }

      .el-collapse-item {
        margin-bottom: 36px;

        .el-collapse-item__header {
          max-width: 532px;
          margin: 0 auto;
          padding: 0 16px;
          border: 2px solid transparent;
          background-color: transparent;

          &.is-active {
            border-bottom: 2px solid var(--borderColor);
          }
        }
      }

      .el-collapse-item__wrap {
        border-bottom-color: #d2d2d2;
        background-color: transparent;
      }

      .el-collapse-item__content {
        max-width: 532px;
        margin: 0 auto;
        padding: 32px 16px 48px;
        background-color: transparent;
      }

      .el-collapse-item__arrow {
        font-family: "icomoon", serif !important;
        color: var(--arrowColor);

        &.is-active {
          transform: rotate(180deg);
          color: #6f6f6f;
        }

        &:before {
          content: "\e901";
        }
      }

      .listTitle {
        font-size: 24px;
        line-height: 28px;
        margin-left: 16px;
      }

      .contentBlock {
        --dot-color: #000;

        li {
          font-size: 16px;
          line-height: 22px;
          position: relative;
          margin-left: 16px;

          &,
          span,
          p {
            color: var(--dot-color);
          }

          &:before {
            position: absolute;
            top: 50%;
            left: -16px;
            width: 5px;
            height: 5px;
            content: "";
            transform: translateY(-50%);
            border-radius: 9999px;
            background-color: var(--dot-color);
          }
        }
      }
    }
  }
}
</style>
