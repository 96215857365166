<template>
  <div class="component component-bt06" :style="styleCSS">
    <a class="bt06__link" :href="toURL">
      <i class="link__icon" :class="`icon-${icon}`"></i>
      {{ content }}
    </a>
  </div>
</template>

<script>

export default {
  name: "BT06",
  props: {
    toURL: String,
    icon: String,
    content: String,
    // color
    iconColor: String,
    contentColor: String,
    // color
    position: String
  },
  computed: {
    styleCSS() {
      return {
        '--icon-color': this.iconColor,
        '--content-color': this.contentColor,
        '--position': this.position,
      }
    }
  }
}
</script>

<style scoped>
.component-bt06 {
  --icon-color: #FFF;
  --content-color: #FFF;
  --position: left;

  text-align: var(--position);
}

.bt06__link {
  display: inline-flex;
  font-weight: 900;
  align-items: center;
  font-size: 16px;
  line-height: 140%;
  color: var(--content-color);
}

.bt06__link .link__icon {
  font-size: 24px;
  margin-right: 10px;
  color: var(--icon-color);
}

</style>
