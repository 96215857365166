<script>
import DlsIcon from "@/components/web-page/DLS/Dls01/DlsIcon.vue";
import {
  Radio,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Input,
  Carousel,
  CarouselItem,
} from "element-ui";
import {
  weekOptions,
  serviceTimeOptions,
  subscriptionOptions,
} from "@/components/web-page/DLS/composables/Options";

import {
  CountryList,
  CityList,
} from "@/components/web-page/DLS/composables/CountryList";

export default {
  components: {
    Carousel,
    CarouselItem,
    DlsIcon,
    Radio,
    Checkbox,
    CheckboxGroup,
    Select,
    Option,
    Input,
  },
  props: {
    carouselImgList: Array,
    request: Object,
  },
  emits: ["onSave"],
  data() {
    return {
      weekOptions,
      serviceTimeOptions,
      CountryList,
      CityList,
      subscriptionOptions,

      selectedCountry: "",
      countryName: "",
      cityName: "",

      address: "",
    };
  },
  computed: {
    currentCityList() {
      return this.CityList[this.selectedCountry.key] || [];
    },
  },
  methods: {
    onCountryChange(countryName) {
      this.selectedCountry = CountryList.find((c) => c.label === countryName);
    },
    onSave() {
      this.$props.request.address = `${this.countryName}${this.cityName}${this.address}`;
      this.$emit("onSave");
    },
  },
};
</script>

<template>
  <div class="dls-first-page-container">
    <div class="icon-carousel-block">
      <div class="carousel">
        <Carousel height="398px">
          <CarouselItem v-for="(imgUrl, index) in carouselImgList" :key="index">
            <img :src="imgUrl" alt="img" style="margin: auto; height: 100%" />
          </CarouselItem>
        </Carousel>
      </div>

      <div class="icon">
        <DlsIcon />
      </div>
    </div>

    <div class="dls-select-option-table-container">
      <div class="title">小家庭方案</div>

      <div class="input-options-list">
        <div class="week-times">
          <div class="sub-title">每週收取次數</div>
          <div class="options">
            <Radio
              :label="item"
              v-for="item in 5"
              :key="item"
              v-model="request.weekTimes"
            >
              {{ item }}次
            </Radio>
          </div>
        </div>

        <div class="service-method">
          <div class="sub-title">服務方式</div>
          <div class="options">
            <Radio label="point" v-model="request.method"> 定時定點 </Radio>
            <Radio label="face" v-model="request.method"> 定時面交 </Radio>
          </div>
        </div>

        <div class="subscription-period">
          <div class="sub-title">訂購期間</div>
          <div class="options">
            <Radio
              v-for="(item, index) in subscriptionOptions"
              :key="index"
              :label="item.value"
              v-model="request.subscription"
            >
              {{ item.label }}
            </Radio>
          </div>
        </div>

        <div class="capacity">
          <div class="sub-title">垃圾容量</div>
          <div class="options">
            <Radio
              v-for="capacity in [15, 20, 30]"
              :key="capacity"
              :label="capacity"
              v-model="request.capacity"
            >
              {{ capacity }}公升
            </Radio>
          </div>
        </div>

        <div class="collect-day">
          <div class="sub-title">收取星期</div>
          <div class="options">
            <CheckboxGroup v-model="request.collectDay">
              <Checkbox
                :label="item.value"
                v-for="item in weekOptions"
                :key="item.value"
              >
                {{ item.label }}
              </Checkbox>
            </CheckboxGroup>
          </div>
        </div>

        <div class="address">
          <div class="sub-title">地址</div>
          <div class="options">
            <div class="address-dropdown">
              <Select
                placeholder="市"
                v-model="countryName"
                @change="onCountryChange"
              >
                <Option
                  :value="item.label"
                  v-for="item in CountryList"
                  :key="item.key"
                  >{{ item.label }}</Option
                >
              </Select>
              <Select placeholder="區域" v-model="cityName">
                <Option
                  :value="item.label"
                  v-for="item in currentCityList"
                  :key="item.key"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>

            <Input placeholder="請輸入完整地址" v-model="address" />
          </div>
        </div>

        <div class="service-time">
          <div class="sub-title">服務時間</div>
          <div class="options">
            <Radio
              :label="time.value"
              v-for="(time, index) in serviceTimeOptions"
              :key="index"
              v-model="request.weekTimes"
            >
              {{ time.label }}
            </Radio>
          </div>
        </div>

        <div class="location">
          <div class="sub-title">放置位子</div>
          <div class="options">
            <Input placeholder="請填寫放置位子" v-model="request.location" />
          </div>
        </div>

        <div class="note">
          <div class="sub-title">備註</div>
          <div class="options">
            <Input
              v-model="request.note"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              resize="none"
            />
          </div>
        </div>
      </div>

      <div class="table-footer">
        <div class="price-unit">
          <span class="price"> NT$ 600 </span>
          <span class="unit">/月</span>
        </div>

        <div class="submit-button" @click="onSave">立即訂購</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$primary-200: #082844;
$gray-200: #f0f0f0;
$gray-700: #6f6f6f;
$checked-color: #07b8dd;

.dls-first-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 36px;

  .icon-carousel-block {
    @media (max-width: 768px) {
      display: none;
    }
    width: 100%;
    max-width: 530px;
    height: 1043px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .carousel {
      .el-carousel {
        border-radius: 16px;
        :deep(.el-carousel__container) {
          width: 100%;
          max-width: 530px;
        }
      }
    }
  }
}

.dls-select-option-table-container {
  border-radius: 16px;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.15);
  padding: 36px;
  background: #fff;
  width: 100%;
  max-width: 530px;

  .title {
    font-size: 36px;
    font-weight: 700;
    border-bottom: 2px dashed #f0f0f0;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .input-options-list {
    display: grid;
    row-gap: 16px;
    .sub-title {
      color: $primary-200;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .options {
      gap: 8px;
      :deep(.el-radio) {
        margin-right: 16px;
        .el-radio__input {
          &.is-checked {
            .el-radio__inner {
              background-color: $checked-color;
              border-color: $checked-color;
            }
          }
        }
        .el-radio__label {
          font-size: 16px;
          padding-left: 4px;
          color: $gray-700;
        }
      }

      :deep(.el-checkbox-group) {
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 16px;

        .el-checkbox {
          margin-right: 0;
          .el-checkbox__input {
            &.is-checked {
              .el-checkbox__inner {
                background-color: $checked-color;
                border-color: $checked-color;
              }
            }
          }
          .el-checkbox__label {
            color: $gray-700;
          }
        }
      }

      :deep(.el-input) {
        .el-input__inner {
          border-radius: 100px;
          background-color: $gray-200;
          border: none;
          &::placeholder {
            color: $gray-700;
          }
        }
      }

      :deep(.el-textarea) {
        .el-textarea__inner {
          background-color: $gray-200;
          border: none;
          border-radius: 16px;
        }
      }

      .address-dropdown {
        display: flex;
        gap: 16px;
        margin-bottom: 8px;

        :deep(.el-select) {
          width: 100%;

          .el-input__inner {
            border-radius: 100px;
            background-color: $gray-200;
            border: none;
            &::placeholder {
              color: $gray-700;
            }
          }

          .el-input__suffix {
            .el-input__icon::before {
              content: "\e78f";
              color: $gray-700;
            }
          }
        }
      }
    }
  }

  .table-footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .price-unit {
      .price {
        color: #0065f2;
        font-size: 36px;
        font-weight: 700;
      }

      .unit {
        font-size: 20px;
      }
    }
    .submit-button {
      cursor: pointer;
      padding: 12px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      border-radius: 100px;
      border: 4px solid #ffffff;
      background-color: $checked-color;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
