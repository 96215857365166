import C03 from '@/components/web-page/Carousel/C03/index.vue';
import G1_1 from "@/layout/grid/G1_1";
import ComponentContainer from "@/layout/ComponentContainer.vue";

export default {
  components: {ComponentContainer},
  title: 'web-page/Carousel/C03',
  paramGroup: {
    buttonGroup: ['buttonColor', 'buttonClickColor'],
    hoverGroup: ['hoverBackgroundColor', 'hoverIcon', 'hoverIconColor']
  },
  argTypes: {
    items: {
      keyType: {
        imgSrc: 'image'
      },
      control: 'array',
      template: {imgSrc: '', alt: '', paddingRight: ''},
      defaultValue: [
        {
          imgSrc: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          alt: 'product',
        },
        {
          imgSrc: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          alt: 'product',
        },
        {
          imgSrc: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          alt: 'product',
        },
        {
          imgSrc: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          alt: 'product',
        },
        {
          imgSrc: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          alt: 'product',
        },
        {
          imgSrc: 'https://official-image.s3.ap-northeast-1.amazonaws.com/default/25x250.png',
          alt: 'product',
        },
      ],
    },
    link: {
      control: 'text',
      defaultValue: '/',
    },
    buttonColor: {
      control: 'color',
      defaultValue: '#001e46',
    },
    buttonIconColor: {
      control: 'color',
      defaultValue: '#001e46',
    },
    buttonClickColor: {
      control: 'color',
      defaultValue: '#000',
    },
    hoverBackgroundColor: {
      control: 'color',
      defaultValue: '#000',
    },
    hoverIcon: {
      control: 'text',
      defaultValue: 'heart',
    },
    hoverIconColor: {
      control: 'color',
      defaultValue: '#fff',
    },
    icon:  {
      control: 'text',
      defaultValue: 'icon-arrow-left',
    },
    aspectRatio: {
      control: 'select',
      options: ['auto', '1:1', '3:4', '4:3', '2:1'],
      defaultValue: 'auto',
    },
  },
};

const Template = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {C03, G1_1, ComponentContainer},
  template: `
    <G1_1>
      <ComponentContainer>
        <C03 v-bind="$props"/>
      </ComponentContainer>
    </G1_1>
  `,
});

export const c03 = Template.bind({});
c03.storyName = 'C03';
