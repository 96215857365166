import component from './index.vue';
import stories from './index.stories.js';
import { componentEnum } from '@/models/componentType';
import { G1 } from '@/models/gridType';

export default {
  componentGroup: componentEnum.Carousel,
  grids: { ...G1 },
  name: 'Carousel03',
  icon: 'mdi-view-dashboard',
  type: 'container',
  isNew: true,
  component: component,
  argTypes: stories.argTypes,
  paramGroup: stories.paramGroup
};
