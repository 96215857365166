import {ControlEnum} from "@/models/Enum/ControlEnum";

let config = {
  buttons: {
    search: true,
    login: true,
    cart: true,
    language: true
  }
}

let generateButtons = (config) => {
  let props = {}
  for (let key in config.buttons) {
    props[key] = {
      type: Boolean,
      default: config.buttons[key]
    }
  }
  return props;
}

//props轉換成argTypes，config需要預設值來源為
export let generateHeaderButtonsArgTypes = (obj) => {
  obj = obj || config;
  let argTypes = {}
  for (let key in obj.buttons) {
    argTypes[key] = {
      control: ControlEnum.BOOL,
      defaultValue: obj.buttons[key]
    }
  }
  return argTypes;
}
//mixins
export default {
  props: generateButtons(config),
  inject: '',
  provide() {
    return {
      headerButtonsStatus: () => ({
        search: this.search,
        login: this.login,
        cart: this.cart,
        language: this.language
      }),
      headerButtonsEvent: {
        onSearch: this.onSearch,
        onLogin: this.onLogin,
        onCart: this.onCart,
        onLanguage: this.onLanguage
      }
    }
  },
  methods: {
    onSearch(query) {
      this.$root.$emit('on-search', query);
    },
    onLanguage() {
    },
    onCart() {
      this.$root.$emit('side-cart', true);
    },
    onLogin() {
      window.location.href = '/login';
    },
  }
}
