<template>
  <div class="menu-container">
    <!--漢堡標按鈕-->
    <span class="line" @click="open = !open">
      <button class="menu-mobile" :class="{active:open}"></button>
    </span>

    <div class="container-body" :class="{active:open}">
      <div class="container" :class="{active: switchLang}">
        <!--搜尋按鈕-->
        <search v-if="searchStatus" class="mobile-show"/>
        <!--選單主體-->
        <ul class="list">
          <template v-for="(item, index) of items">
            <HeaderMenuItem v-bind="{item}"
                            :key="index"
                            :root-item="true"
                            :root-active="isActive"
                            :active-instance="active"
                            @active="onActive"
            />
          </template>
        </ul>
        <!--多國語系按鈕-->
        <language-btn v-if="languageStatus"
                      class="mobile-show"
                      @click="switchLang = $event"/>
      </div>

      <!--手機多國語系選單-->
      <div class="container-switch" >
        <div class="switch-lang" @click="switchLang = false">
          <i class="icon-arrow3-left"></i>
          <h2> 語言 </h2>
        </div>
        <language-list/>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderMenuItem from "./HeaderMenuItem.vue";
import Search from "./buttons/Search.vue";
import LanguageBtn from "./buttons/language/LanguageBtn.vue";
import LanguageList from "./buttons/language/LanguageList.vue";
import HeaderMenuMixin from "@/mixins/header/HeaderMenuMixin";

const Emit = {
  ACTIVE: 'active'
}

export default {
  name: "HeaderMenu",
  mixins: [HeaderMenuMixin],
  inject: ['headerButtonsStatus'],
  components: {LanguageList, LanguageBtn, Search, HeaderMenuItem},
  computed: {
    searchStatus() {
      return this.headerButtonsStatus().search;
    },
    languageStatus() {
      return this.headerButtonsStatus().language;
    },
  },
  watch: {
    open(val) {
      this.$emit(Emit.ACTIVE, val);
    }
  },
  data() {
    return {
      switchLang: false,
      open: false
    }
  }
}
</script>

<style scoped src="../css/header.menu.css"></style>
