import PT05 from "@/components/web-page/PictureText/PT05/index.vue";
import { generateTextArgTypes } from "@/components/base/javaScript/baseTest";

export default {
    title: "web-page/PictureText/PT05",
    argTypes: {
        ...generateTextArgTypes("title", {
            contentDefault: "我是大標題我",
            sizeDefault: "20px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        ...generateTextArgTypes("subTitle", {
            contentDefault: "我是小標題我",
            sizeDefault: "12px",
            colorDefault: "black",
            spacingDefault: "1px",
        }),
        verticalAlignOption: {
            control: "select",
            options: ["top", "center", "bottom"],
            defaultValue: "center",
        },
        textFull: {
            control: "boolean",
            defaultValue: false,
        },
        logoURL: {
            control: {
                type: "text",
                editType: "image",
            },
            defaultValue:
                "https://official-image.s3.ap-northeast-1.amazonaws.com/default/Logo+(1_1).png",
        },
        items: {
            keyType: {
                iconURL: "image",
            },
            control: "array",
            template: {
                iconURL: "https://fakeimg.pl/32/eaeaea/ffd700",
                itemTitle: "我是小標題",
                contentContent:
                    "提供無限上架商品功能、會員系統、金物流等功能，讓你開店無煩惱",
                contentFontColor: "black",
                contentFontSize: "12px",
                contentPadFontSize: null,
                contentDisktopFontSize: null,
                contentLineSpacing: null,
            },
            defaultValue: [
                {
                    iconURL: "https://fakeimg.pl/32/eaeaea/ffd700",
                    itemTitle: "我是小標題",
                    contentContent:
                        "提供無限上架商品功能、會員系統、金物流等功能，讓你開店無煩惱",
                    contentFontColor: "black",
                    contentFontSize: "12px",
                    contentPadFontSize:null,
                    contentDisktopFontSize: null,
                    contentLineSpacing: null,
                },
                {
                    iconURL: "https://fakeimg.pl/32/eaeaea/ffd700",
                    itemTitle: "我是小標題",
                    contentContent:
                        "提供無限上架商品功能、會員系統、金物流等功能，讓你開店無煩惱",
                    contentFontColor: "black",
                    contentFontSize: "12px",
                    contentPadFontSize: null,
                    contentDisktopFontSize: null,
                    contentLineSpacing: null,
                },
                {
                    iconURL: "https://fakeimg.pl/32/eaeaea/ffd700",
                    itemTitle: "我是小標題",
                    contentContent:
                        "提供無限上架商品功能、會員系統、金物流等功能，讓你開店無煩惱",
                    contentFontColor: "black",
                    contentFontSize: "12px",
                    contentPadFontSize: null,
                    contentDisktopFontSize: null,
                    contentLineSpacing: null,
                },
            ],
        },
    },
};

// PT01
const TemplatePT01 = (args, { argTypes }) => ({
    props: Object.keys(argTypes),
    components: { PT05 },
    template: `
    <div style="width: 960px; margin:auto;">
    <PT05 v-bind="$props"/>
    </div>
  `,
});

export const pt05_1 = TemplatePT01.bind({});
// pt05_1.args = {
//     title: null,
// };

export const pt05_2 = TemplatePT01.bind({});
// pt05_2.args = {
//     textFull: true,
//     items: [
//         {
//             iconURL: "https://fakeimg.pl/32/eaeaea/ffd700",
//             content:
//                 "智勇爭奮，師出有名。\n 每個案件都有不同的故事，而本所每位律師的專長領域各有 \n不同，能陪伴當事人解決各項法律問題。",
//         },
//     ],
// };
