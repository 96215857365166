import component from './index.vue';
import stories from './index.stories.js';
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";

export default {
  componentGroup: componentEnum.PictureText,
  grids: {...GridEnum},
  name: 'PictureText03',
  icon: 'mdi-view-dashboard',
  type:'container',
  component: component,
  argTypes: stories.argTypes
}
