import {apiURL, langTag, addCart, token, refURL, errorMsgFn, successMsgFn} from "@/injectKey";

export default {
  inject: {
    token: {
      from:  token,
      default: null
    },
    refURL: {
      from:  refURL,
      default: null
    },
    apiURL: {
      from: apiURL,
      default: "https://test-ecommerce-front-desk.shopinn.tw/web/api/"
    },
    langTag: {
      from:  langTag,
      default: "zh-TW"
    },
    addCart: {
      from: addCart,
      default() {
        return (id) =>  console.log('addCart' + id);
      }
    },
    //msg
    successMsgFn: {
      from: successMsgFn,
      default() {
        return (msg) => alert(msg);
      }
    },
    errorMsgFn: {
      from: errorMsgFn,
      default() {
        return (msg) => alert(msg);
      }
    }
  },
  watch: {
    apiURL(new_api) {
      console.log('new_api', new_api);
      console.log('new_api', this.getData);
      this.getData();
    },
    langTag() {
      this.getData();
    }
  },
  methods: {
    getData() {
      //TODO 是否要丟出錯誤
      throw Error('getData')
    }
  }
}
