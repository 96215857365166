import {baseFormField} from "@/components/functional/key";
import {controlType} from "@/models/controlType";

let global = 0;
export default {
  name: "FormField",
  props: {
    title: {
      type: String,
      default: "標題"
    },
    placeholder: {
      type: String,
      default: "你的回答",
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      controlType: controlType.INPUT,
      keyName: 'parameter' + (global++)
    }
  },
  inject: {
    formInjected: {
      from: baseFormField,
      default: () => {
      }
    }
  },
  watch: {},
  computed: {
    invalid() {
      return this.formInjected?.dataCheck ?
        this.formInjected?.dataCheck[this.keyName] :
        false;
    },
    value() {
      return this?.formInjected?.formData ?
        this?.formInjected?.formData[this.keyName]?.value :
        null;
    }
  },
  methods: {
    setValue(val) {
      this?.formInjected?.setObject(this.keyName, {value: val});
    },
    checkValue() {
      let invalid = false;

      if (this.required) {
        invalid = this.value == undefined || this.value === "" ||  this.value.length === 0;
      }
      console.log(this.controlType, this.value)
      this.formInjected?.checkFieldInvalid(this.keyName, invalid);
    }
  },
  mounted() {
    console.log('mounted textarea');
    let defValue = this.value == undefined ? {
      value: null,
      name: this.title,
      required: this.required,
      type: this.controlType
    } : this.value;


    this.formInjected?.checkFieldInvalid(this.keyName, false);
    this.formInjected?.setObject(this.keyName, defValue);
    this.formInjected?.$on('submit', () => {
      this.checkValue();
    });
  }
}
