<template>
  <header class="main-header header-color header-h01" :style="styleColor">
    <a class="main-header__logo" href="/">
      <img :src="logoURL" alt="logo" />
    </a>
    <div class="main-header__nav-list">
      <RecursiveNavList :items="list" />
    </div>
    <header-operate v-bind="$props">
      <template #other>
        <mobile-recursive-nav-list
          v-bind="{ ...$attrs, ...$props }"
          :items="list"
        />
      </template>
    </header-operate>
  </header>
</template>

<script>
import {
  headerColor,
  operateProps,
  testData,
} from "@/models/headerColorSetting";
import HeaderOperate from "@/components/web-page/Header/H01/HeaderOperate";
import RecursiveNavList from "./RecursiveNavList";
import MobileRecursiveNavList from "./MobileRecursiveNavList";

export default {
  name: "Header01",
  components: { MobileRecursiveNavList, HeaderOperate, RecursiveNavList },
  mixins: [headerColor, operateProps],
  props: {
    logoURL: String,
    list: {
      type: Array,
      default: () => testData,
    },
  },
};
</script>

<style scoped src="@/assets/css/variable/headerColor.css"></style>
<style scoped>
.main-header {
  background-color: var(--bg-color);
  display: flex;
  z-index: 999;

  justify-content: space-between;
  padding: 0 16px;
}

.main-header::after {
  content: "";
  height: 6px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.main-header__logo {
  display: block;
  width: 16.64%;
  min-width: 115px;
  padding: 12px 0;
}

.main-header__logo img {
  width: 115px;
}

.main-header__nav-list {
  display: none;
  margin: auto;
  height: 100%;
}

.nav-list-item {
  display: none;
}

@media screen and (min-width: 1200px) {
  .main-header {
    padding: 0 48px;
  }

  .main-header__nav-list {
    display: block;
  }

  .nav-list-item {
    display: block;
  }
}
</style>
