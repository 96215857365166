<template>
  <div class="base-html-text">
    <iframe ref="iframe" width="100%" :height="height"/>
  </div>
</template>

<script>
// import BaseVueShadow from "@/components/base/BaseVueShadow";

export default {
  name: "BaseHtmlText",
  components: {},
  props: {
    content: {type: String, default: ""},
  },
  data() {
    return {
      height: 0,
      iframe: null,
      body: null
    }
  },
  watch: {
    content(val) {
      this.setText(this.body, val);
    }
  },
  methods: {
    setMeta(content_document, head) {
      let meta = content_document.createElement('meta');
      meta.charset = "utf-8";
      head.append(meta);
    },
    setText(body, content) {
      this.height = 0;
      body.innerHTML = content;
      setTimeout(()=> {
        this.height = body.scrollHeight + 'px';
      }, 0);
    }
  },
  mounted() {
    const iframe = this.$refs.iframe;
    const content_window = iframe.contentWindow;
    const content_document = content_window.document;
    const head = content_document.querySelector('head');
    const body = content_document.querySelector('body');

    this.body = body;
    this.body.style.margin = 0;
    this.body.style.padding = 0;


    this.setText(body, this.content);
    this.setMeta(content_document, head);

    window.addEventListener('resize', ()=> {
      setTimeout(()=> {
        this.height = body.scrollHeight + 'px';
      }, 0);
    })
  }
};
</script>

<style scoped>
.base-html-text {
  width: 100%;
  overflow: hidden;
}
</style>
