import { ControlEnum } from "@/models/Enum/ControlEnum";

/**
 *
 * @param block 區塊名稱-必填
 * @param [options]
 * @param [options.content]
 * @param [options.color]
 * @param [options.size]
 * @param [options.spacing]
 * @param [options.lineSpacing]
 * @param [options.fontWeight]
 * @return {{}}
 */
export function generateTextProps(block, options) {
  let props = {};
  props[options?.content || `${block}Content`] = String;
  props[options?.color || `${block}FontColor`] = String;
  props[options?.size || `${block}FontSize`] = String;
  props[options?.size || `${block}PadFontSize`] = String;
  props[options?.size || `${block}DesktopFontSize`] = String;
  props[options?.spacing || `${block}Spacing`] = String;
  props[options?.lineSpacing || `${block}LineSpacing`] = String;
  props[options?.fontWeight || `${block}FontWeight`] = String;
  return props;
}

/**
 *
 * @param block
 * @param [options]
 * @param [options.hiddeContent]
 * @param [options.content]
 * @param [options.contentDefault]
 * @param [options.color]
 * @param [options.colorDefault]
 * @param [options.size]
 * @param [options.sizeDefault]
 * @param [options.padSize]
 * @param [options.padSizeDefault]
 * @param [options.desktopSize]
 * @param [options.desktopSizeDefault]
 * @param [options.spacing]
 * @param [options.spacingDefault]
 * @param [options.lineSpacing]
 * @param [options.lineSpacingDefault]
 * @param [options.fontWeight]
 * @param [options.fontWeightDefault]
 * @return {{}}
 */
export function generateTextArgTypes(block, options) {
  let props = {};

  if (!options?.hiddeContent) {
    props[options?.content || `${block}Content`] = {
      control: ControlEnum.TEXT,
      defaultValue: options?.contentDefault || "",
    };
  }

  props[options?.color || `${block}FontColor`] = {
    control: ControlEnum.COLOR,
    defaultValue: options?.colorDefault || "#000000",
  };
  props[options?.size || `${block}FontSize`] = {
    control: ControlEnum.TEXT,
    defaultValue: options?.sizeDefault || null,
  };
  props[options?.padSize || `${block}PadFontSize`] = {
    control: ControlEnum.TEXT,
    defaultValue: options?.padSizeDefault || null,
  };
  props[options?.desktopSize || `${block}DesktopFontSize`] = {
    control: ControlEnum.TEXT,
    defaultValue: options?.desktopSizeDefault || null,
  };
  props[options?.spacing || `${block}Spacing`] = {
    control: ControlEnum.TEXT,
    defaultValue: options?.spacingDefault || null,
  };
  props[options?.lineSpacing || `${block}LineSpacing`] = {
    control: ControlEnum.TEXT,
    defaultValue: options?.lineSpacingDefault || null,
  };
  props[options?.fontWeight || `${block}FontWeight`] = {
    control: ControlEnum.TEXT,
    defaultValue: options?.fontWeightDefault || null,
  };

  return props;
}
