<template>
  <div class="show__block" :style="{gap: gap + 'px', 'grid-template-columns': `repeat(${columns},[col-start] minmax(100px, 1fr) [col-end])`}">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "LayoutGrid",
  props: {
    gap: null,
    columns: {
      type: String,
      default: "1"
    }
  }
}
</script>

<style scoped lang="scss">
.show__block {
  position: relative;
  display: grid;
  width: 100%;
  grid-auto-rows: minmax(0, auto);
}

@media screen and (max-width: 800px) {
  .show__block {
    grid-template-columns: repeat(1, 100%) !important;
    gap: 60px !important;
  }

  .show__block > * {
    grid-column: auto !important;
    grid-row: auto !important;
  }
}
</style>
