<template>
    <div class="component component-t06" :style="style">
        <!-- title -->
        <h2 v-if="titleContent !== ''" class="t06__titleContainer">
            <base-text block-name="title" v-bind="$props" />
        </h2>

        <hr  v-if="titleContent !== ''" class="t06__line" />

        <!-- subTitle -->
        <div  v-if="subTitleContent !== ''" class="t06__subTitleContainer">
            <base-text block-name="subTitle" v-bind="$props" />
        </div>
        <!-- content-->
        <div  v-if="contentContent !== ''" class="t06__contentContainer">
            <base-text block-name="content" v-bind="$props" />
        </div>
    </div>
</template>

<script>
import BaseText from "@/components/base/BaseText.vue";
import { generateTextProps } from "@/components/base/javaScript/baseTest";

export default {
    name: "Text06",
    components: {
        BaseText,
    },
    props: {
        ...generateTextProps("title"),
        ...generateTextProps("subTitle"),
        ...generateTextProps("content"),
        lineColor: String,
        horizontalAlignOption: String,
        verticalAlignOption: String,
    },
    methods: {
        alignPosition(val){
            let result
            switch (val) {
                case "left":
                    result = "flex-start" 
                    break;
                case "top":
                    result = "flex-start" 
                    break;
                case "center":
                    result = "center" 
                    break;
                case "right":
                    result = "flex-end" 
                    break;
                case "bottom":
                    result = "flex-end" 
                    break;
                default:
                    result="center"
                    break;
            }
            return result
        },
    },
    computed: {
        style(){
            return {
                "--horizontal-align-option": this.alignPosition(this.horizontalAlignOption),
                "--vertical-align-option": this.alignPosition(this.verticalAlignOption),
                "--content-align": this.horizontalAlignOption,
                "--line-color": this.lineColor,
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.component-t06 {
    display: flex;
    flex-direction: column;
    max-width: 1360px;
    height: 100%;
    align-items: var(--horizontal-align-option);
    justify-content: var(--vertical-align-option);
    margin: 0 auto;
}

.t06__titleContainer {
    font-weight: 700;
}

.t06__line {
    height: 2px;
    width: 24px;
    background-color: var(--line-color);
    border: none;
}

.t06__subTitleContainer {
    font-weight: 400;
    margin: 8px 0;
}

.t06__contentContainer {
    max-width: 1064px;
    font-weight: 400;
    text-align: var(--content-align);
}

@media screen and (max-width: 1100px) {
    .t06__contentContainer {
        width: 80%;
        text-align: justify;
    }
}
</style>
