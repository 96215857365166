import component from "./index.vue";
import stories from "./index.stories.js";
import {componentEnum} from "@/models/componentType";
import {GridEnum} from "@/models/gridType";

export default {
  componentGroup: componentEnum.Picture,
  grids: {...GridEnum},
  name: "picture03",
  icon: "mdi-view-dashboard",
  type: "container",
  isNew: true,
  component: component,
  argTypes: stories.argTypes,
};
