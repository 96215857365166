<template>
  <div class="component component-table-01">
    <div class="select-option-container">
      <div
        class="select-option"
        v-for="item in options"
        :key="item.value"
        @click="setSelectedDataKey(item.value)"
        :style="setOptionsBackground(item.value)"
      >
        <span class="option-label">{{ item.name }}</span>
      </div>
    </div>

    <div class="table-list" v-if="currentSelectedOptionData">
      <div
        class="list-item"
        :style="currentTableBackgroundColor"
        v-for="(item, index) in currentSelectedOptionData"
        :key="index"
      >
        <span class="label" :style="{ color: tableTextColor }">
          {{ item.label }}
        </span>
        <span class="value" :style="{ color: tableTextColor }">
          {{ item.value }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/form.css";

export default {
  name: "Table01",
  props: {
    options: Array,
    tableDataList: Array,
    optionActiveColor: String,
    tableBackgroundColor: String,
    tableTextColor: String,
  },
  data() {
    return {
      selectedOptionDataKey: "",
    };
  },
  computed: {
    currentTableBackgroundColor() {
      return {
        "background-color": this.tableBackgroundColor,
      };
    },
    currentSelectedOptionData() {
      const selectedOptionData = this.tableDataList.find(
        (item) => item.name === this.selectedOptionDataKey
      );
      console.log('selectedOptionData', selectedOptionData)

      if (!selectedOptionData) return [];
      return JSON.parse(selectedOptionData.value);
    },
  },
  methods: {
    setOptionsBackground(optionDataKey) {
      const isActive = this.selectedOptionDataKey === optionDataKey;

      return {
        "background-color": "currentColor",
        color: isActive ? this.optionActiveColor : "#ffffff",
      };
    },
    setSelectedDataKey(value) {
      this.selectedOptionDataKey = value;
    },
  },
  mounted() {
    this.selectedOptionDataKey = this.options[0].value;
  },
};
</script>

<style scoped lang="scss">
$MOBILE_SIZE: 700px;

.component-table-01 {
  width: 100%;
  border-radius: 24px;
  padding: 24px;
  background-color: #ffffff;

  .select-option-container {
    display: flex;
    gap: 16px;
    padding: 0 30px;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: $MOBILE_SIZE) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding: 10px 0;
      justify-content: flex-start;
    }

    .select-option {
      user-select: none;
      cursor: pointer;
      padding: 9px 12px;
      border-radius: 100px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

      .option-label {
        word-break: keep-all;
        white-space: nowrap;
        filter: grayscale(1) contrast(999) invert(1);
      }
    }
  }
  .table-list {
    border-radius: 16px;
    margin-top: 24px;

    .list-item {
      padding: 12px 24px;
      display: flex;
      &:first-child {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      &:last-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &:nth-child(even) {
        background-color: #ffffff !important;
        span {
          color: #000000 !important;
        }
      }

      .label {
        width: 70%;
        text-align: left;
        word-break: break-all;
      }

      .value {
        width: 30%;
        text-align: right;
      }
    }
  }
}
</style>
