<template>
    <div class="component component-t08" :style="style">
        <table class="t08__table">
            <tr v-for="item of list" :key="item.title">
                <td>{{ item.title }}</td>
                <td>{{ item.content }}</td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: "Text08",
    components: {
    },
    props: {
        list: Array,
    },
    computed: {
        style() {
            return {
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.component-t08 {
    max-width: 1360px;
    margin: 48px auto; 
}

table{
    margin: 0 auto;
}

table, tr, td{
    max-width: 1064px;
    border: 1px solid black;
}

table td {
    font-size: 16px;
    padding: 12px;
}

table tr>td:first-child{
    width: 20%;
    font-size: 24px;
    font-weight: 700;
}


@media screen and (max-width: 800px) {
table, tr, td{
    max-width: 626px;
}
}

</style>

