import T02 from '@/components/web-page/Text/T02/index.vue';


export default {
  title: 'web-page/Text/T02',
  argTypes: {
    title: {
      control: "text",
      defaultValue: "標題"
    },
    subTitle: {
      control: "text",
      defaultValue: "副標題"
    },
    content: {
      control: "text",
      defaultValue: "內容內容內容內容內容"
    },
    isContentMiddle: {
      control: "boolean",
      defaultValue: false
    },
    isTitleBold: {
      control: "boolean",
      defaultValue: true
    },
    titleColor: {
      control: "color",
      defaultValue: null
    },
    subTitleColor: {
      control: "color",
      defaultValue: null
    },
    borderColor: {
      control: "color",
      defaultValue: null
    },
    contentColor: {
      control: "color",
      defaultValue: null
    }
  }
}

// T03
const TemplateT03 = (args, {argTypes}) => ({
  props: Object.keys(argTypes),
  components: {T02},
  template: `<T02 v-bind="$props" />`
});

export const t02 = TemplateT03.bind({});

