<template>
  <a class="pic01" :href="link">
    <BaseImage :imgSrc="imgSrc" :alt="alt" :width="width"></BaseImage>
  </a>
</template>

<script>
import BaseImage from "@/components/base/BaseImage/BaseImage.vue";

export default {
  name: "pic01",
  components: { BaseImage },
  props: {
    imgSrc: String,
    alt: String,
    width: String,
    link: String,
  },
};
</script>

<style scoped>
.pic01 {
  display: block;
  width: 100%;
}
</style>
